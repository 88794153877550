import React from "react";
import { Segment, List, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import NextLoader from "NextLoader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import { selectToken } from "slices/tokenSlice";
import {
  selectUserInfo,
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  hasCapability,
  CAN_IMPORT,
  CAN_MANAGE_TASKS,
  CAN_REMOVE_TASKS,
  CAN_ACCESS_ALL_ANALYSIS_DATA,
  isTaskRunning,
  isDataTask,
  hasTaskFailed,
  hasTaskFinished,
  hasTaskFinishedOk,
  canTaskBeRestarted,
  canTaskBeRemoved,
} from "slices/userDataSlice";
import {
  selectAll as selectTasks,
  selectEntities as selectTasksMap,
  selectTasksOngoing,
  selectTasksNext,
  getTasks,
  getNextTasks,
  getUpdatedTasks,
  archiveTasks,
  removeTasks,
  restartTask,
} from "slices/tasksSlice";
import { selectReportOngoing, createTaskReport } from "slices/reportSlice";

import { goTo, parseDatetime, numberToPercentage, toggleSet } from "utils";
import { newHierarchyTo, newAnalysisTo, taskTo, analysesTo } from "appRoutes";
import {
  ADD_HIERARCHY_ICON,
  ADD_ANALYSIS_ICON,
  ARCHIVE_ICON,
  REMOVE_ICON,
  REPORT_ICON,
  DETAILED_REPORT_ICON,
  REFRESH_ICON,
  SELECT_ICON,
  SELECTED_ICON,
  // DIAGNOSES_ICON,
  ANALYSES_ICON,
  RESTART_ICON,
  toIcon,
  taskIcon,
} from "icons";

const FIRST_UPDATE = 5000;
const UPDATE_INTERVAL = 30 * 1000;

const TASKS = "Tasks/tasks";

class Tasks extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      selected: new Set(),
      promptArchive: false,
      promptRemove: false,
    });
  }

  componentDidMount() {
    this.refresh();
    this.intervalID = setInterval(this.tick, UPDATE_INTERVAL);
    this.firstUpdate();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearTimeout(this.timerID);
  }

  refresh = () => {
    const { getTasks, t } = this.props;
    getTasks(opts(TASKS, t("Tasks.refreshError"))).catch((e) => {
      console.error(e);
    });
  };

  firstUpdate = () => {
    if (!this.timerID) {
      this.timerID = setTimeout(() => this.tick(), FIRST_UPDATE);
    }
  };

  tick = () => {
    this.props.getUpdatedTasks().catch((e) => {
      console.error(e);
    });
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(TASKS);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh();
  };

  handleSelect = (_, { value }) => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => ({
      selected: toggleSet(value, state.selected),
    }));
  };

  handleRestart = async (_, { value: task }) => {
    const { restartTask, t } = this.props;
    restartTask(task, opts(TASKS, t("Tasks.restartError"))).catch((e) => {
      console.error(e);
    });
    this.firstUpdate();
  };

  handleAddHierarchy = () => {
    const { location, history } = this.props;
    goTo(newHierarchyTo(location), history);
  };

  handleAddAnalysis = () => {
    const { location, history } = this.props;
    goTo(newAnalysisTo(location), history);
  };

  handleArchive = () => this.setState({ promptArchive: true });
  cancelArchive = () => this.setState({ promptArchive: false });
  // handleRemove = () => this.setState({ promptRemove: true });
  // cancelRemove = () => this.setState({ promptRemove: false });

  handleArchiveOne = (_, { value }) => {
    const { archiveTasks, t } = this.props;
    archiveTasks([value], opts(TASKS, t("Tasks.archiveError"))).catch((e) => {
      console.error(e);
    });
  };

  handleRemoveOne = (_, { value }) => {
    this.setState({ taskToRemove: value, promptRemove: true });
    // const { removeTasks, t } = this.props;
    // removeTasks([value], opts(TASKS, t("Tasks.removeError"))).catch((e) => {
    //   console.error(e);
    // });
  };
  cancelRemove = () =>
    this.setState({ taskToRemove: undefined, promptRemove: false });

  okArchive = () => {
    const { archiveTasks, t } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    this.setState({ selected: new Set(), promptArchive: false });
    archiveTasks(tasks, opts(TASKS, t("Tasks.archiveError"))).catch((e) => {
      console.error(e);
    });
  };

  okRemove = () => {
    const { removeTasks, t } = this.props;
    const { taskToRemove } = this.state;
    this.setState({ taskToRemove: undefined, promptRemove: false });
    removeTasks([taskToRemove], opts(TASKS, t("Tasks.removeError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  handleAnalyses = async () => {
    const { location, history } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    goTo(analysesTo({ tasks }, location), history);
  };

  _handleReport = (reportDetail) => {
    const { createTaskReport, t } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    this.setState({ selected: new Set() });
    createTaskReport(
      tasks,
      reportDetail,
      opts(TASKS, t("Tasks.reportError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  handleReport = () => this._handleReport("advanced");
  handleExpertReport = () => this._handleReport("expert");

  taskStatus = (task) => {
    const { schema, t } = this.props;
    if (isTaskRunning(task, schema)) {
      return (
        <span className="icon Tasks__progress" data-cy="Tasks__running">
          {t("Tasks.progress", {
            progress: numberToPercentage(task.progress),
          })}
        </span>
      );
    } else {
      return (
        <i className="icon Tasks__status">
          {taskIcon(task, schemaKey(schema, "task_status_groups"))}
        </i>
      );
    }
  };

  getNextTasks = () => {
    const { getNextTasks, t } = this.props;
    getNextTasks(opts(TASKS, t("Tasks.moreError"))).catch((e) => {
      console.error(e);
    });
  };

  moreTasks = () => {
    const { tasksNext, t } = this.props;
    return tasksNext
      ? [
          <List.Item
            key={tasksNext}
            as={NextLoader}
            loadNext={this.getNextTasks.bind(this)}
            more={<p className="Tasks__more">{t("Tasks.moreTasks")}</p>}
          />,
        ]
      : [];
  };

  taskItems = memoizeOne(
    (tasks, selected, canRemove, location, _tasksNext, _language) => {
      const { schema, t } = this.props;
      return tasks
        .filter((t) => !t.archived || canRemove)
        .map((task) => {
          const archived = task.archived;
          const dataTask = isDataTask(task, schema);
          const canBeRestarted = canTaskBeRestarted(task, schema);
          const canBeArchived = canTaskBeRemoved(task, schema);
          const canBeRemoved = canBeArchived;
          const finished = hasTaskFinished(task, schema);
          const finishedOk = hasTaskFinishedOk(task, schema);
          const failed = hasTaskFailed(task, schema);
          return (
            <List.Item
              className="Tasks__task"
              key={task.id}
              value={String(task.id)}
              data-cy="Tasks__task"
            >
              {this.taskStatus(task)}
              <List.Content>
                <List.Header>
                  <NavLink to={taskTo(task.id, location)}>
                    {t("Tasks.description", {
                      created_at: parseDatetime(task.created_at),
                      username: task.username,
                    })}
                  </NavLink>
                </List.Header>
                <List.Description>{task.label}</List.Description>
              </List.Content>
              {!archived && !finished && canBeRestarted && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleRestart}
                    circular
                    compact
                    basic
                    value={task.id}
                  >
                    {toIcon(RESTART_ICON)}
                  </Button>
                </span>
              )}
              {!archived && !finished && canBeArchived && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleArchiveOne}
                    circular
                    compact
                    basic
                    value={task.id}
                    data-cy="Tasks__archiveOne"
                  >
                    {toIcon(ARCHIVE_ICON)}
                  </Button>
                </span>
              )}
              {!archived && dataTask && finishedOk && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleSelect}
                    circular
                    compact
                    basic
                    value={task.id}
                    data-cy="Tasks__select"
                  >
                    {toIcon(
                      selected.has(task.id) ? SELECTED_ICON : SELECT_ICON
                    )}
                  </Button>
                </span>
              )}
              {!archived && dataTask && failed && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleArchiveOne}
                    circular
                    compact
                    basic
                    value={task.id}
                    data-cy="Tasks__archiveOne"
                  >
                    {toIcon(ARCHIVE_ICON)}
                  </Button>
                </span>
              )}
              {!archived && !dataTask && finished && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleArchiveOne}
                    circular
                    compact
                    basic
                    value={task.id}
                    data-cy="Tasks__archiveOne"
                  >
                    {toIcon(ARCHIVE_ICON)}
                  </Button>
                </span>
              )}
              {archived && canBeRemoved && (
                <span className="Tasks__action">
                  <Button
                    type="button"
                    className="Tasks__tool"
                    icon
                    onClick={this.handleRemoveOne}
                    circular
                    compact
                    basic
                    value={task.id}
                    data-cy="Tasks__removeOne"
                  >
                    {toIcon(REMOVE_ICON)}
                  </Button>
                </span>
              )}
            </List.Item>
          );
        })
        .concat(this.moreTasks());
    }
  );

  selectedTasksOk = () => {
    const { schema, tasksMap } = this.props;
    const { selected } = this.state;
    return [...selected].every((t) => hasTaskFinishedOk(tasksMap[t], schema));
  };

  render() {
    const {
      serviceError,
      getTasksOngoing,
      archiveTasksOngoing,
      removeTasksOngoing,
      restartTaskOngoing,
      createReportOngoing,
      tasks,
      tasksNext,
      canAdd,
      canArchive,
      canRemove,
      canCreateExpertReport,
      location,
      i18n: { language },
      t,
    } = this.props;
    const { selected, promptArchive, promptRemove } = this.state;

    const loading =
      getTasksOngoing ||
      archiveTasksOngoing ||
      removeTasksOngoing ||
      restartTaskOngoing ||
      createReportOngoing;

    const taskItems = this.taskItems(
      tasks,
      selected,
      canRemove,
      location,
      tasksNext,
      language
    );

    const someSelected = selected.size > 0;
    const selectedTasksOk = someSelected && this.selectedTasksOk();
    const canAddTask = canAdd;
    const canReport = selectedTasksOk;
    const canAccessAnalyses = selectedTasksOk;
    const canArchiveTasks = canArchive && someSelected;

    return (
      <Segment
        as="div"
        className="Tasks"
        loading={loading}
        data-cy="Tasks"
      >
        <Prompt
          open={promptArchive}
          header={t("Tasks.archivePrompt.header")}
          content={t("Tasks.archivePrompt.content")}
          okText={t("Tasks.archivePrompt.ok")}
          cancelText={t("Tasks.archivePrompt.cancel")}
          onOk={this.okArchive}
          onCancel={this.cancelArchive}
        />
        <Prompt
          open={promptRemove}
          header={t("Tasks.removePrompt.header")}
          content={t("Tasks.removePrompt.content")}
          okText={t("Tasks.removePrompt.ok")}
          cancelText={t("Tasks.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />

        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <div className="Tasks__tools">
          <span>
            <Button
              type="button"
              className="Tasks__tool"
              icon
              onClick={this.handleAddHierarchy}
              compact
              basic
              disabled={!canAddTask}
              data-cy="Tasks__addHierarchy"
            >
              {toIcon(ADD_HIERARCHY_ICON)}
            </Button>
            <Button
              type="button"
              className="Tasks__tool"
              icon
              onClick={this.handleAddAnalysis}
              compact
              basic
              disabled={!canAddTask}
              data-cy="Tasks__addAnalysis"
            >
              {toIcon(ADD_ANALYSIS_ICON)}
            </Button>
            <Button
              type="button"
              className="Tasks__tool"
              icon
              onClick={this.handleArchive}
              compact
              basic
              disabled={!canArchiveTasks}
              data-cy="Tasks__archive"
            >
              {toIcon(ARCHIVE_ICON)}
            </Button>
            <Button
              type="button"
              className="Tasks__tool"
              icon
              onClick={this.handleAnalyses}
              compact
              basic
              disabled={!canAccessAnalyses}
              data-cy="Tasks__diagnoses"
            >
              {toIcon(ANALYSES_ICON)}
            </Button>
            <Button
              type="button"
              className="Tasks__tool"
              icon
              onClick={this.handleReport}
              compact
              basic
              disabled={!canReport}
              data-cy="Tasks__report"
            >
              {toIcon(REPORT_ICON)}
            </Button>
            {canCreateExpertReport && (
              <Button
                type="button"
                className="Tasks__tool"
                icon
                onClick={this.handleExpertReport}
                compact
                basic
                disabled={!canReport}
                data-cy="Tasks__detailed_report"
              >
                {toIcon(DETAILED_REPORT_ICON)}
              </Button>
            )}
            <Button
              type="button"
              className="Tasks__tool"
              icon
              compact
              basic
              onClick={this.handleRefresh}
              data-cy="ImporterHome__refresh"
            >
              {toIcon(REFRESH_ICON)}
            </Button>
          </span>
        </div>
        {taskItems.length === 0 && <p>{t("Tasks.noTasks")}</p>}
        {taskItems.length > 0 && <List>{taskItems}</List>}
      </Segment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const userInfo = selectUserInfo(state);
  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  return {
    serviceError: selectServiceError(state, TASKS),
    getTasksOngoing: selectTasksOngoing(state, "getTasks"),
    getUpdatedTasksOngoing: selectTasksOngoing(state, "getUpdatedTasks"),
    archiveTasksOngoing: selectTasksOngoing(state, "archiveTasks"),
    removeTasksOngoing: selectTasksOngoing(state, "removeTasks"),
    restartTaskOngoing: selectTasksOngoing(state, "restartTask"),
    tasksNext: selectTasksNext(state),
    createReportOngoing: selectReportOngoing(state, "createTaskReport"),
    tasks: selectTasks(state),
    tasksMap: selectTasksMap(state),
    token: selectToken(state),
    canAdd: hasCapability(state, CAN_IMPORT),
    canArchive: hasCapability(state, CAN_MANAGE_TASKS),
    canRemove: hasCapability(state, CAN_REMOVE_TASKS),
    canCreateExpertReport: hasCapability(state, CAN_ACCESS_ALL_ANALYSIS_DATA),
    userInfo,
    schema,
    translation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getTasks,
  getNextTasks,
  getUpdatedTasks,
  archiveTasks,
  removeTasks,
  restartTask,
  createTaskReport,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks))
);
