import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Loader from "Loader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
  getIncompleteErrors,
  clearIncompleteErrors,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_LOCATIONS } from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import {
  selectOngoing as selectLocationsOngoing,
  selectEntities as selectLocationsMap,
  selectAll as selectLocations,
  addLocation,
  updateLocation,
  removeLocation,
} from "slices/locationsSlice";
import { parseId, goBack, redirectTo, queryParameters } from "utils";
import { locationTo } from "appRoutes";
import { EDIT_ICON, DROPDOWN_ICON, toIcon } from "icons";

const HIERARCHY = "Location/hierarchy";
const LOCATIONS = "Location/locations";

class Location extends AdaComponent {
  constructor(props) {
    super(props);

    const { location, isNew, t } = props;
    const errors = getIncompleteErrors(location);

    this.setInitialState(
      {
        editing: isNew,
        prompRemove: false,
        ...this.initialEditState(),
      },
      {
        errors,
        header: t("Location.incomplete"),
        content: t("Location.incompleteContent"),
      }
    );
  }

  componentDidMount = () => {
    const { getHierarchy, t } = this.props;

    window.scrollTo(0, 0);
    getHierarchy(false, opts(HIERARCHY, t("Location.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  initialEditState = () => {
    const { location, parentId } = this.props;
    return {
      name: location?.name || "",
      description: location?.description || "",
      isFactory: !parentId,
      parentId,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY, LOCATIONS);
    }
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }

    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleCheck = (_, { name, checked }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: checked });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okToRemove = () => {
    const { location, removeLocation, history, rrLocation, t } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeLocation(location.id, opts(LOCATIONS, t("Location.removeError")))
      .then(() => goBack(history, rrLocation))
      .catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const {
      location,
      addLocation,
      updateLocation,
      history,
      rrLocation,
      t,
    } = this.props;
    const { name, description, isFactory, parentId } = this.state;

    const errors = [];
    if (!name || name.length === 0) {
      errors.push("name");
    }
    if (!isFactory && !parentId) {
      errors.push("parentId");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("Location.validationError"),
        errors,
      });
    }

    const locationData = {
      name,
      description,
      parent: isFactory ? undefined : parentId,
    };
    if (!location) {
      addLocation(locationData, opts(LOCATIONS, t("Location.addError")))
        .then((l) => redirectTo(locationTo(l.id, rrLocation), history))
        .catch((e) => console.error(e));
    } else {
      locationData.data = clearIncompleteErrors(location);
      this.setState({ editing: false });
      updateLocation(
        location.id,
        locationData,
        opts(LOCATIONS, t("Location.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  parentOptions = memoizeOne((location, locations) => {
    const filtered = location
      ? locations.filter((l) => l.id !== location.id)
      : locations;
    return filtered.map((l) => ({
      key: l.id,
      value: l.id,
      text: l.name,
    }));
  });

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      location,
      isNew,
      missingData,
      getHierarchyOngoing,
      addLocationOngoing,
      updateLocationOngoing,
      removeLocationOngoing,
      canEdit,
      locations,
      t,
      i18n: { language },
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (missingData) {
      return <Loader />;
    }

    const { name, description, isFactory, parentId } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addLocationOngoing ||
      updateLocationOngoing ||
      removeLocationOngoing;

    const parentOptions = this.parentOptions(location, locations);

    const showTools = canEdit && !isNew;
    const addingNew = editing && isNew;
    const editingExisting = editing && !isNew;

    return (
      <Segment
        as="div"
        className="Location"
        loading={loading}
        data-cy="Location"
      >
        <Prompt
          open={promptRemove}
          header={t("Location.removePrompt.header")}
          content={t("Location.removePrompt.content")}
          okText={t("Location.removePrompt.ok")}
          cancelText={t("Location.removePrompt.cancel")}
          onOk={this.okToRemove}
          onCancel={this.cancelRemove}
        />
        {showTools && (
          <div className="Location__tools">
            <Button
              className="Location__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
              data-cy="Location__edit"
            />
          </div>
        )}
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("Location.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("Location.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
              data-cy="Location__name"
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("Location.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("Location.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
              data-cy="Location__description"
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              name="isFactory"
              label={t("Location.factory")}
              checked={isFactory}
              onClick={this.handleCheck}
              data-cy="Location__factory"
            />
          </Form.Field>
          {!isFactory && (
            <Form.Field required error={this.hasError("parentId")}>
              <label>{t("Location.parent")}</label>
              <Form.Dropdown
                name="parentId"
                placeholder={t("Location.parentPlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={parentOptions}
                value={parentId}
                onChange={this.handleChange}
                noResultsMessage={t("Location.noLocations")}
                data-cy="Location__parents"
              />
            </Form.Field>
          )}
          {addingNew && (
            <Form.Button
              className="Location__button"
              type="submit"
              primary
              data-cy="Location__add"
            >
              {t("Location.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button
                className="Location__button"
                type="submit"
                primary
                data-cy="Location__update"
              >
                {t("Location.update")}
              </Form.Button>
              <Button
                className="Location__button"
                type="button"
                negative
                onClick={this.handleRemove}
                data-cy="Location__remove"
              >
                {t("Location.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    location: rrLocation /* react-router */,
  } = ownProps;

  const locationId = parseId(id);
  const location = selectLocationsMap(state)[locationId];
  const parentId =
    location?.parent || parseId(queryParameters(rrLocation)["parent"]) || 0;

  return {
    serviceError: selectServiceError(state, HIERARCHY, LOCATIONS),
    locationId,
    location,
    parentId,
    isNew: !locationId,
    missingData: locationId && !location,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addLocationOngoing: selectLocationsOngoing(state, "addLocation"),
    updateLocationOngoing: selectLocationsOngoing(state, "updateLocation"),
    removeLocationOngoing: selectLocationsOngoing(state, "removeLocation"),
    canEdit: hasCapability(state, CAN_MANAGE_LOCATIONS),
    locations: selectLocations(state),
    rrLocation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getHierarchy,
  addLocation,
  updateLocation,
  removeLocation,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Location)
);
