import React, { Component } from "react";
import { Segment, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import Locations from "Locations";
import MachineGroups from "MachineGroups";
import Machines from "Machines";
import Sensors from "Sensors";
import {
  SensorCustomReferences,
  MachineCustomReferences,
} from "CustomReferences";
import Labels from "Labels";
import Tasks from "Tasks";
import SensorGroups from "SensorGroups";

import { redirectTo } from "utils";
import {
  LOCATION_ICON,
  MACHINE_GROUP_ICON,
  MACHINE_ICON,
  SENSOR_ICON,
  CUSTOM_REFERENCE_ICON,
  MACHINE_CUSTOM_REFERENCE_ICON,
  LABEL_ICON,
  TASK_ICON,
  SENSOR_GROUP_ICON,
  toIcon,
} from "icons";
import {
  LOCATIONS,
  MACHINE_GROUPS,
  MACHINES,
  SENSORS,
  CUSTOM_REFERENCES,
  MACHINE_CUSTOM_REFERENCES,
  SENSOR_GROUPS,
  LABELS,
  TASKS,
} from "appRoutes";

class Inventory extends Component {
  constructor(props) {
    super(props);

    const { location, t } = this.props;

    this.sections = [
      // {
      //   props: {
      //     key: "Hierarchy",
      //     text: t("Inventory.hierarchy"),
      //     disabled: true,
      //     className: "Inventory__header",
      //   },
      // },
      {
        props: {
          key: "Locations",
          value: LOCATIONS.path,
          text: t("Inventory.locations"),
          icon: toIcon(LOCATION_ICON),
          className: "Inventory__item",
          "data-cy": "Locations",
        },
        component: Locations,
      },
      {
        props: {
          key: "MachineGroups",
          value: MACHINE_GROUPS.path,
          text: t("Inventory.machineGroups"),
          icon: toIcon(MACHINE_GROUP_ICON),
          className: "Inventory__item",
          "data-cy": "MachineGroups",
        },
        component: MachineGroups,
      },
      {
        props: {
          key: "Machines",
          value: MACHINES.path,
          text: t("Inventory.machines"),
          icon: toIcon(MACHINE_ICON),
          className: "Inventory__item",
          "data-cy": "Machines",
        },
        component: Machines,
      },
      {
        props: {
          key: "Sensors",
          value: SENSORS.path,
          text: t("Inventory.sensors"),
          icon: toIcon(SENSOR_ICON),
          className: "Inventory__item",
          "data-cy": "Sensors",
        },
        component: Sensors,
      },
      {
        props: {
          key: "CustomReferences",
          value: CUSTOM_REFERENCES.path,
          text: t("Inventory.customReferences"),
          icon: toIcon(CUSTOM_REFERENCE_ICON),
          className: "Inventory__item",
          "data-cy": "CustomReferences",
        },
        component: SensorCustomReferences,
      },
      {
        props: {
          key: "MachineCustomReferences",
          value: MACHINE_CUSTOM_REFERENCES.path,
          text: t("Inventory.machineCustomReferences"),
          icon: toIcon(MACHINE_CUSTOM_REFERENCE_ICON),
          className: "Inventory__item",
          "data-cy": "MachineCustomReferences",
        },
        component: MachineCustomReferences,
      },
      {
        props: {
          key: "SensorGroups",
          value: SENSOR_GROUPS.path,
          text: t("Inventory.sensorGroups"),
          icon: toIcon(SENSOR_GROUP_ICON),
          className: "Inventory__item",
          "data-cy": "SensorGroups",
        },
        component: SensorGroups,
      },
      // {
      //   props: {
      //     key: "Data",
      //     text: t("Inventory.data"),
      //     disabled: true,
      //     className: "Inventory__header",
      //   },
      // },
      {
        props: {
          key: "Tasks",
          value: TASKS.path,
          text: t("Inventory.tasks"),
          icon: toIcon(TASK_ICON),
          className: "Inventory__item",
          "data-cy": "Tasks",
        },
        component: Tasks,
      },
      {
        props: {
          key: "Labels",
          value: LABELS.path,
          text: t("Inventory.labels"),
          icon: toIcon(LABEL_ICON),
          className: "Inventory__item",
          "data-cy": "Labels",
        },
        component: Labels,
      },
    ];

    const active =
      this.sections.find((i) => i.props.value === location.pathname) ||
      this.sections[0];
    this.state = {
      active,
    };
  }

  handleChange = (_, { value }) => {
    const { history } = this.props;
    if (value) {
      redirectTo({ pathname: value }, history);
    }
  };

  inventoryOptions = memoizeOne((sections) => sections.map((s) => s.props));

  render() {
    const { t } = this.props;
    const { active } = this.state;
    const inventoryOptions = this.inventoryOptions(this.sections);

    return (
      <Segment as="div" className="Inventory">
        <Dropdown
          placeholder={t("Inventory.select")}
          fluid
          selection
          options={inventoryOptions}
          value={active.props.value}
          onChange={this.handleChange}
          data-cy="Inventory"
        />
        <active.component />
      </Segment>
    );
  }
}

export default withTranslation()(withRouter(connect()(Inventory)));
