import React, { Fragment } from "react";
import { Segment, Item, Accordion, Grid, Header, Divider, Image, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import SensorFolderPath from "SensorFolderPath";
import DiagnosisSummary from "DiagnosisSummary";
import Diagnosis from "Diagnosis";
import AnalysisDetails from "AnalysisDetails";
import MachineLog from "MachineLog";
import Loader from "Loader";

import { toggleFlag, selectFlags } from "slices/uiSlice";
import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  hasCapability,
  CAN_ACCESS_TECHNICIAN_UI,
  CAN_REVISE_DIAGNOSES,
  CAN_ACCESS_ALL_ANALYSIS_DATA,
} from "slices/userDataSlice";

import {
  // selectOngoing as selectHierarchyOngoing,
  // getHierarchy,
  selectLastUpdated,
} from "slices/hierarchySlice";
import { selectEntities as selectMachines } from "slices/machinesSlice";
import { selectEntities as selectMachineGroups } from "slices/machineGroupsSlice";
import { selectEntities as selectSensors } from "slices/sensorsSlice";
import {
  selectEntities as selectAnalyses,
  selectOngoing as selectAnalysesOngoing,
  // selectLinked as selectIsLinkedAnalysis,
  getAnalysis,
} from "slices/analysesSlice";
import { selectEntities as selectDiagnoses } from "slices/diagnosesSlice";

import { parseId } from "./utils";
import { MinusIconSVG, PlusIconSVG } from "./icons";
import { imageUrl } from "./DeployMachineGroupModal";
import { clearMachineLogCache } from "./slices/machineLogSlice";

// const HIERARCHY = "Analysis/hierarchy";
const ANALYSES = "Analysis/analyses";

const DIAGNOSIS_ACCORDION = "Analysis/diagnosis";
const ANALYSIS_DETAILS_ACCORDION = "Analysis/analysis";
const MACHINE_LOG_ACCORDION = "Analysis/machineLog";
const MACHINE_GROUP_IMAGES_ACCORDION = "Analysis/machineGroupImages";

class Analysis extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  refresh = (reload) => {
    const {
      // getHierarchy,
      analysisId,
      analysis,
      // isLinkedAnalysis,
      getAnalysis,
      t,
    } = this.props;

    // getHierarchy(false, opts(HIERARCHY, t("Analysis.hierarchyError"))).catch(
    //   (e) => {
    //     console.error(e);
    //   }
    // );

    // if (!isLinkedAnalysis) {
    if (reload || !analysis) {
      getAnalysis(
        analysisId,
        opts(ANALYSES, t("Analysis.analysesError"))
      ).catch((e) => {
        console.error(e);
      });
    }
    // }
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (this.props.analysisId !== prevProps.analysisId) {
      this.refresh();
    }
  }

  componentWillUnmount() {
    this.props.clearMachineLogCache()
  }

  flag = (section, id) => `${section}-${id}`;

  handleToggle = (_, { flag }) => {
    const { toggleFlag } = this.props;
    toggleFlag(flag);
  };

  isOpen = (flag) => {
    const { accordion } = this.props;
    return !accordion?.[flag];
  };

  toggleAll = (allAccordions) => {
    const someOpen = allAccordions.some(this.isOpen)
    allAccordions
      .filter(it => this.isOpen(it) === someOpen)
      .forEach(this.props.toggleFlag)
  }

  diagnoses = memoizeOne(
    (diagnoses, diagnosisMap, _accordion, _hierarchyLastUpdated) => {
      const { machine } = this.props;
      return diagnoses.map((id, index) => {
        const diagnosis = diagnosisMap[id];
        const flag = this.flag(DIAGNOSIS_ACCORDION, index);
        const active = this.isOpen(flag);
        return (
          <Fragment key={id}>
            <Accordion.Title
              className="Analysis__accordion_title"
              active={active}
              onClick={this.handleToggle}
              flag={flag}
            >
              {active ? <MinusIconSVG/> : <PlusIconSVG/>}
              <DiagnosisSummary as={"span"} diagnosis={diagnosis} hideTodos/>
            </Accordion.Title>
            <Accordion.Content active={active}>
              {active && <Diagnosis diagnosis={diagnosis} machine={machine}/>}
            </Accordion.Content>
          </Fragment>
        );
      });
    }
  );

  render() {
    const {
      serviceError,
      analysis,
      missingData,
      // getHierarchyOngoing,
      getAnalysisOngoing,
      sensor,
      machine,
      machineGroup,
      machineGroupMachines,
      diagnoses,
      accordion,
      hierarchyLastUpdated,
      hideNavigation,
      t,
      i18n: { language },
    } = this.props;

    if (missingData) {
      return <Loader/>;
    }

    const allAccordions = [
      DIAGNOSIS_ACCORDION, ANALYSIS_DETAILS_ACCORDION,
      MACHINE_LOG_ACCORDION, MACHINE_GROUP_IMAGES_ACCORDION,
      ...analysis.diagnoses.map((id, index) => this.flag(DIAGNOSIS_ACCORDION, index))
    ]

    const loading = /* getHierarchyOngoing || */ getAnalysisOngoing;

    const diagnosisItems = this.diagnoses(
      analysis.diagnoses,
      diagnoses,
      accordion,
      hierarchyLastUpdated
    );
    const analysisDetailsActive = this.isOpen(ANALYSIS_DETAILS_ACCORDION);
    const imagesActive = this.isOpen(MACHINE_GROUP_IMAGES_ACCORDION);
    const machineLogActive = this.isOpen(MACHINE_LOG_ACCORDION);

    const someActive = allAccordions.some(this.isOpen)

    return (
      <Segment
        as="div"
        className="Analysis"
        basic
        loading={loading}
        data-cy="Analysis"
      >
        <Grid doubling columns="equal" stackable>
          {!hideNavigation &&
          <Grid.Column width={4}>
            {this.ErrorMessage()}
            {this.ServiceErrorMessage(serviceError, language)}
            <Item>
              <Item.Description className="Analysis__path">
                <SensorFolderPath sensor={sensor}/>
              </Item.Description>
            </Item>
          </Grid.Column>
          }
          <Grid.Column style={{ maxWidth: '1200px' }}>
            <Accordion exclusive={false} fluid styled style={{ boxShadow: 'none' }}>
              <Header as="h4" size="medium" className="Analysis__title">
                {machineGroup.name} / {machine.name} {sensor.name}
              </Header>

              <div>
                <Button
                  icon
                  onClick={() => this.toggleAll(allAccordions)}
                  basic
                  title="Toggle all"
                >
                  {someActive ? <MinusIconSVG/> : <PlusIconSVG/>}
                </Button>
              </div>

              <Accordion.Title
                className="Analysis__accordion_title"
                active={imagesActive}
                onClick={this.handleToggle}
                flag={MACHINE_GROUP_IMAGES_ACCORDION}
              >
                {imagesActive ? <MinusIconSVG/> : <PlusIconSVG/>}
                {t("Analysis.images")}
              </Accordion.Title>
              <Accordion.Content active={imagesActive}>
                {imagesActive && <MachineGroupImages machineGroup={machineGroup}/>}
              </Accordion.Content>

              <Header as="h5" className="Analysis__title">
                {t("Analysis.diagnosis")}
              </Header>
              {diagnosisItems}

              <Divider style={{ width: '95%', margin: 'auto' }}/>

              <Accordion.Title
                className="Analysis__accordion_title"
                active={machineLogActive}
                onClick={this.handleToggle}
                flag={MACHINE_LOG_ACCORDION}
              >
                {machineLogActive ? <MinusIconSVG/> : <PlusIconSVG/>}
                {t("Analysis.machineLog")}
              </Accordion.Title>
              <Accordion.Content active={machineLogActive}>
                <b>Machine Group Log</b>
                <div>
                  {machineGroupMachines.map(it => (
                    <div style={{ maxHeight: '600px', overflowY: 'auto' }} key={it.id}>
                      {machineLogActive && <MachineLog machine={it}/>}
                    </div>
                  ))}
                </div>
              </Accordion.Content>

              <Divider style={{ width: '95%', margin: 'auto' }}/>

              <Accordion.Title
                className="Analysis__accordion_title"
                active={analysisDetailsActive}
                onClick={this.handleToggle}
                flag={ANALYSIS_DETAILS_ACCORDION}
              >
                {analysisDetailsActive ? <MinusIconSVG/> : <PlusIconSVG/>}
                {t("Analysis.details")}
              </Accordion.Title>
              <Accordion.Content active={analysisDetailsActive}>
                {analysisDetailsActive && <AnalysisDetails analysis={analysis}/>}
              </Accordion.Content>

            </Accordion>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

function MachineGroupImages({ machineGroup }) {

  const imgProps = {
    onClick: e => e.target.src && window.open(e.target.src),
    size: 'large',
    wrapped: true,
    title: 'Click to open',
    className: 'Analysis__machinegroup-image',
  }

  return (
    <div className="Analysis__machinegroup-images">
      <div>
        <div><b>Machine Queue</b> {!!machineGroup.machine_queue_image || "(no image)"}</div>
        <div>
          <Image {...imgProps} src={imageUrl(machineGroup.machine_queue_image)} alt="Machine queue image"/>
        </div>
      </div>
      <div>
        <div><b>Machine Plate</b> {!!machineGroup.machine_plate_image || "(no image)"}</div>
        <div>
          <Image {...imgProps} src={imageUrl(machineGroup.machine_plate_image)} alt="Machine plate image"/>
        </div>
      </div>
      <div>
        <div><b>Safety Switch</b> {!!machineGroup.safety_switch_image || "(no image)"}</div>
        <div>
          <Image {...imgProps} src={imageUrl(machineGroup.safety_switch_image)} alt="Safety switch image"/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    i18n: { language },
  } = ownProps;
  const analysisId = parseId(id);
  const analysis = selectAnalyses(state)[analysisId];

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);
  const sensor = selectSensors(state)[analysis?.sensor];
  const machine = selectMachines(state)[sensor?.machine];
  const machineGroup = selectMachineGroups(state)[machine?.machine_group];
  const machineGroupMachines = machineGroup?.machines?.map(it => selectMachines(state)[it]).filter(Boolean) || []

  // const isLinkedAnalysis = selectIsLinkedAnalysis(state, analysisId);
  const analyses = selectAnalyses(state);
  const diagnoses = selectDiagnoses(state);

  return {
    serviceError: selectServiceError(state, /* HIERARCHY, */ ANALYSES),
    analysisId,
    analysis,
    // hasHierarchy: sensor !== undefined,
    accordion: selectFlags(state),
    // isLinkedAnalysis,
    missingData: !((analysis && sensor && machine) /* && isLinkedAnalysis */),
    // getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    getAnalysisOngoing: selectAnalysesOngoing(state, "getAnalysis"),
    technicianUI: hasCapability(state, CAN_ACCESS_TECHNICIAN_UI),
    canReviseDiagnoses: hasCapability(state, CAN_REVISE_DIAGNOSES),
    canSeeDiagnosisDetails: hasCapability(state, CAN_ACCESS_ALL_ANALYSIS_DATA),
    referenceFigures: schemaKey(schema, "reference_figures"),
    sensor,
    machine,
    machineGroup,
    machineGroupMachines,
    analyses,
    diagnoses,
    translation,
    hierarchyLastUpdated: selectLastUpdated(state),
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  clearMachineLogCache,
  // getHierarchy,
  getAnalysis,
  toggleFlag,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Analysis)
);
