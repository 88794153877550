import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  apiCreateCustomReference,
  apiUpdateCustomReference,
  apiDeleteCustomReference,
} from "api.js";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const customReferences = createSlice({
  name: "customReferences",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      adapter.setAll(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default customReferences.reducer;

const {
  start,
  end,
  set,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = customReferences.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.customReferences;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const addCustomReference = (data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("addCustomReference"));
    const r = await apiCreateCustomReference(selectToken(getState()), data);
    batchSuccess(dispatch, options, add(r), end("addCustomReference"));
    return r;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addCustomReference"));
    throw error;
  }
};

export const updateCustomReference = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("updateCustomReference"));
    const r = await apiUpdateCustomReference(selectToken(getState()), id, data);
    batchSuccess(dispatch, options, update(r), end("updateCustomReference"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateCustomReference"));
    throw error;
  }
};

export const removeCustomReference = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("removeCustomReference"));
    const state = getState();
    await apiDeleteCustomReference(selectToken(state), id);
    batchSuccess(dispatch, options, remove(id), end("removeCustomReference"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeCustomReference"));
    throw error;
  }
};
