import React from "react";
import { Button, List } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import MachinePath from "MachinePath";
import DiagnosisSummary from "DiagnosisSummary";

import { diagnosisCategoryGroups, selectServiceSchema, } from "slices/userDataSlice";

import { selectEntities as selectDiagnoses } from "slices/diagnosesSlice";
import { selectEntities as selectMachines } from "slices/machinesSlice";
import { selectEntities as selectSensors } from "slices/sensorsSlice";

import { parseDatetime } from "utils";
import { analysisTo } from "appRoutes";
import {
  SELECTED_FLAG,
  toIcon,
  UNSELECTED_FLAG, VALIDATED_ICON,
} from "./icons";
import { useFlaggedItem } from "./useFlaggedItem";

function AnalysesItem({
                        index,
                        analysis,
                        diagnoses,
                        linkedToAnalysis = true,
                      }) {
  const { t } = useTranslation();
  const location = useLocation();

  const [flagged, setFlagged] = useFlaggedItem("analysis-" + analysis.id)

  const diagnosisMap = useSelector((state) => selectDiagnoses(state));
  const ds = diagnoses || analysis.diagnoses.map((d) => diagnosisMap[d]);
  const machine = useSelector(
    (state) => selectMachines(state)[analysis.machine]
  );
  const sensor = useSelector((state) => selectSensors(state)[analysis.sensor]);

  const schema = useSelector((state) => selectServiceSchema(state));
  const diagnosisItems = ds.map((d) => {
    const { ok, warning, danger, unsupported } = diagnosisCategoryGroups(
      d.category,
      schema
    );
    return {
      diagnosis: d,
      className: classNames({
        AnalysesItem__diagnosis: true,
        AnalysesItem__ok: ok,
        AnalysesItem__warning: warning,
        AnalysesItem__danger: danger,
        AnalysesItem__unsupported: unsupported,
      }),
    };
  });

  const description = machine?.description;
  const measuredAt = parseDatetime(analysis.measured_at);

  return (
    <List.Item
      as={(linkedToAnalysis && Link) || undefined}
      to={analysisTo(analysis.id, location)}
      className={"AnalysesItem__list"}
      data-cy="AnalysisItem"
    >
      <List.Icon>
        {ds.every(it => it.validated) && toIcon(VALIDATED_ICON)}
        <Button
          icon
          onClick={e => {
            e.preventDefault()
            setFlagged(!flagged)
          }}
          compact
          basic
          value={analysis.id}
          title="Mark temporarily. This flag does not persist."
        >
          {toIcon(flagged ? SELECTED_FLAG : UNSELECTED_FLAG)}
        </Button>
      </List.Icon>
      <List.Content>
        <List.Header>
          {index !== undefined && <span className="AnalysesItem__item-index">{index + 1}</span>}
          <MachinePath machine={machine} sensor={sensor}/>
          <span className="AnalysesItem__right-float">
            {t("AnalysesItem.measuredAt", { measuredAt })}
            <div style={{ fontSize: '10px', color: '#999999', textAlign: 'end' }}>
              ID: {analysis.id}
            </div>
          </span>
        </List.Header>
        <div>
          <List.Description>
            {description}
          </List.Description>
          {diagnosisItems.map(({ diagnosis, className }) => (
            <List.Description key={diagnosis.id} className={className}>
              <DiagnosisSummary diagnosis={diagnosis}/>
            </List.Description>
          ))}
        </div>
      </List.Content>
    </List.Item>
  );
}

export default AnalysesItem;
