import React from "react";
import AdaComponent from "./AdaComponent";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parseId, queryParameters } from "./utils";
import { Button, Grid, Segment } from "semantic-ui-react";
import Analysis from "./Analysis";

class GroupAnalyses extends AdaComponent {

  render() {

    const {
      analysesIds,
      ownProps
    } = this.props;

    if (!analysesIds?.length) return (
      <h3 style={{ color: 'red' }}>
        No analyses specified
      </h3>
    )

    const excludeAnalysis = id => {
      const params = new URLSearchParams(window.location.search)
      params.set("analyses", analysesIds.filter(it => it !== id).join(","))
      window.location.search = params.toString()
    }

    return (
      <Segment as="div" basic className="GroupAnalyses">
        <Grid container columns={analysesIds.length} className="GroupAnalyses__container">
          {analysesIds.map(it => (
            <Grid.Column className="GroupAnalyses__analysis">
              <div className="GroupAnalyses__exclude">
                <Button
                  color="grey"
                  size="tiny"
                  compact
                  onClick={() => excludeAnalysis(it)}
                  title="Exclude from the current view"
                >
                  Exclude
                </Button>
              </div>
              <Analysis key={it} {...ownProps} match={{ params: { id: it } }} hideNavigation/>
            </Grid.Column>
          ))}
        </Grid>
      </Segment>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;

  const analysesParsed = queryParameters(location)["analyses"]?.split(',')?.map(id => parseId(id)) || []

  return {
    analysesIds: analysesParsed,
    ownProps: ownProps
  }
};

const mapDispatchToProps = {};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupAnalyses))
);
