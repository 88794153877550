import React from "react";
import { Segment, Form, Button, Input, Checkbox } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectUserInfo,
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  hasCapability,
  CAN_IMPORT,
  CAN_MANAGE_SENSOR_GROUPS,
} from "slices/userDataSlice";
import {
  selectOngoing as selectSensorGroupsOngoing,
  selectAll as selectSensorGroups,
  getAllSensorGroups,
  addSensorGroup,
} from "slices/sensorGroupsSlice";
import {
  selectTasksOngoing,
  selectTasksNext,
  addTask,
} from "slices/tasksSlice";

import { redirectTo } from "utils";
import { tasksTo } from "appRoutes";
import { ATTACH_ICON, DROPDOWN_ICON, toIcon } from "icons";

const SENSOR_GROUPS = "HierarchyImporter/sensorGroups";
const TASKS = "HierarchyImporter/tasks";

class HierarchyImporter extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      ...this.initialEditState(),
    });

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    const { getAllSensorGroups, t } = this.props;

    getAllSensorGroups(
      opts(SENSOR_GROUPS, t("HierarchyImporter.sensorGroupsError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  initialEditState = () => {
    const { hierarchyRoot, fileFormats } = this.props;
    const fileFormat = fileFormats.length === 1 ? fileFormats[0] : undefined;

    return {
      files: [],
      fileFormat: fileFormat,
      hierarchyRoot,
      sensorGroup: undefined,
      modifyExisting: false,
    };
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(SENSOR_GROUPS, TASKS);
    }
  };

  handleFileInputClicked = () => {
    this.fileInputRef.current.click();
  };

  handleChangeFiles = (e) => {
    const { files } = e.target;
    this.clearError("files");
    this.clearServiceErrors();
    this.setState({
      files: Array.from(files?.length > 0 ? files : []),
    });
  };

  handleChange = (_, { name, value }) => {
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleChangeCheckbox = (_, { name }) => {
    this.clearError(name);
    this.clearServiceErrors();
    this.setState((state) => ({ [name]: !state[name] }));
  };

  handleAddSensorGroup = (_, { value: name }) => {
    const { addSensorGroup, t } = this.props;
    addSensorGroup(
      { name },
      opts(SENSOR_GROUPS, t("HierarchyImporter.addSensorGroupError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  handleSubmit = () => {
    const { sensorGroups, addTask, history, location, t } = this.props;
    const {
      files,
      fileFormat: data_file_format,
      sensorGroup,
      hierarchyRoot: hierarchy_root,
      modifyExisting: modify_existing,
    } = this.state;

    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const sensor_group = sensorGroups.find((g) => g.name === sensorGroup)?.id;

    const errors = [];
    if (sensorGroup && !sensor_group) {
      errors.push("sensorGroup");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("HierarchyImporter.validationError"),
        errors,
      });
    }

    this.fileInputRef.current.value = "";
    this.setState({
      files: [],
    });

    addTask(
      {
        data_file_format,
        sensor_group,
        data: {
          hierarchy_root,
          modify_existing,
        },
      },
      files,
      opts(TASKS, t("HierarchyImporter.addTaskError"))
    )
      .then(() => redirectTo(tasksTo(location), history))
      .catch((e) => {
        console.error(e);
      });
  };

  fileFormatOptions = memoizeOne((fileFormats, translation) =>
    fileFormats.map((f) => ({
      key: f,
      value: f,
      text: translationKey(translation, "task_data_file_formats")[f],
    }))
  );

  sensorGroupOptions = memoizeOne((sensorGroups) =>
    sensorGroups.map((g) => ({
      key: g.id,
      value: g.name,
      text: g.name,
      "data-cy": "HierarchyImporter__sensorGroup",
    }))
  );

  filesLabel = (files, _language) => {
    const { t } = this.props;
    if (files.length === 1) {
      return t("HierarchyImporter.selectedFile", { name: files[0].name });
    } else {
      return t("HierarchyImporter.selectedFiles", {
        name: files[0].name,
        count: files.length - 1,
      });
    }
  };

  render() {
    const {
      serviceError,
      sensorGroups,
      getAllSensorGroupsOngoing,
      addSensorGroupOngoing,
      addTaskOngoing,
      canImport,
      canAddSensorGroup,
      fileFormats,
      translation,
      i18n: { language },
      t,
    } = this.props;
    const {
      files,
      fileFormat,
      hierarchyRoot,
      sensorGroup,
      modifyExisting,
    } = this.state;

    if (!canImport) {
      return null;
    }

    const loading = addTaskOngoing;
    const loadingSensorGroups =
      getAllSensorGroupsOngoing || addSensorGroupOngoing;

    const fileFormatOptions = this.fileFormatOptions(fileFormats, translation);
    const sensorGroupOptions = this.sensorGroupOptions(sensorGroups);

    const hasFiles = files.length > 0;
    const filesLabel = hasFiles && this.filesLabel(files, language);
    const canSubmit =
      hasFiles && fileFormat && hierarchyRoot && hierarchyRoot !== "";

    return (
      <Segment
        as="div"
        className="HierarchyImporter"
        loading={loading}
        data-cy="HierarchyImporter"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Group grouped>
            <Form.Field required error={this.hasError("files")}>
              <label>{t("HierarchyImporter.files")}</label>
              {hasFiles && (
                <div>
                  <label
                    className="HierarchyImporter__attachment"
                    data-cy="HierarchyImporter__attachment"
                  >
                    {filesLabel}
                  </label>
                </div>
              )}
              <Button
                type="button"
                onClick={this.handleFileInputClicked}
                data-cy="HierarchyImporter__fileButton"
              >
                {toIcon(ATTACH_ICON)}
                {hasFiles
                  ? t("HierarchyImporter.changeFiles")
                  : t("HierarchyImporter.selectFiles")}
              </Button>
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                multiple
                onChange={this.handleChangeFiles}
                data-cy="HierarchyImporter__fileInput"
              />
            </Form.Field>
            <Form.Field required error={this.hasError("fileFormat")}>
              <label>{t("HierarchyImporter.fileFormat")}</label>
              <Form.Dropdown
                name="fileFormat"
                placeholder={t("HierarchyImporter.fileFormatPlaceholder")}
                fluid
                search
                selection
                icon={toIcon(DROPDOWN_ICON)}
                options={fileFormatOptions}
                value={fileFormat}
                onChange={this.handleChange}
                noResultsMessage={t("HierarchyImporter.noFileFormats")}
                data-cy="HierarchyImporter__formats"
              />
            </Form.Field>
            <Form.Field required error={this.hasError("hierarchyRoot")}>
              <label>{t("HierarchyImporter.hierarchyRoot")}</label>
              <Input
                name="hierarchyRoot"
                value={hierarchyRoot}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field error={this.hasError("modifyExisting")}>
              {/* <label>{t("HierarchyImporter.modifyExisting")}</label> */}
              <Checkbox
                name="modifyExisting"
                label={t("HierarchyImporter.modifyExisting")}
                checked={modifyExisting}
                onChange={this.handleChangeCheckbox}
              />
            </Form.Field>
            <Form.Field error={this.hasError("sensorGroup")}>
              <label>{t("HierarchyImporter.sensorGroup")}</label>
              <Form.Dropdown
                name="sensorGroup"
                placeholder={t("HierarchyImporter.sensorGroupPlaceholder")}
                fluid
                search
                selection
                clearable
                loading={loadingSensorGroups}
                allowAdditions={canAddSensorGroup}
                icon={toIcon(DROPDOWN_ICON)}
                options={sensorGroupOptions}
                value={sensorGroup}
                additionLabel={t("HierarchyImporter.addSensorGroup")}
                onAddItem={this.handleAddSensorGroup}
                onChange={this.handleChange}
                data-cy="HierarchyImporter__sensorGroups"
              />
            </Form.Field>
            <div className="HierarchyImporter__buttons">
              <Form.Button
                className="HierarchyImporter__submit"
                type="submit"
                primary
                disabled={!canSubmit}
                data-cy="HierarchyImporter__submit"
              >
                {t("HierarchyImporter.submit")}
              </Form.Button>
            </div>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  return {
    serviceError: selectServiceError(state, SENSOR_GROUPS, TASKS),
    sensorGroups: selectSensorGroups(state),
    getAllSensorGroupsOngoing: selectSensorGroupsOngoing(
      state,
      "getAllSensorGroups"
    ),
    addSensorGroupOngoing: selectSensorGroupsOngoing(state, "addSensorGroup"),
    tasksNext: selectTasksNext(state),
    addTaskOngoing: selectTasksOngoing(state, "addTask"),
    hierarchyRoot: selectUserInfo(state)?.username,
    canImport: hasCapability(state, CAN_IMPORT),
    canAddSensorGroup: hasCapability(state, CAN_MANAGE_SENSOR_GROUPS),
    fileFormats: schemaKey(schema, "task_hierarchy_formats"),
    translation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getAllSensorGroups,
  addSensorGroup,
  addTask,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HierarchyImporter))
);
