import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";

import { apiGetAll, apiGetSpectrums, apiGetNextJson } from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({});

const spectrums = createSlice({
  name: "spectrums",
  initialState: { ongoing: {}, analyses: {} },
  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      const { id, spectrums } = payload;
      state.analyses[id] = adapter.setAll(adapter.getInitialState(), spectrums);
    },
  },
});

export default spectrums.reducer;

const { start, end, set } = spectrums.actions;

const selectSpectrumsState = (state) => state.spectrums;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectSpectrumsState);

export const selectSpectrumsOngoing = (state, key) =>
  !!selectSpectrumsState(state).ongoing[key];

const selectAnalysisSpectrum = (state, id) =>
  selectSpectrumsState(state).analyses[id];

export const selectSpectrums = (state, id) =>
  selectAnalysisSpectrum(state, id)?.entities;

export const getAllSpectrums = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("getAllSpectrums"));
    const spectrums = await apiGetAll(
      selectToken(getState()),
      apiGetSpectrums,
      apiGetNextJson,
      { analysis: id }
    );
    batchSuccess(
      dispatch,
      options,
      set({ id, spectrums }),
      end("getAllSpectrums")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getAllSpectrums"));
    throw error;
  }
};

export const selectSpectrumImages = memoizeOne((state, id) => {
  const ss = selectAnalysisSpectrum(state, id);
  if (!ss) {
    return {};
  }
  return ss.ids.reduce((acc, id) => {
    const s = ss.entities[id];
    acc[s.category] = s.image;
    return acc;
  }, {});
});
