import React, { Fragment } from "react";
import { Segment, Header, Table, Button, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import SpectrumImage from "SpectrumImage";
import KeyFigures from "KeyFigures";
import Loader from "Loader";
import Trend from "Trend";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  hasCapability,
  CAN_ACCESS_ALL_ANALYSIS_DATA,
  translationKey,
} from "slices/userDataSlice";
import {
  selectOngoing as selectAnalysesOngoing,
  selectLinked as selectIsLinkedAnalysis,
  getLinkedAnalysis,
} from "slices/analysesSlice";
import {
  selectSpectrums,
  selectSpectrumImages,
  selectSpectrumsOngoing,
  getAllSpectrums,
} from "slices/spectrumsSlice";
// import { selectReportOngoing, createSensorReport } from "slices/reportSlice";
import {
  selectTaskFilesOngoing,
  saveTaskFileData,
} from "slices/taskFilesSlice";
import { parseDatetime, timeDiff, dhmDiff } from "./utils";
import { taskTo, analysisTo } from "./appRoutes";
import { REFRESH_ICON, PREVIOUS_ICON, NEXT_ICON, toIcon } from "icons";
import { selectEntities as selectMachinesMap, selectEntities as selectMachines } from "./slices/machinesSlice";

const ANALYSES = "AnalysisDetails/analyses";
const SPECTRUMS = "AnalysisDetails/spectrums";
// const REPORT = "AnalysisDetails/report";
const TASK_FILE_DATA = "AnalysisDetails/task-file-data";

class AnalysisDetails extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  refresh = (reload) => {
    const {
      analysis,
      isLinkedAnalysis,
      getLinkedAnalysis,
      spectrums,
      getAllSpectrums,
      t,
    } = this.props;

    if (reload || !isLinkedAnalysis) {
      getLinkedAnalysis(
        analysis.id,
        opts(ANALYSES, t("AnalysisDetails.analysesError"))
      ).catch((e) => {
        console.error(e);
      });
    }

    if (reload || !spectrums) {
      getAllSpectrums(
        analysis.id,
        opts(SPECTRUMS, t("AnalysisDetails.spectrumsError"))
      ).catch((e) => {
        console.error(e);
      });
    }
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(
        ANALYSES,
        SPECTRUMS,
        // REPORT,
        TASK_FILE_DATA
      );
    }
  };

  componentDidMount() {
    this.clearServiceErrors();
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (this.props.analysis.id !== prevProps.analysis.id) {
      this.clearServiceErrors();
      this.refresh();
    }
  }

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  // createReport = () => {
  //   const { analysis, createSensorReport, t } = this.props;
  //   this.clearServiceErrors();
  //   createSensorReport(
  //     analysis.sensor,
  //     opts(REPORT, t("AnalysisDetails.reportError"))
  //   ).catch((e) => {
  //     console.error(e);
  //   });
  // };

  download = (raw) => {
    const { analysis, saveTaskFileData, t } = this.props;
    this.clearServiceErrors();
    saveTaskFileData(
      {
        id: analysis?.data?.task_file?.id,
        index: analysis?.data?.task_file?.index,
        raw,
        filename: t(
          raw
            ? "AnalysisDetails.rawFilename"
            : "AnalysisDetails.resampledFilename",
          analysis
        ),
      },
      opts(TASK_FILE_DATA, t("AnalysisDetails.taskFileDataError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  downloadRawData = () => {
    this.download(true);
  };

  downloadData = () => {
    this.download(false);
  };

  allKeys = memoizeOne((kf, kfList) => {
    const keys = Object.keys(kf || {});
    if (keys.length === 0) {
      return [];
    }
    const m = kfList.reduce((acc, curr, idx) => {
      acc[curr] = idx + 1;
      return acc;
    }, {});
    return keys.sort(
      (a, b) =>
        (m[a] || Number.MAX_SAFE_INTEGER) - (m[b] || Number.MAX_SAFE_INTEGER)
    );
  });

  createdAt = memoizeOne((d, _language) => {
    const { t } = this.props;
    return {
      createdAt: parseDatetime(d),
      timeDiff: timeDiff(d, t),
    };
  });

  measuredAt = memoizeOne((d, _language) => {
    const { t } = this.props;
    return {
      measuredAt: parseDatetime(d),
      timeDiff: timeDiff(d, t),
    };
  });

  trendData = memoizeOne((keyFigures) => {
    if (!keyFigures?.length) {
      return [];
    }
    const len = keyFigures.length;
    const latest = keyFigures[len - 1].measured_at;
    return keyFigures
      .map((kf) => ({
        ...kf,
        days: dhmDiff(latest, kf.measured_at)["days"],
      }))
      .reverse();
  });

  render() {
    const {
      serviceError,
      analysis,
      missingData,
      getLinkedAnalysisOngoing,
      getAllSpectrumsOngoing,
      // createReportOngoing,
      saveTaskFileDataOngoing,
      canSeeAnalysisDetails,
      canSeeAllKeyFigures,
      spectrumSchema,
      keyFigures,
      images,
      translation,
      t,
      location,
      machine,
      i18n: { language },
    } = this.props;

    if (missingData) {
      return <Loader/>;
    }

    const loading =
      getLinkedAnalysisOngoing ||
      getAllSpectrumsOngoing ||
      // createReportOngoing ||
      saveTaskFileDataOngoing;

    const data = analysis.data;
    const category = analysis.category;
    const values = data.key_figures;
    const allKeys =
      (values && this.allKeys(values, keyFigures[category])) || undefined;

    // const version = analysis.data?.version;
    const createdAt = this.createdAt(analysis.created_at, language);
    const measuredAt = this.measuredAt(analysis.measured_at, language);
    const username = analysis.username;
    const task = analysis.task;

    const fsample = data.sensor_data?.fsample;
    const nsample = data.sensor_data?.nsample;
    const acceleration_unit = data.sensor_data?.acceleration_unit;
    const rotational_speed = data.sensor_data?.rotational_speed_hz;

    const resampled_fsample = data.resampled_sensor_data?.fsample;
    const resampled_nsample = data.resampled_sensor_data?.nsample;
    const resampled_acceleration_unit =
      data.resampled_sensor_data?.acceleration_unit;

    const previous = analysis.links?.previous;
    const next = analysis.links?.next;

    const machineRelubricable = (data?.machine_data?.relubricable || machine.relubricable) ? "Yes" : "No";
    const machineTransmission = data?.machine_data?.transmission || machine.transmission;
    const machineRotationalGroup = data?.machine_data?.rotational_group || machine.rotational_group;

    const trendData = this.trendData(analysis?.links?.key_figures);

    return (
      <Segment as="div" className="AnalysisDetails" loading={loading}>
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <div className="AnalysisDetails__tools">
          <Button
            type="button"
            className="AnalysisDetails__tool"
            icon
            onClick={this.handleRefresh}
            compact
            basic
            data-cy="AnalysisDetails__refresh"
          >
            {toIcon(REFRESH_ICON)}
          </Button>
        </div>

        <Table
          className="AnalysisDetails__table"
          basic="very"
          celled
          unstackable
          size="small"
          compact="very"
          striped
        >
          <Table.Body>
            {/* {version && (
              <Table.Row>
                <Table.Cell>{t("AnalysisDetails.version")}</Table.Cell>
                <Table.Cell>{version}</Table.Cell>
              </Table.Row>
            )} */}
            <Table.Row>
              <Table.Cell>{t("AnalysisDetails.category")}</Table.Cell>
              <Table.Cell>
                {translationKey(translation, "analysis_categories")[category]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("AnalysisDetails.createdAt")}</Table.Cell>
              <Table.Cell>
                {t("AnalysisDetails.createdAtFormat", createdAt)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("AnalysisDetails.measuredAt")}</Table.Cell>
              <Table.Cell>
                {t("AnalysisDetails.measuredAtFormat", measuredAt)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("AnalysisDetails.rotationalSpeed")}</Table.Cell>
              <Table.Cell>{rotational_speed}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("AnalysisDetails.user")}</Table.Cell>
              <Table.Cell>{username}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t("Diagnosis.machineRelubricable")}
              </Table.Cell>
              <Table.Cell>{machineRelubricable}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t("Diagnosis.machineTransmission")}
              </Table.Cell>
              <Table.Cell>{machineTransmission}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t("Diagnosis.machineRotationalGroup")}
              </Table.Cell>
              <Table.Cell>{machineRotationalGroup}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {/* <Segment className="AnalysisDetails__links">
          {(previous && (
            <NavLink to={analysisTo(previous, location)}>
              {toIcon(PREVIOUS_ICON)}
            </NavLink>
          )) || (
            <div>
              <Icon />
            </div>
          )}
          <Button primary onClick={this.createReport}>
            {t("AnalysisDetails.saveAnalyses")}
          </Button>
          {(next && (
            <NavLink to={analysisTo(analysis?.links?.next, location)}>
              {toIcon(NEXT_ICON)}
            </NavLink>
          )) || (
            <div>
              <Icon />
            </div>
          )}
        </Segment> */}

        {canSeeAnalysisDetails && (
          <>
            <Header as="h3">{t("AnalysisDetails.sensorData")}</Header>
            <Table
              className="AnalysisDetails__table"
              basic="very"
              celled
              unstackable
              size="small"
              compact="very"
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t("AnalysisDetails.fsample")}</Table.Cell>
                  <Table.Cell>{fsample}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("AnalysisDetails.nsample")}</Table.Cell>
                  <Table.Cell>{nsample}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("AnalysisDetails.accelerationUnit")}
                  </Table.Cell>
                  <Table.Cell>{acceleration_unit}</Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                  <Table.Cell>
                    {t("AnalysisDetails.rotationalSpeed")}
                  </Table.Cell>
                  <Table.Cell>{rotational_speed}</Table.Cell>
                </Table.Row> */}
                <Table.Row>
                  <Table.Cell>
                    {t("AnalysisDetails.resampledFsample")}
                  </Table.Cell>
                  <Table.Cell>{resampled_fsample}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("AnalysisDetails.resampledNsample")}
                  </Table.Cell>
                  <Table.Cell>{resampled_nsample}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("AnalysisDetails.resampledAccelerationUnit")}
                  </Table.Cell>
                  <Table.Cell>{resampled_acceleration_unit}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("AnalysisDetails.rawData")}</Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      compact={true}
                      size="mini"
                      onClick={this.downloadRawData}
                    >
                      {t("AnalysisDetails.saveData")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("AnalysisDetails.resampledData")}</Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      compact={true}
                      size="mini"
                      onClick={this.downloadData}
                    >
                      {t("AnalysisDetails.saveData")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("AnalysisDetails.taskData")}</Table.Cell>
                  <Table.Cell>
                    <NavLink to={taskTo(task, location)}>
                      {t("AnalysisDetails.taskDataLink", { id: task })}
                    </NavLink>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {spectrumSchema[category].map((s) => (
          <Fragment key={s.category}>
            <Header as="h3">
              {translationKey(translation, "spectrums")[s.category]}
            </Header>
            <span>
              {s.images.map(
                (i) =>
                  images[i] && (
                    <SpectrumImage key={i} img={images[i]} inline={true}/>
                  )
              )}
              {s.trends.map((kf) => (
                <div
                  key={kf}
                  className="AnalysisDetails__trend"
                  // style={{
                  //   display: "inline-block",
                  //   width: "100%",
                  //   maxWidth: "640px",
                  //   verticalAlign: "middle",
                  // }}
                >
                  <Trend
                    data={trendData}
                    keyFigure={kf}
                    title={translationKey(translation, "trends")[kf]["title"]}
                    xLabel={t("AnalysisDetails.trendAxis")}
                    yLabel={translationKey(translation, "trends")[kf]["unit"]}
                  />
                </div>
              ))}
            </span>

            {(previous || next) && (
              <Segment className="AnalysisDetails__links">
                {(previous && (
                  <NavLink to={analysisTo(previous, location)}>
                    {toIcon(PREVIOUS_ICON)}
                  </NavLink>
                )) || (
                  <div>
                    <Icon/>
                  </div>
                )}
                {(next && (
                  <NavLink to={analysisTo(analysis?.links?.next, location)}>
                    {toIcon(NEXT_ICON)}
                  </NavLink>
                )) || (
                  <div>
                    <Icon/>
                  </div>
                )}
              </Segment>
            )}

            {/* {s.images.map(
              (i) => images[i] && <SpectrumImage key={i} img={images[i]} />
            )}
            {s.trends.map((kf) => (
              <div key={kf} style={{ maxWidth: "640px" }}>
                <Trend
                  data={trendData}
                  keyFigure={kf}
                  xLabel={t("AnalysisDetails.trendAxis")}
                />
              </div>
            ))} */}

            {/*values && (
              <KeyFigures
                keyHeader={t("AnalysisDetails.keyFigure")}
                valueHeader={t("AnalysisDetails.value")}
                keys={s.key_figures}
                values={values}
              />
            )*/}
          </Fragment>
        ))}

        {canSeeAllKeyFigures && values && (
          <>
            <Header as="h3">{t("AnalysisDetails.allKeyFigures")}</Header>
            <KeyFigures
              keyHeader={t("AnalysisDetails.keyFigure")}
              valueHeader={t("AnalysisDetails.value")}
              keys={allKeys}
              values={values}
            />
          </>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    analysis,
    i18n: { language },
  } = ownProps;

  const isLinkedAnalysis = selectIsLinkedAnalysis(state, analysis.id);
  const spectrums = selectSpectrums(state, analysis.id);
  const schema = selectServiceSchema(state);
  const machine = selectMachinesMap(state)[analysis.machine];

  return {
    serviceError: selectServiceError(
      state,
      ANALYSES,
      SPECTRUMS /* , REPORT */,
      TASK_FILE_DATA
    ),
    analysis,
    machine,
    spectrums,
    missingData: !(isLinkedAnalysis && spectrums),
    isLinkedAnalysis,
    getLinkedAnalysisOngoing: selectAnalysesOngoing(state, "getLinkedAnalysis"),
    getAllSpectrumsOngoing: selectSpectrumsOngoing(state, "getAllSpectrums"),
    // createReportOngoing: selectReportOngoing(state, "createSensorReport"),
    saveTaskFileDataOngoing: selectTaskFilesOngoing(state, "saveTaskFileData"),
    canSeeAnalysisDetails: hasCapability(state, CAN_ACCESS_ALL_ANALYSIS_DATA),
    canSeeAllKeyFigures: hasCapability(state, CAN_ACCESS_ALL_ANALYSIS_DATA),
    spectrumSchema: schemaKey(schema, "spectrums"),
    keyFigures: schemaKey(schema, "key_figures"),
    images: selectSpectrumImages(state, analysis.id),
    translation: selectTranslation(state, language),
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getLinkedAnalysis,
  getAllSpectrums,
  // createSensorReport,
  saveTaskFileData,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisDetails)
);
