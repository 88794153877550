import React from "react";
import { Segment, Form, Input, List, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import NextLoader from "NextLoader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_LABELS } from "slices/userDataSlice";
import {
  selectAll as selectLabels,
  selectLabelsOngoing,
  selectLabelsNext,
  getLabels,
  getNextLabels,
  addLabel,
  removeLabels,
} from "slices/labelsSlice";
import { toggleSet } from "utils";
import {
  LABEL_ICON,
  ADD_ICON,
  REFRESH_ICON,
  REMOVE_ICON,
  SELECT_ICON,
  SELECTED_ICON,
  toIcon,
} from "icons";

const LABELS = "Labels/labels";

class Labels extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      adding: false,
      name: "",
      selected: new Set(),
      promptRemove: false,
    });
  }

  componentDidMount() {
    const { labels } = this.props;
    if (labels.length === 0) {
      this.refresh();
    }
  }

  refresh = () => {
    const { getLabels, t } = this.props;
    getLabels(opts(LABELS, t("Labels.refreshError"))).catch((e) => {
      console.error(e);
    });
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(LABELS);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState({ adding: false });
    this.refresh();
  };

  handleChange = (_, { value: name }) => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState({ name });
  };

  handleSelect = (_, { value }) => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => ({
      selected: toggleSet(value, state.selected),
    }));
  };

  handleAdding = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => ({ adding: !state.adding }));
  };

  handleAdd = () => {
    const { addLabel, t } = this.props;
    const { name } = this.state;
    this.clearErrors();
    this.clearServiceErrors();
    this.setState({ name: "", adding: false });
    addLabel({ name }, opts(LABELS, t("Labels.addError"))).catch((e) => {
      console.error(e);
    });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okRemove = () => {
    const { removeLabels, t } = this.props;
    const { selected } = this.state;
    const labels = Array.from(selected.values());
    this.setState({ selected: new Set(), promptRemove: false });
    removeLabels(labels, opts(LABELS, t("Labels.removeError"))).catch((e) => {
      console.error(e);
    });
  };

  moreLabels = (key, func) => {
    const { t } = this.props;

    return (
      <List.Item
        key={key}
        as={NextLoader}
        loadNext={() =>
          func(opts(LABELS, t("Labels.moreError"))).catch((e) => {
            console.error(e);
          })
        }
        more={<p className="Labels__more">{t("Labels.moreLabels")}</p>}
      />
    );
  };

  labelItems = memoizeOne((labels, selected, canEdit, _language) => {
    const { labelsNext, getNextLabels } = this.props;
    return labels
      .map((label) => {
        return (
          <List.Item key={label.id}>
            {toIcon(LABEL_ICON)}
            <List.Content>{label.name}</List.Content>
            {canEdit && (
              <div className="Labels__action">
                <Button
                  type="button"
                  className="Labels__select"
                  icon
                  onClick={this.handleSelect}
                  circular
                  compact
                  basic
                  value={label.id}
                  data-cy="Labels__select"
                >
                  {toIcon(selected.has(label.id) ? SELECTED_ICON : SELECT_ICON)}
                </Button>
              </div>
            )}
          </List.Item>
        );
      })
      .concat(labelsNext ? [this.moreLabels(labelsNext, getNextLabels)] : []);
  });

  render() {
    const {
      serviceError,
      getLabelsOngoing,
      addLabelOngoing,
      removeLabelsOngoing,
      canEdit,
      labels,
      i18n: { language },
      t,
    } = this.props;
    const { name, selected, adding, promptRemove } = this.state;

    const loading = getLabelsOngoing || addLabelOngoing || removeLabelsOngoing;

    const labelItems = this.labelItems(labels, selected, canEdit, language);

    const someSelected = selected.size > 0;
    const addDisabled = !canEdit || name.length === 0;
    const canAdd = canEdit;
    const canRemove = canEdit && someSelected;

    return (
      <Segment as="div" className="Labels" loading={loading}>
        <Prompt
          open={promptRemove}
          header={t("Labels.removePrompt.header")}
          okText={t("Labels.removePrompt.ok")}
          cancelText={t("Labels.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <div className="Labels__tools">
          <Button
            type="button"
            className="Labels__tool"
            icon
            onClick={this.handleRefresh}
            compact
            basic
            data-cy="Labels__refresh"
          >
            {toIcon(REFRESH_ICON)}
          </Button>
          <Button
            type="button"
            className="Labels__tool"
            icon
            onClick={this.handleRemove}
            compact
            basic
            disabled={!canRemove}
            data-cy="Labels__remove"
          >
            {toIcon(REMOVE_ICON)}
          </Button>
          <Button
            type="button"
            className="Labels__tool"
            icon
            onClick={this.handleAdding}
            compact
            basic
            disabled={!canAdd}
            data-cy="Labels__add"
          >
            {toIcon(ADD_ICON)}
          </Button>
        </div>
        {adding && (
          <Form className="Labels__input" onSubmit={this.handleAdd}>
            <Input
              action={{
                icon: toIcon(ADD_ICON),
                onClick: this.handleAdd,
                disabled: addDisabled,
              }}
              fluid
              placeholder={t("Labels.addLabelPlaceholder")}
              value={name}
              onChange={this.handleChange}
            />
          </Form>
        )}
        {labelItems.length > 0 ? (
          <List>{labelItems}</List>
        ) : (
          <p>{t("Labels.noLabels")}</p>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceError: selectServiceError(state, LABELS),
  getLabelsOngoing: selectLabelsOngoing(state, "getLabels"),
  addLabelOngoing: selectLabelsOngoing(state, "addLabel"),
  removeLabelsOngoing: selectLabelsOngoing(state, "removeLabels"),
  canEdit: hasCapability(state, CAN_MANAGE_LABELS),
  labelsNext: selectLabelsNext(state),
  labels: selectLabels(state),
});

const mapDispatchToProps = {
  clearServiceErrors,
  getLabels,
  getNextLabels,
  addLabel,
  removeLabels,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Labels))
);
