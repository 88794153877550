import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const DimLoader = ({ active = true }) => (
  <Dimmer active={active} inverted data-cy="Dimmer">
    <Loader className="Dimmer__loader" active={active} />
  </Dimmer>
);

export default DimLoader;
