import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";
import equal from "fast-deep-equal";

import {
  apiGetMachineLogItems,
  apiGetNextJson,
  apiCreateMachineLogEntry,
  apiGetMachineLogEntry,
  apiModifyMachineLogEntry,
  apiDeleteMachineLogEntry,
} from "api";
import { selectToken } from "slices/tokenSlice";
import { selectServiceSchema } from "slices/userDataSlice";
import { getHierarchy } from "slices/hierarchySlice";
import { selectEntities as selectSensors } from "slices/sensorsSlice";
import { selectEntities as selectMachines } from "slices/machinesSlice";

import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) =>
    b.object.measured_at.localeCompare(a.object.measured_at),
});

const machineLog = createSlice({
  name: "machineLog",
  initialState: adapter.getInitialState({
    ongoing: {},
    next: null,
    query: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      const { results, next, query } = payload;
      state.next = next;
      state.query = query;
      adapter.addMany(state, results);
    },
    addNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.addMany(state, results);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, { id: payload.id, changes: payload });
    },
    upsert(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    removeAll(state, { payload }) {
      adapter.removeAll(state)
    }
  },
});

export default machineLog.reducer;

const {
  start,
  end,
  set,
  addNext,
  add,
  update,
  upsert,
  remove,
  removeAll
} = machineLog.actions;

const selectState = (state) => state.machineLog;

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors(
  selectState
);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

const selectSomeOngoing = (state, ...keys) => {
  return keys.some((key) => selectState(state).ongoing[key]);
};

const itemId = (type, id) => `${type} ${id}`;

const toItem = (item) => ({
  id: itemId(item.content_type, item.content_object.id),
  type: item.content_type,
  object: item.content_object,
});

const toItems = (items, query) => ({
  ...items,
  results: items.results.map((i) => toItem(i)),
  query,
});

const selectItemsMO = memoizeOne((items, machine) =>
  items.filter((i) => i.object.machine === machine)
);

export const selectItems = (state, machine = 0) =>
  selectItemsMO(selectAll(state), machine);

const logEntryType = (state) =>
  selectServiceSchema(state)["log_item_groups"]["log_entry"];

export const selectLogEntry = (state, id) =>
  selectEntities(state)[itemId(logEntryType(state), id)]?.object;

export const selectNext = (state) => {
  if (selectSomeOngoing(state, "getMachineLogItems, getNextMachineLogItems")) {
    return null;
  }
  return selectState(state).next;
};

const hasHierarchy = (state, items) => {
  const machines = selectMachines(state);
  const sensors = selectSensors(state);

  return !items.some(({ content_object }) => {
    if (content_object.sensor) {
      return !sensors[content_object.sensor];
    }
    return !machines[content_object.machine];
  });
};

export const getMachineLogItems = (reload, query, options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (
      selectSomeOngoing(state, "getMachineLogItems, getNextMachineLogItems")
    ) {
      return;
    }
    if (!reload && equal(query, selectState(state).query)) {
      return;
    }

    dispatch(start("getMachineLogItems"));
    const logItems = await apiGetMachineLogItems(selectToken(state), query);

    if (!hasHierarchy(state, logItems.results)) {
      console.log("machineLogSlice, no hierarchy");
      await dispatch(getHierarchy(false, options));
    }

    batchSuccess(
      dispatch,
      options,
      set(toItems(logItems, query)),
      end("getMachineLogItems")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getMachineLogItems"));
    throw error;
  }
};

export const clearMachineLogCache = () => async (
  dispatch,
  getState
) => {
  dispatch(removeAll(getState()))
}

export const getNextMachineLogItems = (options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const next = selectNext(state);
    if (!next) {
      return;
    }

    dispatch(start("getNextMachineLogItems"));
    const logItems = await apiGetNextJson(selectToken(state), next);

    if (!hasHierarchy(state, logItems.results)) {
      await dispatch(getHierarchy(false, options));
    }

    batchSuccess(
      dispatch,
      options,
      addNext(toItems(logItems)),
      end("getNextMachineLogItems")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getNextMachineLogItems"));
    throw error;
  }
};

/* LogEntry */

const entryToItem = (type, entry) => {
  return {
    id: itemId(type, entry.id),
    type,
    object: entry,
  };
};

export const getMachineLogEntry = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("getMachineLogEntry"));
    const state = getState();
    const entry = await apiGetMachineLogEntry(selectToken(state), id);
    batchSuccess(
      dispatch,
      options,
      upsert(entryToItem(logEntryType(state), entry)),
      end("getMachineLogEntry")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getMachineLogEntry"));
    throw error;
  }
};

export const addMachineLogEntry = (data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("addMachineLogEntry"));
    const state = getState();
    const entry = await apiCreateMachineLogEntry(selectToken(state), data);
    batchSuccess(
      dispatch,
      options,
      add(entryToItem(logEntryType(state), entry)),
      end("addMachineLogEntry")
    );
    return entry;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addMachineLogEntry"));
    throw error;
  }
};

export const modifyMachineLogEntry = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("modifyMachineLogEntry"));
    const state = getState();
    const entry = await apiModifyMachineLogEntry(selectToken(state), id, data);
    batchSuccess(
      dispatch,
      options,
      update(entryToItem(logEntryType(state), entry)),
      end("modifyMachineLogEntry")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("modifyMachineLogEntry"));
    throw error;
  }
};

export const removeMachineLogEntry = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("removeMachineLogEntry"));
    const state = getState();
    await apiDeleteMachineLogEntry(selectToken(state), id);
    batchSuccess(
      dispatch,
      options,
      remove(itemId(logEntryType(state), id)),
      end("removeMachineLogEntry")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeMachineLogEntry"));
    throw error;
  }
};
