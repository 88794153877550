import React, { useState, useCallback } from "react";
import { Modal, Image } from "semantic-ui-react";

const SpectrumImage = ({ img, inline }) => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  return (
    <Modal
      trigger={<Image onClick={openModal} wrapped src={img} inline={inline} />}
      open={open}
      onClose={closeModal}
    >
      <Modal.Content>
        <Image onClick={closeModal} wrapped src={img} />
      </Modal.Content>
    </Modal>
  );
};

export default SpectrumImage;
