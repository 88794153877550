import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import InventoryList from "InventoryList";

import AdaComponent from "AdaComponent";
import {
  selectServiceError,
  clearServiceErrors,
  opts,
} from "slices/serviceErrorsSlice";
import {
  hasCapability,
  CAN_MANAGE_CUSTOM_REFERENCES,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectCustomReferences } from "slices/customReferencesSlice";
import { selectAll as selectMachineCustomReferences } from "slices/machineCustomReferencesSlice";

import { goTo } from "utils";
import {
  newCustomReferenceTo,
  customReferenceTo,
  newMachineCustomReferenceTo,
  machineCustomReferenceTo,
} from "appRoutes";
import {
  CUSTOM_REFERENCE_ICON,
  MACHINE_CUSTOM_REFERENCE_ICON,
  toIcon,
} from "icons";

const CUSTOM_REFERENCE_HIERARCHY = "CustomReferences/hierarchy";
const MACHINE_CUSTOM_REFERENCE_HIERARCHY = "MachineCustomReferences/hierarchy";

class CustomReferences extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    const { hasHierarchy } = this.props;
    if (!hasHierarchy) {
      this.refresh();
    }
  }

  refresh = (reload = false) => {
    const { getHierarchy, refreshId, t } = this.props;
    getHierarchy(
      reload,
      opts(refreshId, t("CustomReferences.refreshError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  clearServiceErrors = () => {
    const { serviceError, refreshId, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(refreshId);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { newReferenceTo, location, history } = this.props;
    goTo(newReferenceTo(location), history);
  };

  selectTo = (id) => {
    const { referenceTo, location } = this.props;
    return referenceTo(id, location);
  };

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      canEdit,
      customReferences,
      icon,
      t,
      i18n: { language },
    } = this.props;

    const loading = getHierarchyOngoing;

    return (
      <Segment
        as="div"
        className="CustomReferences"
        loading={loading}
        data-cy="MachineGroups"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <InventoryList
          items={customReferences}
          icon={icon}
          onRefresh={this.handleRefresh}
          refreshText={t("CustomReferences.refresh")}
          onAdd={canEdit && this.handleAdd}
          addText={t("CustomReferences.add")}
          selectTo={this.selectTo}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mode } = ownProps;

  let references;
  let newReferenceTo;
  let referenceTo;
  let icon;
  let refreshId;

  switch (mode) {
    default: /* intentional */
    case "sensor":
      references = selectCustomReferences(state);
      newReferenceTo = newCustomReferenceTo;
      referenceTo = customReferenceTo;
      icon = toIcon(CUSTOM_REFERENCE_ICON);
      refreshId = CUSTOM_REFERENCE_HIERARCHY;
      break;
    case "machine":
      references = selectMachineCustomReferences(state);
      newReferenceTo = newMachineCustomReferenceTo;
      referenceTo = machineCustomReferenceTo;
      icon = toIcon(MACHINE_CUSTOM_REFERENCE_ICON);
      refreshId = MACHINE_CUSTOM_REFERENCE_HIERARCHY;
      break;
  }

  return {
    serviceError: selectServiceError(state, refreshId),
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    canEdit: hasCapability(state, CAN_MANAGE_CUSTOM_REFERENCES),
    customReferences: references,
    newReferenceTo,
    referenceTo,
    icon,
    refreshId,
  };
};

const mapStateToSensorProps = (state, props) =>
  mapStateToProps(state, { ...props, mode: "sensor" });

const mapStateToMachineProps = (state, props) =>
  mapStateToProps(state, { ...props, mode: "machine" });

const mapDispatchToSensorProps = {
  getHierarchy,
  clearServiceErrors,
};

const mapDispatchToMachineProps = {
  getHierarchy,
  clearServiceErrors,
};

export const SensorCustomReferences = withTranslation()(
  withRouter(
    connect(
      mapStateToSensorProps,
      mapDispatchToSensorProps
    )((props) => <CustomReferences {...props} mode="sensor" />)
  )
);
export const MachineCustomReferences = withTranslation()(
  withRouter(
    connect(
      mapStateToMachineProps,
      mapDispatchToMachineProps
    )((props) => <CustomReferences {...props} mode="machine" />)
  )
);
