import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Loader from "Loader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
  getIncompleteErrors,
  clearIncompleteErrors,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_LOCATIONS } from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectLocations } from "slices/locationsSlice";
import {
  selectOngoing as selectMachineGroupsOngoing,
  selectEntities as selectMachineGroupsMap,
  addMachineGroup,
  updateMachineGroup,
  removeMachineGroup,
} from "slices/machineGroupsSlice";
import { parseId, goBack, redirectTo, queryParameters } from "utils";
import { machineGroupTo } from "appRoutes";
import { toIcon, EDIT_ICON, DROPDOWN_ICON } from "icons";

const HIERARCHY = "MachineGroup/hierarchy";
export const MACHINE_GROUPS = "MachineGroup/machineGroups";

class MachineGroup extends AdaComponent {
  constructor(props) {
    super(props);

    const { machineGroup, isNew, t } = props;
    const errors = getIncompleteErrors(machineGroup);

    this.setInitialState(
      {
        editing: isNew,
        promptRemove: false,
        ...this.initialEditState(),
      },
      {
        errors,
        header: t("MachineGroup.incomplete"),
        content: t("MachineGroup.incompleteContent"),
      }
    );
  }

  componentDidMount = () => {
    const { getHierarchy, t } = this.props;

    window.scrollTo(0, 0);
    getHierarchy(
      false,
      opts(HIERARCHY, t("MachineGroup.hierarchyError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  initialEditState = () => {
    const { machineGroup, locationId } = this.props;
    return {
      name: machineGroup?.name || "",
      description: machineGroup?.description || "",
      locationId,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY, MACHINE_GROUPS);
    }
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okRemove = async () => {
    const {
      machineGroup,
      removeMachineGroup,
      history,
      rrLocation,
      t,
    } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeMachineGroup(
      machineGroup.id,
      opts(MACHINE_GROUPS, t("MachineGroup.removeError"))
    )
      .then(() => goBack(history, rrLocation))
      .catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = async () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const { name, description, locationId } = this.state;
    const {
      machineGroup,
      addMachineGroup,
      updateMachineGroup,
      history,
      rrLocation,
      t,
    } = this.props;

    const errors = [];
    if (!name || name.length === 0) {
      errors.push("name");
    }
    if (!locationId) {
      errors.push("locationId");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("MachineGroup.validationError"),
        errors,
      });
    }

    const machineGroupData = {
      name,
      description,
      location: locationId,
    };
    if (!machineGroup) {
      addMachineGroup(
        machineGroupData,
        opts(MACHINE_GROUPS, t("MachineGroup.addError"))
      )
        .then((mg) => redirectTo(machineGroupTo(mg.id, rrLocation), history))
        .catch((e) => console.error(e));
    } else {
      machineGroupData.data = clearIncompleteErrors(machineGroup);
      this.setState({ editing: false });
      updateMachineGroup(
        machineGroup.id,
        machineGroupData,
        opts(MACHINE_GROUPS, t("MachineGroup.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  locationOptions = memoizeOne((locations) => {
    return locations.map((l) => ({
      key: l.id,
      value: l.id,
      text: l.name,
    }));
  });

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      isNew,
      isMissingData,
      getHierarchyOngoing,
      addMachineGroupOngoing,
      updateMachineGroupOngoing,
      removeMachineGroupOngoing,
      canEdit,
      locations,
      i18n: { language },
      t,
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (isMissingData) {
      return <Loader />;
    }

    const { name, description, locationId } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addMachineGroupOngoing ||
      updateMachineGroupOngoing ||
      removeMachineGroupOngoing;

    const locationOptions = this.locationOptions(locations);

    const showTools = canEdit && !isNew;
    const addingNew = editing && isNew;
    const editingExisting = editing && !isNew;

    return (
      <Segment
        as="div"
        className="MachineGroup"
        loading={loading}
        data-cy="MachineGroup"
      >
        <Prompt
          open={promptRemove}
          header={t("MachineGroup.removePrompt.header")}
          content={t("MachineGroup.removePrompt.content")}
          okText={t("MachineGroup.removePrompt.ok")}
          cancelText={t("MachineGroup.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />
        {showTools && (
          <div className="MachineGroup__tools">
            <Button
              className="MachineGroup__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
              data-cy="MachineGroup__edit"
            />
          </div>
        )}
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("MachineGroup.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("MachineGroup.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
              data-cy="MachineGroup__name"
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("MachineGroup.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("MachineGroup.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
              data-cy="MachineGroup__description"
            />
          </Form.Field>
          <Form.Field required error={this.hasError("locationId")}>
            <label>{t("MachineGroup.location")}</label>
            <Form.Dropdown
              name="locationId"
              placeholder={t("MachineGroup.locationPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={locationOptions}
              value={locationId}
              onChange={this.handleChange}
              noResultsMessage={t("MachineGroup.noLocations")}
              data-cy="MachineGroup__locations"
            />
          </Form.Field>
          {addingNew && (
            <Form.Button
              className="MachineGroup__button"
              type="submit"
              primary
              data-cy="MachineGroup__add"
            >
              {t("MachineGroup.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button
                className="MachineGroup__button"
                type="submit"
                primary
                data-cy="MachineGroup__update"
              >
                {t("MachineGroup.update")}
              </Form.Button>
              <Button
                className="MachineGroup__button"
                type="button"
                negative
                onClick={this.handleRemove}
                data-cy="MachineGroup__remove"
              >
                {t("MachineGroup.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    location: rrLocation /* react-router */,
  } = ownProps;

  const machineGroupId = parseId(id);
  const machineGroup = selectMachineGroupsMap(state)[machineGroupId];

  const locationId =
    machineGroup?.location ||
    parseId(queryParameters(rrLocation)["location"]) ||
    0;

  return {
    serviceError: selectServiceError(state, HIERARCHY, MACHINE_GROUPS),
    machineGroupId,
    machineGroup,
    locationId,
    isNew: !machineGroupId,
    isMissingData: machineGroupId && !machineGroup,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addMachineGroupOngoing: selectMachineGroupsOngoing(
      state,
      "addMachineGroup"
    ),
    updateMachineGroupOngoing: selectMachineGroupsOngoing(
      state,
      "updateMachineGroup"
    ),
    removeMachineGroupOngoing: selectMachineGroupsOngoing(
      state,
      "removeMachineGroup"
    ),
    canEdit: hasCapability(state, CAN_MANAGE_LOCATIONS),
    locations: selectLocations(state),
    rrLocation,
  };
};

const mapDispatchToProps = {
  getHierarchy,
  addMachineGroup,
  updateMachineGroup,
  removeMachineGroup,
  clearServiceErrors,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MachineGroup)
);
