import React, { useEffect } from "react";
import { List, Loader } from "semantic-ui-react";
import { useInView } from "react-intersection-observer";

const NextLoader = ({ loadNext, more, triggerOnce = true }) => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce });

  useEffect(() => {
    if (inView) {
      loadNext();
    }
  }, [loadNext, inView]);

  return (
    <List.Item>
      <List.Content>
        <div ref={ref}>
          {/* {inView ? <Loader active={inView} inline="centered" /> : more} */}
          <Loader active={inView} inline="centered" />
        </div>
      </List.Content>
    </List.Item>
  );
};

export default NextLoader;
