import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import MainMenu from "MainMenu";
import createCrubs from "Crubs";
import {
  HOME,
  USER_PROFILE,
  LOCATIONS,
  NEW_LOCATION,
  LOCATION,
  MACHINE_GROUPS,
  NEW_MACHINE_GROUP,
  MACHINE_GROUP,
  MACHINES,
  NEW_MACHINE,
  MACHINE,
  SENSORS,
  NEW_SENSOR,
  SENSOR,
  CUSTOM_REFERENCES,
  NEW_CUSTOM_REFERENCE,
  CUSTOM_REFERENCE,
  MACHINE_CUSTOM_REFERENCES,
  NEW_MACHINE_CUSTOM_REFERENCE,
  MACHINE_CUSTOM_REFERENCE,
  SENSOR_GROUPS,
  NEW_SENSOR_GROUP,
  SENSOR_GROUP,
  HIERARCHY,
  TASKS,
  NEW_HIERARCHY,
  NEW_ANALYSIS,
  TASK,
  LABELS,
  ANALYSES,
  ANALYSIS,
  MACHINE_LOG,
  NEW_LOG_ENTRY,
  LOG_ENTRY,
  GROUP_ANALYSES,
} from "appRoutes";

import {
  selectBundle,
  CAN_ACCESS_EXTENDED_UI,
  CAN_ACCESS_TECHNICIAN_UI,
  hasCapability,
} from "slices/userDataSlice";
import { LOGOUT_ICON } from "./icons";

const DynamicLocationBreadcrumb = ({ match }) => <span>{match.params.id}</span>;
const DynamicMachineGroupBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicMachineBreadcrumb = ({ match }) => <span>{match.params.id}</span>;
const DynamicSensorBreadcrumb = ({ match }) => <span>{match.params.id}</span>;
const DynamicCustomReferenceBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicMachineCustomReferenceBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicSensorGroupBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicTaskBreadcrumb = ({ match }) => <span>{match.params.id}</span>;
const DynamicDiagnosisGroupBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicMachineLogBreadcrumb = ({ match }) => (
  <span>{match.params.id}</span>
);
const DynamicLogEntryBreadcrumb = ({ match }) => <span>{match.params.id}</span>;

class Main extends Component {
  constructor(props) {
    super(props);

    const { bundle, t, i18n } = props;

    if (bundle) {
      Object.keys(bundle).forEach((b) => {
        i18n.addResourceBundle(b, "client", bundle[b]);
      });
    }

    this.userRouting = [
      {
        ...HOME,
        title: t("Main.home"),
      },
      {
        ...USER_PROFILE,
        title: t("Main.userProfile"),
      },
    ];
    this.advancedUserRouting = [
      {
        ...HOME,
        title: t("Main.home"),
      },
      {
        ...USER_PROFILE,
        title: t("Main.userProfile"),
      },
      {
        ...LOCATION,
      },
      {
        ...MACHINE_GROUP,
      },
      {
        ...MACHINE,
      },
      {
        ...SENSOR,
      },
      {
        ...CUSTOM_REFERENCE,
      },
      {
        ...MACHINE_CUSTOM_REFERENCE,
      },
      {
        ...HIERARCHY,
        title: t("Main.status"),
        breadcrumb: t("Main.status"),
      },
      {
        ...ANALYSES,
        title: t("Main.analyses"),
      },
      {
        ...ANALYSIS,
      },
      {
        ...TASK,
        breadcrumb: DynamicTaskBreadcrumb,
      },
      {
        ...MACHINE_LOG,
        breadcrumb: DynamicMachineLogBreadcrumb,
      },
      {
        ...NEW_LOG_ENTRY,
        breadcrumb: t("Main.newLogEntry"),
      },
      {
        ...LOG_ENTRY,
        breadcrumb: DynamicLogEntryBreadcrumb,
      },
      {
        ...GROUP_ANALYSES,
      },
    ];
    this.technicianRouting = [
      {
        ...HOME,
        title: t("Main.home"),
        breadcrumb: t("Main.home"),
      },
      {
        ...USER_PROFILE,
        title: t("Main.userProfile"),
        breadcrumb: t("Main.userProfile"),
      },
      {
        ...LOCATIONS,
        title: t("Main.locations"),
        breadcrumb: t("Main.locations"),
      },
      {
        ...NEW_LOCATION,
        breadcrumb: t("Main.newLocation"),
      },
      {
        ...LOCATION,
        breadcrumb: DynamicLocationBreadcrumb,
      },
      {
        ...MACHINE_GROUPS,
        breadcrumb: t("Main.machineGroups"),
      },
      {
        ...NEW_MACHINE_GROUP,
        breadcrumb: t("Main.newMachineGroup"),
      },
      {
        ...MACHINE_GROUP,
        breadcrumb: DynamicMachineGroupBreadcrumb,
      },
      {
        ...MACHINES,
        breadcrumb: t("Main.machines"),
      },
      {
        ...NEW_MACHINE,
        breadcrumb: t("Main.newMachine"),
      },
      {
        ...MACHINE,
        breadcrumb: DynamicMachineBreadcrumb,
      },
      {
        ...SENSORS,
        breadcrumb: t("Main.sensors"),
      },
      {
        ...NEW_SENSOR,
        breadcrumb: t("Main.newSensor"),
      },
      {
        ...SENSOR,
        breadcrumb: DynamicSensorBreadcrumb,
      },
      {
        ...CUSTOM_REFERENCES,
        breadcrumb: t("Main.customReferences"),
      },
      {
        ...NEW_CUSTOM_REFERENCE,
        breadcrumb: t("Main.newCustomReference"),
      },
      {
        ...CUSTOM_REFERENCE,
        breadcrumb: DynamicCustomReferenceBreadcrumb,
      },
      {
        ...MACHINE_CUSTOM_REFERENCES,
        breadcrumb: t("Main.machineCustomReferences"),
      },
      {
        ...NEW_MACHINE_CUSTOM_REFERENCE,
        breadcrumb: t("Main.newMachineCustomReference"),
      },
      {
        ...MACHINE_CUSTOM_REFERENCE,
        breadcrumb: DynamicMachineCustomReferenceBreadcrumb,
      },
      {
        ...SENSOR_GROUPS,
        title: t("Main.sensorGroups"),
        breadcrumb: t("Main.sensorGroups"),
      },
      {
        ...NEW_SENSOR_GROUP,
        breadcrumb: t("Main.newSensorGroup"),
      },
      {
        ...SENSOR_GROUP,
        breadcrumb: DynamicSensorGroupBreadcrumb,
      },
      {
        ...HIERARCHY,
        title: t("Main.hierarchy"),
        breadcrumb: t("Main.hierarchy"),
      },
      {
        ...TASKS,
        title: t("Main.tasks"),
        breadcrumb: t("Main.tasks"),
      },
      {
        ...NEW_HIERARCHY,
        title: t("Main.newHierarchy"),
        breadcrumb: t("Main.newHierarchy"),
      },
      {
        ...NEW_ANALYSIS,
        title: t("Main.newData"),
        breadcrumb: t("Main.newData"),
      },
      {
        ...TASK,
        breadcrumb: DynamicTaskBreadcrumb,
      },
      {
        ...LABELS,
        breadcrumb: t("Main.labels"),
      },
      {
        ...ANALYSES,
        title: t("Main.analyses"),
        breadcrumb: t("Main.analyses"),
      },
      {
        ...ANALYSIS,
        breadcrumb: DynamicDiagnosisGroupBreadcrumb,
      },
      {
        ...GROUP_ANALYSES,
      },
      {
        ...MACHINE_LOG,
        breadcrumb: DynamicMachineLogBreadcrumb,
      },
      {
        ...NEW_LOG_ENTRY,
        breadcrumb: t("Main.newLogEntry"),
      },
      {
        ...LOG_ENTRY,
        breadcrumb: DynamicLogEntryBreadcrumb,
      },
    ];
    this.defaultRoute = 0;
    this.userCrubs = createCrubs(this.userRouting);
    this.advancedUserCrubs = createCrubs(this.advancedUserRouting);
    this.technicianCrubs = createCrubs(this.technicianRouting);
    this.userRoutes = this.userRouting.filter((r) => !!r.component);
    this.advancedUserRoutes = this.advancedUserRouting.filter(
      (r) => !!r.component
    );
    this.technicianRoutes = this.technicianRouting.filter((r) => !!r.component);

    this.userMenuItems = [
      this.userRouting.find((r) => r.key === "Home"),
      this.userRouting.find((r) => r.key === "UserProfile"),
      { key: "divider" },
      { key: "Logout", title: t("Main.logout"), path: "/logout", icon: LOGOUT_ICON },
    ];
    this.advancedUserMenuItems = [
      this.advancedUserRouting.find((r) => r.key === "Home"),
      this.advancedUserRouting.find((r) => r.key === "Hierarchy"),
      { key: "divider" },
      this.advancedUserRouting.find((r) => r.key === "UserProfile"),
      { key: "Logout", title: t("Main.logout"), path: "/logout", icon: LOGOUT_ICON },
    ];
    this.technicianMenuItems = [
      this.technicianRouting.find((r) => r.key === "Home"),
      this.technicianRouting.find((r) => r.key === "Hierarchy"),
      this.technicianRouting.find((r) => r.key === "NewHierarchy"),
      this.technicianRouting.find((r) => r.key === "NewAnalysis"),
      this.technicianRouting.find((r) => r.key === "Tasks"),
      {
        ...this.technicianRouting.find((r) => r.key === "Locations"),
        title: t("Main.inventory"),
      },
      { key: "divider" },
      this.technicianRouting.find((r) => r.key === "UserProfile"),
      {
        key: "Logout",
        title: t("Main.logout"),
        path: "/logout",
        icon: LOGOUT_ICON,
      },
    ];

    this.userMenu = <MainMenu items={this.userMenuItems} />;
    this.advancedUserMenu = <MainMenu items={this.advancedUserMenuItems} />;
    this.technicianMenu = <MainMenu items={this.technicianMenuItems} />;
  }

  logout = () => {
    const { onLogout } = this.props;
    onLogout();
    return <Redirect to="/login" />;
  };

  render() {
    const { extendedUI, technicianUI } = this.props;
    const Menu = technicianUI
      ? this.technicianMenu
      : extendedUI
      ? this.advancedUserMenu
      : this.userMenu;
    const Crubs = technicianUI
      ? this.technicianCrubs
      : extendedUI
      ? this.advancedUserCrubs
      : this.userCrubs;
    const routes = technicianUI
      ? this.technicianRoutes
      : extendedUI
      ? this.advancedUserRoutes
      : this.userRoutes;
    const defaultRoute = this.defaultRoute;
    const showCrubs = !!technicianUI;

    return (
      <>
        {Menu}
        {showCrubs && <Crubs />}
        {routes && (
          <Switch>
            {routes.map((route) => (
              <Route {...route} />
            ))}
            <Route exact path="/logout" render={this.logout} />
            <Redirect to={routes[defaultRoute].path} />
          </Switch>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bundle: selectBundle(state),
  extendedUI: hasCapability(state, CAN_ACCESS_EXTENDED_UI),
  technicianUI: hasCapability(state, CAN_ACCESS_TECHNICIAN_UI),
});

export default withTranslation()(connect(mapStateToProps)(Main));
