import React from "react";
import { Table } from "semantic-ui-react";

const KeyFigures = ({ keyHeader, valueHeader, keys, values }) => {
  return (
    <Table
      className="KeyFigures"
      basic="very"
      celled
      unstackable
      size="small"
      compact="very"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{keyHeader}</Table.HeaderCell>
          <Table.HeaderCell>{valueHeader}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {keys.map((k) => (
          <Table.Row key={k}>
            <Table.Cell>{k}</Table.Cell>
            <Table.Cell textAlign="right">{values[k]}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default KeyFigures;
