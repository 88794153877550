import { batch } from "react-redux";
import { addServiceError, clearServiceError } from "slices/serviceErrorsSlice";

export function startOngoing(state, { payload: key }) {
  state.ongoing[key] = true;
}

export function endOngoing(state, { payload: key }) {
  state.ongoing[key] = false;
}

export { batch };

export function batchSuccess(dispatch, options, ...actions) {
  batch(() => {
    actions.forEach((a) => a && dispatch(a));
    if (options) {
      dispatch(clearServiceError(options.id));
    }
  });
}

export function batchFailure(dispatch, error, options, ...actions) {
  batch(() => {
    actions.forEach((a) => a && dispatch(a));
    if (options) {
      dispatch(addServiceError({ error, ...options }));
    }
  });
}

export const addId = (ids, id) => [...ids, id].sort((a, b) => a - b);
export const removeId = (ids, id) => ids.filter((i) => i !== id);

export const subLocations = (id, locationsMap) => {
  const location = locationsMap[id];
  let locations = [];
  locations = locations.concat(location.children);
  location.children.forEach((c) => {
    locations = locations.concat(subLocations(c, locationsMap));
  });
  return locations;
};

export const subMachineGroups = (locations, locationsMap) => {
  let machineGroups = [];
  locations.forEach((l) => {
    machineGroups = machineGroups.concat(locationsMap[l].machine_groups);
  });

  return machineGroups;
};

export const subMachines = (machineGroups, machineGroupsMap) => {
  let machines = [];
  machineGroups.forEach((m) => {
    machines = machines.concat(machineGroupsMap[m].machines);
  });

  return machines;
};

export const subSensors = (machines, machinesMap) => {
  let sensors = [];
  machines.forEach((m) => {
    sensors = sensors.concat(machinesMap[m].sensors);
  });

  return sensors;
};
