import { createSlice } from "@reduxjs/toolkit";

const ui = createSlice({
  name: "ui",
  initialState: {
    flags: {},
    data: {},
  },
  reducers: {
    toggleFlag(state, { payload }) {
      state.flags[payload] = !state.flags?.[payload];
    },
    setFlag(state, { payload }) {
      state.flags[payload.id] = !!payload.status;
    },
    setData(state, { payload }) {
      const { id, data } = payload;
      state.data[id] = data;
    },
  },
});

export default ui.reducer;

const { toggleFlag, setFlag, setData } = ui.actions;

export { toggleFlag, setFlag, setData };

const selectState = (state) => state.ui;

export const selectFlags = (state) => selectState(state).flags;
export const selectFlag = (state, id) => selectState(state).flags[id];
export const selectData = (state, id) => selectState(state).data[id];
