import moment from "moment";
// import { DateTime, Interval } from "luxon";

export function getTo(pathname, state = {}, location) {
  const { returnPath, returnState } = (location && location.state) || {};
  return {
    pathname,
    state: { ...state, returnPath, returnState },
  };
}

export function goBack(history, location) {
  const { returnPath, returnState } = location.state || {};
  if (returnPath) {
    history.replace(returnPath, returnState);
  } else {
    history.goBack();
  }
}

export function goTo(to, history) {
  history.push(to.pathname, to.state);
}

export function redirectTo(to, history) {
  history.replace(to.pathname, to.state);
}

export const fileKey = (file) =>
  `${file.name} ${file.size} ${file.lastModified}`;

export function filenameFromURL(url) {
  return url.split("/").pop();
}

export const filenameFromPath = (path) => {
  return path && path.split("/").pop();
};

export function currentTime() {
  return moment();
  // return DateTime.local().toJSDate();
}

export function currentTimeString(format) {
  return formatDatetime(currentTime(), format);
  // return DateTime.local().toISO();
}

export function validDatetime(dt, format) {
  const t = format ? moment(dt, format, true) : moment(dt);
  return t && t.isValid() ? t : undefined;
  // const t = format ? DateTime.fromFormat(dt, format) : DateTime.fromISO(dt);
  // return t && !t.invalid ? t.toJSDate() : false;
}

export function parseDatetime(dt) {
  const t = moment(dt);
  return t.isValid() ? t.toDate() : undefined;
  // return DateTime.fromISO(dt).toJSDate();
}

export function formatDatetime(dt, format) {
  const t = moment(dt);
  return format ? t.format(format) : t.toISOString();
  // return DateTime.fromISO(dt).toJSDate();
}

export function dhmDiff(start, end) {
  const mstart = moment(start);
  const mend = moment(end);
  const minutes = mend.diff(mstart, "minutes");
  const hours = mend.diff(mstart, "hours");
  const days = mend.diff(mstart, "days");
  return {
    minutes,
    hours,
    days,
  };

  // const i = Interval.fromDateTimes(start, end);
  // return {
  //   minutes: i.count("minutes"),
  //   hours: i.count("hours"),
  //   days: i.count("days"),
  // };
}

export function timeDiff(dt, t) {
  const now = moment();
  const then = moment(dt);
  // const now = DateTime.local();
  // const then = DateTime.fromISO(dt);

  let dhm;

  const isBefore = then.isBefore(now);
  // const isBefore = now > then;

  if (isBefore) {
    dhm = dhmDiff(then, now);
  } else {
    dhm = dhmDiff(now, then);
  }

  if (dhm.days) {
    return t(isBefore ? "utils.sinceDays" : "utils.toDays", {
      count: dhm.days,
    });
  } else if (dhm.hours) {
    return t(isBefore ? "utils.sinceHours" : "utils.toHours", {
      count: dhm.hours,
    });
  } else if (dhm.minutes) {
    return t(isBefore ? "utils.sinceMinutes" : "utils.toMinutes", {
      count: dhm.minutes,
    });
  } else {
    return t("utils.now");
  }
}

export function timeDiffMillis(before, after) {
  const t1 = moment(before).toDate();
  const t2 = moment(after).toDate();
  // const t1 = DateTime.fromISO(before).toJSDate();
  // const t2 = DateTime.fromISO(after).toJSDate();
  return t2.getTime() - t1.getTime();
}

export function isAfter(a, b) {
  return moment(a).isAfter(b);
  // return DateTime.fromISO(a) > DateTime.fromISO(b);
}

export const isPositiveNumber = (value) => value >= 0;

export const maybeValidNumber = (
  value,
  validator,
  specials = ["", "+", "-"]
) => {
  if (specials.includes(value)) {
    return true;
  }
  value = "" + value;
  if (value.match(/^[+-]?\d+([.,]\d*)?$/)) {
    if (validator) {
      return validator(parseFloat(value.replace(",", ".")));
    }
    return true;
  }
  return false;
};

export const maybeValidPositiveNumber = (value) =>
  maybeValidNumber(value, isPositiveNumber, ["", "+"]);

export const stringToNumber = (value) => {
  value = "" + value;
  if (value.match(/^[+-]?\d+([.,]\d*)?$/)) {
    return parseFloat(value.replace(",", "."));
  } else {
    return undefined;
  }
};

export const numberToString = (value) => {
  if (value === undefined) {
    return undefined;
  }

  return "" + value;
};

export const parseId = (id) => parseInt(id, 10) || undefined;
export const numberToPercentage = (value) => {
  if (value <= 0) {
    return 0;
  }
  if (value >= 1.0) {
    return 100;
  }
  return Math.round(value * 100);
};

export const toggleSet = (value, set) => {
  const newSet = new Set(set);
  if (!newSet.has(value)) {
    newSet.add(value);
  } else {
    newSet.delete(value);
  }
  return newSet;
};

export const queryParameters = (location) => {
  const params = {};
  for (const [key, value] of new URLSearchParams(location.search)) {
    params[key] = value;
  }
  return params;
};

export const analysisGroups = (
  analyses,
  diagnosesMap,
  hierarchyData,
  allSensors,
  priorities,
  sortLocale
) => {
  const groups = new Map();
  const sensorNodes = new Map();

  if (!allSensors || allSensors.length === 0) {
    allSensors = analyses.map((analysis) => analysis.sensor);
  }

  const sensorMap = hierarchyData.sensors;
  const machineMap = hierarchyData.machines;
  const machineGroupMap = hierarchyData.machineGroups;

  allSensors.forEach((id) => {
    const s = sensorMap[id];
    if (!s) {
      return;
    }
    const m = machineMap[s.machine];
    const mg = machineGroupMap[m.machine_group];

    if (!groups.has(mg.id)) {
      groups.set(mg.id, {
        id: mg.id,
        name: mg.name,
        description: mg.description,
        location: mg.location,
        machines: new Map(),
      });
    }
    const machineGroup = groups.get(mg.id);

    const machines = machineGroup.machines;
    if (!machines.has(m.id)) {
      machines.set(m.id, {
        id: m.id,
        name: m.name,
        description: m.description,
        machineGroup: machineGroup,
        sensors: new Map(),
      });
    }
    const machine = machines.get(m.id);

    const sensors = machine.sensors;
    if (!sensors.has(s.id)) {
      sensors.set(s.id, {
        id: s.id,
        name: s.name,
        description: s.description,
        machine,
        analyses: [],
      });
    }
    sensorNodes.set(s.id, sensors.get(s.id));
  });

  // ensure analyses are arranged from the newest to the oldest
  const sorted = [...analyses].sort((a, b) =>
    b.measured_at.localeCompare(a.measured_at, sortLocale)
  );

  // console.log("XOOXOO:", JSON.stringify(sorted, null, 2));

  // Each sensor may have multiple analyses and only the latest
  // status will be used as sensor status

  sorted.forEach((analysis) => {
    const s = sensorNodes.get(analysis.sensor);

    // Add analyses to matching sensors
    s.analyses.push(analysis);

    // Set sensor status
    analysis.diagnoses.forEach((diagnosis) => {
      const d = diagnosesMap ? diagnosesMap[diagnosis] : diagnosis;
      adjustSensorStatus(
        s,
        analysis.id,
        d.category,
        analysis.measured_at,
        priorities
      );
    });
  });

  // Sensor statuses are stable now; move sensor status up to machine
  // and machine group
  for (let s of sensorNodes.values()) {
    adjustNodeStatus(s.machine, s.status, priorities);
    adjustNodeStatus(s.machine.machineGroup, s.machine.status, priorities);
  }

  // for (let s of sensorNodes.values()) {
  //   s.analyses.forEach((a) => {
  //     a.diagnoses.forEach((d) => {
  //       d = diagnosesMap ? diagnosesMap[d] : d;
  //       adjustStatus(s, a.id, d.category, a.measured_at, priorities);
  //     });
  //     adjustStatus(s.machine, s.id, s.status, s.latest_measured_at, priorities);
  //     adjustStatus(
  //       s.machine.machineGroup,
  //       s.machine.id,
  //       s.machine.status,
  //       s.machine.latest_measured_at,
  //       priorities
  //     );
  //   });
  // }

  const sortedGroups = Array.from(groups.values()).sort((a, b) =>
    a.name.localeCompare(b.name, sortLocale)
  );

  sortedGroups.forEach((g) => {
    g.machines = Array.from(g.machines.values()).sort((a, b) =>
      a.name.localeCompare(b.name, sortLocale)
    );
    g.machines.forEach((m) => {
      m.sensors = Array.from(m.sensors.values()).sort((a, b) =>
        a.name.localeCompare(b.name, sortLocale)
      );
    });
  });

  return sortedGroups;
};

export function adjustSensorStatus(
  node,
  source,
  status,
  measured_at,
  priorities
) {
  const m = parseDatetime(measured_at);
  if (!node.status) {
    node.source = source;
    node.status = status;
    node.statusPriority = priorities[status];
    node.latest_measured_at = m;
  } else {
    if (
      priorities[status] > node.statusPriority &&
      m >= node.latest_measured_at
    ) {
      node.source = source;
      node.status = status;
      node.statusPriority = priorities[status];
      node.latest_measured_at = m;
    }
  }
}

export function adjustNodeStatus(node, status, priorities) {
  if (!node.status) {
    node.status = status;
    node.statusPriority = priorities[status];
  } else {
    if (priorities[status] > node.statusPriority) {
      node.status = status;
      node.statusPriority = priorities[status];
    }
  }
}
