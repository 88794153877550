import { createSlice } from "@reduxjs/toolkit";

import { selectToken } from "slices/tokenSlice";
import {
  selectUserInfo,
  selectServiceSchema,
  selectTranslation,
  schemaKey,
  translationKey,
  selectSortLocale,
} from "slices/userDataSlice";
import { selectEntities as selectSensors } from "slices/sensorsSlice";
import { selectEntities as selectTasks } from "slices/tasksSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";
import i18n from "i18n";
import { saveTaskReport, saveSensorReport } from "Reporter";

const report = createSlice({
  name: "report",
  initialState: { ongoing: {} },
  reducers: {
    start: startOngoing,
    end: endOngoing,
  },
});

export default report.reducer;

const { start, end } = report.actions;

export const selectReportOngoing = (state, key) => state.report.ongoing[key];

export const createTaskReport = (taskIds, detailed, opts) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("createTaskReport"));
    const state = getState();

    const tasksMap = selectTasks(state);
    const tasks = taskIds.map((id) => tasksMap[id]);

    const token = selectToken(state);
    const userInfo = selectUserInfo(state);
    const schema = selectServiceSchema(state);
    const { language } = i18n;
    const translation = selectTranslation(state, language);

    await saveTaskReport(
      tasks,
      detailed,
      token,
      userInfo,
      schema,
      translation,
      i18n.getFixedT(language),
      selectSortLocale(state)
    );

    batchSuccess(dispatch, opts, end("createTaskReport"));
  } catch (error) {
    batchFailure(dispatch, error, opts, end("createTaskReport"));
    throw error;
  }
};

export const createSensorReport = (sensorId, opts) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("createSensorReport"));
    const state = getState();

    const sensor = selectSensors(state)[sensorId];
    const token = selectToken(state);
    const userInfo = selectUserInfo(state);
    const schema = selectServiceSchema(state);
    const { language } = i18n;
    const translation = selectTranslation(state, language);

    await saveSensorReport(
      sensor,
      token,
      userInfo,
      schemaKey.bind(undefined, schema),
      translationKey.bind(undefined, translation),
      i18n.getFixedT(language)
    );

    batchSuccess(dispatch, opts, end("createSensorReport"));
  } catch (error) {
    batchFailure(dispatch, error, opts, end("createSensorReport"));
    throw error;
  }
};
