import React, { Fragment } from "react";
import { Breadcrumb } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { locationPath } from "slices/hierarchySlice";
import { selectEntities as selectLocations } from "slices/locationsSlice";

function MachineGroupPath({ machineGroup }) {
  const locations = useSelector(selectLocations);
  const location = locations[machineGroup.location];

  const path = location && locationPath(location, locations);
  const { t } = useTranslation();

  return (
    <Breadcrumb>
      {path
        ? path.map((p) => (
            <Fragment key={p.id}>
              {/* <Breadcrumb.Divider key={`pathb-${p.id}`} />
              <Breadcrumb.Section key={`pathc-${p.id}`} content={p.name} /> */}
              <Breadcrumb.Divider />
              <Breadcrumb.Section content={p.name} />
            </Fragment>
          ))
        : t("MachineGroupPath.unknownPath")}
      <Breadcrumb.Divider />
      {machineGroup.name}
    </Breadcrumb>
  );
}

export default MachineGroupPath;
