import React from "react";
import { Segment, Button, Table, List } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";
import { selectToken } from "slices/tokenSlice";

import AdaComponent from "AdaComponent";
import Loader from "Loader";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  selectTranslation,
  translationKey,
  isTaskRunning,
  hasTaskFinished,
  hasCapability,
  CAN_ACCESS_TECHNICIAN_UI,
  CAN_ACCESS_TASK_FILE,
} from "slices/userDataSlice";
import {
  selectEntities as selectTasks,
  selectTasksOngoing,
  getTask,
} from "slices/tasksSlice";
import {
  selectTaskFilesOngoing,
  selectTaskFiles,
  getAllTaskFiles,
} from "slices/taskFilesSlice";

import {
  parseDatetime,
  timeDiffMillis,
  parseId,
  numberToPercentage,
} from "utils";
import { sensorGroupTo } from "appRoutes";
import { REFRESH_ICON, toIcon } from "icons";
import { apiGetModifiedTarFile } from "./api";

const TASKS = "Task/tasks";
const TASK_FILES = "TaskFile/taskFiles";

class Task extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    const { task, schema, taskId, taskFiles, getAllTaskFiles, t } = this.props;
    window.scrollTo(0, 0);
    if (!task || !hasTaskFinished(task, schema)) {
      this.refresh();
    }
    if (taskFiles.length === 0) {
      getAllTaskFiles(
        taskId,
        opts(TASK_FILES, t("Tasks.refreshError"))
      ).catch((e) => console.error(e));
    }
  }

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(TASKS);
    }
  };

  refresh = async () => {
    const { taskId, getTask, t } = this.props;
    this.clearErrors();
    this.clearServiceErrors();
    getTask(taskId, opts(TASKS, t("Tasks.refreshError"))).catch((e) =>
      console.error(e)
    );
  };

  handleRefresh = () => {
    this.refresh();
  };

  parseCreatedAt = memoizeOne((datetime) => parseDatetime(datetime));
  parseStartedAt = memoizeOne((datetime) => parseDatetime(datetime));
  parseEndedAt = memoizeOne((datetime) => parseDatetime(datetime));
  taskWait = memoizeOne(
    (created_at, started_at) =>
      created_at && started_at && timeDiffMillis(created_at, started_at)
  );
  taskDuration = memoizeOne(
    (started_at, ended_at) =>
      started_at && ended_at && timeDiffMillis(started_at, ended_at)
  );

  render() {
    const {
      serviceError,
      task,
      taskFiles,
      missingData,
      getTasksOngoing,
      getTaskFilesOngoing,
      canAccessTaskDetails,
      canAccessTaskFile,
      schema,
      translation,
      location,
      token,
      i18n: { language },
      t,
    } = this.props;

    if (missingData) {
      return <Loader/>;
    }

    const loading = getTasksOngoing;

    const created_at = this.parseCreatedAt(task.created_at);
    const started_at = this.parseStartedAt(task.started_at);
    const ended_at = this.parseEndedAt(task.ended_at);
    const taskWait = this.taskWait(task.created_at, task.started_at);
    const taskDuration = this.taskDuration(task.started_at, task.ended_at);
    const status = translationKey(translation, "task_statuses")[task.status];
    const user = task.username;
    const data_file_format = task.data_file_format;
    const label = task.label;
    const data = task.data || {};
    const hierarchy_root = data.hierarchy_root;
    const errors = data.errors;
    const warnings = data.warnings;

    const progress = isTaskRunning(task, schema)
      ? t("Task.progressStatus", {
        progress: numberToPercentage(task.progress),
      })
      : undefined;

    return (
      <Segment as="div" className="Task" loading={loading}>
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <div className="Task__toolbar">
          <Button
            className="Task__toolbar_button"
            type="button"
            icon={toIcon(REFRESH_ICON)}
            basic
            compact
            circular
            onClick={this.handleRefresh}
            data-cy="Task__refresh"
          />
        </div>
        <Table className="Task__table" unstackable>
          <Table.Body>
            {canAccessTaskDetails ? (
              <>
                <Table.Row>
                  <Table.Cell>{t("Task.createdAt")}</Table.Cell>
                  <Table.Cell>
                    {t("Task.createdAtFormat", { created_at })}
                  </Table.Cell>
                </Table.Row>
                {started_at && (
                  <Table.Row>
                    <Table.Cell>{t("Task.startedAt")}</Table.Cell>
                    <Table.Cell>
                      {t("Task.startedAtFormat", {
                        started_at,
                        wait: taskWait,
                      })}
                    </Table.Cell>
                  </Table.Row>
                )}
                {ended_at && (
                  <Table.Row>
                    <Table.Cell>{t("Task.endedAt")}</Table.Cell>
                    <Table.Cell>
                      {t("Task.endedAtFormat", {
                        ended_at,
                        duration: taskDuration,
                      })}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>{t("Task.status")}</Table.Cell>
                  <Table.Cell>{status}</Table.Cell>
                </Table.Row>
                {progress && (
                  <Table.Row>
                    <Table.Cell>{t("Task.progress")}</Table.Cell>
                    <Table.Cell>{progress}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>{t("Task.user")}</Table.Cell>
                  <Table.Cell>{user}</Table.Cell>
                </Table.Row>
                {/*
                <Table.Row>
                  <Table.Cell>{t("Task.dataFileFormat")}</Table.Cell>
                  <Table.Cell>{data_file_format}</Table.Cell>
                </Table.Row>
                 */}
                {!!task.sensor_group && (
                  <Table.Row>
                    <Table.Cell>{t("Task.sensorGroup")}</Table.Cell>
                    <Table.Cell>
                      <NavLink to={sensorGroupTo(task.sensor_group, location)}>
                        {t("Task.sensorGroupLink")}
                      </NavLink>
                    </Table.Cell>
                  </Table.Row>
                )}
                {!!label && (
                  <Table.Row>
                    <Table.Cell>{t("Task.label")}</Table.Cell>
                    <Table.Cell>{label}</Table.Cell>
                  </Table.Row>
                )}
                {!!hierarchy_root && (
                  <Table.Row>
                    <Table.Cell>{t("Task.hierarchyRoot")}</Table.Cell>
                    <Table.Cell>{hierarchy_root}</Table.Cell>
                  </Table.Row>
                )}
                {!!errors && (
                  <Table.Row>
                    <Table.Cell>{t("Task.errors")}</Table.Cell>
                    <Table.Cell>
                      {errors.map((e, i) => (
                        <p key={i}>
                          {translationKey(translation, "task_errors")[e.error]}{" "}
                          ({e.description})
                        </p>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {!!warnings && (
                  <Table.Row>
                    <Table.Cell>{t("Task.warnings")}</Table.Cell>
                    <Table.Cell>
                      {warnings.map((w, i) => (
                        <p key={i}>{w}</p>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {!!canAccessTaskFile && (
                  <>
                    <Table.Row>
                      <Table.Cell>{t("Task.taskFiles")}</Table.Cell>
                      <Table.Cell>
                        {(getTaskFilesOngoing && <Loader active inline/>) || (
                          <List>
                            {taskFiles.map((tf) => (
                              <List.Content key={tf.id}>
                                <a
                                  href={tf.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download={tf.name}
                                >
                                  {tf.name}
                                </a>
                              </List.Content>
                            ))}
                          </List>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </>
            ) : (
              <>
                <Table.Row>
                  <Table.Cell>{t("Task.user")}</Table.Cell>
                  <Table.Cell>{user}</Table.Cell>
                </Table.Row>
                {/*
                <Table.Row>
                  <Table.Cell>{t("Task.dataFileFormat")}</Table.Cell>
                  <Table.Cell>{data_file_format}</Table.Cell>
                </Table.Row>
                */}
                {!!canAccessTaskFile && (
                  <>
                    <Table.Row>
                      <Table.Cell>{t("Task.taskFiles")}</Table.Cell>
                      <Table.Cell>
                        {(getTaskFilesOngoing && <Loader active inline/>) || (
                          <List>
                            {taskFiles.map((tf) => (
                              <List.Content key={tf.id}>
                                <a
                                  href={tf.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {tf.name}
                                </a>
                              </List.Content>
                            ))}
                          </List>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </>
            )}
          </Table.Body>
        </Table>

        <div>
          {taskFiles.map(it => (
            <div key={it.id}>
              <Button
                disabled={this.state.exportLoading}
                onClick={async () => {
                  this.setState({ exportLoading: true })
                  apiGetModifiedTarFile(token, { task_file: it.id })
                    .finally(() => {
                      this.setState({ exportLoading: false })
                    })
                    .catch(err => window.alert("Error occurred: " + err))
                }}
              >
                Export tar with modified hierarchy
                {this.state.exportLoading && <Loader/>}
              </Button>
            </div>
          ))}
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    i18n: { language },
  } = ownProps;

  const taskId = parseId(id);
  const task = selectTasks(state)[taskId];
  const taskFiles = selectTaskFiles(state, taskId);

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  return {
    serviceError: selectServiceError(state, TASKS),
    taskId,
    task,
    taskFiles,
    missingData: taskId && !task,
    getTasksOngoing: selectTasksOngoing(state, "getTask"),
    getTaskFilesOngoing: selectTaskFilesOngoing(state, "getAllTaskFiles"),
    canAccessTaskDetails: hasCapability(state, CAN_ACCESS_TECHNICIAN_UI),
    canAccessTaskFile: hasCapability(state, CAN_ACCESS_TASK_FILE),
    schema,
    translation,
    token: selectToken(state)
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getTask,
  getAllTaskFiles,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Task)
);
