import React from "react";
import { List } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import MachineGroupPath from "MachineGroupPath";
import DiagnosisSummary from "DiagnosisSummary";

import {
  selectServiceSchema,
  diagnosisCategoryGroups,
} from "slices/userDataSlice";

import { selectEntities as selectDiagnoses } from "slices/diagnosesSlice";

import { parseDatetime } from "utils";
import { analysisTo } from "appRoutes";
import { MACHINE_GROUP_ICON, MACHINE_ICON, SENSOR_ICON, toIcon } from "icons";

const scn = (baseclass, status, schema) => {
  const groups = diagnosisCategoryGroups(status, schema);
  return classNames({
    [baseclass]: true,
    [`${baseclass}--ok`]: groups.ok,
    [`${baseclass}--warning`]: groups.warning,
    [`${baseclass}--danger`]: groups.danger,
    [`${baseclass}--unsupported`]: groups.unsupported,
  });
};

const topStatus = (diagnoses, diagnosesMap, priorities) => {
  let c = undefined;
  let p = 0;
  diagnoses.forEach((d) => {
    const dd = diagnosesMap[d];
    if (dd) {
      if (priorities[dd.category] > p) {
        c = dd.category;
        p = priorities[dd.category];
      }
    }
  });
  return c;
};

function AnalysesGroup({ group, priorities, linkedToAnalysis = true }) {
  const { t } = useTranslation();
  const location = useLocation();

  const diagnosisMap = useSelector((state) => selectDiagnoses(state));
  const schema = useSelector((state) => selectServiceSchema(state));

  return (
    <List.Item
      key={group.id}
      className={scn("AnalysesGroup__item", group.status, schema)}
      data-cy="AnalysisGroup"
    >
      <List.Content>
        <List.Header>
          {toIcon(MACHINE_GROUP_ICON)}
          <MachineGroupPath machineGroup={group} />
        </List.Header>
        <List.Description>{group.description}</List.Description>

        <List celled className="AnalysesGroup__list">
          {group.machines.map((machine) => (
            <List.Item
              key={machine.id}
              className={scn("AnalysesGroup__item", machine.status, schema)}
            >
              <List.Header>
                {toIcon(MACHINE_ICON)}
                {machine.name}
              </List.Header>
              <List.Description>{machine.description}</List.Description>
              <List celled key={machine.id} className="AnalysesGroup__list">
                {machine.sensors.map((sensor) => (
                  <List.Item
                    key={sensor.id}
                    className={scn(
                      "AnalysesGroup__item",
                      sensor.status,
                      schema
                    )}
                  >
                    <List.Header>
                      {toIcon(SENSOR_ICON)}
                      {sensor.name}
                    </List.Header>
                    <List.Description>{sensor.description}</List.Description>
                    <List
                      celled
                      key={sensor.id}
                      className="AnalysesGroup__list"
                    >
                      {sensor.analyses.map((analysis) => {
                        const measuredAt = parseDatetime(analysis.measured_at);
                        return (
                          <List.Item
                            key={analysis.id}
                            className={scn(
                              "AnalysesGroup__item",
                              topStatus(
                                analysis.diagnoses,
                                diagnosisMap,
                                priorities
                              ),
                              schema
                            )}
                          >
                            <List.Description>
                              {t("AnalysesGroup.measuredAt", {
                                measuredAt,
                              })}
                            </List.Description>
                            <List.Description>
                              {analysis.diagnoses.map((diagnosis) => {
                                diagnosis = diagnosisMap[diagnosis];
                                return (
                                  <List.Item
                                    key={diagnosis.id}
                                    as={(linkedToAnalysis && Link) || undefined}
                                    to={analysisTo(analysis.id, location)}
                                    className={scn(
                                      "AnalysesGroup__link",
                                      diagnosis.category,
                                      schema
                                    )}
                                  >
                                    <DiagnosisSummary diagnosis={diagnosis} />
                                  </List.Item>
                                );
                              })}
                            </List.Description>
                          </List.Item>
                        );
                      })}
                    </List>
                  </List.Item>
                ))}
              </List>
            </List.Item>
          ))}
        </List>
      </List.Content>
    </List.Item>
  );
}

export default AnalysesGroup;
