import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  selectServiceSchema,
  diagnosisCategoryGroups,
} from "slices/userDataSlice";

import { selectTranslation, translationKey } from "slices/userDataSlice";
import { VALIDATED_ICON, toIcon, UNKNOWN_ICON, WARNING_ICON, DANGER_ICON, ACCEPTABLE_ICON } from "icons";

function DiagnosisSummary({ as = "div", diagnosis, hideTodos }) {
  const As = as;
  const {
    i18n: { language },
  } = useTranslation();
  const translation = useSelector((state) =>
    selectTranslation(state, language)
  );
  const { ok, warning, danger, unsupported } = useSelector((state) =>
    diagnosisCategoryGroups(diagnosis.category, selectServiceSchema(state))
  );
  const category = translationKey(translation, "diagnosis_categories")[
    diagnosis.category
    ];
  const fault = translationKey(translation, "diagnosis_faults")[
    diagnosis.fault
    ];
  const validated = diagnosis.validated;
  const original_category = translationKey(translation, "diagnosis_categories")[
    diagnosis.data?.original_diagnosis?.category
    ];
  const original_fault = translationKey(translation, "diagnosis_faults")[
    diagnosis.data?.original_diagnosis?.fault
    ];

  const { ok: original_ok } = useSelector((state) =>
    diagnosisCategoryGroups(original_category, selectServiceSchema(state))
  );

  let original_diagnosis = undefined;
  if (category !== original_category || fault !== original_fault) {
    original_diagnosis = original_ok
      ? original_category
      : `${original_category} - ${original_fault}`;
  }

  let d = original_diagnosis ? `${category} (${original_diagnosis})` : category;
  if (warning || danger) {
    d = original_diagnosis
      ? `${category} - ${fault} [${original_diagnosis}]`
      : `${category} - ${fault}`;
  }

  const categoryIcon = [{
    "Acceptable": ACCEPTABLE_ICON[0],
    "Unsupported": { ...UNKNOWN_ICON[0], color: 'grey' },
    "Warning": WARNING_ICON[0],
    "Danger": DANGER_ICON[0],
  }[diagnosis.category]]

  const todos = [diagnosis?.data?.todo1, diagnosis?.data?.todo2, diagnosis?.data?.todo3]
    .filter(Boolean)
    .map(it => translationKey(translation, 'todos')[it])

  const todosTargetTime = diagnosis?.data?.todos_target_time

  return (
    <As
      className={classNames({
        DiagnosisSummary: true,
        DiagnosisSummary__ok: ok,
        DiagnosisSummary__warning: warning,
        DiagnosisSummary__danger: danger,
        DiagnosisSummary__unsupported: unsupported,
      })}
    >
      <span style={{ fontSize: '1.5em' }}>
        {toIcon(categoryIcon || UNKNOWN_ICON)}
      </span>
      <span>{d}</span>
      {!hideTodos &&
      <div style={{ fontSize: '0.85em', color: 'gray', marginLeft: '30px' }}>
        {todos.join(', ')}
        {todosTargetTime && " [" + translationKey(translation, 'todos_target_times')[todosTargetTime] + "]"}
      </div>}
      {validated && (
        <span className="DiagnosisSummary__validated">
          {toIcon(VALIDATED_ICON)}
        </span>
      )}
    </As>
  );
}

export default DiagnosisSummary;
