import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  apiGetTasks,
  apiGetTask,
  apiGetNextJson,
  apiCreateTask,
  apiArchiveTask,
  apiDeleteTask,
  apiSubmitTask,
  apiRestartTask,
  apiCreateTaskFile,
} from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";
import { isAfter } from "utils";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});

const tasks = createSlice({
  name: "tasks",
  initialState: adapter.getInitialState({
    ongoing: {},
    next: null,
    lastUpdated: null,
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      const { results, next, updated } = payload;
      state.next = next;
      state.lastUpdated = updated;

      adapter.setAll(state, results);
    },
    addNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.addMany(state, results);
    },
    update(state, { payload }) {
      const { results, updated } = payload;
      const newTasks = results.filter((r) =>
        isAfter(r.created_at, state.lastUpdated)
      );
      const currentTasks = results.filter((r) => !!state.entities[r.id]);

      adapter.addMany(state, newTasks);

      adapter.updateMany(
        state,
        currentTasks.map((r) => ({
          id: r.id,
          changes: r,
        }))
      );
      state.lastUpdated = updated;
    },
    upsert(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
    archiveMany(state, { payload }) {
      adapter.updateMany(
        state,
        payload.map((t) => ({
          id: t,
          changes: { archived: true },
        }))
      );
    },
  },
});

export default tasks.reducer;

const {
  start,
  end,
  set,
  addNext,
  update,
  upsert,
  add,
  removeMany,
  archiveMany,
} = tasks.actions;

const selectTasksState = (state) => state.tasks;
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectTasksState);

export const selectTasksOngoing = (state, key) =>
  !!selectTasksState(state).ongoing[key];
const selectTasksOngoingMany = (state, ...keys) => {
  return keys.every((key) => selectTasksState(state).ongoing[key]);
};

export const selectTasksNext = (state) => {
  if (selectTasksOngoingMany(state, "getTasks", "getNextTasks")) {
    return null;
  }
  return selectTasksState(state).next;
};

const _getTasks = (filter, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectTasksOngoing(state, "getTasks")) {
      return;
    }
    dispatch(start("getTasks"));
    const updated = new Date().toISOString();
    const tasks = await apiGetTasks(selectToken(getState()), filter);
    batchSuccess(
      dispatch,
      options,
      set({ ...tasks, updated }),
      end("getTasks")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getTasks"));
    throw error;
  }
};

export const getTasks = (options) => _getTasks({}, options);
export const getOwnTasks = (options) => _getTasks({ user_only: true }, options);

export const getNextTasks = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const next = selectTasksNext(state);
    if (!next || selectTasksOngoing(state, "getNextTasks")) {
      return;
    }
    dispatch(start("getNextTasks"));
    const tasks = await apiGetNextJson(selectToken(state), next);
    batchSuccess(dispatch, options, addNext(tasks), end("getNextTasks"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getNextTasks"));
    throw error;
  }
};

export const _getUpdatedTasks = (filter, options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (selectTasksOngoing(state, "getUpdatedTasks")) {
      return;
    }
    dispatch(start("getUpdatedTasks"));
    const { lastUpdated } = selectTasksState(state);
    const updated = new Date().toISOString();
    let tasks = await apiGetTasks(selectToken(state), {
      ...filter,
      modified_after: lastUpdated,
    });
    batchSuccess(dispatch, options, update({ ...tasks, updated }));
    let next = tasks.next;
    while (next) {
      tasks = await apiGetNextJson(selectToken(state), next);
      dispatch(update({ ...tasks, updated }));
      next = tasks.next;
    }
    dispatch(end("getUpdatedTasks"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getUpdatedTasks"));
    throw error;
  }
};

export const getUpdatedTasks = (options) => _getUpdatedTasks({}, options);
export const getUpdatedOwnTasks = (options) =>
  _getUpdatedTasks({ user_only: true }, options);

export const getTask = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("getTask"));
    const task = await apiGetTask(selectToken(getState()), id);
    batchSuccess(dispatch, options, upsert(task), end("getTask"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getTask"));
    throw error;
  }
};

export const addTask = (data, files, options) => async (dispatch, getState) => {
  try {
    const token = selectToken(getState());
    dispatch(start("addTask"));
    const task = await apiCreateTask(token, data);
    try {
      for (let i = 0; i < files.length; i += 1) {
        await apiCreateTaskFile(token, {
          name: files[i].name,
          task: task.id,
          file: files[i],
        });
      }
    } catch (e) {
      await apiDeleteTask(token, task.id);
      throw e;
    }
    await apiSubmitTask(token, task.id);
    batchSuccess(dispatch, options, add(task), end("addTask"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("addTask"));
    throw error;
  }
};

export const archiveTasks = (tasks, options) => async (dispatch, getState) => {
  try {
    dispatch(start("archiveTasks"));
    await Promise.all(
      tasks.map((task) => apiArchiveTask(selectToken(getState()), task))
    );
    batchSuccess(dispatch, options, archiveMany(tasks), end("archiveTasks"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("archiveTasks"));
    throw error;
  }
};

export const removeTasks = (tasks, options) => async (dispatch, getState) => {
  try {
    dispatch(start("removeTasks"));
    await Promise.all(
      tasks.map((task) => apiDeleteTask(selectToken(getState()), task))
    );
    batchSuccess(dispatch, options, removeMany(tasks), end("removeTasks"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeTasks"));
    throw error;
  }
};

export const restartTask = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("restartTask"));
    await apiRestartTask(selectToken(getState()), id);
    batchSuccess(dispatch, options, end("restartTask"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("restartTask"));
    throw error;
  }
};
