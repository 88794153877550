import { batch } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";

const serviceErrors = createSlice({
  name: "serviceErrors",
  initialState: { errors: {} },
  reducers: {
    addError(state, { payload }) {
      state.errors[payload.id] = payload;
    },
    clearError(state, { payload }) {
      state.errors[payload] = null;
    }
  }
});

export default serviceErrors.reducer;

export const { addError: addServiceError } = serviceErrors.actions;
const { clearError } = serviceErrors.actions;

const selectServiceErrorsState = state => state.serviceErrors;

const selectServiceErrorMO = memoizeOne((errors, ids) => {
  for (let i = 0; i < ids.length; i += 1) {
    const e = errors[ids[i]];
    if (e) {
      return e;
    }
  }
  return undefined;
});

export const selectServiceError = (state, ...ids) => {
  const { errors } = selectServiceErrorsState(state);
  return selectServiceErrorMO(errors, ids);
};

export const clearServiceError = id => async (dispatch, getState) => {
  if (selectServiceErrorsState(getState()).errors[id]) {
    dispatch(clearError(id));
  }
};

export const clearServiceErrors = (...ids) => async (dispatch, getState) => {
  batch(() => {
    ids.forEach(id => {
      if (selectServiceErrorsState(getState()).errors[id]) {
        dispatch(clearError(id));
      }
    });
  });
};

export const opts = (id, message) => {
  return {
    id,
    message
  };
};

export const hasIncompleteErrors = obj => !!obj?.data?.incomplete;

export const getIncompleteErrors = obj => obj?.data?.incomplete;

export const clearIncompleteErrors = obj => {
  if (hasIncompleteErrors(obj)) {
    const d = { ...obj.data };
    d.incomplete = false;
    return d;
  }
  return undefined;
};
