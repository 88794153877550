import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

import {
  apiGetAll,
  apiGetTaskFiles,
  apiGetNextJson,
  apiGetTaskFileData,
} from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({});

const taskFiles = createSlice({
  name: "taskFiles",
  initialState: { ongoing: {}, tasks: {} },
  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      const { id, taskFiles } = payload;
      state.tasks[id] = taskFiles;
    },
  },
});

export default taskFiles.reducer;

const { start, end, set } = taskFiles.actions;

const selectTaskFilesState = (state) => state.taskFiles;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectTaskFilesState);

export const selectTaskFilesOngoing = (state, key) =>
  !!selectTaskFilesState(state).ongoing[key];

export const selectTaskFiles = (state, id) =>
  selectTaskFilesState(state).tasks[id] || [];

export const getAllTaskFiles = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("getAllTaskFiles"));
    const taskFiles = await apiGetAll(
      selectToken(getState()),
      apiGetTaskFiles,
      apiGetNextJson,
      { task: id }
    );
    batchSuccess(
      dispatch,
      options,
      set({ id, taskFiles }),
      end("getAllTaskFiles")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getAllTaskFiles"));
    throw error;
  }
};

export const saveTaskFileData = (
  { id, index, raw, filename },
  options
) => async (dispatch, getState) => {
  try {
    dispatch(start("saveTaskFileData"));
    const data = await apiGetTaskFileData(
      selectToken(getState()),
      id,
      index,
      raw
    );
    const td = data["time_data"];
    const ad = data["acceleration_data"];
    const at = `"Acceleration (${data["acceleration_unit"]})"`;

    const header = td ? `"Time (s)", ${at}` : at;
    const rows = td ? ad.map((d, i) => `${td[i]}, ${d}`) : ad;

    const csv = [header, ...rows].join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);

    batchSuccess(dispatch, options, end("saveTaskFileData"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("saveTaskFileData"));
    throw error;
  }
};
