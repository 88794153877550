import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { init } from "@sentry/react";

import { PersistGate } from "redux-persist/integration/react";

import DimLoader from "DimLoader";
import App from "App";
import configureStore from "store";
import { unregister } from "registerServiceWorker";
import "i18n";

import "./semantic/dist/semantic.min.css";
import "styles/react-datetime.css";
import "styles/index.scss";

if (process.env.NODE_ENV === "production") {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const { store, persistor } = configureStore();

const flushStore = () => persistor.flush();

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={<DimLoader/>} persistor={persistor}>
        <Suspense fallback={<DimLoader/>}>
          <Router>
            <App flushStore={flushStore}/>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App.js", () => {
    console.log("[HMR] Re-rendering App");
    renderApp();
  });
}

renderApp();

unregister();
