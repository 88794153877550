import React, { Fragment } from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Prompt from "Prompt";
import Loader from "Loader";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  hasCapability,
  CAN_MANAGE_CUSTOM_REFERENCES,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import {
  selectOngoing as selectCustomReferencesOngoing,
  selectEntities as selectCustomReferences,
  addCustomReference,
  updateCustomReference,
  removeCustomReference,
} from "slices/customReferencesSlice";
import {
  selectOngoing as selectMachineCustomReferencesOngoing,
  selectEntities as selectMachineCustomReferences,
  addMachineCustomReference,
  updateMachineCustomReference,
  removeMachineCustomReference,
} from "slices/machineCustomReferencesSlice";

import { parseId, goBack, redirectTo } from "./utils";
import { customReferenceTo, machineCustomReferenceTo } from "./appRoutes";
import { toIcon, EDIT_ICON, REMOVE_ICON, DROPDOWN_ICON } from "icons";

const ERROR_IDS = {
  CustomReference: {
    HIERARCHY: "CustomReference/hierarchy",
    CUSTOM_REFERENCES: "CustomReference/customReferences",
  },
  MachineCustomReference: {
    HIERARCHY: "MachineCustomReference/hierarchy",
    CUSTOM_REFERENCES: "MachineCustomReference/customReferences",
  },
};

class CustomReference extends AdaComponent {
  constructor(props) {
    super(props);

    const { isNew } = this.props;
    this.setInitialState({
      editing: isNew,
      promptRemove: false,
      ...this.initialEditState(),
    });
  }

  componentDidMount = () => {
    const { errorIds, getHierarchy, t } = this.props;

    window.scrollTo(0, 0);
    getHierarchy(
      false,
      opts(errorIds.HIERARCHY, t("CustomReference.hierarchyError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  initialEditState = () => {
    const {
      customReference,
      referenceKeys,
      referenceWarnings,
      referenceDangers,
    } = this.props;
    return {
      name: customReference?.name || "",
      description: customReference?.description || "",
      referenceKeys,
      referenceWarnings,
      referenceDangers,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, errorIds, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(errorIds.HIERARHY, errorIds.CUSTOM_REFERENCES);
    }
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  changeReferenceKey = (name, index, value) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();

    const values = this.state[name];
    if (!index !== values.length - 1) {
      return;
    }
    this.changeReference(name, index, value);
  };

  changeReference = (name, index, value) => {
    this.setState((state) => {
      const newValues = [...state[name]];
      newValues[index] = value;
      return {
        [name]: newValues,
      };
    });
  };

  handleChangeReferenceKey = (_, { name, value }) => {
    return this.changeReferenceKey("referenceKeys", name, value);
  };

  handleChangeReferenceWarning = (_, { name, value }) => {
    const { editing, referenceKeys } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(`${referenceKeys[name]}-warning`);
    this.clearServiceErrors();
    return this.changeReference("referenceWarnings", name, value);
  };

  handleChangeReferenceDanger = (_, { name, value }) => {
    const { editing, referenceKeys } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(`${referenceKeys[name]}-danger`);
    this.clearServiceErrors();
    return this.changeReference("referenceDangers", name, value);
  };

  handleRemoveReference = async (_, { value }) => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => ({
      referenceKeys: state.referenceKeys.filter((_, i) => i !== value),
      referenceWarnings: state.referenceWarnings.filter((_, i) => i !== value),
      referenceDangers: state.referenceDangers.filter((_, i) => i !== value),
    }));
  };

  handleAddReference = async () => {
    const { references } = this.props;
    const { referenceKeys } = this.state;
    const rset = new Set(referenceKeys);
    const referenceKeysLeft = references.filter((r) => !rset.has(r));
    if (referenceKeysLeft.length > 0) {
      this.setState((state) => ({
        referenceKeys: state.referenceKeys.concat([referenceKeysLeft[0]]),
        referenceWarnings: state.referenceWarnings.concat([""]),
        referenceDangers: state.referenceDangers.concat([""]),
      }));
    }
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okToRemove = async () => {
    const {
      errorIds,
      customReference,
      removeCustomReference,
      history,
      location,
      t,
    } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeCustomReference(
      customReference.id,
      opts(errorIds.CUSTOM_REFERENCES, t("CustomReference.removeError"))
    )
      .then(() => goBack(history, location))
      .catch((e) => console.error(e));
  };

  handleSubmit = async () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const {
      errorIds,
      customReference,
      addCustomReference,
      updateCustomReference,
      referenceTo,
      history,
      location,
      t,
    } = this.props;
    const {
      name,
      description,
      referenceKeys,
      referenceWarnings,
      referenceDangers,
    } = this.state;

    const errors = [];
    if (!name || name.length === 0) {
      errors.push("name");
    }

    const data = {};
    const rkset = new Set();
    let errorDetail;
    referenceKeys.forEach((r, i) => {
      if (rkset.has(r)) {
        errorDetail = t("CustomReference.duplicateError");
        return errors.push(r);
      }
      rkset.add(r);

      const warning = Number(referenceWarnings[i]);
      const danger = Number(referenceDangers[i]);
      const validWarning = !isNaN(warning) && warning > 0;
      const validDanger = !isNaN(danger) && danger > 0;

      if (!validWarning) {
        errors.push(`${r}-warning`);
      }
      if (!validDanger) {
        errors.push(`${r}-danger`);
      }
      if (validWarning && validDanger && warning > danger) {
        errorDetail = t("CustomReference.referenceError");
        errors.push(`${r}-warning`);
        errors.push(`${r}-danger`);
      }
      data[r] = { warning, danger };
    });

    if (errors.length > 0) {
      return this.setErrors({
        header: t("CustomReference.validationError"),
        content: errorDetail,
        errors,
      });
    }

    if (!customReference) {
      addCustomReference(
        {
          name,
          description,
          data,
        },
        opts(errorIds.CUSTOM_REFERENCES, t("CustomReference.addError"))
      )
        .then((r) => redirectTo(referenceTo(r.id, location), history))
        .catch((e) => console.error(e));
    } else {
      this.setState({ editing: false });
      updateCustomReference(
        customReference.id,
        {
          name,
          description,
          data,
        },
        opts(errorIds.CUSTOM_REFERENCES, t("CustomReference.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  referenceOptions = memoizeOne((references, translation) =>
    references.map((r) => ({
      key: r,
      value: r,
      text: translationKey(translation, "reference_figures")[r],
    }))
  );

  referenceOptionsForLast = memoizeOne(
    (referenceKeys, references, translation) => {
      const rset = new Set(referenceKeys.slice(0, -1));
      return references
        .filter((r) => !rset.has(r))
        .map((r) => ({
          key: r,
          value: r,
          text: translationKey(translation, "reference_figures")[r],
        }));
    }
  );

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      mode,
      serviceError,
      isNew,
      isMissingData,
      getHierarchyOngoing,
      addCustomReferenceOngoing,
      updateCustomReferenceOngoing,
      removeCustomReferenceOngoing,
      canEdit,
      removePromptContent,
      references,
      translation,
      i18n: { language },
      t,
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (isMissingData) {
      return <Loader />;
    }

    const {
      name,
      description,
      referenceKeys,
      referenceWarnings,
      referenceDangers,
    } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addCustomReferenceOngoing ||
      updateCustomReferenceOngoing ||
      removeCustomReferenceOngoing;

    const referenceOptions = this.referenceOptions(references, translation);
    const referenceOptionsForLast = this.referenceOptionsForLast(
      referenceKeys,
      references,
      translation
    );

    const showTools = canEdit && !isNew;
    const addingNew = editing && isNew;
    const editingExisting = editing && !isNew;
    const showAdd = editing && referenceKeys.length < references.length;
    const rklast = referenceKeys.length - 1;

    return (
      <Segment
        as="div"
        className="CustomReference"
        loading={loading}
        data-cy={mode}
      >
        <Prompt
          open={promptRemove}
          header={t("CustomReference.removePrompt.header")}
          content={removePromptContent}
          okText={t("CustomReference.removePrompt.ok")}
          cancelText={t("CustomReference.removePrompt.cancel")}
          onOk={this.okToRemove}
          onCancel={this.cancelRemove}
        />

        {showTools && (
          <div className="CustomReference__tools">
            <Button
              className="CustomReference__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
            />
          </div>
        )}
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("CustomReference.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("CustomReference.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("CustomReference.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("CustomReference.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
            />
          </Form.Field>
          {referenceKeys.map((r, i) => (
            <Fragment key={r}>
              <Form.Field required={i === 0} error={this.hasError(r)}>
                <label>
                  {t("CustomReference.reference")}
                  {editing && i > 0 && (
                    <Button
                      className="CustomReference__remove"
                      type="button"
                      icon={toIcon(REMOVE_ICON)}
                      basic
                      size="tiny"
                      value={i}
                      onClick={this.handleRemoveReference}
                    ></Button>
                  )}
                </label>
                <Form.Dropdown
                  name={i}
                  placeholder={t("CustomReference.referencePlaceholder")}
                  fluid
                  search
                  selection
                  icon={editing && i === rklast ? toIcon(DROPDOWN_ICON) : ""}
                  open={editing && i === rklast && undefined}
                  options={
                    i === rklast ? referenceOptionsForLast : referenceOptions
                  }
                  value={r}
                  onChange={this.handleChangeReferenceKey}
                  noResultsMessage={t("CustomReference.noReferences")}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Input
                  name={i}
                  label={t("CustomReference.warning")}
                  placeholder={t("CustomReference.warningPlaceholder")}
                  className="required"
                  fluid
                  value={referenceWarnings[i]}
                  onChange={this.handleChangeReferenceWarning}
                  error={this.hasError(`${r}-warning`)}
                />
                <Form.Input
                  name={i}
                  label={t("CustomReference.danger")}
                  placeholder={t("CustomReference.dangerPlaceholder")}
                  className="required"
                  fluid
                  value={referenceDangers[i]}
                  onChange={this.handleChangeReferenceDanger}
                  error={this.hasError(`${r}-danger`)}
                />
              </Form.Group>
            </Fragment>
          ))}
          {showAdd && (
            <Form.Field>
              <Button
                type="button"
                positive
                size="tiny"
                onClick={this.handleAddReference}
              >
                {t("CustomReference.addReference")}
              </Button>
            </Form.Field>
          )}

          {addingNew && (
            <Form.Button
              className="CustomReference__button"
              type="submit"
              primary
            >
              {t("CustomReference.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button
                className="CustomReference__button"
                type="submit"
                primary
              >
                {t("CustomReference.update")}
              </Form.Button>
              <Button
                className="CustomReference__button"
                type="button"
                negative
                onClick={this.handleRemove}
              >
                {t("CustomReference.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    mode,
    match: {
      params: { id },
    },
    t,
    i18n: { language },
  } = ownProps;

  let addCustomReferenceOngoing;
  let updateCustomReferenceOngoing;
  let removeCustomReferenceOngoing;

  let customReference;
  let referenceTo;

  let removePromptContent;

  const customReferenceId = parseId(id);
  const errorIds = ERROR_IDS[mode];

  switch (mode) {
    default: /* intentional */
    case "CustomReference":
      addCustomReferenceOngoing = selectCustomReferencesOngoing(
        state,
        "addCustomReference"
      );
      updateCustomReferenceOngoing = selectCustomReferencesOngoing(
        state,
        "updateCustomReference"
      );
      removeCustomReferenceOngoing = selectCustomReferencesOngoing(
        state,
        "removeCustomReference"
      );
      removePromptContent = t("CustomReference.removePrompt.CustomReference");
      customReference = selectCustomReferences(state)[customReferenceId];
      referenceTo = customReferenceTo;
      break;
    case "MachineCustomReference":
      addCustomReferenceOngoing = selectMachineCustomReferencesOngoing(
        state,
        "addMachineCustomReference"
      );
      updateCustomReferenceOngoing = selectMachineCustomReferencesOngoing(
        state,
        "updateMachineCustomReference"
      );
      removeCustomReferenceOngoing = selectMachineCustomReferencesOngoing(
        state,
        "removeMachineCustomReference"
      );
      removePromptContent = t(
        "CustomReference.removePrompt.MachineCustomReference"
      );
      customReference = selectMachineCustomReferences(state)[customReferenceId];
      referenceTo = machineCustomReferenceTo;
      break;
  }

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  const references = schemaKey(schema, "reference_figures");
  const data = customReference?.data || {
    [references[0]]: { warning: "", danger: "" },
  };
  const referenceKeys = [];
  const referenceWarnings = [];
  const referenceDangers = [];

  references.forEach((r) => {
    const d = data[r];
    if (d) {
      referenceKeys.push(r);
      referenceWarnings.push(d.warning);
      referenceDangers.push(d.danger);
    }
  });

  return {
    mode,
    errorIds,
    serviceError: selectServiceError(
      state,
      errorIds.HIERARCHY,
      errorIds.CUSTOM_REFERENCES
    ),
    customReferenceId,
    customReference,
    isNew: !customReferenceId,
    isMissingData: customReferenceId && !customReference,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addCustomReferenceOngoing,
    updateCustomReferenceOngoing,
    removeCustomReferenceOngoing,
    canEdit: hasCapability(state, CAN_MANAGE_CUSTOM_REFERENCES),
    removePromptContent,
    referenceKeys,
    referenceWarnings,
    referenceDangers,
    references,
    referenceTo,
    translation,
  };
};

const mapStateToSensorProps = (state, props) =>
  mapStateToProps(state, { ...props, mode: "CustomReference" });

const mapStateToMachineProps = (state, props) =>
  mapStateToProps(state, { ...props, mode: "MachineCustomReference" });

const mapDispatchToSensorProps = {
  getHierarchy,
  addCustomReference,
  updateCustomReference,
  removeCustomReference,
  clearServiceErrors,
};

const mapDispatchToMachineProps = {
  getHierarchy,
  addCustomReference: addMachineCustomReference,
  updateCustomReference: updateMachineCustomReference,
  removeCustomReference: removeMachineCustomReference,
  clearServiceErrors,
};

export const SensorCustomReference = withTranslation()(
  connect(
    mapStateToSensorProps,
    mapDispatchToSensorProps
  )((props) => <CustomReference {...props} />)
);
export const MachineCustomReference = withTranslation()(
  connect(
    mapStateToMachineProps,
    mapDispatchToMachineProps
  )((props) => <CustomReference {...props} />)
);
