import React from "react";
import { Segment, Form, Button, Header, Table, Flag, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import ReferenceComparison from "ReferenceComparison";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  translationKey,
  selectUserInfo,
  selectRecommendedActions,
  hasCapability,
  CAN_REVISE_DIAGNOSES,
  CAN_ACCESS_ALL_ANALYSIS_DATA, selectTranslations, selectClient,
} from "slices/userDataSlice";
import {
  selectOngoing as selectDiagnosesOngoing,
  reviseDiagnosis,
} from "slices/diagnosesSlice";
import { EDIT_ICON, DROPDOWN_ICON, toIcon } from "icons";

const DIAGNOSES = "Diagnosis/diagnoses";

class Diagnosis extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      editing: false,
      diagnosisLanguage: window.localStorage.getItem('diagnosis-lang') || 'en',
      ...this.initialEditState(),
    });
  }

  initialEditState = () => {
    const { diagnosis } = this.props;
    return {
      category: diagnosis?.category || "",
      fault: diagnosis?.fault || "",
      validated: diagnosis?.validated,
      recommendation: diagnosis?.data?.recommendation?.recommendation || "",
      recommendedBy: diagnosis?.data?.recommendation?.username,
      todo1: diagnosis?.data?.todo1 || "",
      todo2: diagnosis?.data?.todo2 || "",
      todo3: diagnosis?.data?.todo3 || "",
      todos_target_time: diagnosis?.data?.todos_target_time || ""
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(DIAGNOSES);
    }
  };

  handleCheck = (_, { name, checked }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: checked });
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleRecommendationChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({
      [name]: value,
      recommendedBy: this.props.username,
    });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleSubmit = () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const { diagnosis, hasFaultCategories, reviseDiagnosis, t } = this.props;
    const { category, fault, validated, recommendation, todo1, todo2, todo3, todos_target_time } = this.state;

    const isFault = hasFaultCategories.includes(category);

    const errors = [];
    if (!category?.length) {
      errors.push("category");
    }
    if (isFault && !fault?.length) {
      errors.push("fault");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("Diagnosis.validationError"),
        errors,
      });
    }

    const data = {
      category,
      fault: isFault ? fault : "",
      validated,
      recommendation,
      todo1, todo2, todo3,
      todos_target_time
    };
    this.setState({ editing: false });
    reviseDiagnosis(
      diagnosis.id,
      data,
      opts(DIAGNOSES, t("Diagnosis.updateError"))
    ).catch((e) => console.error(e));
  };

  categoryOptions = memoizeOne((categories, translation) =>
    categories.map((c) => ({
      key: c,
      value: c,
      text: translationKey(translation, "diagnosis_categories")[c],
    }))
  );

  faultOptions = memoizeOne((faults, translation) =>
    faults.map((f) => ({
      key: f,
      value: f,
      text: translationKey(translation, "diagnosis_faults")[f],
    }))
  );

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      updateDiagnosisOngoing,
      canEdit,
      canSeeDetails,
      diagnosis,
      machine,
      categories,
      hasFaultCategories,
      referenceFigures,
      sap_url,
      schema,
      translations,
      t,
      i18n: { language },
    } = this.props;
    const { editing, diagnosisLanguage } = this.state;

    const translation = translations[diagnosisLanguage || 'en']

    const {
      category,
      fault,
      validated,
      recommendation,
      recommendedBy
    } = this.activeData();

    const loading = updateDiagnosisOngoing;

    const mCategory = machine?.category
    let faults = schemaKey(schema, "diagnosis_faults")[mCategory]
    // Unsupported category has all faults
    if (mCategory === "Unsupported") {
      const allFaults = new Set(Object.values(schemaKey(schema, "diagnosis_faults")).flat())
      faults = [...allFaults]
    }

    const isFault = hasFaultCategories.includes(category);

    const categoryOptions = this.categoryOptions(categories, translation);
    const faultOptions = isFault && this.faultOptions(faults, translation);

    const showTools = canEdit;

    const recommendationTitle = recommendedBy
      ? t("Diagnosis.customRecommendation", { username: recommendedBy })
      : t("Diagnosis.standardRecommendation");

    const standardRecommendation =
      (!recommendedBy &&
        selectRecommendedActions(translation, category, fault)) ||
      "";

    const data = diagnosis?.data;
    const machineCategory = data?.machine_data?.category;
    const machineFoundation = data?.machine_data?.foundation;
    const machineReferenceGroup = data?.machine_data?.reference_group;
    const machineRotationalSpeed = data?.machine_data?.rotational_speed_hz;
    const machineRotationalSpeedSource =
      data?.machine_data?.rotational_speed_source;
    const sensorReferenceCategory = data?.machine_data?.reference_category;
    const lf_references = data?.lf_references;
    const algorithmVersion = data?.version;

    const machineRelubricable = (data?.machine_data?.relubricable || machine.relubricable) ? "Yes" : "No";
    const machineTransmission = data?.machine_data?.transmission || machine.transmission;
    const machineRotationalGroup = data?.machine_data?.rotational_group || machine.rotational_group;


    const todoOptions = memoizeOne((translation) =>
      Object.entries(translationKey(translation, "todos")).map(([key, val]) => ({
        key: key,
        text: val,
        value: key,
      })))

    const todoTargetTimeOptions = memoizeOne((translation) =>
      Object.entries(translationKey(translation, "todos_target_times")).map(([key, val]) => ({
        key: key,
        text: val,
        value: key,
      })))

    const selectLang = (lang) => {
      window.localStorage.setItem('diagnosis-lang', lang)
      this.setState({ diagnosisLanguage: lang })
    }

    return (
      <Segment
        as="div"
        className="Diagnosis"
        loading={loading}
        data-cy="Diagnosis"
      >
        {showTools && (
          <div className="Diagnosis__tools">
            <Button
              className="Diagnosis__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
              data-cy="Diagnosis__edit"
            />
          </div>
        )}
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            basic
            compact
            active={diagnosisLanguage === "en"}
            onClick={() => selectLang('en')}
          >
            <Flag name="uk"/>
          </Button>
          <Button
            basic
            compact
            active={diagnosisLanguage === "fi"}
            onClick={() => selectLang('fi')}
          >
            <Flag name="fi"/>
          </Button>
        </div>
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Group>
            <Form.Checkbox
              name="validated"
              label={t("Diagnosis.validated")}
              checked={validated}
              onClick={this.handleCheck}
              data-cy="Diagnosis__validated"
            />
            {
              sap_url &&
                <Form.Checkbox
                  name="validated"
                  label={`${t("Diagnosis.maintenanceNotificationSent")} ${diagnosis?.maintenance_notification ?? ""}`}
                  checked={diagnosis?.maintenance_notification ? true : false}
                  readOnly
                />
            }
          </Form.Group> 

          <Form.Field required error={this.hasError("category")}>
            <label>{t("Diagnosis.category")}</label>
            <Form.Dropdown
              name="category"
              placeholder={t("Diagnosis.categoryPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={categoryOptions}
              value={category}
              onChange={this.handleChange}
              noResultsMessage={t("Diagnosis.noCategories")}
            />
          </Form.Field>
          {isFault && (
            <>
              <Form.Field required error={this.hasError("fault")}>
                <label>{t("Diagnosis.fault")}</label>
                <Form.Dropdown
                  name="fault"
                  placeholder={t("Diagnosis.faultPlaceholder")}
                  fluid
                  search
                  selection
                  icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                  open={editing && undefined}
                  options={faultOptions}
                  value={fault}
                  onChange={this.handleChange}
                  noResultsMessage={t("Diagnosis.noFaults")}
                />
              </Form.Field>
              <Form.Field>
                <label>{recommendationTitle}</label>
                <Form.TextArea
                  name="recommendation"
                  value={recommendation || standardRecommendation}
                  onChange={this.handleRecommendationChange}
                  placeholder={t("Diagnosis.recommendationPlaceholder")}
                />
              </Form.Field>

              <label><b>{t("Diagnosis.todos")}</b></label>
              {[1, 2, 3].map(index => (
                <Form.Field key={index} required error={this.hasError("todo" + index)}>
                  <Form.Dropdown
                    className={"Diagnosis__todo-dropdown"}
                    onChange={this.handleChange}
                    name={"todo" + index}
                    value={this.activeData()["todo" + index]}
                    placeholder={t("Diagnosis.selectTodo", { index })}
                    options={todoOptions(translation)}
                    selection
                    search
                  />
                </Form.Field>
              ))}

              <label><b>{t("Diagnosis.todosTargetTime")}</b></label>
              <Form.Field required error={this.hasError("todos_target_time")}>
                <Form.Dropdown
                  className={"Diagnosis__todo-dropdown"}
                  onChange={this.handleChange}
                  name={"todos_target_time"}
                  value={this.activeData()["todos_target_time"]}
                  placeholder={t("Diagnosis.selectTodosTargetTime")}
                  options={todoTargetTimeOptions(translation)}
                  selection
                  search
                />
              </Form.Field>
            </>
          )}
          {editing && (
            <Form.Button
              className="Diagnosis__button"
              type="submit"
              primary
              data-cy="Diagnosis__update"
            >
              {t("Diagnosis.update")}
            </Form.Button>
          )}
        </Form>
        {canSeeDetails && (
          <>
            <Header as="h5">{t("Diagnosis.details")}</Header>
            <Table
              className="Diagnosis__table"
              celled
              unstackable
              size="small"
              compact="very"
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t("Diagnosis.algorithmVersion")}</Table.Cell>
                  <Table.Cell>{algorithmVersion}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("Diagnosis.machineCategory")}</Table.Cell>
                  <Table.Cell>{machineCategory}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t("Diagnosis.machineFoundation")}</Table.Cell>
                  <Table.Cell>{machineFoundation}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.machineReferenceGroup")}
                  </Table.Cell>
                  <Table.Cell>{machineReferenceGroup}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.machineRotationalSpeed")}
                  </Table.Cell>
                  <Table.Cell>{`${machineRotationalSpeed} (${machineRotationalSpeedSource})`}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.sensorReferenceCategory")}
                  </Table.Cell>
                  <Table.Cell>{sensorReferenceCategory}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.machineRelubricable")}
                  </Table.Cell>
                  <Table.Cell>{machineRelubricable}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.machineTransmission")}
                  </Table.Cell>
                  <Table.Cell>{machineTransmission}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t("Diagnosis.machineRotationalGroup")}
                  </Table.Cell>
                  <Table.Cell>{machineRotationalGroup}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            {lf_references && (
              <>
                <Header as="h5">{t("Diagnosis.lfReferences")}</Header>
                <ReferenceComparison
                  figures={referenceFigures}
                  references={lf_references}
                />
              </>
            )}
          </>
        )}
      </Segment>
    );
  }

}


const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const schema = selectServiceSchema(state);
  const categories = schemaKey(schema, "diagnosis_categories");

  const { username } = selectUserInfo(state); 
  const client = selectClient(state);

  return {
    serviceError: selectServiceError(state, DIAGNOSES),
    updateDiagnosisOngoing: selectDiagnosesOngoing(state, "reviseDiagnosis"),
    canEdit: hasCapability(state, CAN_REVISE_DIAGNOSES),
    canSeeDetails: hasCapability(state, CAN_ACCESS_ALL_ANALYSIS_DATA),
    categories,
    hasFaultCategories: schemaKey(schema, "diagnosis_category_groups")[
      "has_fault"
      ],
    referenceFigures: schemaKey(schema, "reference_figures"),
    username: username,
    sap_url: client?.sap_url,
    schema,
    translations: selectTranslations(state),
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  reviseDiagnosis,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Diagnosis)
);
