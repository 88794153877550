import { combineReducers } from "redux";

import ui from "slices/uiSlice.js";
import serviceErrors from "slices/serviceErrorsSlice.js";
import token, { logout } from "slices/tokenSlice.js";
import userData from "slices/userDataSlice.js";
import hierarchy from "slices/hierarchySlice.js";
import locations from "slices/locationsSlice.js";
import machineGroups from "slices/machineGroupsSlice.js";
import machines from "slices/machinesSlice.js";
import sensors from "slices/sensorsSlice.js";
import customReferences from "slices/customReferencesSlice.js";
import machineCustomReferences from "slices/machineCustomReferencesSlice.js";
import machineLog from "slices/machineLogSlice.js";

import tasks from "slices/tasksSlice.js";
import taskFiles from "slices/taskFilesSlice.js";
import labels from "slices/labelsSlice.js";
import sensorGroups from "slices/sensorGroupsSlice.js";

import report from "slices/reportSlice.js";

import diagnoses from "slices/diagnosesSlice.js";
import analyses from "slices/analysesSlice.js";
import spectrums from "slices/spectrumsSlice.js";

const appReducer = combineReducers({
  ui,
  serviceErrors,
  token,
  userData,
  hierarchy,
  locations,
  machineGroups,
  machines,
  sensors,
  customReferences,
  machineCustomReferences,
  machineLog,
  labels,
  sensorGroups,
  tasks,
  taskFiles,
  diagnoses,
  analyses,
  spectrums,
  report,
});

const rootReducer = (state, action) =>
  logout.match(action)
    ? appReducer(undefined, action)
    : appReducer(state, action);

export default rootReducer;
