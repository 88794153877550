import React from "react";
import { Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export function ActionMenu(props) {
  const { value, items, ...rest } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <Dropdown item {...rest}>
      <Dropdown.Menu>
        {items.map((item) => {
          if (item.to) {
            return (
              <Dropdown.Item
                key={item.key}
                as={NavLink}
                to={item.to}
                value={value}
              >
                {item.title}
              </Dropdown.Item>
            );
          } else if (item.handler) {
            return (
              <Dropdown.Item
                key={item.key}
                onClick={item.handler}
                value={value}
              >
                {item.title}
              </Dropdown.Item>
            );
          } else {
            return <Dropdown.Divider key={item.key} />;
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ActionMenu;

const matchFilter = (values, filter) => {
  return Object.keys(filter).every(
    (k) => values[k] === undefined || values[k] === filter[k]
  );
};

export const filterActions = (actions, filter) => {
  return actions.filter((a) => matchFilter(a.filter, filter));
};
