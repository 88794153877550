import React from "react";
import { Segment, Form, List, Button, Grid, Header} from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";
import { debounce } from "debounce";

import AdaComponent from "AdaComponent";
import AnalysesItem from "AnalysesItem";
import NextLoader from "NextLoader";
import AnalysesGroup from "AnalysesGroup";
import DiagnosisFilter from "DiagnosisFilter";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import { setData, selectData, toggleFlag, selectFlag } from "slices/uiSlice";
import {
  selectServiceSchema,
  selectTranslation,
  hasCapability,
  CAN_ACCESS_ANALYSIS_DATA,
  diagnosisCategoryPriorities,
  selectSortLocale,
} from "slices/userDataSlice";
import {
  selectLastUpdated,
  selectHierarchyData,
} from "slices/hierarchySlice";
import {
  selectQuery as selectAnalysesQuery,
  selectOngoing as selectAnalysesOngoing,
  selectNext as selectAnalysesNext,
  selectMatchingAnalyses,
  getAnalyses,
  getNextAnalyses,
  getRestAnalyses,
} from "slices/analysesSlice";
import { selectEntities as selectDiagnosesMap } from "slices/diagnosesSlice";

import {
  SEARCH_ICON,
  toIcon,
  GROUPED_ICON,
} from "icons";
import { queryParameters, analysisGroups } from "utils";

const ANALYSES = "Diagnoses/diagnoses";

const SEARCH_DELAY = 500;
const EMPTY = {};

const SEARCH = "Analyses/search";
const EXCLUDED = "Analyses/exclude";
const GROUPED = "Analyses/grouped";

class Analyses extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      open: false,
      search: "",
    });
  }

  componentDidMount() {
    const { getAnalyses, location, t } = this.props;

    this.setSearch("");

    getAnalyses(
      queryParameters(location),
      opts(ANALYSES, t("Analyses.analysesError"))
    ).catch((e) => {
      console.error(e);
    });
  }

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(ANALYSES);
    }
  };

  handleExclude = (e, { value }) => {
    const { excluded, setData } = this.props;
    e.stopPropagation();
    const newExcluded = { ...excluded };
    newExcluded[value] = !newExcluded[value];
    setData({ id: EXCLUDED, data: newExcluded });
  };

  setSearch = (search) => {
    const { setData } = this.props;
    setData({ id: SEARCH, data: search });
  };

  handleSearch = () => {
    this.setSearch(this.state.search);
    this.typeSearch.clear();
  };

  typeSearch = debounce((search, setSearch) => {
    setSearch(search);
  }, SEARCH_DELAY);

  handleEditSearch = (_, { value: search }) => {
    this.getRest();
    this.setState({ search });
    this.typeSearch(search, this.setSearch);
  };

  handleGrouped = () => {
    const { toggleFlag, grouped } = this.props;
    if (!grouped) {
      this.getRest();
    }
    toggleFlag(GROUPED);
  };

  more = memoizeOne((_language) => {
    const { t } = this.props;
    return <p className="Analyses__more">{t("Analyses.more")}</p>;
  });

  next = () => {
    const { getNextAnalyses, t } = this.props;
    getNextAnalyses(opts(ANALYSES, t("Analyses.moreError"))).catch((e) => {
      console.error(e);
    });
  };

  getRest = () => {
    const { analysesNext, getRestAnalyses, t } = this.props;
    if (analysesNext) {
      getRestAnalyses(opts(ANALYSES, t("Analyses.analysesError"))).catch(
        (e) => {
          console.error(e);
        }
      );
    }
  };

  analyses = memoizeOne((analyses, _hierarchyLastUpdated, _language) => {
    const { canAccessAnalysisData } = this.props;
    return analyses.map((analysis, index) => (
      <List.Item
        key={analysis.id}
        as={AnalysesItem}
        index={index}
        analysis={analysis}
        linkedToAnalysis={canAccessAnalysisData}
      />
    ));
  });

  groups = memoizeOne((analyses, _hierarchyLastUpdated, _language) => {
    const {
      canAccessAnalysisData,
      diagnosesMap,
      hierarchyData,
      priorities,
      sortLocale,
    } = this.props;
    return analysisGroups(
      analyses,
      diagnosesMap,
      hierarchyData,
      [],
      priorities,
      sortLocale
    ).map((group, index) => (
      <List.Item
        key={group.id}
        as={AnalysesGroup}
        index={index}
        group={group}
        priorities={priorities}
        linkedToAnalysis={canAccessAnalysisData}
      />
    ));
  });

  render() {
    const {
      serviceError,
      // getHierarchyOngoing,
      getAnalysesOngoing,
      getRestAnalysesOngoing,
      excluded,
      grouped,
      analysesNext,
      analyses,
      // schema,
      // translation,
      hierarchyLastUpdated,
      t,
      i18n: { language },
    } = this.props;
    const { search } = this.state;

    const loading = /* getHierarchyOngoing || */ getAnalysesOngoing;
    const searchLoading = getRestAnalysesOngoing;
    const analysisItems = (!grouped && this.analyses(analyses, hierarchyLastUpdated, language)) || undefined;
    const groupItems = (grouped && this.groups(analyses, hierarchyLastUpdated, language)) || undefined;

    return (
      <Segment as="div" basic className="Analyses">
        <Grid
          columns={2}
          doubling
          data-cy="Analyses"
        >
          <Grid.Column width={4}>
            <Segment basic className="Analyses__segment">
              <Header as="h4" className="Analyses__title">
                {t("Analyses.filterResults")}
              </Header>
              {this.ErrorMessage()}
              {this.ServiceErrorMessage(serviceError, language)}

              <div className="Analyses__tools">
                <Form className="Analyses__search" onSubmit={this.handleSearch}>
                  <Form.Input
                    label={t("Analyses.search")}
                    name="search"
                    icon={toIcon(SEARCH_ICON)}
                    iconPosition="left"
                    loading={searchLoading}
                    placeholder={t("Analyses.search")}
                    size="small"
                    value={search}
                    onChange={this.handleEditSearch}
                  />
                </Form>
                <div style={{ margin: '30px 0 0 0', }}>
                  <label style={{ fontWeight: "bold" }}>
                    {t("Analyses.filterStatus")}
                  </label>
                  <DiagnosisFilter
                    excluded={excluded}
                    onExclude={this.handleExclude}
                  />
                </div>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column
            width={10}
            as="div"
            loading={loading}
            data-cy="Analyses"
          >
            <Segment loading={loading} className="Analyses__segment">
              <div className="Analyses__analyses_tools">
                <Header as="h3" className="Analyses__title">
                  {t("Analyses.analyses")}
                </Header>
                <div>
                  <Button
                    className="Analyses__grouped"
                    toggle
                    active={grouped}
                    icon
                    onClick={this.handleGrouped}
                    data-cy="Analyses__grouped"
                  >
                    {toIcon(GROUPED_ICON)} {t("Analyses.groupAnalyses")}
                  </Button>
                </div>
              </div>
              <div>
                {analysisItems && (
                  <List celled>
                    {analysisItems}
                    {analysesNext && (
                      <List.Item
                        key={analysesNext}
                        as={NextLoader}
                        loadNext={this.next}
                        more={this.more()}
                      />
                    )}
                  </List>
                )}
                {groupItems && <List celled>{groupItems}</List>}
                {!loading && analyses.length === 0 && <p>{t("Analyses.empty")}</p>}
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const schema = selectServiceSchema(state);

  const excluded = selectData(state, EXCLUDED) || EMPTY;
  const search = selectData(state, SEARCH) || EMPTY;
  const grouped = selectFlag(state, GROUPED);
  const analyses = selectMatchingAnalyses(state, search, excluded);

  return {
    serviceError: selectServiceError(state, /* HIERARCHY, */ ANALYSES),
    query: selectAnalysesQuery(state),
    // getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    getAnalysesOngoing: selectAnalysesOngoing(state, "getAnalyses"),
    getNextAnalysesOngoing: selectAnalysesOngoing(state, "getNextAnalyses"),
    getRestAnalysesOngoing: selectAnalysesOngoing(state, "getRestAnalyses"),
    canAccessAnalysisData: hasCapability(state, CAN_ACCESS_ANALYSIS_DATA),
    excluded,
    search,
    grouped,
    analysesNext: selectAnalysesNext(state),
    analyses,
    diagnosesMap: selectDiagnosesMap(state),
    hierarchyData: selectHierarchyData(state),
    priorities: diagnosisCategoryPriorities(schema),
    sortLocale: selectSortLocale(state),
    schema,
    translation: selectTranslation(state, language),
    hierarchyLastUpdated: selectLastUpdated(state),
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  // getHierarchy,
  getAnalyses,
  getNextAnalyses,
  getRestAnalyses,
  setData,
  toggleFlag,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Analyses)
);
