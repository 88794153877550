import React from "react";
import { Segment, Label, Table } from "semantic-ui-react";

const ReferenceComparisons = ({ figures, references }) => {
  const compared = figures.filter((f) => references[f]);
  const keys = ["category", "value", "warning", "danger"];
  return (
    <>
      {compared.map((f) => (
        <Segment className="ReferenceComparisons" key={f}>
          <Label className="ReferenceComparisons__label" attached="top">
            {f}
          </Label>
          <Table
            className="ReferenceComparisons__table"
            basic="very"
            celled
            unstackable
            size="small"
            compact="very"
          >
            <Table.Header>
              <Table.Row>
                {keys.map((k) => (
                  <Table.HeaderCell key={k}>{k}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                {keys.map((k) => (
                  <Table.Cell key={k}>{references[f][k]}</Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      ))}
    </>
  );
};

export default ReferenceComparisons;
