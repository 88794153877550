import React, { Fragment } from "react";
import { Segment, Form, Button, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import AdaComponent from "AdaComponent";
import Loader from "Loader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectOngoing as selectSensorGroupsOngoing,
  selectEntities as selectSensorGroupsMap,
  addSensorGroup,
  updateSensorGroup,
  removeSensorGroup,
} from "slices/sensorGroupsSlice";
import { hasCapability, CAN_MANAGE_SENSOR_GROUPS } from "slices/userDataSlice";
import {
  // selectHasHierarchy,
  selectOngoing as selectHierarchyOngoing,
  selectHierarchy,
  selectSensorGroupMatches,
  hasMatch,
  getHierarchy,
} from "slices/hierarchySlice";
import { parseId, goBack, redirectTo, toggleSet } from "utils";
import {
  sensorTo,
  machineTo,
  machineGroupTo,
  locationTo,
  sensorGroupTo,
} from "appRoutes";
import {
  EDIT_ICON,
  SELECT_ICON,
  SELECTED_ICON,
  LOCATION_ICON,
  MACHINE_GROUP_ICON,
  MACHINE_ICON,
  SENSOR_ICON,
  REFRESH_ICON,
  toIcon,
} from "icons";

const HIERARCHY = "SensorGroup/hierarchy";
const SENSOR_GROUPS = "SensorGroup/sensorGroups";

class SensorGroup extends AdaComponent {
  constructor(props) {
    super(props);

    const { isNew } = props;

    this.setInitialState({
      editing: isNew,
      promptRemove: false,
      ...this.initialEditState(),
    });
  }

  componentDidMount = () => {
    // const { hasHierarchy } = this.props;
    window.scrollTo(0, 0);

    // if (!hasHierarchy) {
    this.refreshHierarchy();
    // }
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(HIERARCHY, SENSOR_GROUPS);
    }
  };

  refreshHierarchy = (reload = false) => {
    const { getHierarchy, t } = this.props;
    this.clearErrors();
    this.clearServiceErrors();
    getHierarchy(
      reload,
      opts(HIERARCHY, t("SensorGroup.hierarchyError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  handleRefresh = () => {
    this.refreshHierarchy(true);
  };

  initialEditState = () => {
    const { sensorGroup } = this.props;
    return {
      name: sensorGroup?.name || "",
      description: sensorGroup?.description || "",
      selected: new Set(sensorGroup?.sensors || []),
    };
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearErrors(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okToRemove = () => {
    const { sensorGroup, removeSensorGroup, history, location, t } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeSensorGroup(
      sensorGroup.id,
      opts(SENSOR_GROUPS, t("SensorGroup.removeError"))
    )
      .then(() => goBack(history, location))
      .catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const {
      sensorGroup,
      addSensorGroup,
      updateSensorGroup,
      history,
      location,
      t,
    } = this.props;
    const { name, description, selected } = this.state;

    const errors = [];
    if (!name || name.length === 0) {
      errors.push("name");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("SensorGroup.validationError"),
        errors,
      });
    }

    const sensorGroupData = {
      name,
      description,
      sensors: Array.from(selected),
    };
    if (!sensorGroup) {
      addSensorGroup(
        sensorGroupData,
        opts(SENSOR_GROUPS, t("SensorGroup.addError"))
      )
        .then((r) => redirectTo(sensorGroupTo(r.id, location), history))
        .catch((e) => console.error(e));
    } else {
      this.setState({ editing: false });
      updateSensorGroup(
        sensorGroup.id,
        sensorGroupData,
        opts(SENSOR_GROUPS, t("SensorGroup.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  hasMatch = (id, type) => {
    const { matches } = this.props;
    const { editing } = this.state;
    if (editing) {
      return true;
    }
    return hasMatch(id, type, matches);
  };

  handleSelect = (_, { value }) => {
    this.setState((state) => ({
      selected: toggleSet(value, state.selected),
    }));
  };

  renderSensor = (sensor) => {
    const { location } = this.props;
    const { editing, selected } = this.state;
    const { id, name, description } = sensor;
    if (!this.hasMatch(id, "sensors")) {
      return null;
    }
    const isSelected = !editing || selected.has(id);
    const icon = isSelected ? toIcon(SELECTED_ICON) : toIcon(SELECT_ICON);
    return (
      <List.List key={id} className="SensorGroup__sublist">
        {editing && (
          <List.Content floated="right" className="SensorGroup__check">
            <Button
              type="button"
              className="SensorGroup__select"
              basic
              compact
              circular
              icon={icon}
              value={id}
              onClick={this.handleSelect}
            />
          </List.Content>
        )}
        <List.Item className="SensorGroup__item">
          <List.Icon className="SensorGroup__icon">
            {toIcon(SENSOR_ICON)}
          </List.Icon>
          <List.Content
            className={classNames("SensorGroup__content", {
              SensorGroup__selected: isSelected,
            })}
          >
            <List.Header>
              <Link to={sensorTo(id, location)}>{name}</Link>
            </List.Header>
            {description && <List.Description>{description}</List.Description>}
          </List.Content>
        </List.Item>
      </List.List>
    );
  };

  renderMachine = (machine) => {
    const { location } = this.props;
    const { id, name, description, sensors } = machine;
    if (!this.hasMatch(id, "machines")) {
      return null;
    }
    return (
      <List.List key={id} className="SensorGroup__sublist">
        <List.Item className="SensorGroup__item">
          <List.Icon className="SensorGroup__icon">
            {toIcon(MACHINE_ICON)}
          </List.Icon>
          <List.Content className="SensorGroup__content">
            <List.Header>
              <Link to={machineTo(id, location)}>{name}</Link>
            </List.Header>
            {description && <List.Description>{description}</List.Description>}
            {sensors.map((s) => this.renderSensor(s))}
          </List.Content>
        </List.Item>
      </List.List>
    );
  };

  renderMachineGroup = (machineGroup) => {
    const { location } = this.props;
    const { id, name, description, machines } = machineGroup;
    if (!this.hasMatch(id, "machineGroups")) {
      return null;
    }
    return (
      <List.List key={id} className="SensorGroup__sublist">
        <List.Item className="SensorGroup__item">
          <List.Icon className="SensorGroup__icon">
            {toIcon(MACHINE_GROUP_ICON)}
          </List.Icon>
          <List.Content className="SensorGroup__content">
            <List.Header>
              <Link to={machineGroupTo(id, location)}>{name}</Link>
            </List.Header>
            {description && <List.Description>{description}</List.Description>}

            {machines.map((m) => this.renderMachine(m))}
          </List.Content>
        </List.Item>
      </List.List>
    );
  };

  renderLocation = (location) => {
    const { location: rrLocation } = this.props;
    const { id, name, description, children, machine_groups } = location;
    if (!this.hasMatch(location.id, "locations")) {
      return null;
    }
    const matchingMachineGroups = machine_groups
      .map((mg) => this.renderMachineGroup(mg))
      .filter((x) => x);
    const matchingChildren = children
      .map((c) => this.renderLocation(c))
      .filter((x) => x);
    return (
      <Fragment key={id}>
        <List.Item className="SensorGroup__item">
          <List.Icon className="SensorGroup__icon">
            {toIcon(LOCATION_ICON)}
          </List.Icon>
          <List.Content className="SensorGroup__content">
            <List.Header>
              <Link to={locationTo(id, rrLocation)}>{name}</Link>
            </List.Header>
            {description && <List.Description>{description}</List.Description>}
            {matchingMachineGroups.length > 0 && (
              <List.List className="SensorGroup__sublist">
                {matchingMachineGroups}
              </List.List>
            )}
            {matchingChildren.length > 0 && (
              <List.List className="SensorGroup__sublist">
                {matchingChildren}
              </List.List>
            )}
          </List.Content>
        </List.Item>
      </Fragment>
    );
  };

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      sensorGroup,
      isNew,
      isMissingData,
      getHierarchyOngoing,
      addSensorGroupOngoing,
      updateSensorGroupOngoing,
      removeSensorGroupOngoing,
      canEdit,
      roots,
      t,
      i18n: { language },
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (isMissingData) {
      return <Loader />;
    }

    const { name, description } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addSensorGroupOngoing ||
      updateSensorGroupOngoing ||
      removeSensorGroupOngoing;

    const showEdit = canEdit && !isNew;
    const addingNew = editing && isNew;
    const editingExisting = editing && !isNew;

    return (
      <Segment
        as="div"
        className="SensorGroup"
        loading={loading}
        data-cy="SensorGroup"
      >
        <Prompt
          open={promptRemove}
          header={t("SensorGroup.removePrompt.header")}
          okText={t("SensorGroup.removePrompt.ok")}
          cancelText={t("SensorGroup.removePrompt.cancel")}
          onOk={this.okToRemove}
          onCancel={this.cancelRemove}
        />
        <div className="SensorGroup__tools">
          <Button
            type="button"
            className="Tasks__tool"
            icon
            compact
            basic
            onClick={this.handleRefresh}
            data-cy="ImporterHome__refresh"
          >
            {toIcon(REFRESH_ICON)}
          </Button>
          {showEdit && (
            <Button
              className="SensorGroup__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
              data-cy="SensorGroup__edit"
            />
          )}
        </div>
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("SensorGroup.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("SensorGroup.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
              data-cy="SensorGroup__name"
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("SensorGroup.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("SensorGroup.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
              data-cy="SensorGroup__description"
            />
          </Form.Field>
          <Form.Field error={this.hasError("sensors")}>
            <label>{t("SensorGroup.sensors")}</label>
            {!editing && sensorGroup.sensors.length === 0 && (
              <p>{t("SensorGroup.noSensors")}</p>
            )}
            {(editing || sensorGroup.sensors.length > 0) && (
              <List>{roots.map((r) => this.renderLocation(r))}</List>
            )}
          </Form.Field>

          {addingNew && (
            <Form.Button
              className="SensorGroup__button"
              type="submit"
              primary
              data-cy="SensorGroup__add"
            >
              {t("SensorGroup.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button
                className="SensorGroup__button"
                type="submit"
                primary
                data-cy="SensorGroup__update"
              >
                {t("SensorGroup.update")}
              </Form.Button>
              <Button
                className="SensorGroup__button"
                type="button"
                negative
                onClick={this.handleRemove}
                data-cy="SensorGroup__remove"
              >
                {t("SensorGroup.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    // location,
  } = ownProps;

  const sensorGroupId = parseId(id);
  const sensorGroup = selectSensorGroupsMap(state)[sensorGroupId];

  return {
    serviceError: selectServiceError(state, HIERARCHY, SENSOR_GROUPS),
    sensorGroupId,
    sensorGroup,
    isNew: !sensorGroupId,
    // hasHierarchy: selectHasHierarchy(state),
    isMissingData: sensorGroupId && !sensorGroup,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addSensorGroupOngoing: selectSensorGroupsOngoing(state, "addSensorGroup"),
    updateSensorGroupOngoing: selectSensorGroupsOngoing(
      state,
      "updateSensorGroup"
    ),
    removeSensorGroupOngoing: selectSensorGroupsOngoing(
      state,
      "removeSensorGroup"
    ),
    canEdit: hasCapability(state, CAN_MANAGE_SENSOR_GROUPS),
    roots: selectHierarchy(state)[0],
    matches: selectSensorGroupMatches(state, sensorGroup),
    // location,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getHierarchy,
  addSensorGroup,
  updateSensorGroup,
  removeSensorGroup,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SensorGroup)
);
