import React from "react";
import { Form, Button, Segment } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Prompt from "Prompt";
import Loader from "Loader";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
  getIncompleteErrors,
  clearIncompleteErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  hasCapability,
  CAN_MANAGE_SENSORS,
  CAN_MANAGE_CUSTOM_REFERENCES,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import {
  selectOngoing as selectSensorsOngoing,
  selectEntities as selectSensorsMap,
  selectReference as selectCustomReference,
  addSensor,
  updateSensor,
  removeSensor,
} from "slices/sensorsSlice";
import {
  selectEntities as selectMachinesMap,
  selectAll as selectMachines,
} from "slices/machinesSlice";
import { selectAll as selectCustomReferences } from "slices/customReferencesSlice";

import { parseId, goBack, goTo, redirectTo, queryParameters } from "utils";
import { sensorTo, newCustomReferenceTo, customReferenceTo } from "appRoutes";
import { EDIT_ICON, DROPDOWN_ICON, toIcon } from "icons";

const HIERARCHY = "Sensor/hierarchy";
const SENSORS = "Sensor/sensors";

class Sensor extends AdaComponent {
  constructor(props) {
    super(props);

    const { sensor, isNew, t } = props;
    const errors = getIncompleteErrors(sensor);

    this.setInitialState(
      {
        editing: isNew,
        promptRemove: false,
        accordion: new Set(),
        ...this.initialEditState(),
      },
      {
        errors,
        header: t("Sensor.incomplete"),
        content: t("Sensor.incompleteContent"),
      }
    );
  }

  componentDidMount = () => {
    const { getHierarchy, t } = this.props;

    window.scrollTo(0, 0);
    getHierarchy(false, opts(HIERARCHY, t("Sensor.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  initialEditState = () => {
    const {
      sensor,
      reference,
      customReferenceId,
      machineId,
      position,
    } = this.props;
    return {
      name: sensor?.name || "",
      description: sensor?.description || "",
      external_id: sensor?.external_id || "",
      reference,
      customReferenceId,
      machineId,
      position,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY, SENSORS);
    }
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okRemove = async () => {
    const { sensor, removeSensor, history, location, t } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeSensor(sensor.id, opts(SENSORS, t("Sensor.removeError")))
      .then(() => goBack(history, location))
      .catch((e) => {
        console.error(e);
      });
  };

  handleAddCustomReference = () => {
    const { location, history } = this.props;
    goTo(newCustomReferenceTo(location), history);
  };

  handleSubmit = async () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const {
      sensor,
      addSensor,
      updateSensor,
      customReference,
      history,
      location,
      t,
    } = this.props;
    const {
      name,
      description,
      external_id,
      reference,
      customReferenceId,
      machineId,
      position,
    } = this.state;

    const errors = [];
    const usesCustomReference = reference === customReference;
    if (!name || name.length === 0) {
      errors.push("name");
    }
    if (!external_id || external_id.length === 0) {
      errors.push("external_id");
    }
    if (!machineId) {
      errors.push("machineId");
    }
    if (!position) {
      errors.push("position");
    }
    if (usesCustomReference && !customReferenceId) {
      errors.push("customReferenceId");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("Sensor.validationError"),
        errors,
      });
    }

    const crid = (usesCustomReference && customReferenceId) || 0;
    const sensorData = {
      name,
      description,
      external_id,
      reference,
      machine: machineId,
      position,
      custom_references: (crid && [crid]) || [],
    };
    if (!sensor) {
      addSensor(sensorData, opts(SENSORS, t("Sensor.addError")))
        .then((s) => redirectTo(sensorTo(s.id, location), history))
        .catch((e) => console.error(e));
    } else {
      sensorData.data = clearIncompleteErrors(sensor);
      this.setState({ editing: false, customReferenceId: crid });
      updateSensor(
        sensor.id,
        sensorData,
        opts(SENSORS, t("Sensor.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  positionOptions = memoizeOne((positions, translation) =>
    positions.map((p) => ({
      key: p,
      value: p,
      text: translationKey(translation, "machine_positions")[p],
    }))
  );

  referenceOptions = memoizeOne((references, translation) =>
    references.map((r) => ({
      key: r,
      value: r,
      text: translationKey(translation, "sensor_references")[r],
    }))
  );

  customReferenceOptions = memoizeOne((customReferences) =>
    customReferences.map((r) => ({
      key: r.id,
      value: r.id,
      text: r.name,
    }))
  );

  machineOptions = memoizeOne((machines) =>
    machines.map((m) => ({
      key: m.id,
      value: m.id,
      text: m.name,
    }))
  );

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      isNew,
      isMissingData,
      getHierarchyOngoing,
      addSensorOngoing,
      updateSensorOngoing,
      removeSensorOngoing,
      clearReferenceOngoing,
      canEdit,
      canAddReference,
      machines,
      machinesMap,
      positions,
      references,
      customReferences,
      customReference,
      location,
      translation,
      i18n: { language },
      t,
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (isMissingData) {
      return <Loader />;
    }

    const {
      name,
      description,
      external_id,
      reference,
      customReferenceId,
      machineId,
      position,
    } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addSensorOngoing ||
      updateSensorOngoing ||
      removeSensorOngoing ||
      clearReferenceOngoing;

    const referenceOptions = this.referenceOptions(references, translation);
    const customReferenceOptions = this.customReferenceOptions(
      customReferences
    );
    const machineOptions = this.machineOptions(machines);
    const machine = machinesMap[machineId];

    const matchingPositions = positions[machine?.category] || [];
    const positionOptions = this.positionOptions(
      matchingPositions,
      translation
    );

    const showTools = canEdit && !isNew;
    const addingNew = canEdit && isNew;
    const editingExisting = editing && !isNew;
    const showCustom = reference === customReference;

    return (
      <Segment
        as="div"
        className="Sensor"
        loading={loading}
        data-cy="Sensor"
      >
        <Prompt
          open={promptRemove}
          header={t("Sensor.removePrompt.header")}
          content={t("Sensor.removePrompt.content")}
          okText={t("Sensor.removePrompt.ok")}
          cancelText={t("Sensor.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />

        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}

        {showTools && (
          <div className="Sensor__tools">
            <Button
              className="Sensor__tool"
              toggle
              active={editing}
              icon
              onClick={this.handleEdit}
              compact
              circular
              basic
              disabled={!canEdit}
              data-cy="Sensor__edit"
            >
              {toIcon(EDIT_ICON)}
            </Button>
          </div>
        )}

        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("Sensor.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("Sensor.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("Sensor.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("Sensor.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field required error={this.hasError("external_id")}>
            <label>{t("Sensor.externalId")}</label>
            <Form.Input
              name="external_id"
              placeholder={t("Sensor.externalIdPlaceholder")}
              value={external_id}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field required error={this.hasError("reference")}>
            <label>{t("Sensor.reference")}</label>
            <Form.Dropdown
              name="reference"
              placeholder={t("Sensor.referencePlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={referenceOptions}
              value={reference}
              onChange={this.handleChange}
              noResultsMessage={t("Sensor.noReferences")}
            />
          </Form.Field>
          {showCustom && (
            <Form.Field
              className="Sensor__reference"
              required
              error={this.hasError("customReferenceId")}
            >
              <label className="Sensor__reference_label">
                {t("Sensor.customReference")}
              </label>
              {editing && canAddReference && (
                <span className="Sensor__reference_link">
                  <NavLink to={newCustomReferenceTo(location)}>
                    {t("Sensor.addCustomReference")}
                  </NavLink>
                </span>
              )}
              {!editing && (
                <span className="Sensor__reference_link">
                  <NavLink to={customReferenceTo(customReferenceId, location)}>
                    {t("Sensor.viewCustomReference")}
                  </NavLink>
                </span>
              )}
              <Form.Dropdown
                name="customReferenceId"
                placeholder={t("Sensor.customReferencePlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={customReferenceOptions}
                value={customReferenceId}
                onChange={this.handleChange}
                noResultsMessage={t("Sensor.noCustomReferences")}
              />
            </Form.Field>
          )}
          <Form.Field required error={this.hasError("machineId")}>
            <label>{t("Sensor.machine")}</label>
            <Form.Dropdown
              name="machineId"
              placeholder={t("Sensor.machinePlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={machineOptions}
              value={machineId}
              onChange={this.handleChange}
              noResultsMessage={t("Sensor.noMachines")}
            />
          </Form.Field>
          <Form.Field
            required
            disabled={!machine}
            error={this.hasError("position")}
          >
            <label>{t("Sensor.position")}</label>
            <Form.Dropdown
              name="position"
              placeholder={t("Sensor.positionPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={positionOptions}
              value={position}
              onChange={this.handleChange}
              noResultsMessage={t("Sensor.noPositions")}
            />
          </Form.Field>
          {addingNew && (
            <Form.Button className="Sensor__button" type="submit" primary>
              {t("Sensor.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button className="Sensor__button" type="submit" primary>
                {t("Sensor.update")}
              </Form.Button>
              <Button
                className="Sensor__button"
                type="button"
                negative
                onClick={this.handleRemove}
              >
                {t("Sensor.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    location,
    i18n: { language },
  } = ownProps;

  const sensorId = parseId(id);
  const sensor = selectSensorsMap(state)[sensorId];

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  const machineId =
    sensor?.machine || parseId(queryParameters(location)["machine"]) || 0;

  const machinesMap = selectMachinesMap(state);
  const machine = machinesMap[machineId];

  const positions = schemaKey(schema, "machine_positions");
  const matchingPositions = positions[machine?.category] || [];
  const position = matchingPositions.includes(sensor?.position)
    ? sensor?.position
    : undefined;

  const references = schemaKey(schema, "sensor_references");

  const c = schemaKey(schema, "sensor_reference_categories");
  const defaultReference = c["default"];
  const customReference = c["custom"];
  const machineCustomReference = c["machine_custom"];

  const customReferenceId = selectCustomReference(state, id, 0);
  let reference = sensor?.reference;
  if (sensor?.reference === customReference && !customReferenceId) {
    reference = defaultReference;
  }
  if (
    sensor?.reference === machineCustomReference &&
    !machine?.machine_custom_references?.length
  ) {
    reference = defaultReference;
  }

  return {
    serviceError: selectServiceError(state, HIERARCHY, SENSORS),
    sensorId,
    sensor,
    reference,
    customReferenceId,
    machineId,
    position,
    isNew: !sensorId,
    isMissingData: sensorId && !sensor,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addSensorOngoing: selectSensorsOngoing(state, "addSensor"),
    updateSensorOngoing: selectSensorsOngoing(state, "updateSensor"),
    removeSensorOngoing: selectSensorsOngoing(state, "removeSensor"),
    canEdit: hasCapability(state, CAN_MANAGE_SENSORS),
    canAddReference: hasCapability(state, CAN_MANAGE_CUSTOM_REFERENCES),
    machines: selectMachines(state),
    machinesMap,
    positions,
    references,
    customReferences: selectCustomReferences(state),
    customReference,
    translation,
  };
};

const mapDispatchToProps = {
  getHierarchy,
  addSensor,
  updateSensor,
  removeSensor,
  clearServiceErrors,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Sensor)
);
