import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

import {
  apiGetSensors,
  apiGetMachines,
  apiGetMachineGroups,
  apiGetAnalyses,
  apiGetNextJson,
  apiGetSensorGroup,
  apiGetAll,
} from "api";
import { parseDatetime, analysisGroups } from "utils";
import {
  diagnosisCategoryPriorities,
  schemaKey,
  translationKey,
} from "slices/userDataSlice";

const FONT_FAMILY = "Calibri";

const SHEET_HEADER_STYLE = {
  fontFamily: FONT_FAMILY,
  bold: true,
};

const DATA_HEADER_STYLE = {
  fontFamily: FONT_FAMILY,
  bold: true,
};

const linkStyle = (t) => ({
  fontFamily: FONT_FAMILY,
  fontColor: t("Reporter.linkColor"),
  underline: true,
});

const categoryNames = (t) => ({
  Acceptable: t("Reporter.statusOk"),
  Warning: t("Reporter.statusNotOk"),
  Danger: t("Reporter.statusNotOk"),
  Unsupported: t("Reporter.statusUnsupported"),
  "Unsupported data": t("Reporter.statusUnsupported"),
  undefined: t("Reporter.statusUndefined"),
});

const categoryStyles = (t) => ({
  Acceptable: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.okColor") },
    },
  },
  Warning: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.notOkColor") },
    },
  },
  Danger: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.notOkColor") },
    },
  },
  Unsupported: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.unsupportedColor") },
    },
  },
  "Unsupported data": {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.unsupportedColor") },
    },
  },
  undefined: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.missingColor") },
    },
  },
});

const categoryExpertStyles = (t) => ({
  Acceptable: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.okColor") },
    },
  },
  Warning: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.warningColor") },
    },
  },
  Danger: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.dangerColor") },
    },
  },
  Unsupported: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.unsupportedColor") },
    },
  },
  "Unsupported data": {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.unsupportedColor") },
    },
  },
  undefined: {
    fontFamily: FONT_FAMILY,
    fill: {
      type: "solid",
      color: { rgb: t("Reporter.missingColor") },
    },
  },
});

const analysisCategories = (analyses, excludeCategories) =>
  Array.from(
    analyses.reduce((acc, a) => {
      acc.add(a.category);
      return acc;
    }, new Set())
  )
    .filter((c) => c && (!excludeCategories || !excludeCategories.has(c)))
    .sort();

const algorithmVersion = (analyses) =>
  Array.from(
    analyses.reduce((acc, cur) => {
      cur.diagnoses.forEach((d) => acc.add(d.data?.version));
      return acc;
    }, new Set())
  )
    .filter((v) => v)
    .join(", ");

const analysisUrl = (analysis, t) => {
  const href = window.location.href;
  const baseUrl = href.substring(0, href.lastIndexOf(window.location.pathname));
  return t("Reporter.analysisUrl", { baseUrl, id: analysis.id });
};

// const sheetHeader = (
//   sheet,
//   { name, version, user, now },
//   dataHeaders,
//   { t }
// ) => {
//   const headers = [
//     [t("Reporter.taskReportTitle"), ...new Array(dataHeaders.length - 1)],
//     [],
//     [name],
//     [t("Reporter.algorithmVersion", { version })],
//     [t("Reporter.clientName", user)],
//     [t("Reporter.createdAt", { datetime: now })],
//     [t("Reporter.reporter", user)],
//     [],
//   ];

//   return [
//     headers,
//     () => {
//       ["A1", "A3", "A4", "A5", "A6"].forEach((h) =>
//         sheet.cell(h).style(SHEET_HEADER_STYLE)
//       );
//       return headers.length + 1;
//     },
//   ];
// };

// const addStatusSheet = (workbook, groups, { styles, t }) => {
//   const sheet = workbook.addSheet(t("Reporter.statusSheet"));

//   const dataHeaders = [
//     t("Reporter.machineGroup"),
//     t("Reporter.machine"),
//     t("Reporter.status"),
//   ];

//   const data = [];
//   const statuses = [];

//   let gn = "";
//   let mn = "";
//   groups.forEach((g) => {
//     g.machines.forEach((m, i) => {
//       gn = i === 0 ? g.name : "";
//       mn = m.description || m.name;
//       statuses.push([m.status, data.length]);
//       data.push([gn, mn, machineStatus(m, t)]);
//     });
//   });

//   sheet.cell("A1").value([dataHeaders, ...data]);

//   const dataHeaderRow = 1;
//   sheet.row(dataHeaderRow).style(DATA_HEADER_STYLE);

//   const severityStart = dataHeaderRow + 1;

//   statuses.forEach(([s, index]) => {
//     sheet.cell(`C${severityStart + index}`).style(styles(s));
//   });
// };

const setTitleSheet = (workbook, { name, version, user, now }, { t }) => {
  const sheet = workbook.sheet(0).name(t("Reporter.titleSheet"));

  const headers = [
    [t("Reporter.taskReportTitle")],
    [],
    [name],
    [t("Reporter.algorithmVersion", { version })],
    [t("Reporter.clientName", user)],
    [t("Reporter.createdAt", { datetime: now })],
    [t("Reporter.reporter", user)],
    [],
  ];
  sheet.cell("A1").value(headers);

  ["A1", "A3", "A4", "A5", "A6"].forEach((h) =>
    sheet.cell(h).style(SHEET_HEADER_STYLE)
  );
};

const addMachineStatusSheet = (
  workbook,
  groups,
  advanced,
  { translation, t }
) => {
  const sheet = workbook.addSheet(t("Reporter.machineStatusSheet"));

  const cn = categoryNames(t);
  const cs = categoryStyles(t);
  const ls = linkStyle(t);

  const trends = translationKey(translation, "trends");
  const trendKfs = Object.keys(trends);
  const trendTitles = trendKfs.map((t) => trends[t].title);

  const dataHeaders = advanced
    ? [
        t("Reporter.machineGroup"),
        t("Reporter.machine"),
        t("Reporter.sensor"),
        t("Reporter.status"),
        t("Reporter.measuredAt"),
        ...trendTitles,
        t("Reporter.analysisLink"),
      ]
    : [
        t("Reporter.machineGroup"),
        t("Reporter.machine"),
        t("Reporter.sensor"),
        t("Reporter.status"),
        t("Reporter.measuredAt"),
      ];

  const data = [];
  const styles = [];

  groups.forEach((group) => {
    let groupName = group.name;
    group.machines.forEach((machine) => {
      let machineName = machine.name;
      machine.sensors.forEach((sensor) => {
        let sensorName = sensor.name;
        if (sensor.analyses.length === 0) {
          data.push([groupName, machineName, sensorName]);
          styles.push({
            groupStyle: cs[group.status],
            machineStyle: cs[machine.status],
            sensorStyle: cs[undefined],
            diagnosisStyle: {},
            extraStyle: {},
          });
        } else {
          sensor.analyses.forEach((analysis) => {
            analysis.diagnoses.forEach((diagnosis) => {
              const url = analysisUrl(analysis, t);

              const dataRow = [
                groupName,
                machineName,
                sensorName,
                cn[diagnosis.category],
                t("Reporter.datetime", {
                  datetime: parseDatetime(analysis.measured_at),
                }),
              ];
              if (advanced) {
                dataRow.push(
                  ...trendKfs.map(
                    (kf) => analysis.data?.["key_figures"]?.[kf] || undefined
                  ),
                  url
                );
              }

              data.push(dataRow);

              const rowStyle = {
                groupStyle: cs[group.status],
                machineStyle: cs[machine.status],
                sensorStyle: cs[sensor.status],
                diagnosisStyle: cs[diagnosis.category],
                extraStyle: ["Warning", "Danger"].includes(diagnosis.category)
                  ? cs[diagnosis.category]
                  : {},
              };
              if (advanced) {
                rowStyle.linkStyle = ls;
                rowStyle.linkUrl = url;
              }
              styles.push(rowStyle);

              groupName = machineName = sensorName = "";
            });
            groupName = machineName = sensorName = "";
          });
        }
        groupName = machineName = sensorName = "";
      });
    });
  });

  sheet.cell("A1").value([dataHeaders, ...data]);

  const dataHeaderRow = 1;
  sheet.row(dataHeaderRow).style(DATA_HEADER_STYLE);

  const dataStart = dataHeaderRow + 1;

  styles.forEach(
    (
      {
        groupStyle,
        machineStyle,
        sensorStyle,
        diagnosisStyle,
        extraStyle,
        linkStyle,
        linkUrl,
      },
      index
    ) => {
      sheet.cell(`A${dataStart + index}`).style(groupStyle);
      sheet.cell(`B${dataStart + index}`).style(machineStyle);
      sheet.cell(`C${dataStart + index}`).style(sensorStyle);
      sheet.cell(`D${dataStart + index}`).style(diagnosisStyle);
      if (advanced) {
        sheet
          .range(`E${dataStart + index}:J${dataStart + index}`)
          .style(extraStyle);
        if (linkStyle && linkUrl) {
          sheet
            .cell(`J${dataStart + index}`)
            .style(linkStyle)
            .hyperlink(linkUrl);
        }
      }
    }
  );
};

const addResultsSheet = (
  workbook,
  analyses,
  hierarchy,
  allSensors,
  advanced,
  { translation, t }
) => {
  const analysisSensors = new Set(analyses.map((a) => a.sensor));

  const sheet = workbook.addSheet(t("Reporter.resultsSheet"));
  const dataHeaders = advanced
    ? [
        t("Reporter.hierarchy"),
        t("Reporter.name"),
        t("Reporter.severity"),
        t("Reporter.condition"),
        t("Reporter.comments"),
        t("Reporter.recommendation"),
        t("Reporter.measuredAt"),
        t("Reporter.analysisLink"),
      ]
    : [
        t("Reporter.hierarchy"),
        t("Reporter.name"),
        t("Reporter.severity"),
        t("Reporter.condition"),
        t("Reporter.measuredAt"),
      ];

  const diagnosisCategories = translation["diagnosis_categories"];
  const diagnosisFaults = translation["diagnosis_faults"];
  const cs = categoryExpertStyles(t);
  const ls = linkStyle(t);

  const data = [];
  const styles = [];

  const sensorMap = hierarchy.sensors;
  const machineMap = hierarchy.machines;

  analyses.forEach((analysis) => {
    const url = analysisUrl(analysis, t);
    analysis.diagnoses.forEach((diagnosis) => {
      data.push([
        sensorMap[analysis.sensor].external_id,
        machineMap[analysis.machine].description ||
          machineMap[analysis.machine].name,
        diagnosisCategories[diagnosis.category],
        diagnosisFaults[diagnosis.fault],
        ...(advanced ? ["", ""] : []),
        t("Reporter.datetime", {
          datetime: parseDatetime(analysis.measured_at),
        }),
        url,
      ]);
      styles.push({
        diagnosisStyle: cs[diagnosis.category],
        linkStyle: ls,
        linkUrl: url,
      });
    });
  });

  sheet.cell("A1").value([dataHeaders, ...data]);

  const dataHeaderRow = 1;
  sheet.row(dataHeaderRow).style(DATA_HEADER_STYLE);

  const dataStart = dataHeaderRow + 1;
  styles.forEach(({ diagnosisStyle, linkStyle, linkUrl }, index) => {
    sheet.cell(`C${dataStart + index}`).style(diagnosisStyle);
    sheet
      .cell(`H${dataStart + index}`)
      .style(linkStyle)
      .hyperlink(linkUrl);
  });
  const missingStart = dataHeaderRow + styles.length + 1;
  const missingSensors = allSensors.filter((id) => !analysisSensors.has(id));
  if (missingSensors.length > 0) {
    sheet
      .cell(`A${missingStart}`)
      .value([...missingSensors.map((id) => [sensorMap[id].external_id])]);
    missingSensors.forEach((_, i) =>
      sheet.cell(`A${missingStart + i}`).style(cs[undefined])
    );
  }
};

const addExpertSheet = (
  workbook,
  analyses,
  category,
  hierarchy,
  { schema, translation, t }
) => {
  const keyFigures = schemaKey(schema, "key_figures")[category];
  const sheet = workbook.addSheet(category);

  const dataHeaders = [
    t("Reporter.hierarchy"),
    t("Reporter.name"),
    t("Reporter.severity"),
    t("Reporter.condition"),
    t("Reporter.position"),
    t("Reporter.comments"),
    t("Reporter.recommendation"),
    t("Reporter.measuredAt"),
    t("Reporter.category"),
    t("Reporter.rotationalSpeed"),
    ...keyFigures,
    t("Reporter.diagnoserVersion"),
  ];

  const diagnosisCategories = translation["diagnosis_categories"];
  const diagnosisFaults = translation["diagnosis_faults"];
  const machineCategories = translation["machine_categories"];
  const cs = categoryExpertStyles(t);

  const data = [];
  const styles = [];

  const sensorMap = hierarchy.sensors;
  const machineMap = hierarchy.machines;

  analyses
    .filter((analysis) => analysis.category === category)
    .forEach((analysis) => {
      analysis.diagnoses.forEach((diagnosis) => {
        data.push([
          sensorMap[analysis.sensor].external_id,
          machineMap[analysis.machine].description ||
            machineMap[analysis.machine].name,
          diagnosisCategories[diagnosis.category],
          diagnosisFaults[diagnosis.fault],
          "",
          "",
          "",
          t("Reporter.datetime", {
            datetime: parseDatetime(analysis.measured_at),
          }),
          ...[
            machineCategories[diagnosis.data?.machine_data?.category],
            diagnosis.data?.machine_data?.rotational_speed_hz || undefined,
          ],
          ...keyFigures.map(
            (kf) => analysis.data?.["key_figures"][kf] || undefined
          ),
          diagnosis.data?.version,
        ]);
        styles.push({ diagnosisStyle: cs[diagnosis.category] });
      });
    });

  sheet.cell("A1").value([dataHeaders, ...data]);

  const headerRow = 1;
  sheet.row(headerRow).style(DATA_HEADER_STYLE);

  const dataStart = headerRow + 1;

  styles.forEach(({ diagnosisStyle }, i) => {
    sheet.cell(`C${dataStart + i}`).style(diagnosisStyle);
  });
};

export async function saveTaskReport(
  tasks,
  details,
  token,
  user,
  schema,
  translation,
  t,
  sortLocale
) {
  const advanced = ["advanced", "expert"].includes(details);

  // get all machine groups and create machine group map for fast access
  const machineGroups = await apiGetAll(
    token,
    apiGetMachineGroups,
    apiGetNextJson
  );
  const machineGroupMap = machineGroups.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  // get all machines and create machine map for fast access
  const machines = await apiGetAll(token, apiGetMachines, apiGetNextJson);
  const machineMap = machines.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  // get all sensors and create sensor map for fast access
  const sensors = await apiGetAll(token, apiGetSensors, apiGetNextJson);
  const sensorMap = sensors.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  const hierarchyData = {
    locations: {},
    machineGroups: machineGroupMap,
    machines: machineMap,
    sensors: sensorMap,
  };

  // collect all task sensor groups
  const sensorGroups = Array.from(
    new Set(tasks.map((t) => t.sensor_group).filter((r) => !!r)).values()
  );
  let expectedSensors = []; // default, no expectations
  if (tasks.length === 1 && sensorGroups.length === 0) {
    // one task, no sensor group => expect data for all sensors
    expectedSensors = sensors.map((s) => s.id);
  } else if (sensorGroups.length === 1 && tasks.every((t) => t.sensor_group)) {
    // all tasks use sensor group and the sensor group is the
    // same, so expect data for sensor group
    // (beware tasks without sensor group)
    const r = await apiGetSensorGroup(token, sensorGroups[0]);
    expectedSensors = r.sensors;
  }

  const inTasks = tasks.map((t) => t.id).join(",");
  const analyses = await apiGetAll(token, apiGetAnalyses, apiGetNextJson, {
    tasks: inTasks,
  });

  // create excel
  const now = new Date();
  // in case of single task, report name is task label, if any
  const name =
    (tasks.length === 1 && tasks[0].label) ||
    t("Reporter.taskReportName", { datetime: now });
  const version = algorithmVersion(analyses);
  const workbookName = name;
  XlsxPopulate.fromBlankAsync().then((workbook) => {
    const groups = analysisGroups(
      analyses,
      undefined,
      hierarchyData,
      expectedSensors,
      diagnosisCategoryPriorities(schema),
      sortLocale
    );

    setTitleSheet(
      workbook,
      { name, version, user, now },
      {
        t,
      }
    );

    addMachineStatusSheet(workbook, groups, advanced, {
      translation,
      t,
    });

    addResultsSheet(
      workbook,
      analyses,
      hierarchyData,
      expectedSensors,
      advanced,
      {
        schema,
        translation,
        t,
      }
    );

    if (details === "expert") {
      const categories = analysisCategories(
        analyses,
        new Set(
          schemaKey(schema, "analysis_category_groups")["unsupported_data"]
        )
      );
      categories.forEach((category) =>
        addExpertSheet(workbook, analyses, category, hierarchyData, {
          schema,
          translation,
          t,
        })
      );
    }

    return workbook.outputAsync().then((blob) => {
      if (process.env.NODE_ENV !== "production") {
        if (window.Cypress) {
          window.ADA_REPORT = Date.now();
        } else {
          saveAs(blob, `${workbookName}.xlsx`);
        }
        return;
      }
      saveAs(blob, `${workbookName}.xlsx`);
    });
  });
}

// const addSensorSheet = (
//   workbook,
//   category,
//   analyses,
//   diagnosisMap,
//   schemaKey,
//   translationKey,
//   t
// ) => {
//   const ma = analyses.filter((a) => a.category === category);
//   const keyFigures = schemaKey("key_figures")[category];
//   const sheet = workbook.addSheet(category);

//   sheet.cell("A1").value([
//     [t("Reporter.measuredAt")].concat(
//       ma.map((a) =>
//         t("Reporter.datetime", {
//           datetime: parseDatetime(a.measured_at),
//         })
//       )
//     ),
//     ...[0, 1, 2, 3].map((i) =>
//       [t("Reporter.diagnosis", { index: i })].concat(
//         ma.map((a) => {
//           const d = diagnosisMap[a.id][i];
//           return d
//             ? d.fault
//               ? t("Reporter.faultDiagnosis", {
//                   category: translationKey("diagnosis_categories")[d.category],
//                   fault: translationKey("diagnosis_faults")[d.fault],
//                 })
//               : t("Reporter.noFaultDiagnosis", {
//                   category: translationKey("diagnosis_categories")[d.category],
//                 })
//             : undefined;
//         })
//       )
//     ),
//     [],
//     ...keyFigures.map((kf) =>
//       [kf].concat(ma.map((a) => a.data["key_figures"][kf]))
//     ),
//   ]);
// };
export async function saveSensorReport() {
  /* TODO FIXME */
}

// export async function saveSensorReport(
//   sensor,
//   token,
//   userInfo,
//   schemaKey,
//   translationKey,
//   t
// ) {
//   // get all sensor analyses that contain key figures
//   const analyses = (
//     await apiGetAll(token, apiGetAnalyses, apiGetNextJson, {
//       sensor: sensor.id,
//     })
//   ).reverse();

//   // get all sensor diagnoses and create diagnosis map for fast access
//   const diagnoses = await apiGetAll(token, apiGetDiagnoses, apiGetNextJson, {
//     sensor: sensor.id,
//   });
//   const diagnosisMap = diagnoses.reduce((acc, cur) => {
//     if (!acc[cur.analysis]) {
//       acc[cur.analysis] = [cur];
//     } else {
//       acc[cur.analysis].push(cur);
//     }
//     return acc;
//   }, {});

//   // create excel
//   const now = new Date();
//   const reportName = t("Reporter.sensorReportName", {
//     id: sensor.id,
//     datetime: now,
//   });
//   // const reportCreatedAt = t("Reporter.datetime", { datetime: now });

//   const wbName = reportName;
//   XlsxPopulate.fromBlankAsync().then((workbook) => {
//     const sheet = workbook.sheet(0);
//     sheet.cell("A1").value([
//       [t("Reporter.sensorReportTitle")],
//       [],
//       [reportName],
//       [userInfo.client_name],
//       [t("Reporter.created", { datetime: now })],
//       // [
//       //   t("Reporter.datetime", {
//       //     datetime: reportCreatedAt,
//       //   }),
//       // ],
//       [t("Reporter.reporter", userInfo)],
//       [],
//       [sensor.name],
//       [sensor.description],
//       [sensor.external_id],
//     ]);
//     const documentHeaderStyle = {
//       fontFamily: FONT_FAMILY,
//       bold: true,
//     };
//     const headers = ["A1", "A3", "A4", "A5", "A6"];
//     headers.forEach((h) => sheet.cell(h).style(documentHeaderStyle));

//     // FIXME
//     const categories = analysisCategories(
//       // diagnoses.filter(
//       //   (d) =>
//       //     !schemaKey("diagnosis_category_groups")["unsupported_data"].includes(
//       //       d.category
//       //     )
//       // )
//     );

//     categories.forEach((category) =>
//       addSensorSheet(
//         workbook,
//         category,
//         analyses,
//         diagnosisMap,
//         schemaKey,
//         translationKey,
//         t
//       )
//     );

//     return workbook.outputAsync().then((blob) => {
//       if (process.env.NODE_ENV !== "production") {
//         if (window.Cypress) {
//           window.ADA_REPORT = Date.now();
//         } else {
//           saveAs(blob, `${wbName}.xlsx`);
//         }
//         return;
//       }
//       saveAs(blob, `${wbName}.xlsx`);
//     });
//   });
// }
