import { createAction, createSlice } from "@reduxjs/toolkit";
import { apiLogin } from "api";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const token = createSlice({
  name: "token",
  initialState: { ongoing: {}, token: null },
  reducers: {
    start: startOngoing,
    end: endOngoing,
    loginSuccess(state, { payload }) {
      state.token = payload.token;
    },
  },
});

export default token.reducer;

const { start, end, loginSuccess } = token.actions;

export const selectToken = (state) => state.token.token;
export const selectTokenOngoing = (state, key) => !!state.token.ongoing[key];

export const login = (credentials, opts) => async (dispatch, getState) => {
  try {
    if (selectTokenOngoing(getState(), "login")) {
      return;
    }
    dispatch(start("login"));
    const token = await apiLogin(credentials);
    batchSuccess(dispatch, opts, loginSuccess(token), end("login"));
  } catch (error) {
    batchFailure(dispatch, error, opts, end("login"));
    throw error;
  }
};

export const logout = createAction("token/logout");
