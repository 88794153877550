import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { apiCreateMachine, apiUpdateMachine, apiDeleteMachine } from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const machines = createSlice({
  name: "machines",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      adapter.setAll(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default machines.reducer;

const {
  start,
  end,
  set,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = machines.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.machines;
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const selectReference = (state, id, index = 0) => {
  const machine = selectEntities(state)[id];
  const r = machine?.machine_custom_references;
  return (r?.length > index && r[index]) || undefined;
};

export const addMachine = (data, options) => async (dispatch, getState) => {
  try {
    dispatch(start("addMachine"));
    const machine = await apiCreateMachine(selectToken(getState()), data);
    batchSuccess(dispatch, options, add(machine), end("addMachine"));
    return machine;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addMachine"));
    throw error;
  }
};

export const updateMachine = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("updateMachine"));
    const machine = await apiUpdateMachine(selectToken(getState()), id, data);
    batchSuccess(dispatch, options, update(machine), end("updateMachine"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateMachine"));
    throw error;
  }
};

export const removeMachine = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("removeMachine"));
    await apiDeleteMachine(selectToken(getState()), id);
    batchSuccess(dispatch, options, remove(id), end("removeMachine"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeMachine"));
    throw error;
  }
};
