import { createSlice } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";

import defaultLogo from "images/asensiot_logo.svg";

import { apiGetCombinedUserData, apiChangePassword } from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const userData = createSlice({
  name: "userData",
  initialState: { ongoing: {}, data: null },
  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      state.data = {
        userInfo: payload.user_info,
        userCapabilities: payload.user_capabilities,
        userProfile: payload.user_profile,
        clientProfile: payload.client_profile,
        client: payload.client,
        schema: payload.schema,
      };
    },
  },
});

export default userData.reducer;

const { start, end, set } = userData.actions;

const selectUserDataState = (state) => state.userData;

export const selectUserDataOngoing = (state, key) =>
  !!selectUserDataState(state).ongoing[key];

export const selectUserInfo = (state) =>
  selectUserDataState(state).data?.userInfo;
export const selectUserCapabilities = (state) =>
  selectUserDataState(state).data?.userCapabilities;
export const selectUserProfile = (state) =>
  selectUserDataState(state).data?.userProfile;
export const selectClientProfile = (state) =>
  selectUserDataState(state).data?.clientProfile;
export const selectClient = (state) =>
  selectUserDataState(state).data?.client;
export const selectServiceSchema = (state) =>
  selectUserDataState(state).data?.schema?.schema;
export const schemaKey = (schema, key) => schema[key];

const memoizedTranslation = memoizeOne((userData, language) => {
  const translations = userData.data?.schema?.translations;
  const [primary] = language.split("-");
  return (
    translations &&
    (translations[language] || translations[primary] || translations["en"])
  );
});

export const selectTranslation = (state, language) =>
  memoizedTranslation(selectUserDataState(state), language);

export const selectTranslations = (state) =>
  selectUserDataState(state).data?.schema?.translations;

export const translationKey = (translation, key) => translation[key];

export const selectIsUserDataComplete = (state) => {
  const token = selectToken(state);
  const { data } = selectUserDataState(state);
  return !!(
    token &&
    data &&
    data.userInfo &&
    data.userCapabilities &&
    data.userProfile &&
    data.clientProfile &&
    data.schema
  );
};

export const selectLanguage = (state) =>
  selectUserProfile(state).language || "en";
export const selectLocale = (state) => selectUserProfile(state).locale || "en";
export const selectSortLocale = (/* state */) => "fi";
export const selectMenuLogo = (state) =>
  selectClientProfile(state).logo || defaultLogo;
export const selectMenuStyle = (state) => {
  const p = selectClientProfile(state);
  const { themeColor } = p?.data?.ui || {};
  return (themeColor && { backgroundColor: themeColor }) || {};
};
export const selectDataFileFormats = (state) => {
  const p = selectClientProfile(state);
  return p?.data?.dataFileFormats;
};

export const selectBundle = (state) => {
  const { data } = selectUserDataState(state);
  return data?.clientProfile?.data?.i18nBundle;
};

export const selectRecommendedActions = memoizeOne(
  (translation, category, fault) => {
    const t = translation?.recommended_actions;
    return (t?.[category]?.[fault] || t?.[category]?.Default || [""]).join(
      "\n"
    );
  }
);

export const selectUsername = (state) => selectUserInfo(state).username || "";

export const isTaskRunning = (task, schema) =>
  schemaKey(schema, "task_status_groups")["running"].includes(task.status);
export const hasTaskFinished = (task, schema) =>
  schemaKey(schema, "task_status_groups")["finished"].includes(task.status);
export const hasTaskFinishedOk = (task, schema) =>
  schemaKey(schema, "task_status_groups")["finished_ok"].includes(task.status);
export const isDataTask = (task, schema) => {
  if (
    schemaKey(schema, "task_status_groups")["created"].includes(task.status)
  ) {
    return false;
  }
  const dataFileFormats = schemaKey(schema, "task_data_formats");
  return dataFileFormats.includes(task.data_file_format);
};
export const hasTaskFailed = (task, schema) =>
  schemaKey(schema, "task_status_groups")["failed"].includes(task.status);
export const canTaskBeRestarted = (task, schema) =>
  schemaKey(schema, "task_status_groups")["blocked"].includes(task.status);
export const canTaskBeRemoved = (task, schema) =>
  schemaKey(schema, "task_status_groups")["safe_to_remove"].includes(
    task.status
  );

export const CAN_ACCESS_EXTENDED_UI = "can access extended UI";
export const CAN_ACCESS_TECHNICIAN_UI = "can access technician UI";
export const CAN_MODERATE_MACHINE_LOG = "can moderate machine log";
export const CAN_ACCESS_TASK_FILE = "can access task file";
export const CAN_ACCESS_ANALYSIS_DATA = "can access analysis data";
export const CAN_ACCESS_ALL_ANALYSIS_DATA = "can access all analysis data";
export const CAN_IMPORT = "can import";
export const CAN_LOG_ENTRIES = "can log entries";
export const CAN_MANAGE_LOCATIONS = "can manage locations";
export const CAN_MANAGE_MACHINE_GROUPS = "can manage machine groups";
export const CAN_MANAGE_MACHINES = "can manage machines";
export const CAN_MANAGE_SENSORS = "can manage sensors";
export const CAN_MANAGE_CUSTOM_REFERENCES = "can manage custom references";
export const CAN_MANAGE_SENSOR_GROUPS = "can manage sensor groups";
export const CAN_MANAGE_LABELS = "can manage labels";
export const CAN_MANAGE_TASKS = "can manage tasks";
export const CAN_REMOVE_TASKS = "can remove tasks";
export const CAN_REVISE_DIAGNOSES = "can revise diagnoses";

export const hasCapability = (state, capability) =>
  !!selectUserCapabilities(state)?.[capability];
export const hasEveryCapability = (state, capabilities) =>
  capabilities.every((c) => selectUserCapabilities(state)[c]);
export const hasSomeCapability = (state, capabilities) =>
  capabilities.some((c) => selectUserCapabilities(state)[c]);

export const selectIsManualDiagnosis = (state, diagnosis) =>
  schemaKey(selectServiceSchema(state), "diagnosis_source_groups")[
    "manual"
    ].includes(diagnosis?.source);

export const getUserData = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectUserDataOngoing(state, "getUserData")) {
      return;
    }
    dispatch(start("getUserData"));
    const userData = await apiGetCombinedUserData(selectToken(state));
    batchSuccess(dispatch, options, set(userData), end("getUserData"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getUserData"));
    throw error;
  }
};

const categorized = memoizeOne((schema) => {
  const categories = schemaKey(schema, "diagnosis_categories");
  const groups = schemaKey(schema, "diagnosis_category_groups");

  if (!categorized || !groups) return {};

  return categories.reduce((acc, cur) => {
    acc[cur] = {
      ok: groups["ok"].includes(cur),
      warning: groups["warning"].includes(cur),
      danger: groups["danger"].includes(cur),
      unsupported: groups["unsupported"].includes(cur),
    };
    return acc;
  }, {});
});

export const diagnosisCategoryGroups = (category, schema) => {
  return categorized(schema)[category] || {};
};

export const diagnosisCategoryPriorities = memoizeOne((schema) => {
  const categories = schemaKey(schema, "diagnosis_categories");
  const groups = schemaKey(schema, "diagnosis_category_groups");
  return categories.reduce((acc, curr) => {
    if (groups["unsupported"].includes(curr)) {
      acc[curr] = 0;
    }
    if (groups["ok"].includes(curr)) {
      acc[curr] = 1;
    }
    if (groups["warning"].includes(curr)) {
      acc[curr] = 2;
    }
    if (groups["danger"].includes(curr)) {
      acc[curr] = 3;
    }
    return acc;
  }, {});
});

export const isDiagnosedCategory = (c, schema) => {
  const { ok, warning, danger, unsupported } = diagnosisCategoryGroups(
    c,
    schema
  );
  if (!(ok || warning || danger || unsupported)) {
    return false;
  }
  return true;
};

export const changePassword = (data, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectUserDataOngoing(state, "changePassword")) {
      return;
    }
    dispatch(start("changePassword"));
    await apiChangePassword(selectToken(state), data);
    batchSuccess(dispatch, options, end("changePassword"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("changePassword"));
    throw error;
  }
};
