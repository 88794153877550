import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  apiGetLabels,
  apiCreateLabel,
  apiDeleteLabel,
  apiGetNextJson,
} from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const labels = createSlice({
  name: "labels",
  initialState: adapter.getInitialState({
    ongoing: {},
    next: null,
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.setAll(state, results);
    },
    addNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.addMany(state, results);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default labels.reducer;

const { start, end, set, addNext, add, removeMany } = labels.actions;

const selectLabelsState = (state) => state.labels;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectLabelsState);

export const selectLabelsOngoing = (state, key) =>
  !!selectLabelsState(state).ongoing[key];
const selectLabelsOngoingMany = (state, ...keys) => {
  return keys.every((key) => selectLabelsState(state).ongoing[key]);
};

export const selectLabelsNext = (state) => {
  if (selectLabelsOngoingMany(state, "getLabels", "getNextLabels")) {
    return null;
  }
  return selectLabelsState(state).next;
};

export const getLabels = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectLabelsOngoing(state, "getLabels")) {
      return;
    }
    dispatch(start("getLabels"));
    const labels = await apiGetLabels(selectToken(state));
    batchSuccess(dispatch, options, set(labels), end("getLabels"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getLabels"));
    throw error;
  }
};

export const getNextLabels = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const next = selectLabelsNext(state);
    if (!next || selectLabelsOngoing(state, "getNextRoutes")) {
      return;
    }
    dispatch(start("getNextLabels"));
    const labels = await apiGetNextJson(
      selectToken(state),
      selectLabelsNext(state)
    );
    batchSuccess(dispatch, options, addNext(labels), end("getNextLabels"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getNextLabels"));
    throw error;
  }
};

export const addLabel = (data, options) => async (dispatch, getState) => {
  try {
    dispatch(start("addLabel"));
    const label = await apiCreateLabel(selectToken(getState()), data);
    batchSuccess(dispatch, options, add(label), end("addLabel"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("addLabel"));
    throw error;
  }
};

export const removeLabels = (labels, options) => async (dispatch, getState) => {
  try {
    dispatch(start("removeLabels"));
    await Promise.all(
      labels.map((label) => apiDeleteLabel(selectToken(getState()), label))
    );
    batchSuccess(dispatch, options, removeMany(labels), end("removeLabels"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeLabels"));
    throw error;
  }
};
