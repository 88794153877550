import { openDB } from 'idb'

const getDB = async () => openDB("deploy-updates", 1, {
  upgrade(db) {
    db.createObjectStore('machines');
    db.createObjectStore('machinegroup-files');
  },
})

export async function saveChangesToDisk({ machines, machineGroup, machineGroupFiles }) {
  const db = await getDB()

  const attachments = await Promise.all(
    Object.entries(machineGroupFiles)
      .map(async ([name, file]) => ({
        name,
        data: await imageToBase64(file)
      }))
  )

  const machinesTx = db.transaction("machines", 'readwrite');
  await Promise.all([
    ...machines.map(it => (machinesTx.store.put(JSON.stringify(it), it.id)))
  ])
  await machinesTx.done

  await db.put(
    "machinegroup-files",
    JSON.stringify({
      // Fields to save
      id: machineGroup.id,
      name: machineGroup.name,
      description: machineGroup.description,
      sap_id: machineGroup.sap_id,
      attachments: attachments
    }), machineGroup.id)
}

export async function loadChangesFromDisk() {
  const db = await getDB()
  const machines = (await db.getAll('machines')).map(it => JSON.parse(it))
  const machineGroupFiles = (await db.getAll('machinegroup-files'))
    .map(it => JSON.parse(it))
    .map(({ attachments, ...rest }) =>
      attachments.reduce((acc, file) => {
        const blob = dataURItoBlob(file.data)
        // Uploads require a file name so fake it
        acc['attachments'][file.name] = new File([blob], `attachment.${blob.type.split("/").pop()}`);
        return acc
      }, { ...rest, attachments: {} })
    )

  return { machines, machineGroupFiles }
}

export async function deleteChangesFromDisk({ machine, machineGroup }) {
  console.log("deleting changes from disk", { machine, machineGroup })
  const db = await getDB()
  if (machine) await db.delete('machines', machine.id || machine)
  if (machineGroup) await db.delete('machinegroup-files', machineGroup.id || machineGroup)
}


// convert base64/URLEncoded to a blob
// stolen from https://stackoverflow.com/a/5100158
function dataURItoBlob(dataURI) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

async function imageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
