import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  apiCreateMachineCustomReference,
  apiUpdateMachineCustomReference,
  apiDeleteMachineCustomReference,
} from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const machineCustomReferences = createSlice({
  name: "machineCustomReferences",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      adapter.setAll(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default machineCustomReferences.reducer;

const {
  start,
  end,
  set,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = machineCustomReferences.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.machineCustomReferences;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const addMachineCustomReference = (data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("addMachineCustomReference"));
    const r = await apiCreateMachineCustomReference(
      selectToken(getState()),
      data
    );
    batchSuccess(dispatch, options, add(r), end("addMachineCustomReference"));
    return r;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addMachineCustomReference"));
    throw error;
  }
};

export const updateMachineCustomReference = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("updateMachineCustomReference"));
    const r = await apiUpdateMachineCustomReference(
      selectToken(getState()),
      id,
      data
    );
    batchSuccess(
      dispatch,
      options,
      update(r),
      end("updateMachineCustomReference")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateMachineCustomReference"));
    throw error;
  }
};

export const removeMachineCustomReference = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("removeMachineCustomReference"));
    const state = getState();
    await apiDeleteMachineCustomReference(selectToken(state), id);
    batchSuccess(
      dispatch,
      options,
      remove(id),
      end("removeMachineCustomReference")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeMachineCustomReference"));
    throw error;
  }
};
