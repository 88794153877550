import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Header, Image, Input, Modal, Radio, Segment, Select } from "semantic-ui-react"
import { selectEntities as selectMachineGroups, updateMachineGroup, updateMachineGroupImages } from "./slices/machineGroupsSlice"
import { selectEntities as selectMachines, updateMachine } from "slices/machinesSlice"
import { connect, useSelector } from "react-redux"
import { COMPLETED_ICON, RELOAD_ICON, toIcon } from "./icons"
import { opts } from "./slices/serviceErrorsSlice"
import { MACHINES } from "./Machine"
import { MACHINE_GROUPS } from "./MachineGroup"
import { deleteChangesFromDisk, loadChangesFromDisk, saveChangesToDisk } from "./deployOfflineStorage"
import { useTranslation } from "react-i18next"
import Loader from "./Loader"

const ACCEPT_IMAGES = "image/png, image/jpeg"

function DeployMachineGroupModal({
                                   open,
                                   setOpen,
                                   machineGroupId,
                                   updateMachine,
                                   updateMachineGroupImages
                                 }) {

  const { t } = useTranslation()
  const state = useSelector((state) => state)

  const [deployState, setDeployState] = useState({ machines: [], machineGroupFiles: {} })
  const [loading, setLoading] = useState(false)
  const [unsavedCount, setUnsavedCount] = useState(0)

  useEffect(() => {
    checkUnsavedCount()
    const machineGroup = selectMachineGroups(state)[machineGroupId]
    if (machineGroup) {
      setDeployState(prev => ({
        ...prev,
        machines: machineGroup.machines.map(it => selectMachines(state)[it]),
        machineGroup: machineGroup,
      }))
    }
  }, [machineGroupId, state])

  const { machines, machineGroup, machineGroupFiles } = deployState

  const updateMachineState = (id, update) => {
    setDeployState(deployState => {
      const { machines } = deployState
      const otherMachines = machines.filter(it => it.id !== id)
      const newMachine = { ...machines.find(it => it.id === id), ...update }
      return { ...deployState, machines: [...otherMachines, newMachine] }
    })
  }

  const updateImageState = (id, file) => {
    setDeployState({
      ...deployState,
      machineGroupFiles: { ...machineGroupFiles, [id]: file }
    })
  }

  const updateOrSaveOffline = async () => {
    const attemptUpload = async () => {
      await Promise.all(machines.map(it => uploadMachine(it)))
      await uploadMachineGroup(machineGroup, machineGroupFiles)
      return true
    }
    let success = false
    try {
      success = await Promise.race([
        attemptUpload(),
        // mark as failed (and saved) if the upload does not success in ~10 seconds
        new Promise(res => setTimeout(res, 12 * 1000, false))
      ])
    } finally {
      if (!success) {
        await saveChangesToDisk({ machines, machineGroup, machineGroupFiles })
      }
    }
  }

  const uploadMachine = async (machine) => {
    try {
      console.log("uploading machine", machine)
      await updateMachine(
        machine.id,
        machine,
        opts(MACHINES, t("Machine.updateError"))
      )
      await deleteChangesFromDisk({ machine })
    } finally {
      checkUnsavedCount()
    }
  }

  const uploadMachineGroup = async (machineGroup, machineGroupFiles) => {
    try {
      console.log("uploading machine group images", machineGroupFiles)
      await updateMachineGroupImages(
        machineGroup.id || machineGroup,
        {
          // Fields to update
          ...machineGroupFiles,
          name: machineGroup.name,
          description: machineGroup.description,
          sap_id: machineGroup.sap_id,
        },
        opts(MACHINE_GROUPS, t("MachineGroup.updateError"))
      )
      await deleteChangesFromDisk({ machineGroup })
    } finally {
      checkUnsavedCount()
    }
  }

  const checkUnsavedCount = () => {
    loadChangesFromDisk()
      .then(data => {
        setUnsavedCount((data?.machines?.length || 0) + (data?.machineGroupFiles?.length || 0))
      })
  }

  const uploadNotification = (
    <OfflineUpdateNotification
      count={unsavedCount}
      uploadMachine={uploadMachine}
      uploadMachineGroupImages={uploadMachineGroup}
    />
  )

  if (!machineGroup) {
    return uploadNotification
  }
  const { name: oldMachineGroupName } = selectMachineGroups(state)[machineGroup.id]
  return (
    <>
      {uploadNotification}
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Machine Group: {oldMachineGroupName}</Modal.Header>
        <Modal.Content>
          <Segment>
            <Form>
              <b>Machine group name</b>
              <Form.Field>
                <Form.Input
                  value={machineGroup.name}
                  onChange={(_, { value }) => {
                    setDeployState({
                      ...deployState,
                      machineGroup: { ...deployState.machineGroup, name: value }
                    })
                  }}
                />
              </Form.Field>
              <b>Description</b>
              <Form.Field>
                <Form.Input
                  value={machineGroup.description}
                  onChange={(_, { value }) => {
                    setDeployState({
                      ...deployState,
                      machineGroup: { ...deployState.machineGroup, description: value }
                    })
                  }}
                />
              </Form.Field>
              <b>SAP ID</b>
              <Form.Field>
                <Form.Input
                  value={machineGroup.sap_id}
                  onChange={(_, { value }) => {
                    setDeployState(deployState => ({
                      ...deployState,
                      machineGroup: { ...deployState.machineGroup, sap_id: value }
                    }))
                    machines.forEach(it => updateMachineState(it.id, { sap_id: value }))
                  }}
                />
              </Form.Field>
            </Form>
          </Segment>

          <Segment>
            <Header as="h4">Images</Header>
            <div>
              <b>Safety Switch</b>
              <div className="Deploy__machinegroup-image-upload">
                <Input
                  type="file"
                  accept={ACCEPT_IMAGES}
                  icon="camera"
                  onChange={e => updateImageState("safety_switch_image", e.target.files[0])}
                />
              </div>
              <Image style={{ marginTop: '10px' }} size='tiny' src={imageUrl(machineGroup.safety_switch_image)} wrapped alt=""/>
            </div>
            <Divider/>
            <div>
              <b>Machine Plate</b>
              <div className="Deploy__machinegroup-image-upload">
                <Input
                  type="file"
                  accept={ACCEPT_IMAGES}
                  icon="camera"
                  onChange={e => updateImageState("machine_plate_image", e.target.files[0])}
                />
              </div>
              <Image style={{ marginTop: '10px' }} size='tiny' src={imageUrl(machineGroup.machine_plate_image)} wrapped alt=""/>
            </div>
            <Divider/>
            <div>
              <b>Machine Queue</b>
              <div className="Deploy__machinegroup-image-upload">
                <Input
                  type="file"
                  accept={ACCEPT_IMAGES}
                  icon="camera"
                  onChange={e => updateImageState("machine_queue_image", e.target.files[0])}
                />
              </div>
              <Image style={{ marginTop: '10px' }} size='tiny' src={imageUrl(machineGroup.machine_queue_image)} wrapped alt=""/>
            </div>
          </Segment>

          <Divider style={{ backgroundColor: '#afafaf' }}/>

          {machines
            .sort((a, b) => a.id - b.id)
            .map(machine => {
              const { id } = machine
              const { name: oldName } = selectMachines(state)[id]
              return (
                <Segment key={id}>
                  <Header as="h4">Machine ({machine.id}): {oldName}</Header>
                  <Form>
                    <b>Name</b>
                    <Form.Field>
                      <Form.Input
                        value={machine.name}
                        onChange={(_, { value }) => updateMachineState(id, { name: value })}
                      />
                    </Form.Field>
                    <b>Description</b>
                    <Form.Field>
                      <Form.Input
                        value={machine.description}
                        onChange={(_, { value }) => updateMachineState(id, { description: value })}
                      />
                    </Form.Field>

                    <b>SAP ID</b>
                    <Form.Field>
                      <Form.Input
                        value={machine.sap_id}
                        onChange={(_, { value }) => updateMachineState(id, { sap_id: value })}
                      />
                    </Form.Field>

                    <b>{t("Diagnosis.machineRotationalGroup")}</b>
                    <Form.Field>
                      <Select
                        placeholder={t("Diagnosis.machineRotationalGroup")}
                        options={rotationOptions}
                        value={machine.rotational_group}
                        onChange={(_, { value }) => {
                          updateMachineState(id, { rotational_group: value })
                          // If direct transmission, copy rotational group to below machines
                          if (machine.transmission === "direct") {
                            machines.filter(it => it.id > id).forEach(it => {
                              updateMachineState(it.id, { rotational_group: value })
                            })
                          }
                        }}
                      />
                    </Form.Field>

                    <b>{t("Diagnosis.machineRelubricable")}</b>
                    <Form.Field>
                      <Radio
                        label='Yes'
                        checked={machine.relubricable === true}
                        onChange={() => updateMachineState(id, { relubricable: true })}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='No'
                        checked={machine.relubricable === false}
                        onChange={() => updateMachineState(id, { relubricable: false })}
                      />
                    </Form.Field>

                    <b>{t("Diagnosis.machineTransmission")}</b>
                    <Form.Field>
                      <Select
                        placeholder={t("Diagnosis.machineTransmission")}
                        options={transmissionOptions}
                        value={machine.transmission}
                        onChange={(_, { value }) => {
                          // "auto-fill" transmission of machines below to same as here
                          // because all machines in a group have same transmission
                          // (or different will be selected below)
                          machines.filter(it => it.id >= id).forEach(it => {
                            updateMachineState(it.id, { transmission: value })
                          })
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Foundation</label>
                      <Radio
                        label='Stiff (default)'
                        checked={machine.foundation === 'Stiff'}
                        onChange={() => updateMachineState(id, { foundation: 'Stiff' })}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Flexible'
                        checked={machine.foundation === 'Flexible'}
                        onChange={() => updateMachineState(id, { foundation: 'Flexible' })}
                      />
                    </Form.Field>
                  </Form>
                  <Divider style={{ margin: '40px 0' }}/>
                </Segment>
              )
            })}
        </Modal.Content>

        <Modal.Actions>
          <Button
            secondary
            onClick={() => setOpen(false)}
            disabled={loading}
          >Cancel</Button>
          <Button
            primary
            icon
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              updateOrSaveOffline()
                .finally(() => {
                  setLoading(false)
                  setOpen(false)
                })
            }}
          >{toIcon(COMPLETED_ICON)} Save</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}


function OfflineUpdateNotification({ count, uploadMachine, uploadMachineGroupImages }) {

  const [loading, setLoading] = useState(false)

  async function uploadUnsavedBackground() {
    const data = await loadChangesFromDisk()
    if (data?.machines?.length) {
      for (const machine of data.machines) {
        await uploadMachine(machine)
      }
    }
    if (data?.machineGroupFiles?.length) {
      for (const file of data.machineGroupFiles) {
        const { attachments, ...machineGroup } = file
        await uploadMachineGroupImages(machineGroup, attachments)
      }
    }
  }

  if (!count) {
    return null
  }

  return (
    <div style={{ backgroundColor: '#e6e6f1', color: 'red', padding: '10px', textAlign: 'center' }}>
      {loading ? (
        <>
          <h3>Uploading {count} updates...</h3>
          <Loader size="tiny"/>
        </>
      ) : (
        <>
          <h3>{count} uploads waiting for connection </h3>
          <Button icon onClick={() => {
            setLoading(true)
            uploadUnsavedBackground()
              .finally(() => setLoading(false))
          }}>
            {toIcon(RELOAD_ICON)}Try now
          </Button>
        </>
      )}
    </div>
  )
}

export const imageUrl = (url) => {
  if (!url || url.startsWith("http")) return url
  return `${process.env.REACT_APP_API_URL.slice(0, -1)}${url}`
}

const rotationOptions = [{
  key: 'other',
  value: 'Other',
  text: 'Other'
}, {
  key: '500-1000',
  value: '500-1000',
  text: '500 - 1000 rpm'
}, {
  key: '1000-2000',
  value: '1000-2000',
  text: '1000 - 2000 rpm'
}, {
  key: '2000-4000',
  value: '2000-4000',
  text: '2000 - 4000 rpm'
}]

const transmissionOptions = [{
  key: 'direct',
  value: 'direct',
  text: 'Direct (default)'
}, {
  key: 'belt',
  value: 'belt',
  text: 'Belt'
}, {
  key: 'gear',
  value: 'gear',
  text: 'Gear'
}, {
  key: 'magnetic_coupling',
  value: 'magnetic_coupling',
  text: 'Magnetic coupling'
}, {
  key: 'fluid_coupling',
  value: 'fluid_coupling',
  text: 'Fluid coupling'
}]

const mapDispatchToProps = {
  updateMachine,
  updateMachineGroupImages,
  updateMachineGroup,
};

export default connect(null, mapDispatchToProps)(DeployMachineGroupModal)
