import { createSlice } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";

import { apiGetHierarchy, apiGetHierarchyStatus } from "api";

import { selectToken } from "slices/tokenSlice";
import { selectSortLocale } from "slices/userDataSlice";
import {
  selectEntities as selectLocationsMap,
  selectAll as selectLocations,
  set as setLocations,
  upsertMany as upsertLocations,
  removeMany as removeLocations,
} from "slices/locationsSlice";
import {
  selectEntities as selectMachineGroupsMap,
  set as setMachineGroups,
  upsertMany as upsertMachineGroups,
  removeMany as removeMachineGroups,
} from "slices/machineGroupsSlice";
import {
  selectEntities as selectMachinesMap,
  set as setMachines,
  upsertMany as upsertMachines,
  removeMany as removeMachines,
} from "slices/machinesSlice";
import {
  selectEntities as selectSensorsMap,
  set as setSensors,
  upsertMany as upsertSensors,
  removeMany as removeSensors,
} from "slices/sensorsSlice";
import {
  set as setCustomReferences,
  upsertMany as upsertCustomReferences,
  removeMany as removeCustomReferences,
} from "slices/customReferencesSlice";
import {
  set as setMachineCustomReferences,
  upsertMany as upsertMachineCustomReferences,
  removeMany as removeMachineCustomReferences,
} from "slices/machineCustomReferencesSlice";
import {
  set as setSensorGroups,
  upsertMany as upsertSensorGroups,
  removeMany as removeSensorGroups,
} from "slices/sensorGroupsSlice";
import { processAnalysis } from "slices/analysesSlice";
import { upsertMany as upsertDiagnoses } from "slices/diagnosesSlice";
import { startOngoing, endOngoing, batch, batchFailure } from "slices/shared";

const hierarchy = createSlice({
  name: "hierarchy",
  initialState: {
    ongoing: {},
    lastUpdated: undefined,
    status: [],
  },
  reducers: {
    start(state, data) {
      startOngoing(state, data);
      state.lastUpdated = Date.now();
    },
    end: endOngoing,
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export default hierarchy.reducer;

const { start, end, setStatus } = hierarchy.actions;

const selectState = (state) => state.hierarchy;

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const selectLastUpdated = (state) => selectState(state).lastUpdated;
export const selectStatus = (state) => selectState(state).status;

export const locationPath = (location, locationsMap) => {
  const path = [location];
  let parent = locationsMap[location?.parent] || null;
  while (parent) {
    path.push(parent);
    parent = locationsMap[parent?.parent] || null;
  }
  return path.reverse();
};

const sortByName = (data, sortLocale) =>
  data
    .filter((d) => d)
    .sort((a, b) => a.name.localeCompare(b.name, sortLocale));

const sensorNode = (id, maps, nodes) => {
  const { sensorsMap } = maps;
  const { machineNodes, sensorNodes } = nodes;
  const sensor = sensorsMap[id];

  if (!sensor) {
    return null;
  }

  const node = {
    ...sensor,
    machine: machineNodes.get(sensor.machine),
  };
  sensorNodes.set(id, node);
  return node;
};

const machineNode = (id, maps, nodes, sortLocale) => {
  const { machinesMap } = maps;
  const { machineGroupNodes, machineNodes } = nodes;
  const machine = machinesMap[id];

  if (!machine) {
    return null;
  }

  const node = {
    ...machine,
  };
  machineNodes.set(id, node);

  node.machine_group = machineGroupNodes.get(machine.machine_group);
  node.sensors = sortByName(
    machine.sensors.map((s) => sensorNode(s, maps, nodes)),
    sortLocale
  );

  return node;
};

const machineGroupNode = (id, maps, nodes, sortLocale) => {
  const { machineGroupsMap } = maps;
  const { locationNodes, machineGroupNodes } = nodes;
  const machineGroup = machineGroupsMap[id];

  if (!machineGroup) {
    return null;
  }

  const node = {
    ...machineGroup,
  };
  machineGroupNodes.set(id, node);

  node.location = locationNodes.get(machineGroup.location);
  node.machines = sortByName(
    machineGroup.machines.map((m) => machineNode(m, maps, nodes, sortLocale)),
    sortLocale
  );

  return node;
};

const locationNode = (id, maps, nodes, sortLocale) => {
  const { locationsMap } = maps;
  const { locationNodes } = nodes;
  const location = locationsMap[id];

  if (!location) {
    return null;
  }

  const node = {
    ...location,
  };
  locationNodes.set(id, node);

  node.parent = location.parent
    ? locationNodes.get(location.parent)
    : undefined;
  node.children = sortByName(
    location.children.map((c) => locationNode(c, maps, nodes, sortLocale)),
    sortLocale
  );
  node.machine_groups = sortByName(
    location.machine_groups.map((mg) =>
      machineGroupNode(mg, maps, nodes, sortLocale)
    ),
    sortLocale
  );

  return node;
};

const selectRoots = (locations) => locations.filter((l) => !l.parent);

const selectHierarchyMO = memoizeOne(
  (
    locations,
    locationsMap,
    machineGroupsMap,
    machinesMap,
    sensorsMap,
    sortLocale
  ) => {
    const sensorNodes = new Map();
    const roots = sortByName(selectRoots(locations), sortLocale).map(
      (location) =>
        locationNode(
          location.id,
          { locationsMap, machineGroupsMap, machinesMap, sensorsMap },
          {
            locationNodes: new Map(),
            machineGroupNodes: new Map(),
            machineNodes: new Map(),
            sensorNodes,
          },
          sortLocale
        )
    );
    return [roots, sensorNodes];
  }
);

export const selectHierarchy = (state) => {
  const locations = selectLocations(state);
  const sortLocale = selectSortLocale(state);

  return selectHierarchyMO(
    locations,
    selectLocationsMap(state),
    selectMachineGroupsMap(state),
    selectMachinesMap(state),
    selectSensorsMap(state),
    sortLocale
  );
};

export const hasMatch = (id, section, matches) =>
  !matches || matches[section].has(id);

const selectSensorMatchesMO = memoizeOne(
  (sensors, locationsMap, machineGroupsMap, machinesMap, sensorsMap) => {
    const machines = sensors.map((id) => machinesMap[sensorsMap[id]?.machine]);
    const machineGroups = machines.map(
      (m) => machineGroupsMap[m?.machine_group]
    );
    const locations = machineGroups
      .map((mg) => locationsMap[mg?.location])
      .map((l) => locationPath(l, locationsMap))
      .reduce((acc, curr) => acc.concat(curr), []);

    const locationsSet = new Set(locations.filter((l) => l).map((l) => l.id));
    const machineGroupsSet = new Set(
      machineGroups.filter((mg) => mg).map((mg) => mg.id)
    );
    const machinesSet = new Set(machines.filter((m) => m).map((m) => m.id));
    const sensorsSet = new Set(sensors);

    return {
      locations: locationsSet,
      machineGroups: machineGroupsSet,
      machines: machinesSet,
      sensors: sensorsSet,
    };
  }
);

export const selectSensorGroupMatches = (state, sensorGroup) => {
  if (!sensorGroup) {
    return;
  }
  return selectSensorMatchesMO(
    sensorGroup.sensors,
    selectLocationsMap(state),
    selectMachineGroupsMap(state),
    selectMachinesMap(state),
    selectSensorsMap(state),
    selectSortLocale(state)
  );
};

const sensorKeys = (sensor, path, keyList) => {
  const currentPath = { ...path, sensor: sensor.id };
  [sensor.name, sensor.description].forEach(
    (key) => key && keyList.push({ key, path: currentPath })
  );
};

const machineKeys = (machine, path, keyList) => {
  const currentPath = { ...path, machine: machine.id };
  [machine.name, machine.description].forEach(
    (key) => key && keyList.push({ key, path: currentPath })
  );
  machine.sensors.forEach((c) => sensorKeys(c, currentPath, keyList));
};

const machineGroupKeys = (machineGroup, path, keyList) => {
  const currentPath = { ...path, machineGroup: machineGroup.id };
  [machineGroup.name, machineGroup.description].forEach(
    (key) => key && keyList.push({ key, path: currentPath })
  );
  machineGroup.machines.forEach((m) => machineKeys(m, currentPath, keyList));
};

const locationKeys = (location, path, keyList) => {
  const currentPath = { locations: [...path.locations, location.id] };

  [location.name, location.description].forEach(
    (key) => key && keyList.push({ key, path: currentPath })
  );
  location.children.forEach((c) => locationKeys(c, currentPath, keyList));
  location.machine_groups.forEach((mg) =>
    machineGroupKeys(mg, currentPath, keyList)
  );
};

const selectKeysMO = memoizeOne((roots) => {
  if (!roots) {
    return [];
  }
  const keyList = [];
  roots.forEach((r) => locationKeys(r, { locations: [] }, keyList));
  return keyList;
});

const selectKeys = (state) => selectKeysMO(selectHierarchy(state)[0]);

const selectMatchesMO = memoizeOne((search, searchKeys) => {
  if (!search || search.length === 0) {
    return;
  }

  const locations = new Set();
  const machineGroups = new Set();
  const machines = new Set();
  const sensors = new Set();

  const re = new RegExp(search, "i");
  searchKeys.forEach(({ key, path }) => {
    if (key.match(re)) {
      path.locations.forEach((id) => locations.add(id));
      if (path.machineGroup) {
        machineGroups.add(path.machineGroup);
      }
      if (path.machine) {
        machines.add(path.machine);
      }
      if (path.sensor) {
        sensors.add(path.sensor);
      }
    }
  });

  return {
    locations,
    machineGroups,
    machines,
    sensors,
    empty:
      locations.size === 0 &&
      machineGroups.size === 0 &&
      machines.size === 0 &&
      sensors.size === 0,
  };
});

export const selectMatches = (state, search) =>
  selectMatchesMO(search, selectKeys(state));

export const selectHierarchyData = (state) => {
  return {
    locations: selectLocationsMap(state),
    machineGroups: selectMachineGroupsMap(state),
    machines: selectMachinesMap(state),
    sensors: selectSensorsMap(state),
  };
};

export const getHierarchy = (reload, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectOngoing(state, "getHierarchy")) {
      return;
    }
    dispatch(start("getHierarchy"));
    const params = reload ? {} : { last_updated: selectLastUpdated(state) };

    const hierarchy = await apiGetHierarchy(selectToken(getState()), params);

    if (reload) {
      batch(() => {
        dispatch(setLocations(hierarchy.updated["location"]));
        dispatch(setMachineGroups(hierarchy.updated["machine group"]));
        dispatch(setMachines(hierarchy.updated["machine"]));
        dispatch(setSensors(hierarchy.updated["sensor"]));
        dispatch(setCustomReferences(hierarchy.updated["custom reference"]));
        dispatch(
          setMachineCustomReferences(
            hierarchy.updated["machine custom reference"]
          )
        );
        dispatch(setSensorGroups(hierarchy.updated["sensor group"]));
      });
    } else {
      batch(() => {
        dispatch(upsertLocations(hierarchy.updated["location"]));
        dispatch(removeLocations(hierarchy.deleted["location"]));
        dispatch(upsertMachineGroups(hierarchy.updated["machine group"]));
        dispatch(removeMachineGroups(hierarchy.deleted["machine group"]));
        dispatch(upsertMachines(hierarchy.updated["machine"]));
        dispatch(removeMachines(hierarchy.deleted["machine"]));
        dispatch(upsertSensors(hierarchy.updated["sensor"]));
        dispatch(removeSensors(hierarchy.deleted["sensor"]));
        dispatch(upsertCustomReferences(hierarchy.updated["custom reference"]));
        dispatch(removeCustomReferences(hierarchy.deleted["custom reference"]));
        dispatch(
          upsertMachineCustomReferences(
            hierarchy.updated["machine custom reference"]
          )
        );
        dispatch(
          removeMachineCustomReferences(
            hierarchy.deleted["machine custom reference"]
          )
        );
        dispatch(upsertSensorGroups(hierarchy.updated["sensor group"]));
        dispatch(removeSensorGroups(hierarchy.deleted["sensor group"]));
      });
    }

    /* Memoize hierarchy, keys and matches */
    selectHierarchy(getState());
    selectKeys(getState());
    selectMatches(getState());

    dispatch(end("getHierarchy"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getHierarchy"));
    throw error;
  }
};

const processAnalyses = (analyses) => {
  let diagnoses = [];
  analyses.forEach((a) => {
    diagnoses = diagnoses.concat(processAnalysis(a)[1]);
  });
  return [analyses, diagnoses];
};

export const getHierarchyStatus = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectOngoing(state, "getHierarchyStatus")) {
      return;
    }
    dispatch(start("getHierarchyStatus"));

    const [analyses, diagnoses] = processAnalyses(
      await apiGetHierarchyStatus(selectToken(getState()))
    );

    batch(() => {
      dispatch(upsertDiagnoses(diagnoses));
      dispatch(setStatus(analyses));
    });

    dispatch(end("getHierarchyStatus"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getHierarchyStatus"));
    throw error;
  }
};
