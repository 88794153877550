import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Moment from "moment";
// import { DateTime, Settings } from "luxon";
// import numbro from "numbro";

// import 'moment/locale/fi';
// import 'numbro/languages/fi-FI';

import { initReactI18next } from "react-i18next";

import enTranslation from "en_translation";
// import fiTranslation from 'fi_translation';

const fallbackLng = "en";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["client", "common"],
    defaultNS: "client",
    fallbackNS: "common",
    fallbackLng,
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      format: function (value, format /* , lng */) {
        if (value instanceof Date) {
          return Moment(value).format(format);
          // return DateTime.fromJSDate(value).toFormat(format);
        }
        return value;
      },
      escapeValue: false,
    },
  });

i18n.on("languageChanged", function (lng) {
  Moment.locale(lng);
  // Settings.defaultLocale = lng;
  // numbro.setLanguage(lng);
});

i18n.addResourceBundle("en", "common", enTranslation, true, true);
// i18n.addResourceBundle('fi', 'translation', fiTranslation, true, true);
// i18n.availableLanguagesEXT = () => ["en" /* , 'fi' */];
// i18n.currentLanguageEXT = () => {
//   return "en";
//   // const currentLanguage = i18n.language;
//   // if (i18n.availableLanguagesEXT().includes(currentLanguage)) {
//   //   return currentLanguage;
//   // }
//   // return fallbackLng;
// };

i18n.changeLanguageWithCheck = (language) => {
  if (
    i18n.availableLanguages.includes(language) &&
    i18n.language !== language
  ) {
    i18n.changeLanguage(language);
  }
};

export default i18n;
