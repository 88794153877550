import React, { Component } from "react";
import { Segment, Table, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  LOCATIONS,
  HIERARCHY,
  // SENSOR_GROUPS,
  // DIAGNOSES,
  NEW_HIERARCHY,
  NEW_ANALYSIS,
  TASKS,
} from "appRoutes";
import {
  selectUserInfo,
  selectTranslation,
  translationKey,
} from "slices/userDataSlice";

class TechnicianHome extends Component {
  constructor(props) {
    super(props);

    const { t } = props;
    this.items = [
      {
        ...HIERARCHY,
        title: t("Main.hierarchy"),
      },
      // {
      //   ...SENSOR_GROUPS,
      //   title: t("Main.sensorGroups"),
      // },
      // {
      //   ...DIAGNOSES,
      //   title: t("Main.diagnoses"),
      // },
      {
        ...NEW_HIERARCHY,
        title: t("Main.newHierarchy"),
      },
      {
        ...NEW_ANALYSIS,
        title: t("Main.newData"),
      },
      {
        ...TASKS,
        title: t("Main.tasks"),
      },
      {
        ...LOCATIONS,
        title: t("Main.inventory"),
      },
    ];
  }

  render() {
    const { translation, username, client, groups, t } = this.props;
    const groupList = groups
      .map((g) => translationKey(translation, "user_groups")[g])
      .join(", ");

    return (
      <Segment
        as="div"
        className="TechnicianHome"
        data-cy="TechnicianHome"
      >
        <Table className="TechnicianHome__table" unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{t("TechnicianHome.username")}</Table.Cell>
              <Table.Cell data-cy="TechnicianHome__username">
                {username}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>{t("TechnicianHome.client")}</Table.Cell>
              <Table.Cell data-cy="TechnicianHome__client">
                {client || t("TechnicianHome.noClient")}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>{t("TechnicianHome.groups")}</Table.Cell>
              <Table.Cell data-cy="TechnicianHome__groups">
                {groupList || t("TechnicianHome.noGroups")}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <List>
          {this.items.map((item) => (
            <List.Item key={item.key}>
              <Link to={item.path} data-cy="TechnicianHome__link">
                {item.title}
              </Link>
            </List.Item>
          ))}
        </List>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const { username, client_name: client, groups = [] } = selectUserInfo(state);
  const translation = selectTranslation(state, language);

  return {
    translation,
    username,
    client,
    groups,
  };
};

export default withTranslation()(connect(mapStateToProps)(TechnicianHome));
