import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import memoizeOne from "memoize-one";
import equal from "fast-deep-equal";

import {
  apiGetAnalyses,
  apiGetNextJson,
  apiGetAnalysis,
  apiGetLinkedAnalysis,
} from "api";
import { selectToken } from "slices/tokenSlice";
import { locationPath, getHierarchy } from "slices/hierarchySlice";
import { selectEntities as selectLocations } from "slices/locationsSlice";
import { selectEntities as selectMachineGroups } from "slices/machineGroupsSlice";
import { selectEntities as selectMachines } from "slices/machinesSlice";
import { selectEntities as selectSensors } from "slices/sensorsSlice";
import {
  selectEntities as selectDiagnoses,
  upsertMany as upsertDiagnoses,
} from "slices/diagnosesSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";
import { FLAGGED_ONLY_NAME, VALIDATED_ONLY_NAME } from "../DiagnosisFilter";
import { loadFlaggedItems } from "../useFlaggedItem";

const adapter = createEntityAdapter({});

const analyses = createSlice({
  name: "analyses",
  initialState: adapter.getInitialState({
    ongoing: {},
    next: null,
    query: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    startRest(state, { payload: key }) {
      state.ongoing[key] = true;
      state.next = null;
    },
    set(state, { payload }) {
      const { results, next, query } = payload;
      state.next = next;
      state.query = query;
      adapter.setAll(state, results);
    },
    addNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.addMany(state, results);
    },
    addRest(state, { payload }) {
      const { results } = payload;
      adapter.addMany(state, results);
    },
    upsert(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    extra(state, { payload }) {
      state.entities[payload.id] = payload;
    },
  },
});

export default analyses.reducer;

const {
  start,
  startRest,
  end,
  set,
  addNext,
  addRest,
  upsert,
  extra,
} = analyses.actions;

const selectState = (state) => state.analyses;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => selectState(state).ongoing[key];

const selectSomeOngoing = (state, ...keys) => {
  return keys.every((key) => selectState(state).ongoing[key]);
};

export const selectNext = (state) => {
  if (selectSomeOngoing(state, "getAnalyses", "getNextAnalyses")) {
    return null;
  }
  return selectState(state).next;
};

export const selectQuery = (state) => selectState(state).query;

export const processAnalysis = (analysis) => {
  const diagnoses = analysis.diagnoses;
  analysis.diagnoses = analysis.diagnoses.map((d) => d.id);
  return [analysis, diagnoses];
};

const processAnalyses = (analyses) => {
  let diagnoses = [];
  analyses.results.forEach((a) => {
    diagnoses = diagnoses.concat(processAnalysis(a)[1]);
  });
  return [analyses, diagnoses];
};

export const getAnalyses = (query, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectSomeOngoing(state, "getAnalyses", "getNextAnalyses")) {
      return;
    }
    if (equal(query, selectState(state).query)) {
      return;
    }

    dispatch(start("getAnalyses"));
    const [analyses, diagnoses] = processAnalyses(
      await apiGetAnalyses(selectToken(state), query)
    );

    const sensors = selectSensors(state);
    if (analyses.results.some((a) => !sensors[a.sensor])) {
      await dispatch(getHierarchy(false, options));
    }

    batchSuccess(
      dispatch,
      options,
      upsertDiagnoses(diagnoses),
      set({ ...analyses, query }),
      end("getAnalyses")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getAnalyses"));
    throw error;
  }
};

const getNext = (state) =>
  !selectSomeOngoing(state, "getNextAnalyses", "getRestAnalyses") &&
  selectNext(state);

export const getNextAnalyses = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const next = getNext(state);
    if (!next) {
      return;
    }
    dispatch(start("getNextAnalyses"));
    const [analyses, diagnoses] = processAnalyses(
      await apiGetNextJson(selectToken(state), next)
    );

    const sensors = selectSensors(state);
    if (analyses.results.some((a) => !sensors[a.sensor])) {
      await dispatch(getHierarchy(false, options));
    }

    batchSuccess(
      dispatch,
      options,
      upsertDiagnoses(diagnoses),
      addNext(analyses),
      end("getNextAnalyses")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getNextAnalyses"));
    throw error;
  }
};

export const getRestAnalyses = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const next = getNext(state);
    if (!next) {
      return;
    }
    dispatch(startRest("getRestAnalyses"));
    let analyses = { next };
    let diagnoses = [];
    do {
      [analyses, diagnoses] = processAnalyses(
        await apiGetNextJson(selectToken(state), analyses.next)
      );

      const sensors = selectSensors(state);
      if (analyses.results.some((a) => !sensors[a.sensor])) {
        await dispatch(getHierarchy(false, options));
      }

      batchSuccess(
        dispatch,
        options,
        upsertDiagnoses(diagnoses),
        addRest(analyses)
      );
    } while (analyses.next);
    dispatch(end("getRestAnalyses"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("getRestAnalyses"));
    throw error;
  }
};

export const selectLinked = (state, id) =>
  selectState(state)?.entities?.[id]?.links;

export const getAnalysis = (id, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(start("getAnalysis"));
    const [analysis, diagnoses] = processAnalysis(
      await apiGetAnalysis(selectToken(state), id)
    );

    const sensors = selectSensors(state);
    if (!sensors[analysis.sensor]) {
      await dispatch(getHierarchy(false, options));
    }

    batchSuccess(
      dispatch,
      options,
      upsertDiagnoses(diagnoses),
      upsert(analysis),
      end("getAnalysis")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getAnalysis"));
    throw error;
  }
};

export const getLinkedAnalysis = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    dispatch(start("getLinkedAnalysis"));
    const [analysis, diagnoses] = processAnalysis(
      await apiGetLinkedAnalysis(selectToken(state), id)
    );
    const sensors = selectSensors(state);
    if (!sensors[analysis.sensor]) {
      await dispatch(getHierarchy(false, options));
    }
    batchSuccess(
      dispatch,
      options,
      upsertDiagnoses(diagnoses),
      extra(analysis),
      end("getLinkedAnalysis")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getLinkedAnalysis"));
    throw error;
  }
};

const locationKeys = (analysis, location, keyList, locations) => {
  if (!location) {
    return;
  }
  locationPath(location, locations).forEach((l) =>
    keyList.push({ key: l.name, id: analysis.id })
  );
};
const machineGroupKeys = (analysis, machineGroup, keyList, locations) => {
  if (!machineGroup) {
    return;
  }
  keyList.push({ key: machineGroup.name, id: analysis.id });
  locationKeys(analysis, locations[machineGroup.location], keyList, locations);
};

const machineKeys = (analysis, machine, keyList, locations, machineGroups) => {
  if (!machine) {
    return;
  }
  keyList.push({ key: machine.name, id: analysis.id });
  keyList.push({ key: machine.description, id: analysis.id });
  machineGroupKeys(
    analysis,
    machineGroups[machine.machine_group],
    keyList,
    locations
  );
};

const sensorKeys = (
  analysis,
  sensor,
  keyList,
  locations,
  machineGroups,
  machines
) => {
  if (!sensor) {
    return;
  }
  keyList.push({ key: sensor.name, id: analysis.id });
  machineKeys(
    analysis,
    machines[sensor.machine],
    keyList,
    locations,
    machineGroups
  );
};

const analysisKeysMO = memoizeOne(
  (analyses, locations, machineGroups, machines, sensors) => {
    if (!analyses) {
      return [];
    }
    const keyList = [];
    analyses.forEach((a) =>
      sensorKeys(
        a,
        sensors[a.sensor],
        keyList,
        locations,
        machineGroups,
        machines
      )
    );
    return keyList;
  }
);

const hasAll = { has: () => true };

const analysisSearchMatchesMO = memoizeOne((search, searchKeys) => {
  if (!search?.length) {
    return hasAll;
  }
  const analyses = new Set();
  const re = new RegExp(search, "i");
  searchKeys.forEach(({ key, id }) => {
    if (key.match(re)) {
      analyses.add(id);
    }
  });
  return analyses;
});

const selectMatchingAnalysesMO = memoizeOne(
  (
    analyses,
    search,
    excluded,
    locations,
    machineGroups,
    machines,
    sensors,
    diagnoses
  ) => {
    const keys = analysisKeysMO(
      analyses,
      locations,
      machineGroups,
      machines,
      sensors
    );
    const matches = analysisSearchMatchesMO(search, keys);
    const flaggedItems = excluded[FLAGGED_ONLY_NAME] && loadFlaggedItems()
    const validatedOnly = excluded[VALIDATED_ONLY_NAME]
    return analyses
      .filter(
        (a) =>
          matches.has(a.id) &&
          a.diagnoses.some(
            (d) => diagnoses[d] && !excluded[diagnoses[d]?.category]
          )
      )
      .filter(it => !validatedOnly || it.diagnoses.some(d => diagnoses[d]?.validated))
      .filter(it => !flaggedItems || flaggedItems.includes("analysis-" + it.id));
  }
);

export const selectMatchingAnalyses = (state, search, excluded) =>
  selectMatchingAnalysesMO(
    selectAll(state),
    search,
    excluded,
    selectLocations(state),
    selectMachineGroups(state),
    selectMachines(state),
    selectSensors(state),
    selectDiagnoses(state),
  );
