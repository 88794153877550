import React from "react";
import { Accordion, Form, Item, Segment, Button, Grid, Header, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";
import { debounce } from "debounce";
import classNames from "classnames";

import AdaComponent from "AdaComponent";
import ActionMenu, { filterActions } from "ActionMenu";
import StatusFilter from "StatusFilter";

import {
  setData,
  selectData,
  toggleFlag,
  selectFlags,
  selectFlag,
  setFlag,
} from "slices/uiSlice";
import {
  opts,
  selectServiceError,
  clearServiceErrors,
  hasIncompleteErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  diagnosisCategoryPriorities,
  diagnosisCategoryGroups,
  hasCapability,
  hasSomeCapability,
  CAN_MANAGE_LOCATIONS,
  CAN_MANAGE_MACHINE_GROUPS,
  CAN_MANAGE_MACHINES,
  CAN_MANAGE_SENSORS,
  CAN_ACCESS_TECHNICIAN_UI,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  selectHierarchy,
  selectMatches,
  hasMatch,
  selectStatus,
  getHierarchy,
  getHierarchyStatus,
} from "slices/hierarchySlice";
import { selectEntities as selectDiagnosesMap } from "slices/diagnosesSlice";

import { goTo, adjustSensorStatus, adjustNodeStatus } from "utils";
import {
  ADD_ICON,
  CONFIGURE_ICON,
  SEARCH_ICON,
  INCOMPLETE_ICON,
  EDIT_ICON,
  REFRESH_ICON,
  toIcon,
  FactorySVG,
  MotorIcon,
  MachineGroupSVG,
  SensorIcon,
  MinusIconSVG,
  PlusIconSVG,
  PumpIcon,
  OtherMachineIcon,
  BlowerIcon,
  RELOAD_ICON,
} from "icons";
import {
  newLocationTo,
  newMachineGroupTo,
  newMachineTo,
  newSensorTo,
  sensorTo,
  machineTo,
  machineGroupTo,
  locationTo,
  analysisTo,
} from "appRoutes";
import DeployMachineGroupModal from "./DeployMachineGroupModal";

const HIERARCHY = "Hierarchy/hierarchy";
const STATUS = "Hierarchy/status";
const SEARCH_DELAY = 500;
const EMPTY = {};

const LOCATIONS_ACCORDION = "Hierarchy/locations";
const MACHINE_GROUPS_ACCORDION = "Hierarchy/machineGroups";
const MACHINES_ACCORDION = "Hierarchy/machines";
const EDIT_FLAG = "Hierarchy/edit";
const STATUS_FLAG = "Hierarchy/status";
const EXCLUDED = "Hierarchy/exclude";
const SEARCH = "Hierarchy/search";

const resetNodes = (analyses, sensors) => {
  const resetId = Date.now();
  for (let s of sensors.values()) {
    s.status = undefined;
    s.machine.status = undefined;
    s.machine.machine_group.status = undefined;
    let loc = s.machine.machine_group.location;
    do {
      loc.status = undefined;
      if (!loc.counter || loc.counter["resetId"] !== resetId) {
        loc.counter = { sensors: 0, resetId };
      }
      loc.counter["sensors"] += 1;
      loc = loc.parent;
    } while (loc);
  }
  return analyses;
};

const addAnalysis = (node, priority) => {
  node.counter[priority] = (node.counter[priority] || 0) + 1;
};

const scn = (baseclass, status, showStatus, schema) => {
  if (!showStatus || !schema)
    return classNames({
      [baseclass]: true,
    });
  const groups = diagnosisCategoryGroups(status, schema);
  return classNames({
    [baseclass]: true,
    [`${baseclass}--ok`]: groups.ok,
    [`${baseclass}--warning`]: groups.warning,
    [`${baseclass}--danger`]: groups.danger,
    [`${baseclass}--unsupported`]: groups.unsupported,
    [`${baseclass}--unknown`]: !status,
  });
};

class Hierarchy extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      open: false,
      search: "",
      deployMachineGroupId: undefined,
      deployOpen: false,
    });

    const { t } = props;

    this.editActions = {
      roots: [
        {
          key: "addFactory",
          title: t("Hierarchy.addFactory"),
          handler: this.handleAddLocation,
          filter: {
            canEditLocations: true,
          },
        },
      ],
      locations: [
        {
          key: "addLocation",
          title: t("Hierarchy.addLocation"),
          handler: this.handleAddLocation,
          filter: {
            canEditLocations: true,
          },
        },
        {
          key: "addMachineGroup",
          title: t("Hierarchy.addMachineGroup"),
          handler: this.handleAddMachineGroup,
          filter: {
            canEditMachineGroups: true,
          },
        },
      ],
      machineGroups: [
        {
          key: "addMachine",
          title: t("Hierarchy.addMachine"),
          handler: this.handleAddMachine,
          filter: {
            canEditMachines: true,
          },
        },
        {
          key: "deployMachineGroup",
          title: t("Hierarchy.deployMachineGroup"),
          handler: this.handleDeployMachineGroup,
          filter: {
            canEditMachines: true,
          },
        },
      ],
      machines: [
        {
          key: "addSensor",
          title: t("Hierarchy.addSensor"),
          handler: this.handleAddSensor,
          filter: {
            canEditSensors: true,
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.setSearch("");
    this.refreshHierarchy(false);
  }

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY, STATUS);
    }
  };

  refreshHierarchy = (reload) => {
    const { getHierarchy, t } = this.props;
    this.clearErrors();
    this.clearServiceErrors();
    getHierarchy(reload, opts(HIERARCHY, t("Hierarchy.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  refreshStatus = () => {
    const { getHierarchyStatus, t } = this.props;
    this.clearErrors();
    this.clearServiceErrors();
    getHierarchyStatus(opts(STATUS, t("Hierarchy.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  handleRefresh = () => {
    const { showStatus } = this.props;
    this.refreshHierarchy(true);
    if (showStatus) {
      this.refreshStatus(true);
    }
  };

  handleExclude = (e, { value }) => {
    const { excluded, setData } = this.props;
    e.stopPropagation();
    const newExcluded = { ...excluded };
    newExcluded[value] = !newExcluded[value];
    setData({ id: EXCLUDED, data: newExcluded });
  };

  handleEdit = () => {
    const { toggleFlag } = this.props;
    toggleFlag(EDIT_FLAG);
  };

  setSearch = (search) => {
    const { setData } = this.props;
    setData({ id: SEARCH, data: search });
  };

  handleSearch = () => {
    this.setSearch(this.state.search);
    this.typeSearch.clear();
  };

  typeSearch = debounce((search, setSearch) => {
    setSearch(search);
  }, SEARCH_DELAY);

  handleEditSearch = (_, { value: search }) => {
    this.setState({ search });
    this.typeSearch(search, this.setSearch);
  };

  flag = (section, id) => {
    const flag = `${section}-${id}`;
    // Initialize as open (false) so we can toggle all
    if (this.props.accordion?.[flag] === undefined) {
      this.props.setFlag({ id: flag, status: false });
    }
    return flag;
  }

  handleClickAccordion = (_, { flag }) => {
    const { toggleFlag } = this.props;
    toggleFlag(flag);
  };

  isOpenAccordion = (flag) => {
    const { accordion } = this.props;
    return !accordion?.[flag];
  };

  toggleAll = (allAccordions) => {
    const someOpen = allAccordions.some(this.isOpenAccordion)
    allAccordions
      .filter(it => this.isOpenAccordion(it) === someOpen)
      .forEach(this.props.toggleFlag)
  }

  handleStatus = () => {
    const { showStatus, toggleFlag } = this.props;
    toggleFlag(STATUS_FLAG);

    if (!showStatus) {
      this.refreshStatus();
    }
  };

  handleAddLocation = (_, { value: parentId }) => {
    const { history, location } = this.props;
    goTo(newLocationTo(parentId, location), history);
  };

  handleAddMachineGroup = (_, { value: locationId }) => {
    const { history, location } = this.props;
    goTo(newMachineGroupTo(locationId, location), history);
  };

  handleAddMachine = (_, { value: machineGroupId }) => {
    const { history, location } = this.props;
    goTo(newMachineTo(machineGroupId, location), history);
  };

  handleDeployMachineGroup = (_, { value: machineGroupId }) => {
    this.setState({
      deployMachineGroupId: machineGroupId,
      deployOpen: true
    })
  };

  handleAddSensor = (_, { value: machineId }) => {
    const { history, location } = this.props;
    goTo(newSensorTo(machineId, location), history);
  };

  filterEditActions = memoizeOne(
    (
      canEditLocations,
      canEditMachineGroups,
      canEditMachines,
      canEditSensors
    ) => {
      const filter = {
        canEditLocations,
        canEditMachineGroups,
        canEditMachines,
        canEditSensors,
      };
      const newActions = {};
      Object.keys(this.editActions).forEach(
        (a) => (newActions[a] = filterActions(this.editActions[a], filter))
      );
      return newActions;
    }
  );

  sectionEditActions = (section) => {
    const {
      canEditLocations,
      canEditMachineGroups,
      canEditMachines,
      canEditSensors,
    } = this.props;
    return this.filterEditActions(
      canEditLocations,
      canEditMachineGroups,
      canEditMachines,
      canEditSensors
    )[section];
  };

  renderSensorItem = (
    sensor,
    status,
    showStatus,
    showLink,
    schema,
    location,
    t
  ) => (
    <div className="Hierarchy__sensor">
      <Item className="Hierarchy__sensor_item">
        <Item.Content>
          <Item.Header>
            <Link
              to={sensorTo(sensor.id, location)}
              className={scn("Hierarchy__link", status, showStatus, schema)}
            >
              <SensorIcon/>
              {sensor.name}
              {hasIncompleteErrors(sensor) && toIcon(INCOMPLETE_ICON)}
            </Link>
          </Item.Header>
          {sensor.description && <Item.Meta>{sensor.description}</Item.Meta>}
        </Item.Content>
      </Item>
      {showStatus && sensor.latest_measured_at && (
        <span>
          {showLink && (
            <Link
              to={analysisTo(sensor.source)}
              className={scn("Hierarchy__link", status, showStatus, schema)}
            >
              {t("Hierarchy.timestampFormat", {
                timestamp: sensor.latest_measured_at,
              })}
            </Link>
          )}
          {!showLink &&
          t("Hierarchy.timestampFormat", {
            timestamp: sensor.latest_measured_at,
          })}
        </span>
      )}
    </div>
  );

  renderItem = (
    { id, name, description, counter },
    flag,
    isOpen,
    icon,
    to,
    actions,
    incomplete,
    status,
    showStatus,
    schema
  ) => {
    return (
      <>
        <Divider className="Hierarchy__accordion-divider"/>
        <Accordion.Title className="Hierarchy__title" active={isOpen}>
          <Item className="Hierarchy__item">
            <Item.Content>
              <Item.Header>
                <Link
                  to={to}
                  className={scn("Hierarchy__link", status, showStatus, schema)}
                >
                  {icon}
                  {name}
                  {incomplete && toIcon(INCOMPLETE_ICON)}
                </Link>
                {showStatus && counter && (
                  <span className="Hierarchy__counter">
                  <span className="Hierarchy__counter--danger">
                    {counter[3] || 0}
                  </span>
                  <span className="Hierarchy__counter--warning">
                    {counter[2] || 0}
                  </span>
                  <span className="Hierarchy__counter--ok">
                    {counter[1] || 0}
                  </span>
                  <span className="Hierarchy__counter--unsupported">
                    {counter[0] || 0}
                  </span>{" "}
                    <span>
                    {(counter[3] || 0) +
                    (counter[2] || 0) +
                    (counter[1] || 0) +
                    (counter[0] || 0)}
                  </span>
                  /<span>{counter["sensors"] || 0}</span>
                </span>
                )}
              </Item.Header>
              {description && <Item.Meta>{description}</Item.Meta>}
            </Item.Content>
          </Item>
          {isOpen ? (
            <MinusIconSVG
              className="Hierarchy__toggle"
              onClick={(e) => this.handleClickAccordion(e, { flag })}
            />
          ) : (
            <PlusIconSVG
              className="Hierarchy__toggle"
              onClick={(e) => this.handleClickAccordion(e, { flag })}
            />
          )}
          {actions && (
            <ActionMenu
              items={actions}
              className="Hierarchy__item_menu"
              icon={toIcon(CONFIGURE_ICON)}
              value={id}
            />
          )}
        </Accordion.Title>
      </>
    );
  };

  renderSensor = (sensor) => {
    const {
      matches,
      showStatus,
      excluded,
      location,
      technicianUI,
      schema,
      t,
    } = this.props;
    const { id, status } = sensor;
    if (!hasMatch(id, "sensors", matches)) {
      return null;
    }
    if (showStatus && excluded[status]) {
      return null;
    }
    return (
      <Accordion.Accordion
        key={id}
        className="Hierarchy__subaccordion"
        exclusive={false}
      >
        <Accordion.Title
          className={scn("Hierarchy__title", status, showStatus, schema)}
          index={id}
        >
          {this.renderSensorItem(
            sensor,
            status,
            showStatus,
            technicianUI,
            schema,
            location,
            t
          )}
        </Accordion.Title>
      </Accordion.Accordion>
    );
  };

  renderMachine = (machine) => {
    const {
      editing,
      matches,
      showStatus,
      excluded,
      schema,
      location,
    } = this.props;
    const { id, sensors, status } = machine;
    if (!hasMatch(id, "machines", matches)) {
      return null;
    }
    if (showStatus && excluded[status]) {
      return null;
    }
    const flag = this.flag(MACHINES_ACCORDION, id);
    const isOpen = this.isOpenAccordion(flag);

    const category = machine.category?.toLowerCase()

    let machineIcon = <OtherMachineIcon/>
    if (category?.includes('motor')) machineIcon = <MotorIcon/>
    else if (category?.includes('pump')) machineIcon = <PumpIcon/>
    else if (category?.includes('fan')) machineIcon = <BlowerIcon/>

    return (
      <Accordion.Accordion
        key={id}
        className={scn("Hierarchy__subaccordion", status, showStatus, schema)}
        exclusive={false}
      >
        <Accordion.Title className="Hierarchy__title" active={isOpen}>
          {this.renderItem(
            machine,
            flag,
            isOpen,
            machineIcon,
            machineTo(id, location),
            editing && this.sectionEditActions("machines"),
            hasIncompleteErrors(machine),
            status,
            showStatus,
            schema
          )}
        </Accordion.Title>
        {isOpen && sensors.map((s) => this.renderSensor(s))}
      </Accordion.Accordion>
    );
  };

  renderMachineGroup = (machineGroup) => {
    const {
      editing,
      matches,
      showStatus,
      excluded,
      schema,
      location: rrLocation,
    } = this.props;
    const { id, machines, status } = machineGroup;
    if (!hasMatch(id, "machineGroups", matches)) {
      return null;
    }
    if (showStatus && excluded[status]) {
      return null;
    }
    const flag = this.flag(MACHINE_GROUPS_ACCORDION, id);
    const isOpen = this.isOpenAccordion(flag);
    return (
      <Accordion.Accordion
        key={id}
        className={scn("Hierarchy__subaccordion", status, showStatus, schema)}
        exclusive={false}
      >
        <Accordion.Title className="Hierarchy__title" active={isOpen}>
          {this.renderItem(
            machineGroup,
            flag,
            isOpen,
            (<MachineGroupSVG/>),
            machineGroupTo(id, rrLocation),
            editing && this.sectionEditActions("machineGroups"),
            hasIncompleteErrors(machineGroup),
            status,
            showStatus,
            schema
          )}
        </Accordion.Title>
        {isOpen && machines.map((m) => this.renderMachine(m))}
      </Accordion.Accordion>
    );
  };

  renderLocations = (locations) => {
    if (!locations) {
      return;
    }
    const {
      editing,
      matches,
      showStatus,
      excluded,
      schema,
      location: rrLocation,
    } = this.props;
    return locations.map((location) => {
      const { id, children, machine_groups, status } = location;
      if (!hasMatch(location.id, "locations", matches)) {
        return null;
      }
      if (showStatus && excluded[status]) {
        return null;
      }

      const flag = this.flag(LOCATIONS_ACCORDION, id);
      const isOpen = this.isOpenAccordion(flag);
      return (
        <div
          key={id}
          className={scn("Hierarchy__accordion", status, showStatus, schema)}
        >
          <Accordion.Title className="Hierarchy__title" active={isOpen}>
            {this.renderItem(
              location,
              flag,
              isOpen,
              (<FactorySVG/>),
              locationTo(id, rrLocation),
              editing && this.sectionEditActions("locations"),
              hasIncompleteErrors(location),
              status,
              showStatus,
              schema
            )}
          </Accordion.Title>
          {isOpen && machine_groups.map((mg) => this.renderMachineGroup(mg))}
          {isOpen && children && (
            <Accordion.Accordion
              className="Hierarchy__subaccordion"
              exclusive={false}
            >
              {this.renderLocations(children)}
            </Accordion.Accordion>
          )}
        </div>
      );
    });
  };

  applyStatus = memoizeOne(
    (roots, sensors, status, diagnosesMap, priorities) => {
      resetNodes(status, sensors).forEach((a) => {
        const s = sensors.get(a?.sensor);
        if (!s) {
          return;
        }
        a.diagnoses.forEach((d) =>
          adjustSensorStatus(
            s,
            a.id,
            diagnosesMap[d]?.category,
            a.measured_at,
            priorities
          )
        );

        const m = s.machine;
        const mg = m.machine_group;
        let loc = mg.location;
        adjustNodeStatus(m, s.status, priorities);
        adjustNodeStatus(mg, m.status, priorities);
        let status = mg.status;
        do {
          adjustNodeStatus(loc, status, priorities);
          status = loc.status;
          addAnalysis(loc, priorities[s.status]);
          loc = loc.parent;
        } while (loc);
      });

      return roots;
    }
  );

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      getHierarchyStatusOngoing,
      excluded,
      showStatus,
      editing,
      canEdit,
      roots,
      sensors,
      status,
      matches,
      diagnosesMap,
      priorities,
      t,
      i18n: { language },
    } = this.props;
    const { open, search } = this.state;

    const loading = getHierarchyOngoing || getHierarchyStatusOngoing;

    const editActions = this.sectionEditActions("roots");

    const rootNodes = showStatus
      ? this.applyStatus(roots, sensors, status, diagnosesMap, priorities)
      : roots;


    const allAccordions = Object.keys(this.props.accordion)
    const someOpen = allAccordions.some(this.isOpenAccordion)

    return (
      <Grid columns={2} doubling data-cy="Hierarchy" className="Hierarchy">
        <Grid.Column width={4}>
          {this.ErrorMessage()}
          {this.ServiceErrorMessage(serviceError, language)}

          <Segment basic className="Hierarchy__filters">
            <Header as="h4" className="Hierarchy__filter-title">
              {t("Hierarchy.filterResults")}
            </Header>
            <Form
              className="Hierarchy__searchForm"
              onSubmit={this.handleSearch}
            >
              <Form.Input
                className="Hierarchy__search"
                name="search"
                label={t("Hierarchy.search")}
                icon={toIcon(SEARCH_ICON)}
                iconPosition="left"
                placeholder={t("Hierarchy.search")}
                size="small"
                value={search}
                onChange={this.handleEditSearch}
              />
            </Form>
            <span>
              {canEdit && (
                <Button
                  className="Hierarchy__tool"
                  toggle
                  active={editing}
                  icon
                  onClick={this.handleEdit}
                  compact
                  circular
                  basic
                  disabled={showStatus}
                  data-cy="Hierarchy__edit"
                >
                  {toIcon(EDIT_ICON)}
                </Button>
              )}
          </span>
            <div>
              <label style={{ fontWeight: "bold" }}>
                {t("Hierarchy.filterStatus")}
              </label>
              <br/>
              <Button
                className="Hierarchy__tool"
                toggle
                active={showStatus}
                icon
                onClick={this.handleStatus}
                disabled={editing}
                title="Status filter"
                data-cy="Hierarchy__status"
              >
                {t("Hierarchy.showStatus")}
              </Button>
              {showStatus && <StatusFilter
                excluded={excluded}
                onExclude={this.handleExclude}
              />}
            </div>
          </Segment>
          {editing && (
            <ActionMenu
              items={editActions}
              className="Hierarchy__root_menu"
              icon={toIcon(ADD_ICON)}
              value={0}
            />
          )}
        </Grid.Column>
        <Grid.Column width={8}>
          <DeployMachineGroupModal
            open={this.state.deployOpen}
            setOpen={v => this.setState({ deployOpen: v })}
            machineGroupId={this.state.deployMachineGroupId}
          />

          <Segment loading={loading}>
            <div className={"Hierarchy__tools"}>
              <Header as="h4">
                <b>{t("Hierarchy.hierarchy")}</b>
              </Header>
              <div>
                <Button
                  className="Hierarchy__tool"
                  icon
                  title="Refresh"
                  onClick={this.handleRefresh}
                  compact
                  data-cy="Hierarchy__remove"
                >
                  {toIcon(REFRESH_ICON)} {t("Hierarchy.refresh")}
                </Button>
                <Button
                  icon
                  compact
                  onClick={() => this.toggleAll(allAccordions)}
                  basic
                  title="Toggle all"
                >
                  {someOpen ? <MinusIconSVG/> : <PlusIconSVG/>}
                </Button>
              </div>
            </div>

            {rootNodes.length > 0 && (
              <Accordion className="Hierarchy__topaccordion" exclusive={false}>
                {this.renderLocations(rootNodes)}
              </Accordion>
            )}
            {rootNodes.length === 0 && <p>{t("Hierarchy.noHierarchy")}</p>}
            {matches && matches.empty && <p>{t("Hierarchy.noMatches")}</p>}
          </Segment>
        </Grid.Column>
      </Grid>
    )
      ;
  }
}

const mapStateToProps = (state) => {
  const schema = selectServiceSchema(state);

  const excluded = selectData(state, EXCLUDED) || EMPTY;
  const search = selectData(state, SEARCH);
  const [roots, sensors] = selectHierarchy(state);

  return {
    serviceError: selectServiceError(state, HIERARCHY, STATUS),
    accordion: selectFlags(state),
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    getHierarchyStatusOngoing: selectHierarchyOngoing(
      state,
      "getHierarchyStatus"
    ),
    excluded,
    search,
    editing: selectFlag(state, EDIT_FLAG),
    showStatus: selectFlag(state, STATUS_FLAG),
    canEditLocations: hasCapability(state, CAN_MANAGE_LOCATIONS),
    canEditMachineGroups: hasCapability(state, CAN_MANAGE_MACHINE_GROUPS),
    canEditMachines: hasCapability(state, CAN_MANAGE_MACHINES),
    canEditSensors: hasCapability(state, CAN_MANAGE_SENSORS),
    canEdit: hasSomeCapability(state, [
      CAN_MANAGE_LOCATIONS,
      CAN_MANAGE_MACHINE_GROUPS,
      CAN_MANAGE_MACHINES,
      CAN_MANAGE_SENSORS,
    ]),
    technicianUI: hasCapability(state, CAN_ACCESS_TECHNICIAN_UI),
    roots,
    sensors,
    matches: selectMatches(state, search),
    status: selectStatus(state),
    priorities: diagnosisCategoryPriorities(schema),
    diagnosesMap: selectDiagnosesMap(state),
    schema,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getHierarchy,
  getHierarchyStatus,
  setData,
  selectData,
  toggleFlag,
  setFlag,
  selectFlags,
  selectFlag,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Hierarchy)
);
