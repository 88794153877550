import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { apiCreateLocation, apiUpdateLocation, apiDeleteLocation } from "api";

import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const locations = createSlice({
  name: "locations",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      adapter.setAll(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, { id: payload.id, changes: payload });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default locations.reducer;

const {
  start,
  end,
  set,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = locations.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.locations;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const addLocation = (data, options) => async (dispatch, getState) => {
  try {
    dispatch(start("addLocation"));
    const location = await apiCreateLocation(selectToken(getState()), data);
    batchSuccess(dispatch, options, add(location), end("addLocation"));
    return location;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addLocation"));
    throw error;
  }
};

export const updateLocation = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("updateLocation"));
    const newLocation = await apiUpdateLocation(
      selectToken(getState()),
      id,
      data
    );
    batchSuccess(dispatch, options, update(newLocation), end("updateLocation"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateLocation"));
    throw error;
  }
};

export const removeLocation = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("removeLocation"));
    await apiDeleteLocation(selectToken(getState()), id);
    batchSuccess(dispatch, options, remove(id), end("removeLocation"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeLocation"));
    throw error;
  }
};
