import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Prompt from "Prompt";
import Loader from "Loader";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
  getIncompleteErrors,
  clearIncompleteErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  hasCapability,
  CAN_MANAGE_MACHINES,
  CAN_MANAGE_CUSTOM_REFERENCES,
  CAN_ACCESS_TECHNICIAN_UI,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import {
  selectOngoing as selectMachinesOngoing,
  selectEntities as selectMachinesMap,
  selectReference as selectMachineCustomReference,
  addMachine,
  updateMachine,
  removeMachine,
} from "slices/machinesSlice";
import { selectAll as selectMachineGroups } from "slices/machineGroupsSlice";
import { selectAll as selectMachineCustomReferences } from "slices/machineCustomReferencesSlice";
import {
  parseId,
  goTo,
  goBack,
  redirectTo,
  maybeValidPositiveNumber,
  stringToNumber,
  numberToString,
  queryParameters,
  toggleSet,
} from "utils";
import {
  machineTo,
  newMachineCustomReferenceTo,
  machineCustomReferenceTo,
  machineLogTo,
} from "appRoutes";
import { EDIT_ICON, MACHINE_LOG_ICON, DROPDOWN_ICON, toIcon } from "icons";

const HIERARCHY = "Machine/hierarchy";
export const MACHINES = "Machine/machines";

class Machine extends AdaComponent {
  constructor(props) {
    super(props);

    const { machine, isNew, t } = props;
    const errors = getIncompleteErrors(machine);

    this.setInitialState(
      {
        editing: isNew,
        promptRemove: false,
        accordion: new Set(),
        ...this.initialEditState(),
      },
      {
        errors,
        header: t("Machine.incomplete"),
        content: t("Machine.incompleteContent"),
      }
    );
  }
  componentDidMount = () => {
    const { getHierarchy, t } = this.props;

    window.scrollTo(0, 0);
    getHierarchy(false, opts(HIERARCHY, t("Machine.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  initialEditState = () => {
    const {
      machine,
      category,
      reference_group,
      foundation,
      rotational_speed_hz,
      rotational_group,
      machineCustomReferenceId,
      machineGroupId,
    } = this.props;
    return {
      name: machine?.name || "",
      description: machine?.description || "",
      category,
      reference_group,
      foundation,
      rotational_speed_hz,
      rotational_group,
      usesMachineCustomReference: !!machineCustomReferenceId,
      machineCustomReferenceId,
      machineGroupId,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY, MACHINES);
    }
  };

  handleChange = (_, { name, value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleChangeRotationalSpeed = (_, { value }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearServiceErrors();
    if (maybeValidPositiveNumber(value)) {
      this.setState({ rotational_speed_hz: value });
    }
  };

  handleMachineCategoryChange = (e, data) => {
    this.handleChange(e, data);
    this.setState({ reference_group: 0, rotational_group: 0 });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleMachineLog = () => {
    const { machineId, location, history } = this.props;
    goTo(machineLogTo(machineId, location), history);
  };

  handleCheck = (_, { name, checked }) => {
    const { editing } = this.state;
    if (!editing) {
      return;
    }
    this.clearErrors();
    this.clearServiceErrors();
    this.setState({ [name]: checked });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okRemove = async () => {
    const { machine, removeMachine, history, location, t } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeMachine(machine.id, opts(MACHINES, t("Machine.removeError")))
      .then(() => goBack(history, location))
      .catch((e) => {
        console.error(e);
      });
  };

  usesReferenceGroup = (category) => {
    const { referenceGroups } = this.props;
    return referenceGroups[category]?.length > 0;
  };

  usesRotationalGroup = (category) => {
    const { rotationalGroups } = this.props;
    return rotationalGroups[category]?.length > 0;
  };

  handleSubmit = async () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const {
      machine,
      addMachine,
      updateMachine,
      history,
      location,
      t,
    } = this.props;
    const {
      name,
      description,
      category,
      reference_group,
      foundation,
      rotational_speed_hz,
      rotational_group,
      usesMachineCustomReference,
      machineCustomReferenceId,
      machineGroupId,
    } = this.state;

    const errors = [];
    if (!name || name.length === 0) {
      errors.push("name");
    }
    if (this.usesReferenceGroup(category) && !reference_group) {
      errors.push("reference_group");
    }
    const rotational_speed_hz_validated = stringToNumber(rotational_speed_hz);
    if (rotational_speed_hz_validated === undefined) {
      errors.push("rotational_speed_hz");
    }
    if (this.usesRotationalGroup(category) && !rotational_group) {
      errors.push("rotational_group");
    }
    if (usesMachineCustomReference && !machineCustomReferenceId) {
      errors.push("machineCustomReferenceId");
    }
    if (!machineGroupId) {
      errors.push("machineGroupId");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("Machine.validationError"),
        errors,
      });
    }

    const mcrid = (usesMachineCustomReference && machineCustomReferenceId) || 0;
    const machine_custom_references = (mcrid && [mcrid]) || [];
    const machineData = {
      name,
      description,
      category,
      reference_group: reference_group || undefined,
      foundation,
      rotational_speed_hz: rotational_speed_hz_validated,
      rotational_group: rotational_group || undefined,
      machine_group: machineGroupId,
      machine_custom_references,
    };
    if (!machine) {
      addMachine(machineData, opts(MACHINES, t("Machine.addError")))
        .then((m) => redirectTo(machineTo(m.id, location), history))
        .catch((e) => console.error(e));
    } else {
      machineData.data = clearIncompleteErrors(machine);
      this.setState({ editing: false, machineCustomReferenceId: mcrid });
      updateMachine(
        machine.id,
        machineData,
        opts(MACHINES, t("Machine.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  handleToggle = (_, { index }) =>
    this.setState((state) => ({
      accordion: toggleSet(index, state.accordion),
    }));

  categoryOptions = memoizeOne((categories, translation) =>
    categories.map((c) => ({
      key: c,
      value: c,
      text: translationKey(translation, "machine_categories")[c],
    }))
  );

  referenceGroupOptions = memoizeOne((referenceGroups, translation) =>
    referenceGroups.map((g) => ({
      key: g,
      value: g,
      text: translationKey(translation, "machine_reference_groups")[g],
    }))
  );

  foundationOptions = memoizeOne((foundations, translation) =>
    foundations.map((f) => ({
      key: f,
      value: f,
      text: translationKey(translation, "machine_foundations")[f],
    }))
  );

  rotationalGroupOptions = memoizeOne((rotationalGroups, translation) =>
    rotationalGroups.map((g) => ({
      key: g,
      value: g,
      text: translationKey(translation, "machine_rotational_groups")[g],
    }))
  );

  machineCustomReferenceOptions = memoizeOne((machineCustomReferences) =>
    machineCustomReferences.map((r) => ({
      key: r.id,
      value: r.id,
      text: r.name,
    }))
  );

  machineGroupOptions = memoizeOne((machineGroups) =>
    machineGroups.map((m) => ({
      key: m.id,
      value: m.id,
      text: m.name,
    }))
  );

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      isNew,
      isMissingData,
      getHierarchyOngoing,
      addMachineOngoing,
      updateMachineOngoing,
      removeMachineOngoing,
      canEdit,
      technicianUI,
      canAddReference,
      categories,
      referenceGroups,
      foundations,
      rotationalGroups,
      machineCustomReferences,
      machineGroups,
      location,
      translation,
      i18n: { language },
      t,
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (isMissingData) {
      return <Loader />;
    }

    const {
      name,
      description,
      category,
      reference_group,
      foundation,
      rotational_speed_hz,
      rotational_group,
      usesMachineCustomReference,
      machineCustomReferenceId,
      machineGroupId,
    } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addMachineOngoing ||
      updateMachineOngoing ||
      removeMachineOngoing;

    const categoryOptions = this.categoryOptions(categories, translation);
    const matchingReferenceGroups = referenceGroups[category];
    const referenceGroupOptions = this.referenceGroupOptions(
      matchingReferenceGroups,
      translation
    );
    const foundationOptions = this.foundationOptions(foundations, translation);
    const matchingRotationalGroups = rotationalGroups[category];
    const rotationalGroupOptions = this.rotationalGroupOptions(
      matchingRotationalGroups,
      translation
    );
    const machineCustomReferenceOptions = this.machineCustomReferenceOptions(
      machineCustomReferences
    );
    const machineGroupOptions = this.machineGroupOptions(machineGroups);

    const showEdit = canEdit && !isNew;
    const addingNew = canEdit && isNew;
    const editingExisting = editing && !isNew;
    const showLog = !(isNew || editing);
    const showReferenceGroup = referenceGroupOptions.length > 1;
    const showRotationalGroup = rotationalGroupOptions.length > 1;

    return (
      <Segment
        as="div"
        className="Machine"
        loading={loading}
        data-cy="Machine"
      >
        <Prompt
          open={promptRemove}
          header={t("Machine.removePrompt.header")}
          content={t("Machine.removePrompt.content")}
          okText={t("Machine.removePrompt.ok")}
          cancelText={t("Machine.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />

        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}

        <div className="Machine__tools">
          {showEdit && (
            <Button
              className="Machine__tool"
              toggle
              active={editing}
              icon
              onClick={this.handleEdit}
              compact
              circular
              basic
              disabled={!canEdit}
              data-cy="Machine__edit"
            >
              {toIcon(EDIT_ICON)}
            </Button>
          )}
          {technicianUI && (
            <Button
              className="Machine__tool"
              icon
              onClick={this.handleMachineLog}
              compact
              circular
              basic
              disabled={!showLog}
              data-cy="Machine__log"
            >
              {toIcon(MACHINE_LOG_ICON)}
            </Button>
          )}
        </div>

        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Field required error={this.hasError("name")}>
            <label>{t("Machine.name")}</label>
            <Form.Input
              name="name"
              placeholder={t("Machine.namePlaceholder")}
              value={name}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field error={this.hasError("description")}>
            <label>{t("Machine.description")}</label>
            <Form.Input
              name="description"
              placeholder={t("Machine.descriptionPlaceholder")}
              value={description}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field required error={this.hasError("category")}>
            <label>{t("Machine.category")}</label>
            <Form.Dropdown
              name="category"
              placeholder={t("Machine.categoryPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={categoryOptions}
              value={category}
              onChange={this.handleMachineCategoryChange}
              noResultsMessage={t("Machine.noCategories")}
            />
          </Form.Field>
          {showReferenceGroup && (
            <Form.Field required error={this.hasError("reference_group")}>
              <label>{t("Machine.referenceGroup")}</label>
              <Form.Dropdown
                name="reference_group"
                placeholder={t("Machine.referenceGroupPlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={referenceGroupOptions}
                value={reference_group}
                onChange={this.handleChange}
                noResultsMessage={t("Machine.noReferenceGroups")}
              />
            </Form.Field>
          )}
          <Form.Field required error={this.hasError("foundation")}>
            <label>{t("Machine.foundation")}</label>
            <Form.Dropdown
              name="foundation"
              placeholder={t("Machine.foundationPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={foundationOptions}
              value={foundation}
              onChange={this.handleChange}
              noResultsMessage={t("Machine.noFoundations")}
            />
          </Form.Field>
          <Form.Field required error={this.hasError("rotational_speed_hz")}>
            <label>{t("Machine.rotationalSpeed")}</label>
            <Form.Input
              name="rotational_speed_hz"
              placeholder={t("Machine.rotationalSpeedPlaceholder")}
              value={rotational_speed_hz}
              onChange={this.handleChangeRotationalSpeed}
            />
          </Form.Field>
          {showRotationalGroup && (
            <Form.Field required error={this.hasError("rotational_group")}>
              <label>{t("Machine.rotationalGroup")}</label>
              <Form.Dropdown
                name="rotational_group"
                placeholder={t("Machine.rotationalGroupPlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={rotationalGroupOptions}
                value={rotational_group}
                onChange={this.handleChange}
                noResultsMessage={t("Machine.noRotationalGroups")}
              />
            </Form.Field>
          )}
          <Form.Field>
            <Form.Checkbox
              name="usesMachineCustomReference"
              label={t("Machine.usesReference")}
              checked={usesMachineCustomReference}
              onClick={this.handleCheck}
            />
          </Form.Field>
          {usesMachineCustomReference && (
            <Form.Field
              className="Machine__reference"
              required
              error={this.hasError("machineCustomReferenceId")}
              disabled={!usesMachineCustomReference}
            >
              <label className="Machine__reference_label">
                {t("Machine.reference")}
              </label>
              {editing && canAddReference && (
                <span className="Machine__reference_link">
                  <NavLink to={newMachineCustomReferenceTo(location)}>
                    {t("Machine.addReference")}
                  </NavLink>
                </span>
              )}
              {!editing && (
                <span className="Machine__reference_link">
                  <NavLink
                    to={machineCustomReferenceTo(
                      machineCustomReferenceId,
                      location
                    )}
                  >
                    {t("Machine.viewReference")}
                  </NavLink>
                </span>
              )}
              <Form.Dropdown
                name="machineCustomReferenceId"
                placeholder={t("Machine.referencePlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={machineCustomReferenceOptions}
                value={machineCustomReferenceId}
                onChange={this.handleChange}
                noResultsMessage={t("Machine.noReferences")}
              />
            </Form.Field>
          )}
          <Form.Field required error={this.hasError("machineGroupId")}>
            <label>{t("Machine.machineGroup")}</label>
            <Form.Dropdown
              name="machineGroupId"
              placeholder={t("Machine.machineGroupPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={machineGroupOptions}
              value={machineGroupId}
              onChange={this.handleChange}
              noResultsMessage={t("Machine.noMachineGroups")}
            />
          </Form.Field>
          {addingNew && (
            <Form.Button className="Machine__button" type="submit" primary>
              {t("Machine.add")}
            </Form.Button>
          )}
          {editingExisting && (
            <>
              <Form.Button className="Machine__button" type="submit" primary>
                {t("Machine.update")}
              </Form.Button>
              <Button
                className="Machine__button"
                type="button"
                negative
                onClick={this.handleRemove}
              >
                {t("Machine.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    location,
    i18n: { language },
  } = ownProps;

  const machineId = parseId(id);
  const machine = selectMachinesMap(state)[machineId];

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  const categories = schemaKey(schema, "machine_categories");
  const category = machine?.category || categories[0];

  const referenceGroups = schemaKey(schema, "machine_reference_groups");
  const matchingReferenceGroups = schemaKey(schema, "machine_reference_groups")[
    category
  ];
  const reference_group =
    machine?.reference_group ||
    (matchingReferenceGroups && matchingReferenceGroups[0]);

  const foundations = schemaKey(schema, "machine_foundations");
  const foundation = machine?.foundation || foundations[0];

  const rotationalGroups = schemaKey(schema, "machine_rotational_groups");
  const matchingRotationalGroups = schemaKey(
    schema,
    "machine_rotational_groups"
  )[category];
  const rotational_group =
    machine?.rotational_group ||
    (matchingRotationalGroups && matchingRotationalGroups[0]);

  const machineGroupId =
    machine?.machine_group ||
    parseId(queryParameters(location)["machineGroup"]) ||
    0;

  return {
    serviceError: selectServiceError(state, HIERARCHY, MACHINES),
    machineId,
    machine,
    category,
    reference_group,
    foundation,
    rotational_speed_hz: numberToString(machine?.rotational_speed_hz) || 0,
    rotational_group,
    machineCustomReferenceId: selectMachineCustomReference(state, id),
    machineGroupId,
    isNew: !machineId,
    isMissingData: machineId && !machine,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addMachineOngoing: selectMachinesOngoing(state, "addMachine"),
    updateMachineOngoing: selectMachinesOngoing(state, "updateMachine"),
    removeMachineOngoing: selectMachinesOngoing(state, "removeMachine"),
    canEdit: hasCapability(state, CAN_MANAGE_MACHINES),
    canAddReference: hasCapability(state, CAN_MANAGE_CUSTOM_REFERENCES),
    technicianUI: hasCapability(state, CAN_ACCESS_TECHNICIAN_UI),
    categories,
    referenceGroups,
    foundations,
    rotationalGroups,
    machineCustomReferences: selectMachineCustomReferences(state),
    machineGroups: selectMachineGroups(state),
    translation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getHierarchy,
  addMachine,
  updateMachine,
  removeMachine,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Machine)
);
