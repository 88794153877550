import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import InventoryList from "InventoryList";

import AdaComponent from "AdaComponent";
import {
  selectServiceError,
  clearServiceErrors,
  opts,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_LOCATIONS } from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectLocations } from "slices/locationsSlice";

import { goTo } from "utils";
import { newRootLocationTo, locationTo } from "appRoutes";
import { LOCATION_ICON, toIcon } from "icons";

const HIERARCHY = "Locations/hierarchy";

class Locations extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    const { hasHierarchy } = this.props;
    if (!hasHierarchy) {
      this.refresh();
    }
  }

  refresh = (reload = false) => {
    const { getHierarchy, t } = this.props;
    getHierarchy(reload, opts(HIERARCHY, t("Locations.refreshError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { location, history } = this.props;
    goTo(newRootLocationTo(location), history);
  };

  selectTo = (id) => {
    const { location } = this.props;
    return locationTo(id, location);
  };

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      canEdit,
      locations,
      t,
      i18n: { language },
    } = this.props;

    return (
      <Segment
        as="div"
        className="Locations"
        loading={getHierarchyOngoing}
        data-cy="Locations"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <InventoryList
          items={locations}
          icon={toIcon(LOCATION_ICON)}
          onRefresh={this.handleRefresh}
          refreshText={t("Locations.refresh")}
          onAdd={canEdit && this.handleAdd}
          addText={t("Locations.add")}
          selectTo={this.selectTo}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceError: selectServiceError(state, HIERARCHY),
  getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
  canEdit: hasCapability(state, CAN_MANAGE_LOCATIONS),
  locations: selectLocations(state),
});

const mapDispatchToProps = {
  getHierarchy,
  clearServiceErrors,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations))
);
