import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Login from "Login";
import DimLoader from "DimLoader";
import Main from "Main";

import { selectToken, logout } from "slices/tokenSlice";
import {
  selectUserDataOngoing,
  selectIsUserDataComplete,
  getUserData,
} from "slices/userDataSlice";

class App extends Component {
  handleLogin = () => {
    const { getUserData, flushStore, location, history } = this.props;

    const { from } = location.state || { from: { pathname: "/" } };

    getUserData()
      .then(flushStore)
      .then(() => history.replace(from))
      .catch((e) => {
        console.error(e);
        return this.handleLogout();
      });
  };

  handleLogout = () => {
    const { logout, flushStore } = this.props;
    logout();
    flushStore().catch((e) => {
      console.error(e);
    });
  };

  render() {
    const { userDataComplete, getUserDataOngoing, location } = this.props;
    return userDataComplete ? (
      <Main onLogout={this.handleLogout} />
    ) : getUserDataOngoing ? (
      <Switch>
        <Route path="/loading" component={DimLoader} />
        <Redirect to="/loading" />
      </Switch>
    ) : (
      <Switch>
        <Route
          path="/login"
          render={(routeProps) => (
            <Login {...routeProps} onLogin={this.handleLogin} />
          )}
        />
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: {
                pathname: location.pathname,
                search: location.search,
                hash: location.hash,
              },
            },
          }}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  getUserDataOngoing: selectUserDataOngoing(state, "getUserData"),
  token: selectToken(state),
  userDataComplete: selectIsUserDataComplete(state),
});

const mapDispatchToProps = {
  logout,
  getUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
