import React from "react";
import { connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Button, Grid, Segment, Image } from "semantic-ui-react";

import AdaComponent from "AdaComponent";
import DimLoader from "DimLoader";

import { login, selectTokenOngoing } from "slices/tokenSlice";
import {
  clearServiceError,
  selectServiceError,
  opts,
} from "slices/serviceErrorsSlice";
import defaultLogo from "images/asensiot_logo.svg";

const LOGIN = "Login/token";

class Login extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      username: "",
      password: "",
    });
  }

  handleChange = (_, { name, value }) => {
    this.clearErrors();
    this.props.clearServiceError(LOGIN);
    this.setState({ [name]: value });
  };

  handleLogin = async () => {
    const { username, password } = this.state;
    const { login, onLogin, t } = this.props;

    const errors = [];
    if (username.length === 0) {
      errors.push("username");
    }
    if (password.length === 0) {
      errors.push("password");
    }
    if (errors.length > 0) {
      return this.setErrors({
        errors,
        header: t("Login.validationError"),
      });
    }

    this.setState({ password: "" });

    login({ username, password }, opts(LOGIN, t("Login.loginError")))
      .then(onLogin)
      .catch((e) => console.error(e));
  };

  render() {
    const { language, loginOngoing, serviceError, t } = this.props;
    const { username, password } = this.state;

    return (
      <div className="Login" data-cy="Login">
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column className="Login__dialog">
            <Image
              className="Login__logo"
              src={defaultLogo}
            />
            <Form
              size="large"
              onSubmit={this.handleLogin}
              error={this.hasErrors(serviceError)}
            >
              <DimLoader active={loginOngoing}/>
              {this.ErrorMessage()}
              {this.ServiceErrorMessage(serviceError, language, "LoginErrors")}
              <Segment stacked padded>
                <h2 className="Login__title">{t("Login.login")}</h2>
                <Form.Input
                  label={t("Login.username")}
                  name="username"
                  error={this.hasError("username")}
                  fluid
                  icon="user"
                  iconPosition="right"
                  placeholder={t("Login.username")}
                  autoComplete="off"
                  value={username}
                  onChange={this.handleChange}
                  data-cy="Login__username"
                />

                <Form.Input
                  label={t("Login.password")}
                  name="password"
                  error={this.hasError("password")}
                  fluid
                  icon="lock"
                  iconPosition="right"
                  placeholder={t("Login.password")}
                  type="password"
                  autoComplete="off"
                  value={password}
                  onChange={this.handleChange}
                  data-cy="Login__password"
                />

                <Button
                  className="Login__login"
                  disabled={!username || !password}
                  primary
                  fluid
                  size="large"
                  type="submit"
                  data-cy="Login__login"
                  color="green"
                >
                  {t("Login.login")}
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;
  return {
    loginOngoing: selectTokenOngoing(state, "login"),
    serviceError: selectServiceError(state, LOGIN),
    language,
  };
};

const mapDispatchToProps = {
  login,
  clearServiceError,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
