import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import InventoryList from "./InventoryList";

import AdaComponent from "./AdaComponent";
import {
  selectServiceError,
  clearServiceErrors,
  opts,
} from "slices/serviceErrorsSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectSensors } from "slices/sensorsSlice";

import { goTo } from "./utils";
import { newSensorTo, sensorTo } from "./appRoutes";
import { SENSOR_ICON, toIcon } from "./icons";

const HIERARCHY = "Sensors/hierarchy";

class Sensors extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }
  componentDidMount() {
    this.refresh();
  }

  refresh = (reload = false) => {
    const { getHierarchy, t } = this.props;
    getHierarchy(reload, opts(HIERARCHY, t("Sensors.refreshError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { location, history } = this.props;
    goTo(newSensorTo(0, location), history);
  };

  selectTo = (id) => {
    const { location } = this.props;
    return sensorTo(id, location);
  };

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      sensors,
      t,
      i18n: { language },
    } = this.props;

    const loading = getHierarchyOngoing;

    return (
      <Segment
        as="div"
        className="Sensors"
        loading={loading}
        data-cy="Sensors"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <InventoryList
          items={sensors}
          icon={toIcon(SENSOR_ICON)}
          onRefresh={this.handleRefresh}
          refreshText={t("Sensors.refresh")}
          onAdd={this.handleAdd}
          addText={t("Sensors.add")}
          selectTo={this.selectTo}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceError: selectServiceError(state, HIERARCHY),
  getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
  sensors: selectSensors(state),
});

const mapDispatchToProps = {
  getHierarchy,
  clearServiceErrors,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Sensors))
);
