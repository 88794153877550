import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { apiReviseDiagnosis } from "api.js";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});

const diagnoses = createSlice({
  name: "diagnoses",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    upsert(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
  },
});

export default diagnoses.reducer;

const { start, end, upsert, upsertMany } = diagnoses.actions;

export { upsertMany };

const selectState = (state) => state.diagnoses;
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => selectState(state).ongoing[key];

export const reviseDiagnosis = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("reviseDiagnosis"));
    const diagnosis = await apiReviseDiagnosis(
      selectToken(getState()),
      id,
      data
    );
    batchSuccess(dispatch, options, upsert(diagnosis), end("reviseDiagnosis"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("reviseDiagnosis"));
    throw error;
  }
};
