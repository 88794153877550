import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  apiGetSensorGroups,
  apiCreateSensorGroup,
  apiUpdateSensorGroup,
  apiDeleteSensorGroup,
  apiGetNextJson,
  apiGetAll,
} from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const sensorGroups = createSlice({
  name: "sensorGroups",
  initialState: adapter.getInitialState({
    ongoing: {},
    next: null,
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      state.next = null;
      adapter.setAll(state, payload);
    },
    setNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.setAll(state, results);
    },
    addNext(state, { payload }) {
      const { results, next } = payload;
      state.next = next;
      adapter.addMany(state, results);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, { id: payload.id, changes: payload });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default sensorGroups.reducer;

const {
  start,
  end,
  set,
  setNext,
  addNext,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = sensorGroups.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.sensorGroups;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

const selectSomeOngoing = (state, ...keys) => {
  return keys.some((key) => selectState(state).ongoing[key]);
};

export const selectNext = (state) => {
  if (selectSomeOngoing(state, "getSensorGroups, getNextSensorGroups")) {
    return null;
  }
  return selectState(state).next;
};

export const getSensorGroups = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectSomeOngoing(state, "getSensorGroups, getNextSensorGroups")) {
      return;
    }
    dispatch(start("getSensorGroups"));
    const sensorGroups = await apiGetSensorGroups(selectToken(state));
    batchSuccess(
      dispatch,
      options,
      setNext(sensorGroups),
      end("getSensorGroups")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getSensorGroups"));
    throw error;
  }
};

export const getNextSensorGroups = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const next = selectNext(state);
    if (!next) {
      return;
    }
    dispatch(start("getNextSensorGroups"));
    const sensorGroups = await apiGetNextJson(selectToken(state), next);
    batchSuccess(
      dispatch,
      options,
      addNext(sensorGroups),
      end("getNextSensorGroups")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getNextSensorGroups"));
    throw error;
  }
};

export const addSensorGroup = (data, options) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(start("addSensorGroup"));
    const sensorGroup = await apiCreateSensorGroup(selectToken(state), data);
    batchSuccess(dispatch, options, add(sensorGroup), end("addSensorGroup"));
    return sensorGroup;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addSensorGroup"));
    throw error;
  }
};

export const updateSensorGroup = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    dispatch(start("updateSensorGroup"));
    const sensorGroup = await apiUpdateSensorGroup(
      selectToken(state),
      id,
      data
    );
    batchSuccess(
      dispatch,
      options,
      update(sensorGroup),
      end("updateSensorGroup")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateSensorGroup"));
    throw error;
  }
};

export const removeSensorGroup = (id, options) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    dispatch(start("removeSensorGroup"));
    await apiDeleteSensorGroup(selectToken(state), id);
    batchSuccess(dispatch, options, remove(id), end("removeSensorGroup"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeSensorGroup"));
    throw error;
  }
};

export const getAllSensorGroups = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (selectSomeOngoing(state, "getSensorGroups, getSensorNextGroups")) {
      return;
    }
    dispatch(start("getAllSensorGroups"));
    const sensorGroups = await apiGetAll(
      selectToken(state),
      apiGetSensorGroups,
      apiGetNextJson
    );
    batchSuccess(
      dispatch,
      options,
      set(sensorGroups),
      end("getAllSensorGroups")
    );
  } catch (error) {
    batchFailure(dispatch, error, options, end("getAllSensorGroups"));
    throw error;
  }
};
