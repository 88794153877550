import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import AdaComponent from "AdaComponent";
import InventoryList from "InventoryList";

import {
  selectServiceError,
  clearServiceErrors,
  opts,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_MACHINE_GROUPS } from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectMachineGroups } from "slices/machineGroupsSlice";

import { goTo } from "utils";
import { newMachineGroupTo, machineGroupTo } from "appRoutes";
import { MACHINE_GROUP_ICON, toIcon } from "icons";

const HIERARCHY = "MachineGroups/hierachy";

class MachineGroups extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    const { hasHierarchy } = this.props;
    if (!hasHierarchy) {
      this.refresh();
    }
  }

  refresh = (reload = false) => {
    const { getHierarchy, t } = this.props;
    getHierarchy(
      reload,
      opts(HIERARCHY, t("MachineGroups.refreshError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { location, history } = this.props;
    goTo(newMachineGroupTo(0, location), history);
  };

  selectTo = (id) => {
    const { location } = this.props;
    return machineGroupTo(id, location);
  };

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      canEdit,
      machineGroups,
      t,
      i18n: { language },
    } = this.props;

    const loading = getHierarchyOngoing;

    return (
      <Segment
        as="div"
        className="MachineGroups"
        loading={loading}
        data-cy="MachineGroups"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <InventoryList
          items={machineGroups}
          icon={toIcon(MACHINE_GROUP_ICON)}
          onRefresh={this.handleRefresh}
          refreshText={t("MachineGroups.refresh")}
          onAdd={canEdit && this.handleAdd}
          addText={t("MachineGroups.add")}
          selectTo={this.selectTo}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceError: selectServiceError(state, HIERARCHY),
  getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
  canEdit: hasCapability(state, CAN_MANAGE_MACHINE_GROUPS),
  machineGroups: selectMachineGroups(state),
});

const mapDispatchToProps = {
  getHierarchy,
  clearServiceErrors,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MachineGroups))
);
