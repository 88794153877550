import React from "react";
import { Segment, Form, Button, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import Loader from "Loader";
import Prompt from "Prompt";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  selectUserInfo,
  hasCapability,
  CAN_MODERATE_MACHINE_LOG,
} from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import {
  selectOngoing as selectMachineLogOngoing,
  selectLogEntry as selectMachineLogEntry,
  addMachineLogEntry,
  getMachineLogEntry,
  modifyMachineLogEntry,
  removeMachineLogEntry,
} from "slices/machineLogSlice";
import {
  selectEntities as selectMachineMap,
  selectAll as selectMachines,
} from "slices/machinesSlice";
import {
  parseId,
  goBack,
  redirectTo,
  validDatetime,
  currentTimeString,
  formatDatetime,
  queryParameters,
} from "utils";
import { logEntryTo } from "appRoutes";
import {
  EDIT_ICON,
  DROPDOWN_ICON,
  ATTACH_ICON,
  REMOVE_ICON,
  toIcon,
} from "icons";

const HIERARCHY = "Location/hierarchy";
const LOG_ENTRIES = "LogEntry/entries";

const TIMESTAMP_FORMAT = "Y-MM-DD HH:mm";

class LogEntry extends AdaComponent {
  constructor(props) {
    super(props);

    const { isNew } = props;

    this.setInitialState({
      editing: isNew,
      prompRemove: false,
      ...this.initialEditState(),
    });

    this.fileInputRef = React.createRef();
  }

  componentDidMount = () => {
    const {
      // hasHierarchy,
      getHierarchy,
      missingData,
      logEntryId,
      getMachineLogEntry,
      t,
    } = this.props;
    window.scrollTo(0, 0);

    // // if (!hasHierarchy) {
    getHierarchy(false, opts(HIERARCHY, t("LogEntry.hierarchyError"))).catch(
      (e) => {
        console.error(e);
      }
    );
    // // }

    if (missingData) {
      getMachineLogEntry(
        logEntryId,
        opts(LOG_ENTRIES, t("LogEntry.logEntryError"))
      ).catch((e) => {
        console.error(e);
      });
    }
  };

  initialEditState = () => {
    const { logEntry, machineId } = this.props;

    if (this.fileInputRef && this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    return {
      machineId,
      timestamp:
        formatDatetime(logEntry?.measured_at, TIMESTAMP_FORMAT) ||
        currentTimeString(TIMESTAMP_FORMAT),
      category: logEntry?.category || "",
      text: logEntry?.text || "",
      attachmentURL: logEntry?.attachment,
      attachmentFile: null,
    };
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(/* HIERARCHY, */ LOG_ENTRIES);
    }
  };

  handleFileInputClicked = () => {
    this.fileInputRef.current.click();
  };

  handleChangeAttachment = (e) => {
    const { files } = e.target;
    this.clearError("attachmentFile");
    this.clearServiceErrors();
    this.setState({
      attachmentURL: null,
      attachmentFile: files?.length > 0 ? files[0] : null,
    });
  };

  handleClearAttachment = () => {
    this.clearError("attachmentFile");
    this.clearServiceErrors();
    this.setState({
      attachmentURL: null,
      attachmentFile: null,
    });
  };

  handleNone = () => {};

  handleChange = (_, { name, value }) => {
    if (!this.state.editing) {
      return;
    }
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleEdit = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.setState((state) => {
      if (!state.editing) {
        return { editing: true, ...this.initialEditState() };
      }
      return { editing: false };
    });
  };

  handleChangeTimestamp = (timestamp) => {
    this.clearError("timestamp");
    this.clearServiceErrors();
    this.setState({ timestamp });
  };

  handleRemove = () => this.setState({ promptRemove: true });
  cancelRemove = () => this.setState({ promptRemove: false });

  okRemove = () => {
    const {
      logEntry,
      removeMachineLogEntry,
      history,
      location,
      t,
    } = this.props;
    this.setState({ editing: false, promptRemove: false });
    removeMachineLogEntry(
      logEntry.id,
      opts(LOG_ENTRIES, t("LogEntry.removeError"))
    )
      .then(() => goBack(history, location))
      .catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = () => {
    const {
      logEntry,
      addMachineLogEntry,
      modifyMachineLogEntry,
      history,
      location,
      t,
    } = this.props;
    const {
      machineId,
      timestamp,
      category,
      text,
      attachmentURL,
      attachmentFile,
    } = this.state;

    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    this.fileInputRef.current.value = "";
    this.setState({
      attachmentFile: null,
    });

    const timestampString =
      typeof timestamp === "string"
        ? validDatetime(timestamp, TIMESTAMP_FORMAT)?.toISOString()
        : timestamp.toISOString();

    // console.log('TIMESTAMP:', timestampString)

    const errors = [];
    if (!timestampString) {
      errors.push("timestamp");
    }
    if (!category) {
      errors.push("category");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("LogEntry.validationError"),
        errors,
      });
    }

    const baseData = {
      measured_at: timestampString,
      category,
      text,
    };

    let attachmentData = {};

    if (!logEntry) {
      if (attachmentFile) {
        attachmentData = {
          attachment_name: attachmentFile.name,
          attachment: attachmentFile,
        };
      }
      addMachineLogEntry(
        {
          ...baseData,
          ...attachmentData,
          machine: machineId,
        },
        opts(LOG_ENTRIES, t("LogEntry.addError"))
      )
        .then((e) => redirectTo(logEntryTo(e.id, location), history))
        .catch((e) => console.error(e));
    } else {
      this.setState({ editing: false });

      if (!attachmentURL) {
        // attachment changed

        if (attachmentFile) {
          // attachment file selected, so replace
          attachmentData = {
            attachment_name: attachmentFile.name,
            attachment: attachmentFile,
          };
        } else if (!attachmentFile && logEntry.attachment) {
          // attachment file not selected and entry has attachment, so clear
          attachmentData = {
            attachment_name: "",
            attachment: "",
          };
        }
      }
      modifyMachineLogEntry(
        logEntry.id,
        {
          ...baseData,
          ...attachmentData,
        },
        opts(LOG_ENTRIES, t("LogEntry.updateError"))
      ).catch((e) => console.error(e));
    }
  };

  machineOptions = memoizeOne((machines) =>
    machines.map((m) => ({
      key: m.id,
      value: m.id,
      text: m.name,
    }))
  );

  categoryOptions = memoizeOne((categories, translation) =>
    categories.map((c) => ({
      key: c,
      value: c,
      text: translationKey(translation, "log_entry_categories")[c],
    }))
  );

  activeData = () =>
    this.state.editing ? this.state : this.initialEditState();

  render() {
    const {
      serviceError,
      logEntry,
      isNew,
      missingData,
      getHierarchyOngoing,
      addLogEntryOngoing,
      modifyLogEntryOngoing,
      removeLogEntryOngoing,
      username,
      canEdit,
      machines,
      categories,
      translation,
      t,
      i18n: { language },
    } = this.props;
    const { editing, promptRemove } = this.state;

    if (missingData) {
      return <Loader />;
    }

    const {
      machineId,
      timestamp,
      category,
      text,
      attachmentURL,
      attachmentFile,
    } = this.activeData();

    const loading =
      getHierarchyOngoing ||
      addLogEntryOngoing ||
      modifyLogEntryOngoing ||
      removeLogEntryOngoing;

    const machineOptions = machines && this.machineOptions(machines);
    const categoryOptions = this.categoryOptions(categories, translation);

    const showTools = canEdit && !isNew;
    const adding = editing && isNew;
    const modifying = editing && !isNew;

    const attachmentString =
      attachmentURL || attachmentFile
        ? t("LogEntry.updateAttachment")
        : t("LogEntry.addAttachment");

    return (
      <Segment
        as="div"
        className="LogEntry"
        loading={loading}
        data-cy="LogEntry"
      >
        <Prompt
          open={promptRemove}
          header={t("LogEntry.removePrompt.header")}
          okText={t("LogEntry.removePrompt.ok")}
          cancelText={t("LogEntry.removePrompt.cancel")}
          onOk={this.okRemove}
          onCancel={this.cancelRemove}
        />
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        {showTools && (
          <div className="LogEntry__tools">
            <Button
              className="LogEntry__tool"
              type="button"
              icon={toIcon(EDIT_ICON)}
              basic
              compact
              circular
              toggle
              active={editing}
              onClick={this.handleEdit}
              data-cy="LogEntry__edit"
            />
          </div>
        )}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          {machineOptions && (
            <Form.Field required error={this.hasError("machineId")}>
              <label>{t("LogEntry.machine")}</label>
              <Form.Dropdown
                name="machineId"
                placeholder={t("LogEntry.machinePlaceholder")}
                fluid
                search
                selection
                icon={editing ? toIcon(DROPDOWN_ICON) : ""}
                open={editing && undefined}
                options={machineOptions}
                value={machineId}
                onChange={this.handleChange}
                noResultsMessage={t("LogEntry.noMachines")}
              />
            </Form.Field>
          )}
          <Form.Field required error={this.hasError("timestamp")}>
            <label>{t("LogEntry.timestamp")}</label>
            {editing ? (
              <Form.Group inline>
                <Datetime
                  locale={language}
                  dateFormat={t("LogEntry.timestampDateformat")}
                  timeFormat={t("LogEntry.timestampTimeformat")}
                  input={true}
                  value={timestamp}
                  onChange={this.handleChangeTimestamp}
                />
              </Form.Group>
            ) : (
              <Form.Input
                name="timestamp"
                placeholder={t("LogEntry.timestampPlaceholder")}
                value={t("LogEntry.timestampFormat", {
                  timestamp,
                })}
                onChange={this.handleNone}
                data-cy="LogEntry__timestamp"
              />
            )}
          </Form.Field>
          <Form.Field required error={this.hasError("category")}>
            <label>{t("LogEntry.category")}</label>
            <Form.Dropdown
              name="category"
              placeholder={t("LogEntry.categoryPlaceholder")}
              fluid
              search
              selection
              icon={editing ? toIcon(DROPDOWN_ICON) : ""}
              open={editing && undefined}
              options={categoryOptions}
              value={category}
              onChange={this.handleChange}
              noResultsMessage={t("LogEntry.noCategories")}
            />
          </Form.Field>
          <Form.Field error={this.hasError("text")}>
            <label>{t("LogEntry.text")}</label>
            <Form.TextArea
              name="text"
              placeholder={t("LogEntry.textPlaceholder")}
              value={text}
              onChange={this.handleChange}
              data-cy="LogEntry__text"
            />
          </Form.Field>
          <Form.Field error={this.hasError("attachment")}>
            <label>{t("LogEntry.attachment")}</label>
            {editing ? (
              <span>
                <Button type="button" onClick={this.handleFileInputClicked}>
                  {toIcon(ATTACH_ICON)}
                  {attachmentString}
                </Button>
                <input
                  ref={this.fileInputRef}
                  type="file"
                  hidden
                  onChange={this.handleChangeAttachment}
                />
                {attachmentFile && <Label>{attachmentFile.name}</Label>}
                {attachmentURL && (
                  <Button type="button" onClick={this.handleClearAttachment}>
                    {toIcon(REMOVE_ICON)}
                    {t("LogEntry.clearAttachment")}
                  </Button>
                )}
              </span>
            ) : (
              <div>
                {attachmentURL ? (
                  <a
                    href={attachmentURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={logEntry?.attachment_name}
                  >
                    {logEntry?.attachment_name}
                  </a>
                ) : (
                  t("LogEntry.noAttachment")
                )}
              </div>
            )}
          </Form.Field>
          <Form.Field>
            <label>{t("LogEntry.user")}</label>
            <div>{logEntry?.username || username}</div>
          </Form.Field>

          {adding && (
            <Form.Button
              className="LogEntry__button"
              type="submit"
              primary
              data-cy="LogEntry__add"
            >
              {t("LogEntry.add")}
            </Form.Button>
          )}
          {modifying && (
            <>
              <Form.Button
                className="LogEntry__button"
                type="submit"
                primary
                data-cy="LogEntry__update"
              >
                {t("LogEntry.update")}
              </Form.Button>
              <Button
                className="LogEntry__button"
                type="button"
                negative
                onClick={this.handleRemove}
                data-cy="LogEntry__remove"
              >
                {t("LogEntry.remove")}
              </Button>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id },
    },
    location,
    i18n: { language },
  } = ownProps;

  const logEntryId = parseId(id);
  const logEntry = selectMachineLogEntry(state, logEntryId);
  // console.log("LOG ENTRY:", logEntry);

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  const machineId =
    logEntry?.machine || parseId(queryParameters(location)["machine"]) || 0;
  const machine = selectMachineMap(state)[machineId];

  const canEdit =
    logEntry?.is_author || hasCapability(state, CAN_MODERATE_MACHINE_LOG);

  return {
    serviceError: selectServiceError(state, /* HIERARCHY, */ LOG_ENTRIES),
    logEntryId,
    logEntry,
    isNew: !logEntryId,
    // hasHierarchy: selectHasHierarchy(state),
    missingData: logEntryId && !logEntry,
    getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    addLogEntryOngoing: selectMachineLogOngoing(state, "addMachineLogEntry"),
    getLogEntryOngoing: selectMachineLogOngoing(state, "getMachineLogEntry"),
    modifyLogEntryOngoing: selectMachineLogOngoing(
      state,
      "modifyMachineLogEntry"
    ),
    removeLogEntryOngoing: selectMachineLogOngoing(
      state,
      "removeMachineLogEntry"
    ),
    username: selectUserInfo(state).username,
    canEdit,
    machineId,
    machines: machine ? undefined : selectMachines(state),
    categories: schemaKey(schema, "log_entry_categories"),
    translation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getHierarchy,
  addMachineLogEntry,
  getMachineLogEntry,
  modifyMachineLogEntry,
  removeMachineLogEntry,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LogEntry)
);
