const { REACT_APP_API_URL } = process.env;

const backendURL = (url) => `${REACT_APP_API_URL}${url}`;

const createError = (status, error = {}) => {
  return {
    status,
    keys: Object.keys(error),
    error,
  };
};

const backendFetch = (
  url,
  method = "GET",
  headers = undefined,
  body = undefined
) =>
  window.fetch(url, {
    method,
    mode: "cors",
    headers,
    body,
  });

const tokenHeader = (header, token) => {
  if (token) {
    header.authorization = `Token ${token}`;
  }
  return header;
};

const jsonHeader = (header) => {
  header["Content-Type"] = "application/json";
  header["Accept"] = "application/json";
  return header;
};

const acceptJson = (header) => {
  header["Accept"] = "application/json";
  return header;
};

const toStatus = (r) => {
  if (r.status >= 200 && r.status < 300) {
    return r.status === 204 ? Promise.resolve() : r.json();
  }
  if (r.status >= 400 && r.status < 500) {
    return r.json().then((e) => Promise.reject(createError(r.status, e)));
  }
  r.text().then((text) => console.error(`API error:`, r.status, text));
  return Promise.reject(createError(r.status));
};

const getJson_DIRECT_URL = (token, url) =>
  backendFetch(url, "GET", jsonHeader(tokenHeader({}, token))).then(toStatus);

const getJson = (token, url) => getJson_DIRECT_URL(token, backendURL(url));

const formData = (data) => {
  const d = new FormData();
  for (const name in data) {
    if (data[name] !== undefined) {
      d.append(name, data[name]);
    }
  }
  return d;
};

const postMultipart = (token, url, data) => {
  return backendFetch(
    backendURL(url),
    "POST",
    acceptJson(tokenHeader({}, token)),
    formData(data)
  ).then(toStatus);
};

const patchMultipart = (token, url, data) => {
  return backendFetch(
    backendURL(url),
    "PATCH",
    acceptJson(tokenHeader({}, token)),
    formData(data)
  ).then(toStatus);
};

const postJson = (token, url, data) =>
  backendFetch(
    backendURL(url),
    "POST",
    jsonHeader(tokenHeader({}, token)),
    JSON.stringify(data)
  ).then(toStatus);

const putJson = (token, url, data) =>
  backendFetch(
    backendURL(url),
    "PUT",
    jsonHeader(tokenHeader({}, token)),
    JSON.stringify(data)
  ).then(toStatus);

const patchJson = (token, url, data) =>
  backendFetch(
    backendURL(url),
    "PATCH",
    jsonHeader(tokenHeader({}, token)),
    JSON.stringify(data)
  ).then(toStatus);

const deleteJson = (token, url) =>
  backendFetch(
    backendURL(url),
    "DELETE",
    jsonHeader(tokenHeader({}, token))
  ).then(toStatus);

const buildQuery = (data) => {
  if (!data) return "";
  if (typeof data === "string") return data;
  const query = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      query.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
    }
  }
  if (query.length === 0) return "";
  return "?" + query.join("&");
};

export const apiGetNextJson = (token, next) => getJson_DIRECT_URL(token, next);
export const apiLogin = (data) => postJson(undefined, "v1/me/login/", data);

export const apiGetCombinedUserData = (token) =>
  getJson(token, "v1/me/combined_data/");

export const apiChangePassword = (token, data) =>
  putJson(token, "v1/me/change_password/", data);

export function apiGetHierarchy(token, params) {
  return getJson(token, `v1/hierarchy/${buildQuery(params)}`);
}

export function apiGetHierarchyStatus(token) {
  return getJson(token, "v1/hierarchy_status/");
}

// export function apiGetLocations(token) {
//   return getJson(token, "v1/locations/");
// }

export function apiCreateLocation(token, data) {
  return postJson(token, "v1/locations/", data);
}

export function apiUpdateLocation(token, id, data) {
  return putJson(token, `v1/locations/${id}/`, data);
}

export function apiDeleteLocation(token, id) {
  return deleteJson(token, `v1/locations/${id}/`);
}

export function apiGetMachineGroups(token) {
  return getJson(token, "v1/machine_groups/");
}

export function apiCreateMachineGroup(token, data) {
  return postJson(token, "v1/machine_groups/", data);
}

export function apiUpdateMachineGroup(token, id, data) {
  return putJson(token, `v1/machine_groups/${id}/`, data);
}

export function apiUpdateMachineGroupImages(token, id, data) {
  return patchMultipart(token, `v1/machine_groups/${id}/`, data);
}

export function apiDeleteMachineGroup(token, id) {
  return deleteJson(token, `v1/machine_groups/${id}/`);
}

export function apiGetMachines(token) {
  return getJson(token, "v1/machines/");
}

export function apiCreateMachine(token, data) {
  return postJson(token, "v1/machines/", data);
}

export function apiUpdateMachine(token, id, data) {
  return putJson(token, `v1/machines/${id}/`, data);
}

export function apiDeleteMachine(token, id) {
  return deleteJson(token, `v1/machines/${id}/`);
}

export function apiGetSensors(token) {
  return getJson(token, "v1/sensors/");
}

export function apiCreateSensor(token, data) {
  return postJson(token, "v1/sensors/", data);
}

export function apiGetSensor(token, id) {
  return getJson(token, `v1/sensors/${id}/`);
}

export function apiUpdateSensor(token, id, data) {
  return putJson(token, `v1/sensors/${id}/`, data);
}

export function apiDeleteSensor(token, id) {
  return deleteJson(token, `v1/sensors/${id}/`);
}

// export function apiGetCustomReferences(token) {
//   return getJson(token, "v1/custom_references/");
// }

export function apiCreateCustomReference(token, data) {
  return postJson(token, "v1/custom_references/", data);
}

export function apiGetCustomReference(token, id) {
  return getJson(token, `v1/custom_references/${id}/`);
}

export function apiUpdateCustomReference(token, id, data) {
  return putJson(token, `v1/custom_references/${id}/`, data);
}

export function apiDeleteCustomReference(token, id) {
  return deleteJson(token, `v1/custom_references/${id}/`);
}

// export function apiGetMachineCustomReferences(token) {
//   return getJson(token, "v1/machine_custom_references/");
// }

export function apiCreateMachineCustomReference(token, data) {
  return postJson(token, "v1/machine_custom_references/", data);
}

export function apiGetMachineCustomReference(token, id) {
  return getJson(token, `v1/machine_custom_references/${id}/`);
}

export function apiUpdateMachineCustomReference(token, id, data) {
  return putJson(token, `v1/machine_custom_references/${id}/`, data);
}

export function apiDeleteMachineCustomReference(token, id) {
  return deleteJson(token, `v1/machine_custom_references/${id}/`);
}

export function apiGetSensorGroups(token, params) {
  return getJson(token, `v1/sensor_groups/${buildQuery(params)}`);
}

export const apiGetSensorGroup = (token, id) =>
  getJson(token, `v1/sensor_groups/${id}/`);

export const apiCreateSensorGroup = (token, data) =>
  postJson(token, "v1/sensor_groups/", data);

export const apiUpdateSensorGroup = (token, id, data) =>
  putJson(token, `v1/sensor_groups/${id}/`, data);

export function apiDeleteSensorGroup(token, id) {
  return deleteJson(token, `v1/sensor_groups/${id}/`);
}

export function apiGetMachineLogItems(token, params) {
  return getJson(token, `v1/machine_logs/${buildQuery(params)}`);
}

export function apiCreateMachineLogEntry(token, data) {
  return postMultipart(token, "v1/log_entries/", data);
}

export function apiGetMachineLogEntry(token, id) {
  return getJson(token, `v1/log_entries/${id}/`);
}

export function apiModifyMachineLogEntry(token, id, data) {
  return patchMultipart(token, `v1/log_entries/${id}/`, data);
}

export function apiDeleteMachineLogEntry(token, id) {
  return deleteJson(token, `v1/log_entries/${id}/`);
}

export const apiGetTasks = (token, params) =>
  getJson(token, `v1/tasks/${buildQuery(params)}`);

export const apiGetTask = (token, id) => getJson(token, `v1/tasks/${id}/`);

export const apiCreateTask = (token, data) =>
  postJson(token, "v1/tasks/", data);

export function apiDeleteTask(token, id) {
  return deleteJson(token, `v1/tasks/${id}/`);
}

export const apiSubmitTask = (token, id) =>
  postJson(token, `v1/tasks/${id}/submit/`, {});

export const apiRestartTask = (token, id) =>
  postJson(token, `v1/tasks/${id}/restart/`, {});

export const apiArchiveTask = (token, id) =>
  postJson(token, `v1/tasks/${id}/archive/`, {});

export const apiCreateTaskFile = (token, data) =>
  postMultipart(token, "v1/task_files/", data);

export function apiGetTaskFiles(token, params) {
  return getJson(token, `v1/task_files/${buildQuery(params)}`);
}

export function apiGetTaskFileData(token, id, index = 0, raw = false) {
  return getJson(
    token,
    `v1/task_files/${id}/data/?index=${index}${raw ? "&raw=1" : ""}`
  );
}

export const apiGetLabels = (token) => getJson(token, "v1/labels/");

export const apiCreateLabel = (token, data) =>
  postJson(token, "v1/labels/", data);

export function apiDeleteLabel(token, id) {
  return deleteJson(token, `v1/labels/${id}/`);
}

// export function apiGetDiagnoses(token, params) {
//   return getJson(token, `v1/diagnoses/${buildQuery(params)}`);
// }

// export function apiCreateDiagnosis(token, data) {
//   return postJson(token, "v1/diagnoses/", data);
// }

// export const apiGetDiagnosis = (token, id) =>
//   getJson(token, `v1/diagnoses/${id}/`);

export function apiGetAnalyses(token, params) {
  return getJson(token, `v1/analyses/${buildQuery(params)}`);
}

export function apiGetAnalysis(token, id) {
  return getJson(token, `v1/analyses/${id}/`);
}

export const apiGetLinkedAnalysis = (token, id) =>
  getJson(token, `v1/analyses/${id}/linked/`);

export function apiReviseDiagnosis(token, id, data) {
  return patchJson(token, `v1/diagnoses/${id}/revise/`, data);
}

export function apiGetSpectrums(token, params) {
  return getJson(token, `v1/spectrums/${buildQuery(params)}`);
}

export const apiGetAll = async (token, func, next, query = undefined) => {
  let results = [];
  let r = await func(token, query);
  results = results.concat(r.results);
  while (r.next) {
    r = await next(token, r.next);
    results = results.concat(r.results);
  }
  return results;
};

export const apiGetModifiedTarFile = (token, { task_file }) => {
  return backendFetch(
    backendURL("v1/export_modified_tar?task_file=" + task_file),
    "GET",
    tokenHeader({}, token)
  ).then(resp => resp.blob()
  ).then(blob => {
    // "hack" to download using ajax
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = task_file + '_new_hierarchy.tar';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  })
}
