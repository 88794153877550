import React from "react";
import { List } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { locationTo, machineGroupTo, machineTo, sensorTo } from "./appRoutes";
import {
  FactorySVG,
  MachineGroupSVG,
  MotorIcon,
  SensorIcon,

} from "./icons";

import { locationPath } from "slices/hierarchySlice";
import { selectEntities as selectLocations } from "slices/locationsSlice";
import { selectEntities as selectMachineGroups } from "slices/machineGroupsSlice";
import { selectEntities as selectMachines } from "slices/machinesSlice";

function Path({ locationPath, machineGroup, machine, sensor }) {
  const rrLocation = useLocation();
  const [location, ...rest] = locationPath;
  return (
    <List.Item>
      <List.Content className="SensorFolderPath__content">
        <List.Header>
          <FactorySVG/>
          <Link to={locationTo(location.id)}>{location.name}</Link>
        </List.Header>
        {location.description && (
          <List.Description>{location.description}</List.Description>
        )}
        {rest.length > 0 && (
          <List.List className="SensorFolderPath__sublist">
            <Path
              locationPath={rest}
              machineGroup={machineGroup}
              machine={machine}
              sensor={sensor}
            />
          </List.List>
        )}
        {rest.length === 0 && (
          <List.List className="SensorFolderPath__sublist">
            <List.Item>
              <List.Content className="SensorFolderPath__content">
                <List.Header>
                  <Link to={machineGroupTo(machineGroup.id, rrLocation)}>
                    <MachineGroupSVG/>
                    {machineGroup.name}
                  </Link>
                </List.Header>
                {machineGroup.description && (
                  <List.Description>
                    {machineGroup.description}
                  </List.Description>
                )}
                <List.List className="SensorFolderPath__sublist">
                  <List.Item>
                    <List.Content className="SensorFolderPath__content">
                      <List.Header>
                        <Link as={Link} to={machineTo(machine.id, rrLocation)}>
                          <MotorIcon/>
                          {machine.name}
                        </Link>
                      </List.Header>
                      {machine.description && (
                        <List.Description>
                          {machine.description}
                        </List.Description>
                      )}
                      <List.List className="SensorFolderPath__sublist">
                        <List.Item>
                          <List.Content className="SensorFolderPath__content">
                            <List.Header>
                              <Link
                                as={Link}
                                to={sensorTo(sensor.id, rrLocation)}
                              >
                                <SensorIcon/>
                                {sensor.name}
                              </Link>
                            </List.Header>
                            {sensor.description && (
                              <List.Description>
                                {sensor.description}
                              </List.Description>
                            )}
                          </List.Content>
                        </List.Item>
                      </List.List>
                    </List.Content>
                  </List.Item>
                </List.List>
              </List.Content>
            </List.Item>
          </List.List>
        )}
      </List.Content>
    </List.Item>
  );
}

function SensorFolderPath({ sensor }) {
  const state = useSelector((state) => state);

  const machine = selectMachines(state)[sensor?.machine];
  const machineGroup = selectMachineGroups(state)[machine?.machine_group];
  const locations = selectLocations(state);
  const location = locations[machineGroup?.location];
  const path = locationPath(location, locations);

  return (
    <List className="SensorFolderPath">
      <Path
        locationPath={path}
        machineGroup={machineGroup}
        machine={machine}
        sensor={sensor}
      />
    </List>
  );
}

export default SensorFolderPath;
