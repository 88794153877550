import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  createMigrate,
  persistStore,
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "reducers";

/* clearing persisted state when moving from version 0 upwards */
const migrations = {
  0: (state) => {
    return state;
  },
  1: (/* state */) => {
    return {};
  },
};

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  whitelist: ["token", "userData"],
  migrate: createMigrate(migrations, { debug: false }),
};

export default function configureAppStore(preloadedState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
  });
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => {
      console.log("[HMR] Reloading reducers");
      const nextRootReducer = require("./reducers").default;
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return { store, persistor: persistStore(store) };
}
