import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { apiCreateSensor, apiUpdateSensor, apiDeleteSensor } from "api";
import { selectToken } from "slices/tokenSlice";
import {
  startOngoing,
  endOngoing,
  batchSuccess,
  batchFailure,
} from "slices/shared";

const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const sensors = createSlice({
  name: "sensors",
  initialState: adapter.getInitialState({
    ongoing: {},
  }),

  reducers: {
    start: startOngoing,
    end: endOngoing,
    set(state, { payload }) {
      adapter.setAll(state, payload);
    },
    add(state, { payload }) {
      adapter.addOne(state, payload);
    },
    update(state, { payload }) {
      adapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
    },
    remove(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    upsertMany(state, { payload }) {
      adapter.upsertMany(state, payload);
    },
    removeMany(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
});

export default sensors.reducer;

const {
  start,
  end,
  set,
  add,
  update,
  remove,
  upsertMany,
  removeMany,
} = sensors.actions;

export { set, upsertMany, removeMany };

const selectState = (state) => state.sensors;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectState);

export const selectOngoing = (state, key) => !!selectState(state).ongoing[key];

export const selectReference = (state, id, index = 0) => {
  const sensor = selectEntities(state)[id];
  const r = sensor?.custom_references;
  return (r?.length > index && r[index]) || undefined;
};

export const addSensor = (data, options) => async (dispatch, getState) => {
  try {
    dispatch(start("addSensor"));
    const sensor = await apiCreateSensor(selectToken(getState()), data);
    batchSuccess(dispatch, options, add(sensor), end("addSensor"));
    return sensor;
  } catch (error) {
    batchFailure(dispatch, error, options, end("addSensor"));
    throw error;
  }
};

export const updateSensor = (id, data, options) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(start("updateSensor"));
    const sensor = await apiUpdateSensor(selectToken(getState()), id, data);
    batchSuccess(dispatch, options, update(sensor), end("updateSensor"));
    return sensor;
  } catch (error) {
    batchFailure(dispatch, error, options, end("updateSensor"));
    throw error;
  }
};

export const removeSensor = (id, options) => async (dispatch, getState) => {
  try {
    dispatch(start("removeSensor"));
    await apiDeleteSensor(selectToken(getState()), id);
    batchSuccess(dispatch, options, remove(id), end("removeSensor"));
  } catch (error) {
    batchFailure(dispatch, error, options, end("removeSensor"));
    throw error;
  }
};
