import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import NextLoader from "NextLoader";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectServiceSchema,
  schemaKey,
  selectTranslation,
  translationKey,
  hasCapability,
  CAN_IMPORT,
  CAN_MANAGE_SENSOR_GROUPS,
  CAN_MANAGE_LABELS,
} from "slices/userDataSlice";
import {
  selectOngoing as selectSensorGroupsOngoing,
  selectAll as selectSensorGroups,
  getAllSensorGroups,
  addSensorGroup,
} from "slices/sensorGroupsSlice";
import {
  selectLabelsOngoing,
  selectAll as selectLabels,
  selectLabelsNext,
  getLabels,
  getNextLabels,
  addLabel,
} from "slices/labelsSlice";
import {
  selectTasksOngoing,
  selectTasksNext,
  addTask,
} from "slices/tasksSlice";

import { redirectTo } from "utils";
import { tasksTo } from "appRoutes";
import { ATTACH_ICON, DROPDOWN_ICON, toIcon } from "icons";

const SENSOR_GROUPS = "DataImporter/sensorGroups";
const LABELS = "DataImporter/labels";
const TASKS = "DataImporter/tasks";

class DataImporter extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      ...this.initialEditState(),
    });

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    const { getAllSensorGroups, getLabels, t } = this.props;

    getAllSensorGroups(
      opts(SENSOR_GROUPS, t("DataImporter.sensorGroupsError"))
    ).catch((e) => {
      console.error(e);
    });

    getLabels(opts(LABELS, t("DataImporter.labelsError"))).catch((e) => {
      console.error(e);
    });
  };

  initialEditState = () => {
    return {
      files: [],
      fileFormat: undefined,
      sensorGroup: undefined,
      label: undefined,
    };
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(SENSOR_GROUPS, LABELS, TASKS);
    }
  };

  handleFileInputClicked = () => {
    this.fileInputRef.current.click();
  };

  handleChangeFiles = (e) => {
    const { files } = e.target;
    this.clearError("files");
    this.clearServiceErrors();
    this.setState({
      files: Array.from(files?.length > 0 ? files : []),
    });
  };

  handleChange = (_, { name, value }) => {
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleAddSensorGroup = (_, { value: name }) => {
    const { addSensorGroup, t } = this.props;
    addSensorGroup(
      { name },
      opts(SENSOR_GROUPS, t("DataImporter.addSensorGroupError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  handleAddLabel = (_, { value: name }) => {
    const { addLabel, t } = this.props;
    addLabel({ name }, opts(LABELS, t("DataImporter.addLabelError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  handleSubmit = () => {
    const { sensorGroups, addTask, history, location, t } = this.props;
    const {
      files,
      fileFormat: data_file_format,
      sensorGroup,
      label,
      hierarchyRoot: hierarchy_root,
    } = this.state;

    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const sensor_group = sensorGroups.find((g) => g.name === sensorGroup)?.id;

    const errors = [];
    if (sensorGroup && !sensor_group) {
      errors.push("sensorGroup");
    }
    if (errors.length > 0) {
      return this.setErrors({
        header: t("DataImporter.validationError"),
        errors,
      });
    }

    this.fileInputRef.current.value = "";
    this.setState({
      files: [],
    });

    addTask(
      {
        data_file_format,
        sensor_group,
        label,
        data: {
          hierarchy_root,
        },
      },
      files,
      opts(TASKS, t("DataImporter.addTaskError"))
    )
      .then(() => redirectTo(tasksTo(location), history))
      .catch((e) => {
        console.error(e);
      });
  };

  fileFormatOptions = memoizeOne((fileFormats, translation) =>
    fileFormats.map((f) => ({
      key: f,
      value: f,
      text: translationKey(translation, "task_data_file_formats")[f],
    }))
  );

  sensorGroupOptions = memoizeOne((sensorGroups) =>
    sensorGroups.map((g) => ({
      key: g.id,
      value: g.name,
      text: g.name,
      "data-cy": "DataImporter__sensorGroup",
    }))
  );

  getNextLabels = () => {
    const { getNextLabels, t } = this.props;
    getNextLabels(opts(LABELS, t("DataImporter.moreLabelsError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  moreLabels = () => {
    const { labelsNext, t } = this.props;
    return labelsNext
      ? [
          {
            key: labelsNext,
            as: NextLoader,
            loadNext: this.getNextLabels.bind(this),
            more: (
              <p className="DataImporter__more_labels">
                {t("DataImporter.moreLabels")}
              </p>
            ),
            triggerOnce: true,
          },
        ]
      : [];
  };

  labelOptions = memoizeOne((labels, _labelsNext) =>
    labels
      .map((l) => ({
        key: l.id,
        value: l.name,
        text: l.name,
        "data-cy": "DataImporter__label",
      }))
      .concat(this.moreLabels())
  );

  filesLabel = (files, _language) => {
    const { t } = this.props;
    if (files.length === 1) {
      return t("DataImporter.selectedFile", { name: files[0].name });
    } else {
      return t("DataImporter.selectedFiles", {
        name: files[0].name,
        count: files.length - 1,
      });
    }
  };

  render() {
    const {
      serviceError,
      sensorGroups,
      getAllSensorGroupsOngoing,
      addSensorGroupOngoing,
      labels,
      labelsNext,
      getLabelsOngoing,
      addLabelOngoing,
      addTaskOngoing,
      canImport,
      canAddSensorGroup,
      canAddLabel,
      fileFormats,
      translation,
      i18n: { language },
      t,
    } = this.props;
    const { files, fileFormat, sensorGroup, label } = this.state;

    if (!canImport) {
      return null;
    }

    const loading = addTaskOngoing;
    const loadingSensorGroups =
      getAllSensorGroupsOngoing || addSensorGroupOngoing;
    const loadingLabels = getLabelsOngoing || addLabelOngoing;

    const fileFormatOptions = this.fileFormatOptions(fileFormats, translation);
    const sensorGroupOptions = this.sensorGroupOptions(sensorGroups);
    const labelOptions = this.labelOptions(labels, labelsNext);

    const hasFiles = files.length > 0;
    const filesLabel = hasFiles && this.filesLabel(files, language);
    const canSubmit = hasFiles && fileFormat;

    return (
      <Segment
        as="div"
        className="DataImporter"
        loading={loading}
        data-cy="DataImporter"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
          <Form.Group grouped>
            <Form.Field required error={this.hasError("files")}>
              <label>{t("DataImporter.files")}</label>
              {hasFiles && (
                <div>
                  <label
                    className="DataImporter__attachment"
                    data-cy="DataImporter__attachment"
                  >
                    {filesLabel}
                  </label>
                </div>
              )}
              <Button
                type="button"
                onClick={this.handleFileInputClicked}
                data-cy="DataImporter__fileButton"
              >
                {toIcon(ATTACH_ICON)}
                {hasFiles
                  ? t("DataImporter.changeFiles")
                  : t("DataImporter.selectFiles")}
              </Button>
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                multiple
                onChange={this.handleChangeFiles}
                data-cy="DataImporter__fileInput"
              />
            </Form.Field>
            <Form.Field required error={this.hasError("fileFormat")}>
              <label>{t("DataImporter.fileFormat")}</label>
              <Form.Dropdown
                name="fileFormat"
                placeholder={t("DataImporter.fileFormatPlaceholder")}
                fluid
                search
                selection
                icon={toIcon(DROPDOWN_ICON)}
                options={fileFormatOptions}
                value={fileFormat}
                onChange={this.handleChange}
                noResultsMessage={t("DataImporter.noFileFormats")}
                data-cy="DataImporter__formats"
              />
            </Form.Field>
            <Form.Field error={this.hasError("sensorGroup")}>
              <label>{t("DataImporter.sensorGroup")}</label>
              <Form.Dropdown
                name="sensorGroup"
                placeholder={t("DataImporter.sensorGroupPlaceholder")}
                fluid
                search
                selection
                clearable
                loading={loadingSensorGroups}
                allowAdditions={canAddSensorGroup}
                icon={toIcon(DROPDOWN_ICON)}
                options={sensorGroupOptions}
                value={sensorGroup}
                additionLabel={t("DataImporter.addSensorGroup")}
                onAddItem={this.handleAddSensorGroup}
                onChange={this.handleChange}
                data-cy="DataImporter__sensorGroups"
              />
            </Form.Field>
            <Form.Field error={this.hasError("label")}>
              <label>{t("DataImporter.label")}</label>
              <Form.Dropdown
                name="label"
                placeholder={t("DataImporter.labelPlaceholder")}
                fluid
                search
                selection
                clearable
                loading={loadingLabels}
                allowAdditions={canAddLabel}
                icon={toIcon(DROPDOWN_ICON)}
                options={labelOptions}
                value={label}
                additionLabel={t("DataImporter.addLabel")}
                onAddItem={this.handleAddLabel}
                onChange={this.handleChange}
                data-cy="DataImporter__labels"
              />
            </Form.Field>
            <div className="DataImporter__buttons">
              <Form.Button
                className="DataImporter__submit"
                type="submit"
                primary
                disabled={!canSubmit}
                data-cy="DataImporter__analyze"
              >
                {t("DataImporter.submit")}
              </Form.Button>
            </div>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const schema = selectServiceSchema(state);
  const translation = selectTranslation(state, language);

  return {
    serviceError: selectServiceError(state, SENSOR_GROUPS, LABELS, TASKS),
    sensorGroups: selectSensorGroups(state),
    getAllSensorGroupsOngoing: selectSensorGroupsOngoing(
      state,
      "getAllSensorGroups"
    ),
    addSensorGroupOngoing: selectSensorGroupsOngoing(state, "addSensorGroup"),
    labels: selectLabels(state),
    labelsNext: selectLabelsNext(state),
    getLabelsOngoing: selectLabelsOngoing(state, "getLabels"),
    getNextLabelsOngoing: selectLabelsOngoing(state, "getNextLabels"),
    addLabelOngoing: selectLabelsOngoing(state, "addLabel"),
    tasksNext: selectTasksNext(state),
    addTaskOngoing: selectTasksOngoing(state, "addTask"),
    canImport: hasCapability(state, CAN_IMPORT),
    canAddSensorGroup: hasCapability(state, CAN_MANAGE_SENSOR_GROUPS),
    canAddLabel: hasCapability(state, CAN_MANAGE_LABELS),
    fileFormats: schemaKey(schema, "task_data_formats"),
    translation,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  getAllSensorGroups,
  addSensorGroup,
  getLabels,
  getNextLabels,
  addLabel,
  addTask,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DataImporter))
);
