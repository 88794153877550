import React from "react";
import { Divider, List } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import {
  selectServiceSchema,
  selectTranslation,
  schemaKey,
  translationKey,
} from "slices/userDataSlice";
import { toIcon, SELECT_ICON, SELECTED_ICON, UNSELECTED_FLAG, CIRCLE_ICON, VALIDATED_ICON } from "icons";

export function categoryColor(category) {
  return {
    Acceptable: "green",
    Warning: "yellow",
    Danger: "red",
  }[category] || "grey"
}

const filters = memoizeOne((schema, translation) =>
  schemaKey(schema, "diagnosis_categories").map((c) => {

    return {
      key: c,
      text: (
        <span className="DiagnosisFilter">
          {toIcon([{ ...CIRCLE_ICON[0], color: categoryColor(c), size: 'small' }])}
          {translationKey(translation, "diagnosis_categories")[c]}
        </span>
      ),
      value: c,
    };
  })
);

const filterItems = memoizeOne((excluded, onExclude, schema, translation) =>
  filters(schema, translation).map((f) => {
    const selected = !excluded[f.value];
    return (
      <List.Item key={f.key} as="a" active={selected} value={f.value} onClick={onExclude}>
        {toIcon(selected ? SELECTED_ICON : SELECT_ICON)}
        <List.Content>
          <List.Header>
            {f.text}
          </List.Header>
        </List.Content>
      </List.Item>
    );
  })
);

function DiagnosisFilter({ excluded, onExclude, ...rest }) {
  const { i18n: { language } } = useTranslation();

  const schema = useSelector((state) => selectServiceSchema(state));
  const translation = useSelector((state) =>
    selectTranslation(state, language)
  );

  const items = filterItems(excluded, onExclude, schema, translation);

  // Hacky, we want the "include flagged only" false by default so use excluded as an included .-.
  const flaggedOnly = excluded[FLAGGED_ONLY_NAME]
  const validatedOnly = excluded[VALIDATED_ONLY_NAME]

  return (
    <List className="DiagnosisFilter">
      {items}
      <Divider style={{ width: '50%' }}/>
      <List.Item as="a" active={flaggedOnly} value={FLAGGED_ONLY_NAME} onClick={onExclude}>
        {toIcon(flaggedOnly ? SELECTED_ICON : SELECT_ICON)}
        <List.Content>
          <List.Header>
            {toIcon(UNSELECTED_FLAG)}
            Flagged Only
          </List.Header>
        </List.Content>
      </List.Item>
      <List.Item as="a" active={validatedOnly} value={VALIDATED_ONLY_NAME} onClick={onExclude}>
        {toIcon(validatedOnly ? SELECTED_ICON : SELECT_ICON)}
        <List.Content>
          <List.Header>
            {toIcon(VALIDATED_ICON)}
            Validated Only
          </List.Header>
        </List.Content>
      </List.Item>
    </List>
  );
}

export const FLAGGED_ONLY_NAME = "temp_flag"
export const VALIDATED_ONLY_NAME = "validated_only"

export default DiagnosisFilter;
