import React, { useRef, useMemo, useEffect } from "react";

import { select } from "d3-selection";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleLinear } from "d3-scale";
import { max } from "d3-array";
import { line } from "d3-shape";

const WIDTH = 640;
const HEIGHT = 480;
const MARGIN = { left: 60, top: 40, right: 50, bottom: 60 };
const TITLE_OFFSET = 20;
const X_LABEL_OFFSET = HEIGHT - 15;
const Y_LABEL_OFFSET = 2;
const TEXT_PADDING_ONE = { x: 0, y: 20 };
const TEXT_PADDING = { x: 0, y: -10 };

function Trend({ keyFigure, data, title, xLabel, yLabel }) {
  const ref = useRef();

  // console.log("TREND:", keyFigure);
  // console.log("TREND:", JSON.stringify(data, null, 2));

  const xScale = useMemo(
    () =>
      scaleLinear()
        .domain([-1, max(data, (d) => d.days) + 1])
        .nice()
        .range([MARGIN.left, WIDTH - MARGIN.right]),
    [data]
  );

  const yScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, max(data, (d) => d[keyFigure]) * 1.2])
        .nice()
        .range([HEIGHT - MARGIN.bottom, MARGIN.top]),
    [data, keyFigure]
  );

  const xAxis = useMemo(
    () => (g) =>
      g.attr("transform", `translate(0,${HEIGHT - MARGIN.bottom})`).call(
        axisBottom(xScale)
          .ticks(WIDTH / 80)
          .tickSizeOuter(0)
          .tickFormat((d) => {
            if (d < 0) return "";
            if (Math.round(d) !== d) return "";
            return d;
          })
      ),
    [xScale]
  );

  const yAxis = useMemo(
    () => (g) =>
      g.attr("transform", `translate(${MARGIN.left},0)`).call(axisLeft(yScale)),
    [yScale]
  );

  const kfLine = useMemo(
    () =>
      line()
        .defined((d) => !isNaN(d[keyFigure]))
        .x((d) => xScale(d.days))
        .y((d) => yScale(d[keyFigure])),
    [keyFigure, xScale, yScale]
  );

  useEffect(() => {
    const svg = select(ref.current).style(
      "-webkit-tap-highlight-color",
      "transparent"
    );
    svg.selectAll("*").remove();

    svg
      .append("g")
      // .selectAll(".Trend--xaxis")
      .call(xAxis);
    // .attr("class", "Trend--xaxis");

    svg
      .append("g")
      // .selectAll(".Trend--yaxis")
      .call(yAxis);
    // .attr("class", "Trend--yaxis");

    svg
      .append("g")
      // .selectAll(".Trend--title")
      .append("text")
      // .attr("class", "Trend--title")
      .attr("transform", `translate(${WIDTH / 2},${TITLE_OFFSET})`)
      .style("text-anchor", "middle")
      .text(title);

    svg
      .append("g")
      // .selectAll(".Trend--axis")
      .append("text")
      // .attr("class", "Trend--axis")
      .attr("transform", `translate(${WIDTH / 2},${X_LABEL_OFFSET})`)
      .style("text-anchor", "middle")
      .text(xLabel);

    svg
      .append("g")
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", Y_LABEL_OFFSET)
      .attr("x", -(HEIGHT / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(yLabel);

    svg
      .append("g")
      // .selectAll("path")
      .append("path")
      .datum(data)
      // .data(data)
      // .attr("class", "Trend--path")
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", kfLine);

    svg
      .append("g")
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      // .enter()
      // .attr("class", "Trend--marker")
      .attr("r", 4)
      .attr("cx", (d) => xScale(d.days))
      .attr("cy", (d) => yScale(d[keyFigure]))
      .attr("fill", "steelblue");

    const padding = data?.length > 1 ? TEXT_PADDING : TEXT_PADDING_ONE;

    svg
      .append("g")
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      // .attr("class", "Trend--labels")
      .attr("text-anchor", "middle")
      .attr("x", (d) => xScale(d.days) + padding.x)
      .attr("y", (d) => yScale(d[keyFigure]) + padding.y)
      .text((d) => d[keyFigure].toFixed(2));
  }, [
    data,
    keyFigure,
    title,
    xLabel,
    yLabel,
    kfLine,
    xAxis,
    yAxis,
    xScale,
    yScale,
  ]);

  return (
    <svg
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      ref={ref}
      // style={{
      //   border: "1px solid black",
      // }}
    />
  );
}

export default Trend;
