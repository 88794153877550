import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import AdaComponent from "AdaComponent";
import InventoryList from "InventoryList";

import {
  selectServiceError,
  clearServiceErrors,
  opts,
} from "slices/serviceErrorsSlice";
import { hasCapability, CAN_MANAGE_MACHINES } from "slices/userDataSlice";
import {
  selectOngoing as selectHierarchyOngoing,
  getHierarchy,
} from "slices/hierarchySlice";
import { selectAll as selectMachines } from "slices/machinesSlice";

import { goTo } from "utils";
import { newMachineTo, machineTo } from "appRoutes";
import { MACHINE_ICON, toIcon } from "icons";

const HIERARCHY = "Machines/hierarchy";

class Machines extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    const { hasHierarchy } = this.props;
    if (!hasHierarchy) {
      this.refresh();
    }
  }

  refresh = (reload = false) => {
    const { getHierarchy, t } = this.props;
    getHierarchy(reload, opts(HIERARCHY, t("Machines.refreshError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(HIERARCHY);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { location, history } = this.props;
    goTo(newMachineTo(0, location), history);
  };

  selectTo = (id) => {
    const { location } = this.props;
    return machineTo(id, location);
  };

  render() {
    const {
      serviceError,
      getHierarchyOngoing,
      canEdit,
      machines,
      t,
      i18n: { language },
    } = this.props;

    const loading = getHierarchyOngoing;

    return (
      <Segment
        as="div"
        className="Machines"
        loading={loading}
        data-cy="Machines"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <InventoryList
          items={machines}
          icon={toIcon(MACHINE_ICON)}
          onRefresh={this.handleRefresh}
          refreshText={t("Machines.refresh")}
          onAdd={canEdit && this.handleAdd}
          addText={t("Machines.add")}
          selectTo={this.selectTo}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceError: selectServiceError(state, HIERARCHY),
  getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
  canEdit: hasCapability(state, CAN_MANAGE_MACHINES),
  machines: selectMachines(state),
});

const mapDispatchToProps = {
  getHierarchy,
  clearServiceErrors,
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Machines))
);
