import React from "react";
import { Button, Modal } from "semantic-ui-react";

export default function Prompt({
  open,
  header,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
}) {
  return (
    <Modal size="mini" open={open} onClose={onCancel} dimmer="inverted">
      <Modal.Header>{header}</Modal.Header>
      {content && <Modal.Content>{content}</Modal.Content>}
      <Modal.Actions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button positive onClick={onOk}>
          {okText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
