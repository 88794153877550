import React, { Component } from "react";
import { Message } from "semantic-ui-react";
import memoizeOne from "memoize-one";

import { ERROR_ICON, toIcon } from "icons";

const errorIcon = toIcon(ERROR_ICON);
const errorMessageData = (header, content, icon = errorIcon) => ({
  error: true,
  icon,
  header,
  content,
});

export default class AdaComponent extends Component {
  setInitialState(obj, init) {
    const errors = new Set();
    let message;
    if (init && init.errors) {
      init.errors.forEach((e) => errors.add(e));
      message = errorMessageData(init.header, init.content);
    }

    // eslint-disable-next-line
    this.state = {
      ...obj,
      errors,
      message,
    };
  }

  // errordef(domain, operation) {
  //   return {
  //     domain,
  //     operation
  //   };
  // }

  // componentWillUnmount() {
  //   this.__unmounted = true;
  // }

  // ifMounted(f) {
  //   if (!this.__unmounted) {
  //     f();
  //   }
  // }

  setErrors({ header, content, errors, extra = {} }) {
    const es = new Set();
    if (errors) {
      errors.forEach((e) => es.add(e));
    }
    this.setState({
      errors: es,
      message: errorMessageData(header, content),
      ...extra,
    });
  }

  clearError(key) {
    this.setState(({ errors }) => {
      if (errors.has(key)) {
        const newErrors = new Set(errors);
        newErrors.delete(key);
        return {
          errors: newErrors,
          message: undefined,
        };
      }
      return {};
    });
  }

  clearErrors() {
    this.setState({
      errors: new Set(),
      message: undefined,
    });
  }

  hasError(key) {
    return this.state.errors.has(key);
  }

  hasErrors(...other) {
    return this.state.errors.size !== 0 || other.length > 0;
  }

  ErrorMessage() {
    const { message } = this.state;
    return (
      (message && (
        <Message
          key={"Error"}
          className="AdaComponent__errorMessage"
          {...message}
          data-cy="AdaComponent__error"
        />
      )) ||
      null
    );
  }

  ServiceErrorMessage = memoizeOne(
    (serviceError, _lng, category = "ServiceErrors") => {
      if (!serviceError) {
        return null;
      }
      const { t } = this.props;
      const { message, error } = serviceError;
      const raw =
        error.keys && error.keys.map((k) => `${k}: ${error.error[k]}`).join();
      const content = t(
        [`${category}.${error.status}`, `${category}.unspecific`],
        {
          raw,
        }
      );
      return (
        <Message
          key={"ServiceError"}
          className="AdaComponent__serviceErrorMessage"
          {...errorMessageData(message, content)}
          data-cy="AdaComponent__serviceError"
        />
      );
    }
  );
}
