import React, { Fragment } from "react";
import { Breadcrumb } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { locationPath } from "slices/hierarchySlice";
import { selectEntities as selectLocations } from "slices/locationsSlice";
import { selectEntities as selectMachineGroups } from "slices/machineGroupsSlice";
import { selectAll as selectAnalyses } from "slices/analysesSlice";

// How many analyses to include when clicking the group analyses link in the machine path
const MAX_GROUP_ANALYSES = 3

function MachinePath({ machine, sensor }) {
  const locations = useSelector(selectLocations);
  const machineGroups = useSelector(selectMachineGroups);
  const analyses = useSelector(selectAnalyses);

  const machineGroup = machineGroups[machine?.machine_group];
  const location = locations[machineGroup?.location];
  const groupAnalyses = analyses
    ?.filter(it => machineGroup?.machines?.includes(it.machine))
    ?.map(it => it.id)
    ?.slice(0, MAX_GROUP_ANALYSES)


  const path = location && locationPath(location, locations);
  const { t } = useTranslation();

  return (
    <Breadcrumb className="MachinePath">
      {path
        ? path.map((p) => (
          <Fragment key={"patha" + p.id}>
            <Breadcrumb.Divider key={"pathb" + p.id}/>
            <Breadcrumb.Section key={"pathc" + p.id} content={p.name}/>
          </Fragment>
        ))
        : t("MachinePath.unknownPath")}
      <Breadcrumb.Divider/>
      {machineGroup ? (
        <Breadcrumb.Section
          key={"machineGroup" + machineGroup.id}
          content={machineGroup.name}
          href={groupAnalyses?.length && "/group-analyses?analyses=" + groupAnalyses?.join(',')}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        t("MachinePath.unknownMachineGroup")
      )}
      <Breadcrumb.Divider/>
      {machine ? (
        <Breadcrumb.Section
          key={"machine" + machine.id}
          content={machine.name}
        />
      ) : (
        t("MachinePath.unknownMachine")
      )}
      {sensor && (
        <>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section
            key={"sensor" + sensor.id}
            content={sensor.name}
          />
        </>
      )}
    </Breadcrumb>
  );
}

export default MachinePath;
