import React from "react";
import { Button, Checkbox, Divider, List, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import NextLoader from "NextLoader";
import AnalysesItem from "AnalysesItem";

import { clearServiceErrors, opts, selectServiceError, } from "slices/serviceErrorsSlice";
import { CAN_LOG_ENTRIES, hasCapability, selectServiceSchema, } from "slices/userDataSlice";
import { selectLastUpdated, } from "slices/hierarchySlice";
// import {
//   selectOngoing as selectHierarchyOngoing,
//   getHierarchy,
// } from "slices/hierarchySlice";
import {
  getMachineLogItems,
  getNextMachineLogItems,
  selectItems as selectMachineLogItems,
  selectNext as selectMachineLogNext,
  selectOngoing as selectMachineLogOngoing,
} from "slices/machineLogSlice";
import { goTo, parseDatetime, parseId } from "utils";
import { logEntryTo, newLogEntryTo } from "appRoutes";
import {
  ADD_LOG_ENTRY_ICON,
  LOG_ENTRY_ANALYSIS_ICON,
  LOG_ENTRY_COMMENT_ICON,
  LOG_ENTRY_DIAGNOSIS_ICON,
  LOG_ENTRY_MAINTENANCE_ICON,
  LOG_ENTRY_OBSERVATION_ICON,
  LOG_ENTRY_UNCATEGORIZED_ICON,
  REFRESH_ICON,
  toIcon,
} from "icons";
import { selectEntities as selectMachineMap } from "./slices/machinesSlice";
import { selectAll as selectSensors } from "./slices/sensorsSlice";

// const HIERARCHY = "MachineLog/hierarchy";
const ITEMS = "MachineLog/items";

const MAX_GROUP_ANALYSES = 5

class MachineLog extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({});
  }

  componentDidMount() {
    this.refresh(true);
  }

  refresh = (reload) => {
    const { /* getHierarchy, */ machine, getMachineLogItems, t } = this.props;

    // getHierarchy(false, opts(HIERARCHY, t("Analyses.hierarchyError"))).catch(
    //   (e) => {
    //     console.error(e);
    //   }
    // );

    getMachineLogItems(
      reload,
      { machine: machine.id },
      opts(ITEMS, t("MachineLog.refreshError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  clearServiceErrors = () => {
    const { serviceError, clearServiceErrors } = this.props;
    if (serviceError) {
      clearServiceErrors(ITEMS);
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refresh(true);
  };

  handleAdd = () => {
    const { machine, location, history } = this.props;
    goTo(newLogEntryTo(machine.id, location), history);
  };

  next = () => {
    const { getNextMachineLogItems, t } = this.props;
    getNextMachineLogItems(opts(ITEMS, t("MachineLog.moreError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  more = () => {
    const { machineLogNext, t } = this.props;
    return machineLogNext
      ? [
        <List.Item
          key={machineLogNext}
          as={NextLoader}
          // loadNext={this.getNextLogItems.bind(this)}
          loadNext={this.next}
          more={<p className="MachineLog__more">{t("MachineLog.more")}</p>}
        />,
      ]
      : [];
  };

  entryGroupIcons = memoizeOne((groups) => {
    const iconMap = {};
    groups["comment"].forEach(
      (e) => (iconMap[e] = toIcon(LOG_ENTRY_COMMENT_ICON))
    );
    groups["observation"].forEach(
      (e) => (iconMap[e] = toIcon(LOG_ENTRY_OBSERVATION_ICON))
    );
    groups["diagnosis"].forEach(
      (e) => (iconMap[e] = toIcon(LOG_ENTRY_DIAGNOSIS_ICON))
    );
    groups["maintenance"].forEach(
      (e) => (iconMap[e] = toIcon(LOG_ENTRY_MAINTENANCE_ICON))
    );
    groups["uncategorized"].forEach(
      (e) => (iconMap[e] = toIcon(LOG_ENTRY_UNCATEGORIZED_ICON))
    );
    return iconMap;
  });

  logEntryIcon = (e) => {
    const { entryGroups } = this.props;
    return this.entryGroupIcons(entryGroups)[e.category];
  };

  listId = (type, id) => `${type}-${id}`;

  logEntry = (id, e, location, t) => (
    <List.Item
      key={this.listId("entry", id)}
      as={Link}
      to={logEntryTo(e.id, location)}
      data-cy="LogEntry"
    >
      {this.logEntryIcon(e)}
      <List.Content>
        <List.Header>{e.category}</List.Header>
        <List.Description>
          {t("MachineLog.timestamp", {
            timestamp: parseDatetime(e.measured_at),
          })}
        </List.Description>
      </List.Content>
    </List.Item>
  );

  analysis = (id, a, _location, _t) => (
    <List.Item
      key={this.listId("analysis", id)}
      as={AnalysesItem}
      analysis={a}
      diagnoses={a.diagnoses}
      itemicon={toIcon(LOG_ENTRY_ANALYSIS_ICON)}
    />
  );

  toggleSensorFilter = (_, { name }) => {
    const sensorFilter = this.state.sensorFilter
    const newFilter = sensorFilter?.includes(name)
      ? sensorFilter.filter(it => it !== name) : [...(sensorFilter || []), name]
    this.setState({ sensorFilter: newFilter })
  }

  items = memoizeOne(
    (logItems, _machineLogNext, _hierarchyLastUpdated, _language) => {
      const { itemGroups, location, t } = this.props;
      const itemMap = {
        [itemGroups["log_entry"]]: this.logEntry,
        [itemGroups["analysis"]]: this.analysis,
      };

      return logItems
        .map((i) => itemMap[i.type](i.id, i.object, location, t))
        .concat(this.more());
    }
  );

  render() {
    const {
      serviceError,
      // getHierarchyOngoing,
      getMachineLogOngoing,
      logItems: allLogItems,
      machine,
      machineLogNext,
      canLog,
      hierarchyLastUpdated,
      i18n: { language },
      t,
    } = this.props;


    const loading = /* getHierarchyOngoing || */ getMachineLogOngoing;
    const { sensorFilter } = this.state;

    const filteredItems = allLogItems.filter(({ object: analysis }) => {
      if (!sensorFilter?.length) return true
      return sensorFilter.includes(String(analysis.sensor))
    })

    const items = this.items(
      filteredItems,
      machineLogNext,
      hierarchyLastUpdated,
      language
    );

    const analysesIds = filteredItems
      .map(it => it.object.id)
      .slice(0, MAX_GROUP_ANALYSES)
      .reverse()
      .join(',')

    return (
      <Segment
        as="div"
        className="MachineLog"
        loading={loading}
        data-cy="MachineLog"
      >
        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}
        <b>{machine?.name}</b>
        <div className="MachineLog__tools">
          <Button
            type="button"
            className="MachineLog__tool"
            icon
            onClick={this.handleRefresh}
            compact
            basic
            data-cy="MachineLog__refresh"
          >
            {toIcon(REFRESH_ICON)}
          </Button>
          {canLog && (
            <Button
              className="MachineLog__tool"
              type="button"
              icon={toIcon(ADD_LOG_ENTRY_ICON)}
              basic
              compact
              circular
              onClick={this.handleAdd}
              data-cy="MachineLog__add"
            />
          )}
        </div>
        <div className="MachineLog__sensor-filters">
          {this.props.machine.sensors
            .map(id => this.props.sensors.find(it => it.id === id))
            .map(sensor => {
              return (
                <Checkbox
                  className="MachineLog__sensor-filter"
                  key={sensor.name}
                  label={sensor.name}
                  name={String(sensor.id)}
                  onClick={this.toggleSensorFilter}
                />
              )
            })
          }
          <a href={`/group-analyses?analyses=${analysesIds}`}>View</a>
        </div>

        {items.length > 0 ? (
          <List celled>
            {items}
          </List>
        ) : (
          <p>{t("MachineLog.empty")}</p>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { machine, match } = ownProps;

  const machineId = machine?.id || parseId(match?.params?.id);

  return {
    serviceError: selectServiceError(state, ITEMS),
    // getHierarchyOngoing: selectHierarchyOngoing(state, "getHierarchy"),
    getMachineLogOngoing: selectMachineLogOngoing(state, "getMachineLogItems"),
    machineLogNext: selectMachineLogNext(state),
    machine: selectMachineMap(state)[machineId],
    logItems: selectMachineLogItems(state, machineId),
    canLog: hasCapability(state, CAN_LOG_ENTRIES),
    itemGroups: selectServiceSchema(state)["log_item_groups"],
    entryGroups: selectServiceSchema(state)["log_entry_groups"],
    hierarchyLastUpdated: selectLastUpdated(state),
    sensors: selectSensors(state),
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  // getHierarchy,
  getMachineLogItems,
  getNextMachineLogItems,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MachineLog))
);
