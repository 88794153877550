import { useEffect, useState } from "react";

const STORAGE_KEY = "flagged-items";

const setFlaggedItems = (newSelected) => window.localStorage.setItem(STORAGE_KEY, JSON.stringify(newSelected))

// TODO: storage is never cleared / does not expire

export const loadFlaggedItems = () => {
  try {
    const res = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
    if (Array.isArray(res)) {
      return res
    }
  } catch (err) {
    console.error(err)
  }
  return []
}

export function useFlaggedItem(id) {

  const [flagged, setFlagged] = useState(() => loadFlaggedItems().includes(id))

  useEffect(() => {
    const items = loadFlaggedItems();
    if (!flagged) {
      setFlaggedItems(items.filter(it => it !== id))
    } else if (!items.includes(id)) {
      setFlaggedItems([...items, id])
    }
  }, [flagged, id])

  return [flagged, setFlagged]
}
