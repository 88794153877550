import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

function Crubs({ breadcrumbs }) {
  return (
    <Breadcrumb>
      {breadcrumbs
        .map(({ breadcrumb, component, location, match }) => {
          return (
            <Breadcrumb.Section
              as={
                component && match.url !== location.pathname ? NavLink : "div"
              }
              to={match.url}
              key={breadcrumb.key}
              active={match.url === location.pathname}
            >
              {breadcrumb}
            </Breadcrumb.Section>
          );
        })
        .reduce((prev, curr, index) => [
          prev,
          <Breadcrumb.Divider key={index} />,
          curr,
        ])}
    </Breadcrumb>
  );
}

export default function createCrubs(routes) {
  return withBreadcrumbs([...routes])(Crubs);
}
