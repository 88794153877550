import React, { Component } from "react";
import { List, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import memoizeOne from "memoize-one";

import { hasIncompleteErrors } from "slices/serviceErrorsSlice";
import { INCOMPLETE_ICON, toIcon } from "icons";

class InventoryList extends Component {
  itemsMO = memoizeOne((items, icon, selectTo) =>
    items.map((i) => (
      <List.Item key={i.id} data-cy="InventoryList__item">
        {icon}
        <List.Content>
          <NavLink to={selectTo(i.id)}>{i.name}</NavLink>
          {hasIncompleteErrors(i) &&
            toIcon(INCOMPLETE_ICON, {
              className: "InventoryList__incomplete",
            })}
        </List.Content>
      </List.Item>
    ))
  );

  render() {
    const {
      items,
      icon,
      onRefresh,
      refreshText,
      onAdd,
      addText,
      selectTo,
    } = this.props;
    const itemsMO = this.itemsMO(items, icon, selectTo);

    return (
      <>
        <List>{itemsMO}</List>
        <div className="InventoryList__buttons">
          <Button
            positive
            onClick={onAdd}
            disabled={!onAdd}
            data-cy="InventoryList__add"
          >
            {addText}
          </Button>
          <Button onClick={onRefresh} data-cy="InventoryList__refresh">
            {refreshText}
          </Button>
        </div>
      </>
    );
  }
}

export default InventoryList;
