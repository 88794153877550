import React from "react";
import { List } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import {
  selectServiceSchema,
  selectTranslation,
  schemaKey,
  translationKey,
} from "slices/userDataSlice";
import { toIcon, SELECT_ICON, SELECTED_ICON, CIRCLE_ICON } from "icons";
import { categoryColor } from "./DiagnosisFilter";

const filters = memoizeOne((schema, translation, t) => {
  const diagnosed = schemaKey(schema, "diagnosis_categories");
  return [...diagnosed, undefined].map((c) => {
    return {
      key: c || 0,
      text: (
        <span className="StatusFilter">
          {toIcon([{ ...CIRCLE_ICON[0], color: categoryColor(c), size: 'small' }])}
          {c ? translationKey(translation, "diagnosis_categories")[c] : t("StatusFilter.unknown")}
        </span>
      ),
      value: c,
    };
  });
});

const filterItems = memoizeOne((excluded, onExclude, schema, translation, t) =>
  filters(schema, translation, t).map((f) => {
    const selected = !excluded[f.value];
    return (
      <List.Item key={f.key} as="a" active={selected} value={f.value} onClick={onExclude}>
        {toIcon(selected ? SELECTED_ICON : SELECT_ICON)}
        <List.Content>
          <List.Header>
            {f.text}
          </List.Header>
        </List.Content>
      </List.Item>
    );
  })
);

function StatusFilter({ excluded, onExclude }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const schema = useSelector((state) => selectServiceSchema(state));
  const translation = useSelector((state) =>
    selectTranslation(state, language)
  );

  const items = filterItems(excluded, onExclude, schema, translation, t);

  return (
    <List className="DiagnosisFilter">
      {items}
    </List>
  );
}

export default StatusFilter;
