import React from "react";
import { useSelector } from "react-redux";

import UserHome from "UserHome";
import TechnicianHome from "TechnicianHome";
import { CAN_ACCESS_TECHNICIAN_UI, hasCapability } from "slices/userDataSlice";

function Home() {
  const state = useSelector((state) => state);
  const technicianUI = hasCapability(state, CAN_ACCESS_TECHNICIAN_UI);
  return technicianUI ? <TechnicianHome /> : <UserHome />;
}

export default Home;
