import Home from "Home";
import UserProfile from "UserProfile";
import Inventory from "Inventory";
import Location from "Location";
import MachineGroup from "MachineGroup";
import Machine from "Machine";
import Sensor from "Sensor";
import { SensorCustomReference, MachineCustomReference } from "CustomReference";
import SensorGroup from "SensorGroup";
import Hierarchy from "Hierarchy";
import Task from "Task";
import HierarchyImporter from "HierarchyImporter";
import DataImporter from "DataImporter";
import Analyses from "Analyses";
import Analysis from "Analysis";
import MachineLog from "MachineLog";
import LogEntry from "LogEntry";
import GroupAnalyses from "./GroupAnalyses";

import { getTo } from "utils";
import { HOME_ICON, CHART_ICON, USER_ICON } from "./icons";

export const HOME = {
  key: "Home",
  icon: HOME_ICON,
  path: "/",
  exact: true,
  component: Home,
};

export const USER_PROFILE = {
  key: "UserProfile",
  icon: USER_ICON,
  path: "/user-profile",
  exact: true,
  component: UserProfile,
};

export const LOCATIONS = {
  key: "Locations",
  path: "/locations",
  exact: true,
  component: Inventory,
};

export const NEW_LOCATION = {
  key: "NewLocation",
  path: "/locations/new",
  exact: true,
  component: Location,
};

export function newRootLocationTo(location) {
  return getTo(NEW_LOCATION.path, {}, location);
}

export function newLocationTo(parentId, location) {
  const params = new URLSearchParams({ parent: parentId });
  return getTo(`${NEW_LOCATION.path}/?${params}`, {}, location);
}

export const LOCATION = {
  key: "Location",
  path: "/locations/:id(\\d+)",
  exact: true,
  component: Location,
};

export const GROUP_ANALYSES = {
  key: "GroupAnalyses",
  path: "/group-analyses",
  exact: true,
  component: GroupAnalyses,
}

export function locationTo(locationId, location) {
  return getTo(LOCATION.path.replace(":id(\\d+)", locationId), {}, location);
}

export const MACHINE_GROUPS = {
  key: "MachineGroups",
  path: "/machine-groups",
  exact: true,
  component: Inventory,
};

export const NEW_MACHINE_GROUP = {
  key: "NewMachineGroup",
  path: "/machine-groups/new",
  exact: true,
  component: MachineGroup,
};

export const MACHINE_GROUP = {
  key: "MachineGroup",
  path: "/machine-groups/:id(\\d+)",
  exact: true,
  component: MachineGroup,
};

export function newMachineGroupTo(locationId, location) {
  const params = new URLSearchParams({ location: locationId });
  return getTo(`${NEW_MACHINE_GROUP.path}/?${params}`, {}, location);
}

export function machineGroupTo(machineGroupId, location) {
  return getTo(
    MACHINE_GROUP.path.replace(":id(\\d+)", machineGroupId),
    {},
    location
  );
}

export const MACHINES = {
  key: "Machines",
  path: "/machines",
  exact: true,
  component: Inventory,
};

export const NEW_MACHINE = {
  key: "NewMachine",
  path: "/machines/new",
  exact: true,
  component: Machine,
};

export const MACHINE = {
  key: "Machine",
  path: "/machines/:id(\\d+)",
  exact: true,
  component: Machine,
};

export function newMachineTo(machineGroupId, location) {
  const params = new URLSearchParams({ machineGroup: machineGroupId });
  return getTo(`${NEW_MACHINE.path}/?${params}`, {}, location);
}

export function machineTo(machineId, location) {
  return getTo(MACHINE.path.replace(":id(\\d+)", machineId), {}, location);
}

export const SENSORS = {
  key: "Sensors",
  path: "/sensors",
  exact: true,
  component: Inventory,
};

export const NEW_SENSOR = {
  key: "NewSensor",
  path: "/sensors/new",
  exact: true,
  component: Sensor,
};

export const SENSOR = {
  key: "Sensor",
  path: "/sensors/:id(\\d+)",
  exact: true,
  component: Sensor,
};

export function newSensorTo(machineId, location) {
  const params = new URLSearchParams({ machine: machineId });
  return getTo(`${NEW_SENSOR.path}/?${params}`, {}, location);
}

export function sensorTo(sensorId, location) {
  return getTo(SENSOR.path.replace(":id(\\d+)", sensorId), {}, location);
}

export const CUSTOM_REFERENCES = {
  key: "CustomReferences",
  path: "/custom-references",
  exact: true,
  component: Inventory,
};

export const NEW_CUSTOM_REFERENCE = {
  key: "NewCustomReference",
  path: "/custom-references/new",
  exact: true,
  component: SensorCustomReference,
};

export const CUSTOM_REFERENCE = {
  key: "CustomReference",
  path: "/custom-references/:id(\\d+)",
  exact: true,
  component: SensorCustomReference,
};

export function newCustomReferenceTo(location) {
  return getTo(NEW_CUSTOM_REFERENCE.path, {}, location);
}

export function customReferenceTo(customReferenceId, location) {
  return getTo(
    CUSTOM_REFERENCE.path.replace(":id(\\d+)", customReferenceId),
    {},
    location
  );
}

export const MACHINE_CUSTOM_REFERENCES = {
  key: "MachineCustomReferences",
  path: "/machine-custom-references",
  exact: true,
  component: Inventory,
};

export const NEW_MACHINE_CUSTOM_REFERENCE = {
  key: "NewMachineCustomReference",
  path: "/machine-custom-references/new",
  exact: true,
  component: MachineCustomReference,
};

export const MACHINE_CUSTOM_REFERENCE = {
  key: "MachineCustomReference",
  path: "/machine-custom-references/:id(\\d+)",
  exact: true,
  component: MachineCustomReference,
};

export function newMachineCustomReferenceTo(location) {
  return getTo(NEW_MACHINE_CUSTOM_REFERENCE.path, {}, location);
}

export function machineCustomReferenceTo(customReferenceId, location) {
  return getTo(
    MACHINE_CUSTOM_REFERENCE.path.replace(":id(\\d+)", customReferenceId),
    {},
    location
  );
}

export const SENSOR_GROUPS = {
  key: "SensorGroups",
  path: "/sensor-groups",
  exact: true,
  component: Inventory,
};

export const NEW_SENSOR_GROUP = {
  key: "NewSensorGroup",
  path: "/sensor-groups/new",
  exact: true,
  component: SensorGroup,
};

export function newSensorGroupTo(location) {
  return getTo(NEW_SENSOR_GROUP.path, {}, location);
}

export const SENSOR_GROUP = {
  key: "SensorGroup",
  path: "/sensor-groups/:id(\\d+)",
  exact: true,
  component: SensorGroup,
};

export function sensorGroupTo(sensorGroupId, location) {
  return getTo(
    SENSOR_GROUP.path.replace(":id(\\d+)", sensorGroupId),
    {},
    location
  );
}

export function sensorGroupsTo(location) {
  return getTo(SENSOR_GROUPS.path, {}, location);
}

export const HIERARCHY = {
  key: "Hierarchy",
  icon: CHART_ICON,
  path: "/hierarchy",
  exact: true,
  component: Hierarchy,
};

export const TASKS = {
  key: "Tasks",
  path: "/tasks",
  exact: true,
  component: Inventory,
};

export function tasksTo(location) {
  return getTo(TASKS.path, {}, location);
}

export const NEW_HIERARCHY = {
  key: "NewHierarchy",
  path: "/tasks/new-hierarchy",
  exact: true,
  component: HierarchyImporter,
};

export function newHierarchyTo(location) {
  return getTo(NEW_HIERARCHY.path, {}, location);
}

export const NEW_ANALYSIS = {
  key: "NewAnalysis",
  path: "/tasks/new-analysis",
  exact: true,
  component: DataImporter,
};

export function newAnalysisTo(location) {
  return getTo(NEW_ANALYSIS.path, {}, location);
}

export const TASK = {
  key: "Task",
  path: "/tasks/:id(\\d+)",
  exact: true,
  component: Task,
};

export function taskTo(taskId, location) {
  return getTo(TASK.path.replace(":id(\\d+)", taskId), {}, location);
}

export const LABELS = {
  key: "Labels",
  path: "/labels",
  exact: true,
  component: Inventory,
};

export function labelsTo(location) {
  return getTo(LABELS.path, {}, location);
}

export const ANALYSES = {
  key: "Analyses",
  path: "/analyses",
  exact: true,
  component: Analyses,
};

export function analysesTo(filter, location) {
  const params = new URLSearchParams(filter);
  return getTo(`${ANALYSES.path}/?${params}`, {}, location);
}

// export const DIAGNOSIS_GROUPS = {
//   key: "DiagnosisGroups",
//   path: "/diagnosis-groups",
// };

export const ANALYSIS = {
  key: "Analysis",
  path: "/analysis/:id(\\d+)",
  exact: true,
  component: Analysis,
};

export function analysisTo(analysisId, location) {
  return getTo(ANALYSIS.path.replace(":id(\\d+)", analysisId), {}, location);
}

export const MACHINE_LOG = {
  key: "MachineLog",
  path: "/machine-log/:id(\\d+)",
  exact: true,
  component: MachineLog,
};

export function machineLogTo(machineLogId, location) {
  return getTo(
    MACHINE_LOG.path.replace(":id(\\d+)", machineLogId),
    {},
    location
  );
}

export const NEW_LOG_ENTRY = {
  key: "NewLogEntry",
  path: "/log-entries/new",
  exact: true,
  component: LogEntry,
};

export function newLogEntryTo(machineId, location) {
  const params = new URLSearchParams({ machine: machineId });
  return getTo(`${NEW_LOG_ENTRY.path}/?${params}`, {}, location);
}

export const LOG_ENTRY = {
  key: "LogEntry",
  path: "/log-entries/:id(\\d+)",
  exact: true,
  component: LogEntry,
};

export function logEntryTo(logEntryId, location) {
  return getTo(LOG_ENTRY.path.replace(":id(\\d+)", logEntryId), {}, location);
}
