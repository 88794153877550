import React from "react";
import { Button, Form, Header, Icon, Input, List, Progress, Segment, Grid, Table, Label } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

import AdaComponent from "AdaComponent";
import NextLoader from "NextLoader";
import Prompt from "Prompt";

import { clearServiceErrors, opts, selectServiceError, } from "slices/serviceErrorsSlice";
import { selectToken } from "slices/tokenSlice";
import {
  CAN_ACCESS_EXTENDED_UI,
  CAN_ACCESS_TASK_FILE,
  CAN_MANAGE_TASKS,
  hasCapability,
  hasTaskFinished,
  hasTaskFinishedOk,
  isDataTask,
  isTaskRunning,
  schemaKey,
  selectDataFileFormats,
  selectServiceSchema,
  selectTranslation,
  selectUserInfo,
  translationKey,
} from "slices/userDataSlice";
import {
  getNextSensorGroups,
  getSensorGroups,
  selectAll as selectAllSensorGroups,
  selectNext as selectSensorGroupsNext,
  selectOngoing as selectSensorGroupsOngoing,
} from "slices/sensorGroupsSlice";
import {
  addTask,
  archiveTasks,
  getNextTasks,
  getOwnTasks,
  getTasks,
  getUpdatedOwnTasks,
  getUpdatedTasks,
  selectAll as selectTasks,
  selectEntities as selectTasksMap,
  selectTasksNext,
  selectTasksOngoing,
} from "slices/tasksSlice";
import { createTaskReport, selectReportOngoing } from "slices/reportSlice";

import { analysesTo, taskTo } from "appRoutes";
import { goTo, numberToPercentage, parseDatetime, toggleSet } from "utils";
import {
  ANALYSES_ICON,
  ARCHIVE_ICON,
  RELOAD_ICON,
  REPORT_ICON,
  RUNNING_ICON,
  SEARCH_ICON,
  SELECT_ICON,
  SELECTED_ICON,
  taskIcon,
  toIcon,
} from "icons";

const FIRST_UPDATE = 5000;
const UPDATE_INTERVAL = 20 * 1000;

const SENSOR_GROUPS = "UserHome/sensorGroups";
const TASKS = "UserHome/tasks";
const REPORT = "UserHome/report";

const DISABLE_EXTRA_REPORT_ACTIONS = true

class UserHome extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      ...this.initialEditState(),
      selected: new Set(),
      promptArchive: false,
    });
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.refreshTasks();
    this.intervalID = setInterval(this.tick, UPDATE_INTERVAL);
  }

  onSensorGroupsOpen = () => {
    const { getSensorGroups, t } = this.props;
    getSensorGroups(opts(SENSOR_GROUPS, t("UserHome.sensorGroupsError"))).catch(
      (e) => {
        console.error(e);
      }
    );
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearTimeout(this.timerID);
  }

  initialEditState = () => {
    const { fileFormats, userInfo, t } = this.props;
    const fileFormat = 'Vibscanner2 ADA';

    if (this.fileInputRef && this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }

    return {
      files: [],
      fileFormat,
      sensorGroup: undefined,
      label: t("UserHome.defaultLabel", {
        now: new Date(),
        userInfo,
      }),
    };
  };

  firstUpdate = () => {
    setTimeout(this.refreshTasks, 500)
    if (!this.timerID) {
      this.timerID = setTimeout(() => this.tick(), FIRST_UPDATE);
    }
  };

  refreshTasks = () => {
    const { extendedUI, getOwnTasks, getTasks, t } = this.props;
    if (extendedUI) {
      getTasks(opts(TASKS, t("UserHome.tasksError"))).catch((e) => {
        console.error(e);
      });
    } else {
      getOwnTasks(opts(TASKS, t("UserHome.tasksError"))).catch((e) => {
        console.error(e);
      });
    }
  };

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(SENSOR_GROUPS, TASKS, REPORT);
    }
  };

  tick = () => {
    const { extendedUI, getUpdatedOwnTasks, getUpdatedTasks } = this.props;
    if (extendedUI) {
      getUpdatedTasks().catch((e) => {
        console.error(e);
      });
    } else {
      getUpdatedOwnTasks().catch((e) => {
        console.error(e);
      });
    }
  };

  handleRefresh = () => {
    this.clearErrors();
    this.clearServiceErrors();
    this.refreshTasks();
  };

  handleFileInputClicked = () => {
    this.fileInputRef.current.click();
  };

  handleChangeFiles = (e) => {
    const { files } = e.target;
    this.clearError("files");
    this.clearServiceErrors();
    this.setState({
      files: Array.from(files?.length > 0 ? files : []),
    });
  };

  handleChange = (_, { name, value }) => {
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value });
  };

  handleSelectTask = (_, { value }) => {
    this.setState((state) => ({
      selected: toggleSet(value, state.selected),
    }));
  };

  handleSubmit = async () => {
    window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const { addTask, t } = this.props;
    const { files, fileFormat, sensorGroup, label } = this.state;

    this.fileInputRef.current.value = "";
    this.setState({
      files: [],
    });

    addTask(
      {
        data_file_format: fileFormat,
        sensor_group: sensorGroup,
        label,
      },
      files,
      opts(TASKS, t("UserHome.analyzeError"))
    )
      .then(this.firstUpdate)
      .catch((e) => {
        console.error(e);
      });
  };

  handleArchive = () => this.setState({ promptArchive: true });
  cancelArchive = () => this.setState({ promptArchive: false });

  okArchive = () => {
    const { archiveTasks, t } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    this.setState({ selected: new Set(), promptArchive: false });
    archiveTasks(tasks, opts(TASKS, t("UserHome.archiveError"))).catch((e) => {
      console.error(e);
    });
  };

  handleAnalyses = async () => {
    const { location, history } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    goTo(analysesTo({ tasks }, location), history);
  };

  fileFormatOptions = memoizeOne((fileFormats, translation) =>
    fileFormats.map((f) => ({
      key: f,
      value: f,
      text: translationKey(translation, "task_data_file_formats")[f],
    }))
  );

  canAnalyze = () => {
    const { files, fileFormat, label } = this.state;
    return !!(
      files &&
      files.length > 0 &&
      fileFormat &&
      label &&
      label.length > 0
    );
  };

  createReport = async () => {
    const { createTaskReport, extendedUI, t } = this.props;
    const { selected } = this.state;
    const tasks = Array.from(selected.values());
    this.setState({ selected: new Set() });
    const reportDetail = extendedUI ? "advanced" : "basic";
    createTaskReport(
      tasks,
      reportDetail,
      opts(REPORT, t("UserHome.reportError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  getNextSensorGroups = () => {
    const { getNextSensorGroups, t } = this.props;
    getNextSensorGroups(
      opts(SENSOR_GROUPS, t("UserHome.sensorGroupsError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  moreSensorGroups = () => {
    const { serviceError, sensorGroupsNext, t } = this.props;
    return sensorGroupsNext
      ? [
        serviceError ? (
          <List.Item key={0}>
            <p className="UserHome__more_sensor_groups">
              {t("UserHome.moreError")}
            </p>
          </List.Item>
        ) : (
          {
            key: sensorGroupsNext,
            as: NextLoader,
            loadNext: this.getNextSensorGroups.bind(this),
            more: (
              <p className="UserHome__more_sensor_groups">
                {t("UserHome.moreSensorGroups")}
              </p>
            ),
            triggerOnce: true,
          }
        ),
      ]
      : [];
  };

  sensorGroupOptions = memoizeOne(
    (sensorGroups, _sensorGroupsNext, _serviceError) =>
      sensorGroups
        .map((g) => ({
          key: g.id,
          value: g.id,
          text: g.name,
          "data-cy": "UserHome__sensor_group",
        }))
        .concat(this.moreSensorGroups())
  );

  taskStatus = (task) => {
    const { schema, t } = this.props;
    if (isTaskRunning(task, schema)) {
      return (
        <div>
          <Progress active percent={numberToPercentage(task.progress)} size="tiny" color="green">
            {toIcon(RUNNING_ICON)}
            {t("UserHome.taskProgress", {
              progress: numberToPercentage(task.progress),
            })}
          </Progress>
        </div>
      );
    } else {
      return (
        <i className="icon UserHome__task_status">
          {taskIcon(task, schemaKey(schema, "task_status_groups"))}
        </i>
      );
    }
  };

  getNextTasks = () => {
    const { getNextTasks, t } = this.props;
    getNextTasks(opts(TASKS, t("UserHome.tasksError"))).catch((e) => {
      console.error(e);
    });
  };

  moreTasks = () => {
    const { serviceError, tasksNext, t } = this.props;
    return tasksNext
      ? [
        serviceError ? (
          <Table.Row key="moreError">
            <Table.Cell>
              <p className="UserHome__more_tasks">{t("UserHome.moreError")}</p>
            </Table.Cell>
          </Table.Row>
        ) : (
          <Table.Row key="moreTasks">
            <Table.Cell>
              <List.Item
                key={tasksNext}
                as={NextLoader}
                loadNext={this.getNextTasks.bind(this)}
                more={
                  <p className="UserHome__more_tasks">
                    {t("UserHome.moreTasks")}
                  </p>
                }
                triggerOnce={true}
              />
            </Table.Cell>
          </Table.Row>
        ),
      ]
      : [];
  }
  ;

  taskItems = memoizeOne(
    (
      tasks,
      selected,
      canAccessTaskFile,
      location,
      _tasksNext,
      _serviceError,
      _language,
      taskSearch
    ) => {
      const {
        schema
        ,
        t
      }

        = this.props;
      return tasks
        .filter((task) => !task.archived && isDataTask(task, schema))
        .filter(task => {
          const taskText = (task.label + task.status + t("UserHome.report", { created_at: parseDatetime(task.created_at), }))?.toLowerCase()
          return !taskSearch || taskSearch?.toLowerCase()?.split(" ").every(it => taskText.includes(it))
        })
        .map((task) => (
          <Table.Row
            className="UserHome__task"
            key={task.id}
            value={String(task.id)}
            data-cy="UserHome__task"
          >
            <Table.Cell className="UserHome__status_cell">
              {this.taskStatus(task)}
            </Table.Cell>
            <Table.Cell>
              <span>
                {canAccessTaskFile ? (
                  <NavLink to={taskTo(task.id, location)}>
                    {task.label || t("UserHome.noName", task)}
                  </NavLink>
                ) : (
                  task.label
                )}
              </span>
            </Table.Cell>
            <Table.Cell>
              {t("UserHome.report", { created_at: parseDatetime(task.created_at), })}
            </Table.Cell>
            <Table.Cell className="UserHome__select_cell">
              {hasTaskFinished(task, schema) && (
                <Button
                  className="UserHome__select_button"
                  icon
                  onClick={this.handleSelectTask}
                  compact
                  basic
                  value={task.id}
                  data-cy="UserHome__select"
                >
                  {toIcon(selected.has(task.id) ? SELECTED_ICON : SELECT_ICON)}
                </Button>
              )}
            </Table.Cell>
            {isTaskRunning(task, schema) && (<hr/>)}
          </Table.Row>
        ))
        .concat(this.moreTasks());
    }
  );

  selectedTasksOk = () => {
    const { schema, tasksMap } = this.props;
    const { selected } = this.state;
    return [...selected].every((t) => hasTaskFinishedOk(tasksMap[t], schema));
  }
  ;

  filesLabel = (files, _language) => {
    const { t } = this.props;
    if (files.length === 1) {
      return t("UserHome.selectedFile", { name: files[0].name });
    } else {
      return t("UserHome.selectedFiles", {
        name: files[0].name,
        count: files.length - 1,
      });
    }
  }
  ;

  render() {
    const {
      translation,
      getSensorGroupsOngoing,
      getTasksOngoing,
      addTaskOngoing,
      archiveTasksOngoing,
      createReportOngoing,
      serviceError,
      fileFormats,
      sensorGroups,
      sensorGroupsNext,
      tasks,
      tasksNext,
      extendedUI,
      canArchive,
      canAccessTaskFile,
      location,
      i18n: { language },
      t,
    } = this.props;
    const {
      files,
      fileFormat,
      sensorGroup,
      label,
      selected,
      promptArchive,
      taskSearch,
    } = this.state;

    const taskItems = this.taskItems(
      tasks,
      selected,
      canAccessTaskFile,
      location,
      tasksNext,
      serviceError,
      language,
      taskSearch
    );
    const fileFormatOptions = this.fileFormatOptions(fileFormats, translation);
    const sensorGroupOptions = this.sensorGroupOptions(
      sensorGroups,
      sensorGroupsNext,
      serviceError
    );

    const loading1 = addTaskOngoing;
    const loading2 = getTasksOngoing || archiveTasksOngoing;

    const hasFiles = files.length > 0;
    const filesLabel = hasFiles && this.filesLabel(files, language);
    const canAnalyze = this.canAnalyze();
    const someSelected = selected.size > 0;
    const selectedTasksOk = someSelected && this.selectedTasksOk();
    const canReport = selectedTasksOk;
    const canAccessAnalyses = selectedTasksOk;
    const canArchiveTasks = canArchive && someSelected;

    return (
      <Segment
        as="div"
        className="UserHome"
        basic
        loading={createReportOngoing}
        data-cy={extendedUI ? "AdvancedUserHome" : "UserHome"}
      >
        <Prompt
          open={promptArchive}
          header={t("UserHome.archivePrompt.header")}
          content={t("UserHome.archivePrompt.content")}
          okText={t("UserHome.archivePrompt.ok")}
          cancelText={t("UserHome.archivePrompt.cancel")}
          onOk={this.okArchive}
          onCancel={this.cancelArchive}
        />
        <Grid columns={2} doubling>
          <Grid.Column width={4}>
            <Segment className="UserHome__segment" basic loading={loading1}>
              <Header as="h3" className="UserHome__title">
                {t("UserHome.title")}
              </Header>
              {this.ErrorMessage()}
              {this.ServiceErrorMessage(serviceError, language)}
              <Form onSubmit={this.handleSubmit} error={this.hasErrors()}>
                <Form.Group grouped>
                  <Form.Field required error={this.hasError("file")}>
                    <label><b>{t("UserHome.file")}</b> </label>
                    {
                      <div>
                        <label
                          className="UserHome__attachment"
                          data-cy="UserHome__attachment"
                        >
                          {filesLabel || t("UserHome.noFile")}
                        </label>
                      </div>
                    }
                    <Button
                      className="UserHome__selectFilesBtn"
                      type="button"
                      onClick={this.handleFileInputClicked}
                      data-cy="UserHome__attach"
                    >
                      <Icon name="attach"/>
                      {hasFiles
                        ? t("UserHome.changeFiles")
                        : t("UserHome.selectFiles")}
                    </Button>
                    <input
                      ref={this.fileInputRef}
                      type="file"
                      hidden
                      multiple
                      onChange={this.handleChangeFiles}
                      data-cy="UserHome__attachInput"
                    />
                  </Form.Field>
                  <Form.Field required error={this.hasError("label")}>
                    <label>{t("UserHome.label")}</label>
                    <Input
                      name="label"
                      value={label}
                      onChange={this.handleChange}
                      data-cy="UserHome__label"
                    />
                  </Form.Field>
                  {/*
              <Form.Field error={this.hasError("sensorGroup")}>
                <label>{t("UserHome.sensorGroup")}</label>
                <Dropdown
                  name="sensorGroup"
                  loading={getSensorGroupsOngoing}
                  placeholder={t("UserHome.sensorGroupPlaceholder")}
                  fluid
                  search
                  selection
                  clearable
                  icon={"dropdown"}
                  options={sensorGroupOptions}
                  value={sensorGroup}
                  additionLabel={t("UserHome.addSensorGroup")}
                  onOpen={this.onSensorGroupsOpen}
                  onChange={this.handleChange}
                  noResultsMessage={t("UserHome.noSensorGroups")}
                  data-cy="UserHome__sensorGroups"
                />
              </Form.Field>
              */}
                  <div className="UserHome__analyze">
                    <Form.Button
                      type="submit"
                      color="green"
                      disabled={!canAnalyze}
                      data-cy="UserHome__analyze"
                    >
                      {toIcon(REPORT_ICON)}
                      {t("UserHome.analyze")}
                    </Form.Button>
                  </div>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <Segment className="UserHome__segment" loading={loading2}>
              <div className="UserHome__reports">
                <Header as="h4" size="medium" style={{ fontWeight: "bold" }}>
                  {t("UserHome.reports")}
                </Header>
                <span>
              {!!extendedUI && (
                <>
                  {!DISABLE_EXTRA_REPORT_ACTIONS && (
                    <Button
                      type="button"
                      className="UserHome__tool"
                      icon
                      onClick={this.handleArchive}
                      compact
                      basic
                      disabled={!canArchiveTasks}
                      data-cy="UserHome__archive"
                    >
                      {toIcon(ARCHIVE_ICON)}
                    </Button>
                  )}
                  <Button
                    title={t("UserHome.analyses")}
                    type="button"
                    className="UserHome__tool"
                    icon
                    onClick={this.handleAnalyses}
                    disabled={!canAccessAnalyses}
                    data-cy="UserHome__analyses"
                  >
                    {toIcon(ANALYSES_ICON)} {t("UserHome.analyses")}
                  </Button>
                </>
              )}

                  {!DISABLE_EXTRA_REPORT_ACTIONS && (
                    <Button
                      className="UserHome__tool"
                      icon
                      onClick={this.createReport}
                      compact
                      basic
                      disabled={!canReport}
                      data-cy="UserHome__report"
                    >
                      {toIcon(REPORT_ICON)}
                    </Button>
                  )}
                  <Button
                    className="UserHome__tool"
                    icon
                    compact
                    onClick={this.handleRefresh}
                    data-cy="UserHome__refresh"
                  >
                {toIcon(RELOAD_ICON)}
              </Button>
            </span>
              </div>
              <div>
                <Form className="UserHome__search">
                  <Form.Input
                    name="taskSearch"
                    icon={toIcon(SEARCH_ICON)}
                    iconPosition="left"
                    placeholder={t("UserHome.search")}
                    size="small"
                    value={taskSearch || ""}
                    onChange={this.handleChange}
                  />
                </Form>
              </div>
              <div className="UserHome__task_list">
                <Table unstackable basic singleLine className="UserHome__task_list">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="UserHome__status_cell">State</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell className="UserHome__select_cell">Select</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body basic>
                    {taskItems.length === 0 && (
                      <Table.Row>
                        <Table.Cell>
                          <Label>{t("UserHome.noReports")}</Label>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {taskItems}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    const {
      i18n: { language },
    } = ownProps;

    const userInfo = selectUserInfo(state);
    const schema = selectServiceSchema(state);
    const translation = selectTranslation(state, language);

    const defaultFileFormats = schemaKey(schema, "task_data_formats");
    const profileFileFormats = selectDataFileFormats(state);
    const fileFormats = profileFileFormats || defaultFileFormats;

    return {
      serviceError: selectServiceError(state, SENSOR_GROUPS, TASKS, REPORT),
      getSensorGroupsOngoing: selectSensorGroupsOngoing(state, "getSensorGroups"),
      sensorGroupsNext: selectSensorGroupsNext(state),
      getTasksOngoing: selectTasksOngoing(state, "getTasks"),
      addTaskOngoing: selectTasksOngoing(state, "addTask"),
      archiveTasksOngoing: selectTasksOngoing(state, "archiveTasks"),
      removeTasksOngoing: selectTasksOngoing(state, "removeTasks"),
      tasksNext: selectTasksNext(state),
      createReportOngoing: selectReportOngoing(state, "createTaskReport"),
      fileFormats,
      sensorGroups: selectAllSensorGroups(state),
      tasks: selectTasks(state),
      tasksMap: selectTasksMap(state),
      token: selectToken(state),
      extendedUI: hasCapability(state, CAN_ACCESS_EXTENDED_UI),
      canArchive: hasCapability(state, CAN_MANAGE_TASKS),
      canAccessTaskFile: hasCapability(state, CAN_ACCESS_TASK_FILE),
      userInfo,
      schema,
      translation,
    };
  }
;

const mapDispatchToProps =
  {
    clearServiceErrors,
    getSensorGroups,
    getNextSensorGroups,
    getOwnTasks,
    getTasks,
    getNextTasks,
    getUpdatedOwnTasks,
    getUpdatedTasks,
    addTask,
    archiveTasks,
    createTaskReport,
  }
;

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserHome))
);
