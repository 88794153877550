import React from "react";
import { Segment, Table, Header, Form, Button, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import AdaComponent from "AdaComponent";

import {
  opts,
  selectServiceError,
  clearServiceErrors,
} from "slices/serviceErrorsSlice";
import {
  selectUserInfo,
  selectTranslation,
  translationKey,
  selectUserDataOngoing,
  changePassword,
} from "slices/userDataSlice";

const USER_DATA = "UserProfile/userData";

class UserProfile extends AdaComponent {
  constructor(props) {
    super(props);

    this.setInitialState({
      old_password: "",
      new_password: "",
    });
  }

  clearServiceErrors = () => {
    if (this.props.serviceError) {
      this.props.clearServiceErrors(USER_DATA);
    }
  };

  handleChange = (_, { name, value }) => {
    this.clearError(name);
    this.clearServiceErrors();
    this.setState({ [name]: value, success: false });
  };

  handleSubmit = () => {
    // window.scrollTo(0, 0);
    this.clearErrors();
    this.clearServiceErrors();

    const { changePassword, t } = this.props;
    const { old_password, new_password } = this.state;

    this.setState({ old_password: "", new_password: "" });

    changePassword(
      { old_password, new_password },
      opts(USER_DATA, t("UserProfile.changeError"))
    ).catch((e) => {
      console.error(e);
    });
  };

  render() {
    const {
      serviceError,
      changePasswordOngoing,
      language,
      translation,
      username,
      firstName,
      lastName,
      email,
      phoneNumber,
      client,
      groups,
      t,
    } = this.props;
    const { old_password, new_password } = this.state;

    const loading = changePasswordOngoing;

    const groupList = groups
      .map((g) => translationKey(translation, "user_groups")[g])
      .join(", ");

    const canSubmit = old_password?.length > 0 && new_password?.length > 0;

    return (
      <Segment
        as="div"
        className="UserProfile"
        data-cy="UserProfile"
        loading={loading}
      >
        <Table className="UserProfile__table" unstackable striped padded>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{t("UserProfile.username")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__username">
                {username}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("UserProfile.firstName")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__firstName">
                {firstName}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("UserProfile.lastName")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__lastName">
                {lastName}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("UserProfile.email")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__email">{email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t("UserProfile.phoneNumber")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__phoneNumber">
                {phoneNumber}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>{t("UserProfile.client")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__client">
                {client || t("UserProfile.noClient")}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>{t("UserProfile.groups")}</Table.Cell>
              <Table.Cell data-cy="UserProfile__groups">
                {groupList || t("UserProfile.noGroups")}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {this.ErrorMessage()}
        {this.ServiceErrorMessage(serviceError, language)}

        <Divider style={{ marginTop: '30px' }}/>

        <Header as="h5">{t("UserProfile.changePassword")}</Header>

        <Form onSubmit={this.handleSubmit} error={this.hasErrors()} className="UserProfile__changePassword">
          <Form.Input
            error={this.hasError("old_password")}
            name="old_password"
            required
            label={t("UserProfile.oldPassword")}
            type="password"
            value={old_password}
            onChange={this.handleChange}
            data-cy="UserProfile__newPassword"
          />

          <Form.Input
            error={this.hasError("new_password")}
            name="new_password"
            required
            label={t("UserProfile.newPassword")}
            type="password"
            value={new_password}
            onChange={this.handleChange}
            data-cy="UserProfile__newPassword"
          />

          <Button
            className="UserProfile__button"
            type="submit"
            primary
            disabled={!canSubmit}
          >
            {t("UserProfile.submit")}
          </Button>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    i18n: { language },
  } = ownProps;

  const {
    username,
    first_name: firstName,
    last_name: lastName,
    email,
    phone_number: phoneNumber,
    client_name: client,
    groups = [],
  } = selectUserInfo(state);

  const translation = selectTranslation(state, language);

  return {
    serviceError: selectServiceError(state, USER_DATA),
    changePasswordOngoing: selectUserDataOngoing(state, "changePassword"),
    translation,
    username,
    firstName,
    lastName,
    email,
    phoneNumber,
    client,
    groups,
  };
};

const mapDispatchToProps = {
  clearServiceErrors,
  changePassword,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
