import React from "react";
import { useSelector } from "react-redux";
import { Image, Dropdown, Menu } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { selectMenuStyle } from "slices/userDataSlice";
import menuLogo from "images/asensiot_logo_green.svg";
import { MENU_ICON, toIcon } from "icons";
import { useScreenWidth } from "./useScreenWidth";

export function MainMenu({ items }) {

  const menuStyle = useSelector(selectMenuStyle)
  const { isMobile } = useScreenWidth()

  return (
    <div className="MainMenu" style={menuStyle}>
      {isMobile ? <MobileMenu items={items}/> : <DesktopMenu items={items}/>}
    </div>
  )
}

const DesktopMenu = ({ items }) => {
  const { t } = useTranslation();
  return (
    <Menu
      className="MainMenu__nav"
      direction="left"
      data-cy="MainMenu"
    >
      <Link to="/" className="MainMenu__logoContainer">
        <Image
          className="MainMenu__logo"
          src={menuLogo}
          alt={t("MainMenu.altLogo")}
        />
      </Link>
      {items.map((item, i) =>
        item.path ? (
          <Menu.Item
            style={i === 0 ? { marginLeft: 'auto' } : {}}
            className="MainMenu__item"
            key={item.key}
            as={NavLink}
            to={item.path}
            exact
            data-cy={item.key}
          >
            {item.icon && (<span style={{ marginRight: '5px' }}>{toIcon(item.icon)}</span>)}
            {item.title}
          </Menu.Item>
        ) : (
          <Dropdown.Divider key={item.key}/>
        )
      )}
    </Menu>
  )
}

const MobileMenu = ({ items }) => {
  const { t } = useTranslation()
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <Link to="/">
        <Image
          className="MainMenu__logo"
          src={menuLogo}
          alt={t("MainMenu.altLogo")}
        />
      </Link>

      <Dropdown
        className="MainMenu__dropdown"
        icon={toIcon(MENU_ICON)}
        item
        direction="left"
        data-cy="MainMenu"
      >
        <Dropdown.Menu>
          {items.map((item) =>
            item.path ? (
              <Dropdown.Item
                key={item.key}
                as={NavLink}
                to={item.path}
                exact
                data-cy={item.key}
              >
                {item.title}
              </Dropdown.Item>
            ) : (
              <Dropdown.Divider key={item.key}/>
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default MainMenu;
